import React, { Component } from "react";
import { Card, Table, Button, FormControl } from "react-bootstrap";
import key from "../../../config/key.json";
import { toast } from "react-toastify";
import { getFromStorage } from "../../../utils/storage";
import errorConfig from "../../../config/errorConfig.json";
import { withRouter, Link } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { VideoCall, CloudUpload, CloudDownload, FileCopy } from "@material-ui/icons";
import moment from "moment";
import UploadPrescription from "./UploadPrescription";
import axios from "axios";
import api from "../../../config/api.json";
import imageCompression from "browser-image-compression";
import fire from "../../../firebase/fire";
import { getToken, getNotifications } from "../saveToken";
import { connect } from "react-redux";

const color = "#229a88";

class Call extends Component {
  state = {
    isLoading: true,
    show: false,
    base64: "",
    files: [],
    isUploading: false,
    downloadedPrescription: null,
    presignedURL: "",
    fields: "",
    isDownloading: false,
  };

  //Download Prescription
  downloadPrescription = () => {
    if (getFromStorage(key.loginInfoKey).task === "patient") {
      this.setState({ isDownloading: true }, () => {
        const body = {
          task: "getCurrentPrescription",
          appointment_id: this.props.location.pathname.split("/")[
            this.props.location.pathname.split("/").length - 3
          ],
        };
        const headers = { headers: { "Content-Type": "application/json" } };
        axios
          .post(api.PatientApi, body, headers)
          .then((res) => {
            console.log(res.data)
            if (res.data) {
              this.setState({ downloadPrescription: res.data }, () => {
                window.open(res.data);
              });
            } else {
              this.notifyError("Could not download no file");
            }
            this.setState({ isDownloading: false });
          })
          .catch((err) => {
            this.notifyError(err.toString());
            this.setState({ isDownloading: false });
          });
      });
    } else {
      this.notifyError("No file is there to download");
    }
  };

  getPresignedUrl = () => {
    const body = {
      task: "uploadCurrentPrescription",
      appointment_id: this.props.location.pathname.split("/")[
        this.props.location.pathname.split("/").length - 3
      ],
    };
    const headers = { headers: { "Content-Type": "application/json" } };
    axios
      .post(api.DoctorApi, body, headers)
      .then((res) => {
        if (!res.data.errorMessage) {
          this.setState({
            presignedURL: res.data.url,
            fields: res.data.fields,
          });
        }
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.notifyError(err.toString());
        this.setState({ isLoading: false });
      });
  };

  //Upload prescription
  uploadPrescription = () => {
    if (getFromStorage(key.loginInfoKey).task === "doctor") {
      this.setState({ isUploading: true }, () => {
        const formData = new FormData();
        const arr = Object.keys(this.state.fields);

        arr.forEach((key) => {
          formData.append(key, this.state.fields[key]);
        });
        formData.append("file", this.state.files);
        console.log(formData, this.state.presignedURL)
        axios
          .post(this.state.presignedURL, formData)
          .then((res) => {
            if (!res.data.errorMessage) {
              this.notifySuccess("Prescription Uploaded Successfully");
              this.setState({ show: false, files: [], base64: "" });
            } else {
              this.notifyError("Can not upload");
            }
            this.setState({ isUploading: false });
          })
          .catch((err) => {
            this.notifyError(err.toString());
            this.setState({ isUploading: false });
          });
      });
    }
  };

  //Notification Request
  notificationRequest = (token, type, id, notification_id) => {
    const time = this.props.location.pathname.split("/")[
      this.props.location.pathname.split("/").length - 2
    ];

    const headers = {
      "Content-Type": "application/json",
      Authorization:
        "key=AAAAcHXSJak:APA91bFW5NnyfjZx4wYC7soTVuCblryMMawUSVAGezx_E4NVLtB50BF6orjSwvUoTztk4o43JrjGvYM3rx_JgpMErpTPBA6xpe8hHfuyKCSOG9OohBkE2hQNeHMt7mlRHnjwsn12Vr5Z",
    };
    const body = {
      notification: {
        title: "SemDoc",
        body:
          type === "patients"
            ? `Doctor is calling you and waiting for you at appointment id ${
                this.props.location.pathname.split("/")[
                  this.props.location.pathname.split("/").length - 3
                ]
              }`
            : `Patient is calling you and waiting for you at appointment id ${
                this.props.location.pathname.split("/")[
                  this.props.location.pathname.split("/").length - 3
                ]
              }`,
      },
      data: {
        id,
        // notification_id,
        appointment_id: this.props.location.pathname.split("/")[
          this.props.location.pathname.split("/").length - 3
        ],
        date: `${new Date(
          moment(time, "YYYYMMDDhhmmss")
        ).getDate()}&hour=${new Date(
          moment(time, "YYYYMMDDhhmmss").add(60, "minutes")
        ).getHours()}&minute=${new Date(
          moment(time, "YYYYMMDDhhmmss").add(60, "minutes")
        ).getMinutes()}`,
        type,
        role: type === "patients" ? "doctor" : "patient",
        meetingName:  this.props.location.pathname.split("/")[
            this.props.location.pathname.split("/").length - 3
        ],
        patient_id: id,
        doctor_id: getFromStorage("doctor")
      },
      android: {
        priority: "high"
      },
      priority: 10,
      to: token,
    };

    fetch("https://fcm.googleapis.com/fcm/send", {
      method: "POST",
      body: JSON.stringify(body),
      headers,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success === 1) {
        }
      })
      .catch((err) => {
        console.log(err.response);
        this.notifyError(err.toString());
      });
  };

  //Send notification
  sendNotification = async (type, id) => {
    try {
      console.log(type, id)
      let body = { task: "getDoctorDeviceToken", doctor_id: id }
      console.log(body)
      if(type === "doctors") {
        const res = await axios.post(api.PatientApi, body)
        console.log(res.data)
        if(res.data && res.data.length)
          this.notificationRequest(res.data[0].device_token, type, id, new Date().getTime())
      } else {
        console.log(id)
        body = {task: "getPatientDeviceToken", patient_id: id}
        const res = await axios.post(api.DoctorApi, body)
        console.log(res.data)
        if(res.data && res.data.length)
          this.notificationRequest(res.data[0].device_token, type, id, new Date().getTime())
      }
    } catch (err) {
      console.log(err)
    }
  };

  //Set file for upload
  setFile = (data) => {
    this.setState(
      {
        files: data.length > 0 ? data[0].src.file : null,
        base64: data.length > 0 ? data[0].src.base64 : "",
      },
      () => {
        if (this.state.files) {
          const options = {
            maxSizeMB: 0.01,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          };
          imageCompression(this.state.files, options)
            .then((res) => {
              this.setState({ files: res });
            })
            .catch((err) => console.log(err));
        }
      }
    );
  };

  //Success Notification
  notifySuccess = (msg) => {
    toast.success("" + msg.toString());
  };

  //Error Notification
  notifyError = (error) => {
    toast.error("" + error.toString());
  };

  //First function run when page load
  componentDidMount() {
    toast.dismiss();
    if (!getFromStorage(key.loginInfoKey)) {
      this.notifyError(errorConfig.notLoggedInError);
      this.props.history.push("/");
    } else if (
      getFromStorage(key.loginInfoKey).task === "authAdmin" ||
      getFromStorage(key.loginInfoKey).task === "authDiagAdminPanel"
    ) {
      this.props.history.push("/Graph");
    } else if (getFromStorage(key.loginInfoKey).task === "patient") {
      getNotifications(this.props.dispatch, this.props.notifications);
      getToken("patients");
      this.setState({ isLoading: false });
    } else if (getFromStorage(key.loginInfoKey).task === "doctor") {
      getNotifications(this.props.dispatch, this.props.notifications);
      getToken("doctors");
      this.getPresignedUrl();
    } else if (getFromStorage(key.loginInfoKey).task === "provider") {
      this.props.history.push("/provider_doctors");
    }
  }

  render() {
    const time = this.props.location.pathname.split("/")[
      this.props.location.pathname.split("/").length - 2
    ];
    if (this.state.isLoading) {
      return (
        <div
          style={{
            marginLeft: this.props.isOpened ? "160px" : "20px",
            marginTop: "70px",
            textAlign: "center",
          }}
        >
          <CircularProgress />
        </div>
      );
    }
    return (
      <div
        style={{
          marginLeft: this.props.isOpened
            ? window.innerWidth > 560
              ? "160px"
              : "20px"
            : "20px",
          marginTop: "70px",
          marginRight: "20px",
        }}
      >
        <Card
          style={{ padding: "20px", fontSize: "14px" }}
          className="shadow-4"
        >
          <div style={{ overflow: "auto" }}>
            <UploadPrescription
              show={this.state.show}
              base64={this.state.base64}
              setFile={this.setFile}
              isUploading={this.state.isUploading}
              handleClose={() =>
                this.setState({ show: false, files: [], base64: "" })
              }
              uploadPrescription={this.uploadPrescription}
            />
            <table>
              <tbody>
                <tr>
                  <td style={{ fontWeight: "bold" }}>Time:</td>
                  <td>
                    {moment(
                      this.props.location.pathname.split("/")[
                        this.props.location.pathname.split("/").length - 2
                      ],
                      "YYYYMMDDhhmmss"
                    ).format("DD/MM/YYYY hh:mm A")}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold" }}>Call Duration:</td>
                  <td>5 minutes</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold" }}>Cost:</td>
                  <td>32</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold" }}>
                    {getFromStorage("doctor") ?  "Patient:" : "Doctor:"}
                  </td>
                  <td>
                    {
                      this.props.location.pathname.split("/")[
                        this.props.location.pathname.split("/").length - 1
                      ]
                    }
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold" }}>Prescription:</td>
                  <td></td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold" }}>Lab Reports:</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div style={{ textAlign: "center" }}>
              <Link to={getFromStorage("doctor") ? `/digital_prescription/${this.props.location.pathname.split("/")[this.props.location.pathname.split("/").length - 3]}` : `/patient_digital_prescription/${this.props.location.pathname.split("/")[this.props.location.pathname.split("/").length - 3]}`}>
                <Button
                  size="sm"
                  variant="primary"
                  style={{ margin: "5px", width: "170px" }}
                >
                  <FileCopy fontSize="small" />
                  <span style={{ marginLeft: "5px" }}>Digital Prescription</span>
                </Button>
              </Link>
              {/* <Button
                onClick={() => {
                  window.open(`https://semdoc.semion.ai/#/permission`);
                }}
                size="sm"
                variant="success"
                style={{
                  margin: "5px",
                  width: "170px",
                  color: "white",
                  textTransform: "none",
                }}
              >
                <VideoCall fontSize="small" />
                <span style={{ marginLeft: "5px" }}>Test Video Audio</span>
              </Button> */}
              {new Date() >= new Date(moment(time, "YYYYMMDDhhmmss")) &&
                new Date() <=
                  new Date(
                    moment(time, "YYYYMMDDhhmmss").add(60, "minutes")
                  ) && getFromStorage(key.loginInfoKey).task === "doctor" && (
                  <Button
                    onClick={() => {
                      if (getFromStorage(key.loginInfoKey).task === "doctor") {
                        this.sendNotification(
                          "patients",
                          this.props.location.pathname.split("/")[
                            this.props.location.pathname.split("/").length - 1
                          ]
                        );
                      } else {
                        this.sendNotification(
                          "doctors",
                          this.props.location.pathname.split("/")[
                            this.props.location.pathname.split("/").length - 1
                          ]
                        );
                      }
                      const meeting_id = this.props.location.pathname.split("/")[this.props.location.pathname.split("/").length - 3];
                      const user_id = getFromStorage("doctor") ? getFromStorage("doctor") : getFromStorage("patient")
                      
                      window.open(`/#/devices/${meeting_id}/${user_id}`)
                    }}
                    size="sm"
                    variant="success"
                    style={{
                      margin: "5px",
                      width: "170px",
                      color: "white",
                      textTransform: "none",
                    }}
                  >
                    <VideoCall fontSize="small" />
                    <span style={{ marginLeft: "5px" }}>Call Now</span>
                  </Button>
                )} 
              {getFromStorage("doctor") && 
              <Button
                size="sm"
                variant="primary"
                style={{ margin: "5px", width: "170px" }}
                onClick={() => this.setState({ show: true })}
              >
                <CloudUpload fontSize="small" />
                <span style={{ marginLeft: "5px" }}>Upload Prescription</span>
              </Button>}
              {getFromStorage("doctor") && 
                <Link to={`/previous_patient_records/${this.props.location.pathname.split("/")[
                  this.props.location.pathname.split("/").length - 1
                ]}`}>
                  <Button
                    size="sm"
                    variant="primary"
                    style={{ margin: "5px", width: "170px" }}
                  >
                    <CloudUpload fontSize="small" />
                    <span style={{ marginLeft: "5px" }}>View Records</span>
                  </Button>
                </Link>
              }

              <Button
                size="sm"
                variant="danger"
                disabled={this.state.isDownloading}
                style={{ margin: "5px", width: "170px" }}
                onClick={this.downloadPrescription}
              >
                <CloudDownload fontSize="small" />
                <span style={{ marginLeft: "5px" }}>
                  {!this.state.isDownloading ? "Download" : "Downloading..."}
                </span>
              </Button>
            </div>
            <br />
            <h4 style={{ color, textAlign: "center", fontWeight: "bold", fontSize: 22 }}>
              Call History
            </h4>
            <br/>
            <Table striped bordered hover size="sm">
              <thead>
                <tr style={{ color }}>
                  <th>#</th>
                  <th>Call Duration</th>
                  <th>Call Start Time</th>
                  <th>Call End Time</th>
                </tr>
              </thead>
              <tbody></tbody>
            </Table>
            <div style={{ textAlign: "center" }}>No Call History</div>
          </div>
        </Card>
      </div>
    );
  }
}

export default connect((state) => ({
  notifications: state.notifications,
}))(withRouter(Call));
