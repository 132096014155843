import React, {Component} from "react"
import { toast } from "react-toastify";
import key from "../../../config/key.json"
import { getFromStorage } from "../../../utils/storage";
import errorConfig from "../../../config/api.json"
import { Card } from "react-bootstrap";
import ChangePassword from "./ChangePassword";
import { getToken, getNotifications } from "../saveToken";
import { connect } from "react-redux";

class ChangePass extends Component{
    //Error Notification
    notifyError = error => {
        toast.error("" + error.toString());
    };

    //First function run when page load
    componentDidMount() {
        toast.dismiss();
        
        if (!getFromStorage(key.loginInfoKey)) {
            this.notifyError(errorConfig.notLoggedInError);
            this.props.history.push("/");
        } else if (getFromStorage(key.loginInfoKey).task === "authAdmin" || getFromStorage(key.loginInfoKey).task === "authDiagAdminPanel") {
            this.props.history.push("/Graph")
        } else if (getFromStorage(key.loginInfoKey).task === "patient") {
            getNotifications(this.props.dispatch, this.props.notifications)
            getToken("patients")
        } else if (getFromStorage(key.loginInfoKey).task === "doctor") {
            getNotifications(this.props.dispatch, this.props.notifications)
            getToken("doctors")
        } else if (getFromStorage(key.loginInfoKey).task === "provider") {
            this.props.history.push("/provider_doctors")
        }
    }
    render(){
        if (getFromStorage(key.loginInfoKey).task === "patient" || getFromStorage(key.loginInfoKey).task === "doctor") {
            return (
                <div style={{marginLeft: this.props.isOpened ? (window.innerWidth > 560 ? "160px" : "20px") : "20px", marginTop: "70px", marginRight: "20px"}}>
                    <Card style={{padding: "20px", fontSize: "14px"}} className="shadow-4">
                        <ChangePassword />
                    </Card>
                </div>
            )
        }
        return <div></div>
    }
}

export default connect(state => ({
    notifications: state.notifications
}))(ChangePass)