import { ADD_NOTIFICATION, UPDATE_NOTIFICATION } from "../actions/notificationAction";

function notification_reducer(state = [], action) {
  // this function will cater all the action related to ToDo Tasks

  switch (action.type) {
    // This switch case will check for incoming action's type and perform task accordingly
    case ADD_NOTIFICATION:
        return state.concat([action.notification])
    case UPDATE_NOTIFICATION:{
        let remaning = state.findIndex(item => item.id === action.notification.id);
        var item ="";
        for(var i in item) {
            if(item[i].id === action.notification.id) {
                item[i].read = true;
                break;
            }
        }
        state[remaning].read = true

        return state;
    }
    default:
        return state;
  }
}

export default notification_reducer;
