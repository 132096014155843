import React, { Component } from 'react';
import { Modal, Button, FormCheck, FormLabel } from 'react-bootstrap';
import { getFromStorage } from '../../../utils/storage';
import moment from 'moment';
import QRCode from "qrcode.react";

export default class ConfirmDialog extends Component {

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom: "-100px"}}>Message</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>Invoice Number: {this.props.type === "stripe" ? this.props.invoice : getFromStorage("inv_num")}</div>
                    <div>Time of Trasaction: {moment(getFromStorage("pay_time"), "YYYY-MM-DDThh:mm:ss").format("hh:mm:ss a DD-MM-YYYY")}</div>
                    {
                        
                    }
                    {
                        this.props.statuss === "failure" ? 
                        <div style={{textAlign: "center"}}>
                            <img src={require("../../../image/error.png")} height="200px" width="200px"/>
                            <h4>Payment Failed</h4>
                        </div>
                        :
                        this.props.statuss === "cancel" ?
                        <div style={{textAlign: "center"}}>
                            <img src={require("../../../image/error.png")} height="200px" width="200px"/>
                            <h4>Payment Cancelled</h4>
                        </div>
                        : this.props.statuss === "success" ?
                        <div style={{textAlign: "center"}}>
                            <img src={require("../../../image/success.png")} height="200px" width="200px"/>
                            <h4>Payment Successful</h4>
                            <QRCode
                                value={`avx`}
                                style={{ width: "200px", height: "200px" }}
                            />
                        </div>
                        :
                        <div style={{textAlign: "center"}}>
                            <img src={require("../../../image/error.png")} height="200px" width="200px"/>
                            <h4>Payment Failed</h4>
                            <p>{this.props.statuss}</p>
                        </div>
                    }
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" size="sm" onClick={this.props.handleClose}>OK</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}