import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { Modal, Button } from 'react-bootstrap';

export default class CartModal extends Component {
  
    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom: "-100px"}}>Cart & Checkout</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Name</th>
                                <th>Price</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.data.map((item, i) =>
                                    <tr key={i}>
                                        <td>{i+1}</td>
                                        <td>{item.name}</td>
                                        <td>{item.price}</td>
                                        <td align="center">
                                            <Button size="sm" variant="danger" onClick={() => this.props.deleteCart(item)}>
                                                Delete
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            }
                            {this.props.data.length ? 
                                <tr>
                                    <td colSpan={2}>Total</td>
                                    <td colSpan={2}>{this.props.data.map(item => item.price).reduce((a, b) => a + b, 0)}</td>
                                </tr>
                                :
                                <></>
                            }
                        </tbody>

                    </Table>
                    <Button disabled={!this.props.data.length} variant="success" 
                        onClick={() => {
                                this.props.handleClose()
                                this.props.setShowCard(true)
                            }
                        }
                    >
                        Checkout
                    </Button>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" size="sm" onClick={this.props.handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}