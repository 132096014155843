import React, {Component} from "react";
import { Card, Table, Button, Row, Col, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import key from "../../../config/key.json";
import { toast } from "react-toastify";
import { getFromStorage } from "../../../utils/storage";
import errorConfig from "../../../config/errorConfig.json";
import { withRouter } from "react-router";
import api from "../../../config/api.json";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import { Markup } from 'interweave';

const color = "#229a88";

class SetAppointment extends Component {
    state = {
        times: ["00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00"],
        appointments: [],
        doctors: [],
        isLoading: false,
        day: "",
        startTime: 0,
        endTime: 0,
        tableData: [],
        tempTableData: [],
        isSubmitting: false,
        isSearching: false,
        minDate: new Date(),
        maxDate: new Date(),
        selectDate: "",
        dates: [],
        time: "",
        dayAppointment: "",
        arrays: [],
        doc_id: "",
        doc_name: "",
        patient_id: "",
        patient_name: "",
        doctorCalendar:[],
        appointment_time: "",
        appointment_date: "",
        phone: "",
        patient_phone: "",
        isSearchingDoctor: false,
        isSearchingPatient: false,
        patients: []
    }
    //Error Notification
    notifyError = error => {
        toast.error("" + error.toString());
    };

    //Success Notification
    notifySuccess = success => {
        toast.success("" + success.toString());
    };

    whenChangeHandler = (e) => {
        const {name, value} = e.target
        this.setState({[name] : value})
    }

    dateTimeChecker = item => {
        if (item.startTime < this.state.endTime && item.endTime > this.state.startTime) {
          return true;
        }
        return false;
    };

    nextWeek = () => {
        var dates = [];
        var start = moment(this.state.minDate).add(1, 'week');
        var end = moment(this.state.maxDate).add(1, 'week');

        this.setState({arrays: [], minDate: new Date(start), maxDate: new Date(end),  selectDate: `${start.format("DD/MM/YYYY")} - ${end.format("DD/MM/YYYY")}` }, () => {
            this.getDoctorCalendar()
        })
        dates.push(start.format('YYYY-MM-DD'));          
        while(!start.isSame(end.format('YYYY-MM-DD'))){
            start = start.add(1, 'days');
            dates.push(start.format('YYYY-MM-DD'));
        }
        this.setState({ dates: dates.map(item => new Date(new Date(item).setHours(0,0,0,0)) >= new Date(new Date().setHours(0,0,0,0)) ? item : null), time: "", dayAppointment: "", appointment_date: "", appointment_time: ""})
    }

    setDates = () => {
        var dates = [];
        var startDate = moment().startOf('week')
        var endDate = moment().endOf('week')
        
        this.setState({ 
            arrays: [], 
            minDate: new Date(startDate), 
            maxDate: new Date(endDate), 
            selectDate: `${startDate.format("DD/MM/YYYY")} - ${endDate.format("DD/MM/YYYY")}` 
        }, () => {
            this.getDoctorCalendar()
        })

        dates.push(startDate.format('YYYY-MM-DD'));          
        while(!startDate.isSame(endDate.format('YYYY-MM-DD'))){
            startDate = startDate.add(1, 'days');
            dates.push(startDate.format('YYYY-MM-DD'));
        }
        this.setState({ dates: dates.map(item =>new Date(new Date(item).setHours(0,0,0,0)) >= new Date(new Date().setHours(0,0,0,0)) ? item : null), time: "", dayAppointment: "", appointment_date: "", appointment_time: "" })
    }

    previousWeek = () => {
        var dates = [];
        var start = moment(this.state.minDate).subtract(1, 'week');
        var end = moment(this.state.maxDate).subtract(1, 'week');

        this.setState({arrays: [], minDate: new Date(start), maxDate: new Date(end),  selectDate: `${start.format("DD/MM/YYYY")} - ${end.format("DD/MM/YYYY")}` }, () => {
            this.getDoctorCalendar();
        })
        dates.push(start.format('YYYY-MM-DD'));          
        while(!start.isSame(end.format('YYYY-MM-DD'))){
            start = start.add(1, 'days');
            dates.push(start.format('YYYY-MM-DD'));
        }
        this.setState({ dates:  dates.map(item =>new Date(new Date(item).setHours(0,0,0,0)) >= new Date(new Date().setHours(0,0,0,0)) ? item : null), time: "", dayAppointment: "", appointment_date: "", appointment_time: ""})
    }

    getDoctorCalendar = () => {
        toast.dismiss()
        const headers = {
            headers: {"Content-Type": "application/json"}
        }
        
        const body = {
            task: "getDocCalendar",
            doctor_id: this.state.doc_id,
            start_date: moment(this.state.minDate).format("YYYY-MM-DD 12:00:00"),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }

        axios 
        .post(api.PatientApi, body, headers)
        .then(res => {
            if(res.data[0]){
                this.setState({doctorCalendar: res.data}, () => {

                    let arr1 = [], arr2 = [], arr3 = [], arr4 = [], arr5 = [], arr6 = [], arr7 = [];
                    res.data[0][0].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr1.push(this.state.times[i] + `${" AM"}`) 
                    })
                    res.data[0][1].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr1.push(this.state.times[i] + `${" PM"}`) 
                    })
                    res.data[1][0].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr2.push(this.state.times[i] + `${" AM"}`) 
                    })
                    res.data[1][1].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr2.push(this.state.times[i] + `${" PM"}`) 
                    })
                    
                    res.data[2][0].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr3.push(this.state.times[i] + `${" AM"}`) 
                    })
                    res.data[2][1].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr3.push(this.state.times[i] + `${" PM"}`) 
                    })
                    res.data[3][0].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr4.push(this.state.times[i] + `${" AM"}`) 
                    })
                    res.data[3][1].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr4.push(this.state.times[i] + `${" PM"}`) 
                    })

                    res.data[4][0].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr5.push(this.state.times[i] + `${" AM"}`) 
                    })
                    res.data[4][1].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr5.push(this.state.times[i] + `${" PM"}`) 
                    })
                    res.data[5][0].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr6.push(this.state.times[i] + `${" AM"}`) 
                    })
                    res.data[5][1].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr6.push(this.state.times[i] + `${" PM"}`) 
                    })

                    res.data[6][0].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr7.push(this.state.times[i] + `${" AM"}`) 
                    })
                    res.data[6][1].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr7.push(this.state.times[i] + `${" PM"}`) 
                    })
                    this.setState({arrays: [
                        arr1,
                        arr2,
                        arr3,
                        arr4,
                        arr5,
                        arr6,
                        arr7
                    ]})
                    this.setState({ isLoading: false})
                })
            } else {
                this.setState({isLoading: false})
                this.notifyError("Something went wrong")
            }
        })
        .catch(err => {
            this.setState({isLoading: false})
            this.notifyError(err.toString())
        })
    }

    setAppointment = () => {
        this.setState({ isSubmitting: true}, () => {
            if(this.state.appointment_date !== "" && this.state.appointment_time !== "") {
                const headers = {
                    headers: {"Content-Type": "application/json"}
                }
           
                const body = {
                    task: "createAppointment",
                    doctor_id: this.state.doc_id,
                    patient_id: this.state.patient_id,
                    time_slot: moment(this.state.appointment_date.split("$")[0]).format("YYYYMMDD") + moment(this.state.appointment_time, ["h:mm A"]).format("HHmm") + "00",
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
                }
                axios 
                .post(api.PatientApi, body, headers)
                .then(res => {
                    if(res.data === 200){
                        this.notifySuccess("Appointment set successfully")
                        this.setState({ appointment_date: "", appointment_time: "", doc_id: "", doc_name: "", patients: [], doctors: [], patient_id: "", patient_name: "" })
                    } else {
                        this.notifyError("Something went wrong")
                    }
                    this.setState({isSubmitting: false})
                })
                .catch(err => {
                    this.setState({isSubmitting: false})
                    this.notifyError(err.toString())
                })
            } else {
                this.notifyError("All Fields Required")
                this.setState({isSubmitting: false})
            }
        })
    }

    //fetch appointments
    fetchDoctorDetail = (e) => {
        e.preventDefault();
        toast.dismiss();
        this.setState({isSearchingDoctor: true }, () => { 
            const body = {
                task: 'getDoctorDetails',
                phone: this.state.phone
            }
            const headers = { headers: { "Content-Type" : "application/json" } }
            axios.post(api.ProviderApi, body, headers)
            .then(res => {
                if(!res.data.errorMessage){
                    this.setState({doctors: res.data})
                } else {
                    this.notifyError("Not Found")
                }
                this.setState({isLoading: false, isSearchingDoctor: false, phone: ""})
            }
            )
            .catch(err => {
                this.notifyError(err.toString())
                this.setState({isLoading: false, isSearchingDoctor: false})
            })
        })
    }

    //fetch appointments
    fetchPatientDetail = (e) => {
        e.preventDefault();
        toast.dismiss();
        this.setState({isSearchingPatient: true }, () => { 
            const body = {
                task: 'getPatientDetails',
                phone: this.state.patient_phone
            }
            const headers = { headers: { "Content-Type" : "application/json" } }
            axios.post(api.ProviderApi, body, headers)
            .then(res => {
                if(!res.data.errorMessage){
                    this.setState({patients: res.data})
                } else {
                    this.notifyError("Not Found")
                }
                this.setState({isLoading: false, isSearchingPatient: false, patient_phone: ""})
            }
            )
            .catch(err => {
                this.notifyError(err.toString())
                this.setState({isLoading: false, isSearchingPatient: false})
            })
        })
    }

    //First function run when page load
    componentDidMount() {
        toast.dismiss();
        
        if (!getFromStorage(key.loginInfoKey)) {
            this.notifyError(errorConfig.notLoggedInError);
            this.props.history.push("/");
        } else if (getFromStorage(key.loginInfoKey).task === "authAdmin" || getFromStorage(key.loginInfoKey).task === "authDiagAdminPanel") {
            this.props.history.push("/Graph")
        } else if (getFromStorage(key.loginInfoKey).task === "patient") {
            this.props.history.push("/home")
        } else if (getFromStorage(key.loginInfoKey).task === "doctor") {
            this.props.history.push("/home")
        } 
    }

    render() {
        if(this.state.isLoading) {
            return (
                <div style={{marginLeft: this.props.isOpened ? (window.innerWidth > 560 ? "180px" : "20px") : "20px", marginTop: "70px", textAlign: "center"}}>
                    <CircularProgress />
                </div>
            )
        }
        return (
            <div style={{marginLeft: this.props.isOpened ? (window.innerWidth > 560 ? "180px" : "20px") : "20px", marginTop: "70px", marginRight: "20px"}}>
                <Card style={{padding: "20px", fontSize: "14px"}} className="shadow-4">
                    <div style={{paddingLeft: "20px", paddingRight: "20px"}}>
                            <h3 style={{ color, textAlign: "center", fontWeight: "bold" }}>Set Appointment</h3>
                            <form>
                                <div style={{fontWeight: "bold", marginBottom: "5px"}}>
                                    {this.state.doc_id !== "" && 
                                        <Row>
                                            <Col sm="3">
                                                Doctor ID: {this.state.doc_id}
                                            </Col>
                                            <Col sm="5">
                                                Doctor Name: {this.state.doc_name}
                                            </Col>
                                        </Row>
                                    }
                                    {this.state.patient_id !== "" &&
                                        <Row>
                                            <Col sm="3">
                                                Patient ID: {this.state.patient_id}
                                            </Col>
                                            <Col sm="5">
                                                Patient Name: {this.state.patient_name}
                                            </Col>
                                        </Row>
                                    }
                                </div>
                        {(this.state.doc_id !== "" && this.state.patient_id !== "") &&
                            <div>
                                <Row>
                                    <Col sm="3">
                                        <FormGroup controlId="weekly">
                                            <FormLabel style={{fontWeight: "bold"}}>Weekly<span style={{color: "red"}}>*</span></FormLabel>
                                            <FormControl 
                                                type="text" 
                                                size="sm" 
                                                name="selectDate" 
                                                disabled={false}
                                                value={this.state.selectDate} 
                                                onChange={() => {}}
                                            />
                                            <div style={{marginTop: "5px", textAlign: "center"}}>
                                                <Button variant="outline-danger" size="sm" onClick={this.previousWeek}>
                                                    <ArrowLeft fontSize="small"/>
                                                </Button>
                                                <Button variant="outline-danger" size="sm" style={{marginLeft: "5px"}} onClick={this.nextWeek}>
                                                    <ArrowRight fontSize="small"/>
                                                </Button>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3">
                                        <FormGroup controlId="startTime">
                                            <FormLabel style={{fontWeight: "bold"}}>Date<span style={{color: "red"}}>*</span></FormLabel>
                                            <FormControl 
                                                size="sm"
                                                as="select"
                                                name="appointment_date" 
                                                value={this.state.appointment_date} 
                                                onChange={(e) => { this.setState({appointment_date: e.target.value, appointment_time: ""}) }}
                                            >
                                                <option value="" style={{color: "silver"}}>Select Date</option>
                                                {this.state.dates.map((item, i) =>
                                                item && <option value={item + "$" + i.toString()} key={item}>{item}</option>)}
                                            </FormControl>
                                        </FormGroup>
                                    </Col>
                                    {this.state.arrays.length > 0 &&
                                    <Col sm="3">
                                        <FormGroup controlId="time">
                                            <FormLabel style={{fontWeight: "bold"}}>Time<span style={{color: "red"}}>*</span></FormLabel>
                                            <FormControl
                                                as="select" 
                                                size="sm"
                                                name="time" 
                                                value={this.state.appointment_time} 
                                                onChange={(e) => this.setState({appointment_time: e.target.value})}
                                            >
                                                <option value="" style={{color: "silver"}}>Select Time</option>
                                                {
                                                    this.state.appointment_date.split("$")[1] === "0" ? 
                                                    this.state.arrays[0].map(item => <option key={item} value={item}>{item}</option>) : 
                                                    this.state.appointment_date.split("$")[1] === "1" ? 
                                                    this.state.arrays[1].map(item => <option key={item} value={item}>{item}</option>) : 
                                                    this.state.appointment_date.split("$")[1] === "2" ? 
                                                    this.state.arrays[2].map(item => <option key={item} value={item}>{item}</option>) : 
                                                    this.state.appointment_date.split("$")[1] === "3" ? 
                                                    this.state.arrays[3].map(item => <option key={item} value={item}>{item}</option>) :
                                                    this.state.appointment_date.split("$")[1] === "4" ? 
                                                    this.state.arrays[4].map(item => <option key={item} value={item}>{item}</option>) :  
                                                    this.state.appointment_date.split("$")[1] === "5" ? 
                                                    this.state.arrays[5].map(item => <option key={item} value={item}>{item}</option>) : 
                                                    this.state.appointment_date.split("$")[1] === "6" && 
                                                    this.state.arrays[6].map(item => <option key={item} value={item}>{item}</option>) 
                                                }
                                            </FormControl> 
                                        </FormGroup>
                                    </Col>}
                                    <Col sm="3">
                                        <Button size="sm" variant="success" disabled={this.state.isSubmitting} style={{marginTop: window.innerWidth > 560 ? "27px" : "0px", width: "100px"}} onClick={this.setAppointment}>
                                            <span style={{marginLeft: "5px"}}>{!this.state.isSubmitting ? "Confirm" : "Loading..."}</span>
                                        </Button>      
                                    </Col>
                                </Row>
                                </div>}
                                <br/>
                            </form>
                        <h4 style={{ color, textAlign: "center", fontWeight: "bold", fontSize: 22 }}>Select Doctor</h4>
                        <br/>
                    </div>
                    <form onSubmit={this.fetchDoctorDetail}>
                        <Row>
                            <Col sm="3">
                                <FormGroup  controlId="search">
                                    <FormLabel>Search Doctor:</FormLabel>
                                    <FormControl 
                                        placeholder="Phone Number (eg. 01712345678)"
                                        name="phone"
                                        pattern="[0-9]*"
                                        value={this.state.phone}
                                        size="sm"
                                        onChange={(e) => {
                                            if(e.target.validity.valid)
                                                this.setState({phone: e.target.value})
                                        }}
                                    />  
                                </FormGroup>
                            </Col>
                            <Col>
                                <Button size="sm" variant="success" type="submit" style={{marginTop: 27}}>
                                    Search
                                </Button>
                            </Col>
                        </Row>
                    </form>
                    {!this.state.isSearchingDoctor ?
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr style={{color, fontWeight: "bold"}}>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Phone</th>
                                    <th>Gender</th>
                                    <th>Age</th>
                                    <th>Designation</th>
                                    <th>Speciality</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.doctors.map(item =>
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.name}</td>
                                            <td>{item.phone}</td>
                                            <td>{item.gender === "M" ? "Male" : "Female"}</td>
                                            <td>{item.age}</td>
                                            <td><Markup content={item.designation} /></td>
                                            <td><Markup content={item.speciality} /></td>
                                            <td align="center">
                                                <Button
                                                    size="sm" 
                                                    variant="info"
                                                    onClick={() => {
                                                       this.setState({doc_id: item.id, doc_name: item.name},() => {
                                                            this.setDates()
                                                       })
                                                    }} 
                                                >
                                                    <span style={{marginLeft: "5px"}}>Select Doctor</span>
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                        :
                        <div style={{textAlign: "center"}}>
                            <CircularProgress />
                        </div>
                    }
                    {this.state.doc_id !== "" && 
                    <div>
                    <h4 style={{ color, textAlign: "center", fontWeight: "bold", fontSize: 22 }}>Select Patient</h4>
                    <br/>
                    <form onSubmit={this.fetchPatientDetail}>
                        <Row>
                            <Col sm="3">
                                <FormGroup controlId="search">
                                    <FormLabel>Search Patient:</FormLabel>
                                    <FormControl 
                                        placeholder="Phone Number (eg. 01712345678)"
                                        name="phone"
                                        pattern="[0-9]*"
                                        value={this.state.patient_phone}
                                        size="sm"
                                        onChange={(e) =>{ 
                                            if(e.target.validity.valid)
                                                this.setState({patient_phone: e.target.value})
                                        }}
                                    />  
                                </FormGroup>
                            </Col>
                            <Col>
                                <Button size="sm" variant="success" type="submit" style={{marginTop: 27}}>
                                    Search
                                </Button>
                            </Col>
                        </Row>
                    </form>
                    {!this.state.isSearchingPatient ?
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr style={{color, fontWeight: "bold"}}>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Phone</th>
                                    <th>Gender</th>
                                    <th>Age</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.patients.map(item =>
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.name}</td>
                                            <td>{item.phone}</td>
                                            <td>{item.gender === "M" ? "Male" : "Female"}</td>
                                            <td>{item.age}</td>
                                            <td align="center">
                                                <Button
                                                    size="sm" 
                                                    variant="info"
                                                    onClick={() => {
                                                       this.setState({patient_id: item.id, patient_name: item.name})
                                                    }} 
                                                >
                                                    <span style={{marginLeft: "5px"}}>Select Patient</span>
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                        :
                        <div style={{textAlign: "center"}}>
                            <CircularProgress />
                        </div>
                    }
                    </div>}
                </Card>
                <br/>
            </div>
        )
    }
}

export default withRouter(SetAppointment);