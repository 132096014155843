import React, {Component} from 'react';
import { Add, Event, ExitToApp, Menu, LocalHospital, LocalHospitalRounded, PersonAddRounded, PersonAddTwoTone, EventAvailable, Lock, PhoneAndroidOutlined, EventAvailableRounded } from "@material-ui/icons";
import { NavLink, withRouter } from 'react-router-dom';
import { removeFromStorage, getFromStorage } from '../../../utils/storage';
import key from "../../../config/key.json"

const backgroundColor = "#545151";
const color= "white";

class Sidebar extends Component {
    state = {
        show: false,
    }

    render() {
        return (
            <div style={{position: "fixed", top: 0, zIndex: 1}}>
                <div style={{cursor: "pointer",height: "50px", backgroundColor,marginLeft: this.props.isOpened ? "170px": "0px", width: "100%", top: 0, left: 0, right: 0, position: "fixed", border: "0.5px solid #5c5857"}}>
                    <Menu style={{marginLeft: "10px", color, fontSize: "25px", marginTop: "10px"}} onClick={this.props.changeState}/>
                </div>
                <div className="sidebar1" style={{width: this.props.isOpened ? "170px": "0px", backgroundColor, height: "100vh", border: "0.5px solid #5c5857"}}>
                    {this.props.isOpened && 
                    <div style={{position: "absolute", color, marginLeft: "10px", width: "150px", }}>
                        <ul style={{borderBottom: "0.5px solid #5c5857"}}>
                            
                            <li style={{marginLeft: "5px"}}>
                                <Add style={{fontSize: "20px"}}/>
                                <span style={{fontSize: "14px"}}>Online Doctor</span>
                            </li>
                            <li style={{fontSize: "14px", color: "silver", marginLeft: "10px", marginTop: "-15px"}}>
                                {getFromStorage(key.loginInfoKey) ? getFromStorage(key.loginInfoKey).name : "name"}
                            </li>
                        </ul>
                        <ul className="lists" style={{marginTop: "-10px", marginLeft: "10px", color: "silver", fontSize: "14px"}}>
                            <NavLink to="/provider_doctors" activeStyle={{color: "white"}} style={{textDecoration: "none", color: "silver"}}>
                                <li>
                                    <LocalHospital style={{fontSize: "20px"}}/>
                                    <span style={{marginLeft: "5px"}}>Doctors</span>
                                </li>
                            </NavLink>
                            <NavLink to="/provider_patients" activeStyle={{color: "white"}} style={{textDecoration: "none", color: "silver"}}>
                                <li>
                                    <LocalHospitalRounded style={{fontSize: "20px"}}/>
                                    <span style={{marginLeft: "5px"}}>Patients</span>
                                </li>
                            </NavLink>
                            <NavLink to="/add_patient" activeStyle={{color: "white"}} style={{textDecoration: "none", color: "silver"}}>
                                <li>
                                    <PersonAddRounded style={{fontSize: "20px"}}/>
                                    <span style={{marginLeft: "5px"}}>Add Patient</span>
                                </li>
                            </NavLink>
                            <NavLink to="/add_doctor" activeStyle={{color: "white"}} style={{textDecoration: "none", color: "silver"}}>
                                <li>
                                    <PersonAddTwoTone style={{fontSize: "20px"}}/>
                                    <span style={{marginLeft: "5px"}}>Add Doctor</span>
                                </li>
                            </NavLink>
                            <NavLink to="/set_appointment" activeStyle={{color: "white"}} style={{textDecoration: "none", color: "silver"}}>
                                <li>
                                    <EventAvailable style={{fontSize: "20px"}}/>
                                    <span style={{marginLeft: "5px"}}>Set Appointment</span>
                                </li>
                            </NavLink>
                            <NavLink to="/provider_appointments" activeStyle={{color: "white"}} style={{textDecoration: "none", color: "silver"}}>
                                <li>
                                    <Event style={{fontSize: "20px"}}/>
                                    <span style={{marginLeft: "5px"}}>Appointments</span>
                                </li>
                            </NavLink>
                            <NavLink
                                to="/free_appointment_requests"
                                activeStyle={{ color: "white" }}
                                style={{ textDecoration: "none", color: "silver" }}
                            >
                                <li>
                                    <EventAvailableRounded style={{ fontSize: "20px" }} />
                                    <span style={{ marginLeft: "5px" }}>Free Appointment Requests</span>
                                </li>
                            </NavLink>
                            <NavLink
                                to="/admin_call_requests"
                                activeStyle={{ color: "white" }}
                                style={{ textDecoration: "none", color: "silver" }}
                            >
                                <li>
                                    <PhoneAndroidOutlined style={{ fontSize: "20px" }} />
                                    <span style={{ marginLeft: "5px" }}>Admin Call Requests</span>
                                </li>
                            </NavLink>
                            <NavLink to="/provider_change_password" activeStyle={{color: "white"}} style={{textDecoration: "none", color: "silver"}}>
                                <li>
                                    <Lock style={{fontSize: "20px"}}/>
                                    <span style={{marginLeft: "5px"}}>Change Password</span>
                                </li>
                            </NavLink>
                            <li onClick={() => {
                                        removeFromStorage(key.loginInfoKey)
                                        this.props.history.replace("/");
                                    }}
                                style={{cursor: "pointer"}}
                            >
                                <ExitToApp style={{fontSize: "20px"}}/>
                                <span style={{marginLeft: "5px"}}>Logout</span>
                            </li>
                        </ul>
                    </div>}
                </div>
            </div>
        )
    }
}

export default withRouter(Sidebar);