import React, {Component} from "react"
import { toast } from "react-toastify";
import key from "../../../config/key.json"
import { getFromStorage } from "../../../utils/storage";
import errorConfig from "../../../config/api.json"
import { Card, Table } from "react-bootstrap";

const color = "#229a88";
export default class ChangePass extends Component{
    state = {

    }

    //Error Notification
    notifyError = error => {
        toast.error("" + error.toString());
    };

    //First function run when page load
    componentDidMount() {
        toast.dismiss();
        
        if (!getFromStorage(key.loginInfoKey)) {
            this.notifyError(errorConfig.notLoggedInError);
            this.props.history.push("/");
        } else if (getFromStorage(key.loginInfoKey).task === "authAdmin" || getFromStorage(key.loginInfoKey).task === "authDiagAdminPanel") {
            this.props.history.push("/Graph");
        } else if (getFromStorage(key.loginInfoKey).task === "patient") {
            this.props.history.push("/home");
        } else if (getFromStorage(key.loginInfoKey).task === "doctor") {
            this.props.history.push("/home");
        } else if (getFromStorage(key.loginInfoKey).task === "provider") {
            
        }
    }
    render(){
        if (getFromStorage(key.loginInfoKey).task === "provider") {
            return (
                <div style={{marginLeft: this.props.isOpened ? (window.innerWidth > 560 ? "180px" : "20px") : "20px", marginTop: "70px", marginRight: "20px"}}>
                    <Card style={{padding: "20px", fontSize: "14px"}} className="shadow-4">
                        <h3 style={{color, textAlign: "center", fontWeight: "bold"}}>Calculate Bills</h3>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr style={{color, fontWeight: "bold"}}>
                                    <th>Id</th>
                                    <th>Rate</th>
                                    <th>Count</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>$100</td>
                                    <td>23</td>
                                    <td>$3400</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Card>
                </div>
            )
        }
        return <div></div>
    }
}