import React, { useEffect, useState } from 'react';
import axios from 'axios'
import Api from '../../../config/api.json'
import { CircularProgress } from '@material-ui/core';
import { Button, Form, FormLabel, Row, Table } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { FormGroup } from 'react-bootstrap';
import { FormControl } from 'react-bootstrap';
import { Dropdown } from 'semantic-ui-react'
import { getFromStorage, setInStorage } from '../../../utils/storage';
import { useParams, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getNotifications, getToken } from '../saveToken';
import { connect } from 'react-redux';

const continueTime = ['1 Day', '2 Days', '3 Days','4 Days', '5 Days','6 Days','7 Days', '8 Days','9 Days','10 Days', '11 Days','12 Days','13 Days','14 Days','15 Days','3 Weeks', '1 month', '1.5 months', '2 Months', '3 Months', '4 Months', '5 Months', '6 Months', 'Continue']
const administrations = ['Using Respo Chamber', "I/M", "I/V", "P/R", "P/V", "Left Eye", "Both Eye"]
const doseTimings = ['Before Meal', 'After Meal', '1/2 hour before meal', '1/2 hour after meal', '1 hour before meal', '1 hour after meal', 'at bed time']
// const dosages = ['0',".5",".75","1","1.25","1.5","1.75", "2", "2.5" ]
// const dosages1 = ['0',".5",".75","1","1.25","1.5","1.75", "2", "2.5", "Puff", "Spoon", "Drop", "Ampule", "Stick" ]

const DigitalPrescription = (props) => {
    const [data, setData] = useState([])
    const [dosages, setDosages] = useState(['', '0',".5",".75","1","1.25","1.5","1.75", "2", "2.5"])
    const [dosages1, setDosages1] = useState(['', '0',".5",".75","1","1.25","1.5","1.75", "2", "2.5", "Puff", "Spoon", "Drop", "Ampule", "Stick" ])
    const [isChange, setIsChange] = useState(false)
    const {appointment_id} = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const [radiologyTests, setRadiologyTests] = useState([])
    const [pathologyTests, setPathologyTests] = useState([])
    const [testInfoRadiology, setTestInfoRadiology] = useState("")
    const [testInfoPathology, setTestInfoPathology] = useState("")
    const [filteredRadiology, setFilteredRadiology] = useState([])
    const [filteredPathology, setFilteredPathology] = useState([])
    const [searchingRadiology, setSearchingRadiology] = useState(false)
    const [searchingPathology, setSearchingPathology] = useState(false)
    const [searching, setSearching] = useState(false)
    const [medicineName, setMedicineName] = useState("")
    const [continueTill, setContinueTill] = useState("")
    const [searchingMedicine, setSearchingMedicine] = useState(false)
    const [searchingMedicineDropDown, setSearchingMedicineDropDown] = useState(false)
    const [filteredMedicine, setFilteredMedicine] = useState([])
    const [medicineData, setMedicineData] = useState([])
    const [medicines, setMedicines] = useState([])
    const [selectedMedicine, setSelectedMedicine] = useState(null)
    const [complaint, setComplaint] = useState("")
    const [history, setHistory] = useState("") 
    const [submitting, setSubmitting] = useState(false)
    const [administration, setAdministration] = useState("")
    const [timing, setTiming] = useState("")
    const [dosage, setDosage] = useState(["", "", "", "", ""])
    const [dosageExtra, setDosageExtra] = useState("")
    const [dm, setDm] = useState(false)
    const [htn, setHtn] = useState(false)
    const [bronchial, setBronchial] = useState(false)
    const [smoking, setSmoking] = useState(false)
    const [dysilipidemia, setDysilipidemia] = useState(false)
    const [filteredDosages, setFilteredDosages] = useState([[], [], [], [], []])
    const [weight, setWeight] = useState("")
    const [bp1, setBP1] = useState("")
    const [bp2, setBP2] = useState("")
    const [bodyTemperature, setBodyTemperature] = useState("")
    const [pulse, setPulse] = useState("")
    const [advices, setAdvices] = useState("")
    const [examinations, setExaminations] = useState([])
    const [followUp, setFollowUp] = useState("")
    const [diagnosis, setDiagnosis] = useState("")
    const [otherExams, setOtherExams] = useState([])

    //Check if brackets are fine for regular expression
    const isBalanced = (input) => {

        let brackets = "[]{}()<>"
        let stack = []
      
        for(let bracket of input) {
          let bracketsIndex = brackets.indexOf(bracket)
      
          if (bracketsIndex === -1){
            continue
          }
      
          if(bracketsIndex % 2 === 0) {
            stack.push(bracketsIndex + 1)
          } else {
            if(stack.pop() !== bracketsIndex) {
              return false;
            }
          }
        }
        return stack.length === 0
      }

    //Search Tests
    const onSearch = (text, task) => {
        const temp = text.toLowerCase()
        console.log(temp)
        if(task !== "getMedicineNames") {
            setSearching(true)
        } else {
            setSearchingMedicineDropDown(true)
        }

        if (text && text.length === 3) {
            if(task === "getRadiologyTestNames") {
                setSearchingRadiology(true)
            } else if(task === "getPathologyTestNames") {
                setSearchingPathology(true)
            } else{
                setSearchingMedicine(true)
            }

            getTests(task, temp)
        } else if(!text.length) {
            if(task === "getRadiologyTestNames") {
                setSearchingRadiology(false)
                setSearching(false)
            } else if(task === "getPathologyTestNames") {
                setSearchingPathology(false)
                setSearching(false)
            } else {
                setSearchingMedicineDropDown(false)
                setSearchingMedicine(false)
            }
            
        } else {
            if(task === "getRadiologyTestNames") {
                setSearchingRadiology(true)
                if(isBalanced(temp)) {
                    const tempList = radiologyTests.length ? radiologyTests.filter(item => {
                        if(item.testName.toLowerCase().match(new RegExp(`${temp}`)))
                            return item
                    }) : []
                    console.log(tempList)
                    setFilteredRadiology(tempList)
                }
            } else if(task === "getPathologyTestNames") {
                setSearchingPathology(true)
                if(isBalanced(temp)) {
                    const tempList = pathologyTests.length ? pathologyTests.filter(item =>{ 
                        if(item.testName.toLowerCase().match(new RegExp(`${temp}`)))
                            return item
                    }) : []
                    setFilteredPathology(tempList)
                }
            } else {
                setSearchingMedicine(true)
                if(isBalanced(temp)) {
                    const tempList = medicines.length ? medicines.filter(item =>{ 
                        if(`${item.drugname && item.drugname.toLowerCase()}, ${item.drugstrength && item.drugstrength.toLowerCase()}`.match(new RegExp(`${temp}`)))
                            return item
                    }) : []
                    setFilteredMedicine(tempList)
                }
            }

            if(task !== "getMedicineNames") {
                setSearching(false)
            } else {
                setSearchingMedicineDropDown(false)
            }
        }
    }
    

    //Get Tests
    const getTests = (task, substr) => {
        const body = {
            task,
            substr
        }
        console.log(task)
        axios.post(Api.TEST_API, body)
        .then(res => {
            console.log(res.data)
            if(!res.data.errorMessage) {
                if(task === "getRadiologyTestNames") {
                    setRadiologyTests(res.data)
                    setFilteredRadiology(res.data)
                    setSearching(false)
                } else if(task === "getPathologyTestNames") {
                    setPathologyTests(res.data)
                    setFilteredPathology(res.data)
                    setSearching(false)
                } else {
                    setMedicines(res.data)
                    setFilteredMedicine(res.data)
                    setSearchingMedicineDropDown(false)
                }
            }
        })
        .catch(err => {
            console.log(err)

            if(task !== "getMedicineNames") {
                setSearchingMedicineDropDown(false)
            } else {
                setSearching(false)
            }

        })
    }

    //delete data
    const deleteData = (id) => {
        setData([...data.filter(item => item.id !== id)])
    }

    //Add Data 
    const addData = () => {
        if((testInfoPathology.trim().length || testInfoRadiology.trim().length)) {
            if(testInfoPathology.trim().length && pathologyTests.find(item => item.testName === testInfoPathology)) {
                const findTest = pathologyTests.find(item => item.testName === testInfoPathology)
                data.push({id: data.length+1, name: findTest.testName, id_m: findTest.id, rate: findTest.rate})
                setData([...data])
            } 

            if (testInfoRadiology.trim().length && radiologyTests.find(item => item.testName === testInfoRadiology)) {
                const findTest = radiologyTests.find(item => item.testName === testInfoRadiology)
                data.push({id: data.length+1, name: findTest.testName, id_m: findTest.id, rate: findTest.rate})
                setData([...data])
            }

            if(!pathologyTests.find(item => item.testName === testInfoPathology) && !radiologyTests.find(item => item.testName === testInfoRadiology)) {
                alert("Test names are incorrect")
            }
        } else {
            alert("Test and Institute Required or not correct")
        }
    }
    //delete data
    const deleteMedicine = (id) => {
        setMedicineData([...medicineData.filter(item => item.id !== id)])
    }

    //delete data
    const deleteExamination = (id) => {
        setExaminations([...examinations.filter(item => item.id !== id)])
    }

    //Add Data 
    const addMedicine = () => {
        if(medicineName.trim().length) {
            if(medicines.find(item => item.drugname === medicineName)) {
                const findMedicine = medicines.find(item => item.drugname === medicineName)
                medicineData.push({id: medicineData.length+1, drugname: findMedicine.drugname, dosage: `${dosage[0]}${dosage[1].length ? " " : ""}${dosage[1]}${dosage[2].length ? " " : ""}${dosage[2]}${dosage[3].length ? " " : ""}${dosage[3]}${dosage[4].length ? " " : ""}${dosage[4]}${timing.length ? " " : ""}${timing}${continueTill.length ? " " : ""}${continueTill}`})
                setMedicineData([...medicineData])
                setMedicineName("")
                setAdministration("")
                setDosage(["", "", "", "", ""])
                setDosageExtra("")
                setTiming("")
                setSelectedMedicine(null)
                setContinueTill("")
            }

            if(!medicines.find(item => item.drugname === medicineName)) {
                alert("Medicine name is incorrect")
            }
        } else {
            alert("All * fields Required or not correct")
        }
    }

    //Add Data 
    const addExamination = () => {
        // if(weight.trim().length || anaemia.trim().length || heart.trim().length || (bp1.trim().length && bp2.trim().length) || bodyTemperature.trim().length || clubbing.trim().length || pulse.trim().length) {
        //     examinations.push({id: examinations.length+1, weight, anaemia, heart, bp: `${bp1} / ${bp2}`, bodyTemperature, clubbing, pulse})
        //     setExaminations([...examinations])
        //     setWeight("")
        //     setAnaemia("")
        //     setHeart("")
        //     setBP1("")
        //     setBP2("")
        //     setBodyTemperature("")
        //     setClubbing("")
        //     setPulse("")
        // } else {
        //     alert("Atleast one field required")
        // }
        let temp = otherExams
        temp.push({name: "", value: ""})
        setOtherExams([...temp])
    }

    //save prescription
    const savePrescription = async () => {
        setSubmitting(true)
        try {
            const body = { 
                task:"savePrescription", 
                appointment_id: appointment_id,
                complaint,
                comment: history,
                tests: JSON.stringify(data),
                medicines: JSON.stringify(medicineData),
                examinations: JSON.stringify({weight, bp: `${bp1} / ${bp2}`, bodyTemperature, pulse, otherExams}),
                advices,
                followup: followUp,
                diagnoses: diagnosis
                // tests: JSON.stringify(data.map(item => ([item.id_m, item.name]))),
                // medicines: JSON.stringify(medicineData.map(item => ([item.drugname, item.drugstrength, item.drugmanufacturer, item.druggenericname, item.continueTill])))
            }
            console.log(body)
            const res = await axios.post(Api.TEST_API, body)
            setSubmitting(false)
            console.log(res.data)
            if(res.data) {
                setData([])
                setTestInfoPathology("")
                setTestInfoRadiology("")
                setHistory("")
                setComplaint("")
                setExaminations([])
                setAdvices("")
                setDm(false)
                setBronchial(false)
                setHtn(false)
                setSmoking(false)
                setDysilipidemia(false)
                setMedicineData([])
                setFollowUp("")
                setDiagnosis("")
                setOtherExams([])
                setWeight("")
                setBP1("")
                setBP2("")
                setBodyTemperature("")
                setPulse("")
                toast.success("Submitted Successfully")
            }
        } catch(err) {
            setSubmitting(false)
            toast.error("" + err.toString());
        }
    }

    //This function will run first
    useEffect(() => {
        if(getFromStorage("doctor")) {
            getNotifications(props.dispatch, props.notifications)
            getToken("doctors")
            setIsLoading(false)
        } else {
            props.history.replace("/")
        }
    }, [])

    if(isLoading) {
        return (
            <div style={{marginLeft: props.isOpened ? (window.innerWidth > 560 ? "160px" : "20px") : "20px", marginTop: "70px", textAlign: "center"}}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <div style={{marginLeft: props.isOpened ? (window.innerWidth > 560 ? "160px" : "20px") : "20px", marginTop: "70px", marginRight: "20px"}}>
            <form>
                <Row>
                    <Col sm="8">
                        <FormGroup controlId="institute">
                            <FormLabel style={{fontWeight: "bold"}}>Chief Complaint</FormLabel>
                            <FormControl 
                                size="md"
                                type="text"
                                name="complaint" 
                                value={complaint} 
                                onChange={(e) => { setComplaint(e.target.value) }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm="8">
                        <FormGroup controlId="institute">
                            <FormLabel style={{fontWeight: "bold"}}>History</FormLabel>
                            <FormControl 
                                size="md"
                                as="textarea"
                                name="history" 
                                value={history} 
                                rows={5}
                                onChange={(e) => { setHistory(e.target.value) }}
                            />
                        </FormGroup>
                    </Col>
                    <Col sm="4">
                        <FormLabel style={{fontWeight: "bold"}}></FormLabel>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="DM" checked={dm} onChange={(e) => {
                                    if(!dm && !history.includes("DM")){
                                        setHistory(`${history}${"DM,"}`)
                                    } else if(dm) {
                                        setHistory(history.includes("DM,") ? history.replace("DM,", "") : history.replace("DM", ""))
                                    }
                                    setDm(!dm)
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="HTN" checked={htn} onChange={(e) =>{ 
                                if(!htn && !history.includes("HTN")){
                                    setHistory(`${history}${"HTN,"}`)
                                } else if(htn) {
                                    setHistory(history.includes("HTN,") ? history.replace("HTN,", "") : history.replace("HTN", ""))
                                }
                                setHtn(!htn)
                            }}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Bronchial Asthma" checked={bronchial} onChange={(e) => {
                                if(!bronchial && !history.includes("Bronchial Asthma")){
                                    setHistory(`${history}${"Bronchial Asthma,"}`)
                                } else if(bronchial) {
                                    setHistory(history.includes("Bronchial Asthma,") ? history.replace("Bronchial Asthma,", "") : history.replace("Bronchial Asthma", ""))
                                }
                                setBronchial(!bronchial)
                            }}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Smoking" checked={smoking} onChange={(e) => {
                                if(!smoking && !history.includes("Smoking")){
                                    setHistory(`${history}${"Smoking,"}`)
                                } else if(smoking) {
                                    setHistory(history.includes("Smoking,") ? history.replace("Smoking,", "") : history.replace("Smoking", ""))
                                }
                                setSmoking(!smoking)
                            }}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Dyslipidemia" checked={dysilipidemia} onChange={(e) => {
                                if(!dysilipidemia && !history.includes("Dysilipidemia")){
                                    setHistory(`${history}${"Dysilipidemia,"}`)
                                } else if(dysilipidemia) {
                                    setHistory(history.includes("Dysilipidemia,") ? history.replace("Dysilipidemia,", "") : history.replace("Dysilipidemia", ""))
                                }
                                setDysilipidemia(!dysilipidemia)
                            }}/>
                        </Form.Group>
                    </Col>
                </Row>
                <div style={{fontWeight: "bold", fontSize: 18}}>On Examination</div>
                <br/>
                <Row>
                    <Col sm="4">
                        <FormGroup controlId="startTime">
                            <FormLabel style={{fontWeight: "bold"}}>Weight</FormLabel>
                            <FormControl 
                                size="md"
                                type="text"
                                name="weight" 
                                value={weight} 
                                onChange={(e) => setWeight(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
        
                    <Col sm="4">
                        <FormGroup controlId="startTime">
                            <FormLabel style={{fontWeight: "bold"}}>BP</FormLabel>
                            <Row>
                                <Col sm="5">
                                    <FormControl 
                                        size="md"
                                        type="text"
                                        name="bp1" 
                                        value={bp1} 
                                        onChange={(e) => setBP1(e.target.value)}
                                    />
                                </Col>
                                <Col sm="2">
                                    <div style={{fontSize: "18px", marginTop: 5}}>/</div>
                                </Col>
                                <Col sm="5">
                                    <FormControl 
                                        size="md"
                                        type="text"
                                        name="bp2" 
                                        value={bp2}
                                        onChange={(e) => setBP2(e.target.value)} 
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                    <Col sm="4"></Col>
                    <Col sm="4">
                        <FormGroup controlId="startTime">
                            <FormLabel style={{fontWeight: "bold"}}>Pulse</FormLabel>
                            <FormControl 
                                size="md"
                                type="text"
                                name="pulse" 
                                value={pulse} 
                                onChange={(e) => setPulse(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col sm="4">
                        <FormGroup controlId="startTime">
                            <FormLabel style={{fontWeight: "bold"}}>Body Temperature</FormLabel>
                            <FormControl 
                                size="md"
                                type="text"
                                name="bodyTemperature" 
                                value={bodyTemperature} 
                                onChange={(e) => setBodyTemperature(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    
                </Row>
                {otherExams.map((item, i) =>
                    <>
                    <Row>
                        <Col sm="4">
                            <FormLabel style={{fontWeight: "bold"}}>Other Examination Name {i+1}</FormLabel>
                            <FormControl 
                                size="md"
                                type="text"
                                name={`otherExamName${i+1}`} 
                                value={otherExams[i].name} 
                                onChange={(e) => {
                                    let temp = otherExams
                                    temp[i].name = e.target.value
                                    setOtherExams([...temp])
                                }}
                            />
                        </Col>
                        <Col sm="4">
                            <FormLabel style={{fontWeight: "bold"}}>Other Examination Value {i+1}</FormLabel>
                            <FormControl 
                                size="md"
                                type="text"
                                name={`otherExamValue${i+1}`} 
                                value={otherExams[i].value} 
                                onChange={(e) => {
                                    let temp = otherExams
                                    temp[i].value = e.target.value
                                    setOtherExams([...temp])
                                }}
                            />
                        </Col>
                    </Row>
                    <br/>
                    </>
                )}
                <Row>
                    <Col sm="4">
                        <Button size="md" variant="success" style={{ width: "100%"}} onClick={addExamination}>
                            <span style={{marginLeft: "5px"}}>Add Other Examination</span>
                        </Button>      
                    </Col>
                </Row>
                <br/>
                <br/>
                <div style={{fontWeight: "bold", fontSize: 18}}>Diagnosis</div>
                <br/>
                <Row>
                    <Col sm="8">
                        <FormGroup controlId="startTime">
                            {/* <FormLabel style={{fontWeight: "bold"}}>Radiology Tests</FormLabel> */}
                            <FormControl 
                                size="md"
                                type="text"
                                name="diagnosis" 
                                value={diagnosis}
                                onChange={(e) => {
                                    setDiagnosis(e.target.value)
                                }} 
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <br/>
                <div style={{fontWeight: "bold", fontSize: 18}}>Investigation</div>
                <br/>
                <Row>
                    <Col sm="4">
                        <FormGroup controlId="startTime">
                            <FormLabel style={{fontWeight: "bold"}}>Imaging Tests</FormLabel>
                            <Dropdown
                                placeholder='Search Imaging Test'
                                fluid
                                search
                                selection
                                options={filteredRadiology.map(item => ({value: item.testName, text: item.testName}))}
                                value={testInfoRadiology}
                                onSearchChange={(e) => {
                                    // setTestInfoRadiology(e.target.value)
                                    onSearch(e.target.value, "getRadiologyTestNames")
                                }}
                                onChange={(e, {value}) => {
                                    console.log(value)
                                    setTestInfoRadiology(value)
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col sm="4">
                        <FormGroup controlId="startTime">
                            <FormLabel style={{fontWeight: "bold"}}>Pathology Tests</FormLabel>
                            <Dropdown
                                placeholder='Search Pathology Test'
                                fluid
                                search
                                selection
                                options={filteredPathology.map(item => ({value: item.testName, text: item.testName}))}
                                value={testInfoPathology}
                                onSearchChange={(e) => {
                                    console.log(e.target.value)
                                    onSearch(e.target.value, "getPathologyTestNames")
                                }}
                                onChange={(e, {value}) => setTestInfoPathology(value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm="2">
                        <Button size="md" variant="success" style={{ width: "100%"}} onClick={addData}>
                            <span style={{marginLeft: "5px"}}>Add Test</span>
                        </Button>      
                    </Col>
                </Row>
                <br/>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Ser</th>
                            <th>Name of Test</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((item, i) =>
                                <tr key={i}>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                    <td align="center">
                                        <Button size="sm" variant="danger" onClick={() => deleteData(item.id)}>
                                            Delete
                                        </Button>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>

                </Table>
                <br/>
                <div style={{fontWeight: "bold", fontSize: 18}}>Medication</div>
                <br/>
                <Row>
                    <Col sm="4">
                        <FormGroup controlId="startTime">
                            <FormLabel style={{fontWeight: "bold"}}>Medicine Name<span style={{color: "red"}}>*</span></FormLabel>
                            <Dropdown
                                placeholder='Search Medicine'
                                fluid
                                search
                                selection
                                options={filteredMedicine.map(item => ({value: item.drugname, text: item.drugname}))}
                                value={medicineName}
                                onSearchChange={(e) => {
                                    // setTestInfoRadiology(e.target.value)
                                    onSearch(e.target.value, "getMedicineNames")
                                }}
                                onChange={(e, {value}) => {
                                    console.log(value)
                                    setMedicineName(value)
                                    setSelectedMedicine(medicines.find(item => item.drugname === value))
                                }}
                            />
                        </FormGroup>
                    </Col>
                    {dosage.map((item, i) => 
                        <Col sm="4" key={i}>
                            <FormGroup controlId="startTime">
                                {<FormLabel style={{fontWeight: "bold"}}>Dosage</FormLabel>}
                                {i === 0 || i === 2 ? 
                                    <Dropdown
                                        placeholder=''
                                        fluid
                                        search
                                        selection
                                        options={i === 0 ? dosages.map(item => ({value: item, text: item})) : dosages1.map(item => ({value: item, text: item}))}
                                        value={dosage[i]}
                                        onSearchChange={(e) => {
                                            const temp = dosage
                                            temp[i] = e.target.value
                                            setDosage([...temp])
                                        }}
                                        onClose={(e, {value}) => {
                                            console.log("close", value)
                                            if(i === 0 && !dosages.includes(value) && !isChange) {
                                                const temp = dosages
                                                temp.push(value.toString())
                                                setDosages([...temp])
                                            } else if(i !== 0 && !dosages1.includes(value) && !isChange) {
                                                const temp = dosages1
                                                temp.push(value.toString())
                                                setDosages1([...temp])
                                            }
                                            setIsChange(false)
                                        }}
                                        onChange={(e, {value}) => {
                                            console.log(value)
                                            setIsChange(true)
                                            const temp = dosage
                                            temp[i] = value
                                            setDosage([...temp])
                                        }}
                                    />
                                :
                                i === 1 ? 
                                    <FormControl 
                                        size="md"
                                        as="select"
                                        name={`dosage[${i}]`} 
                                        value={dosage[i]} 
                                        onChange={(e) => { 
                                            const temp = dosage
                                            temp[i] = e.target.value
                                            setDosage([...temp])
                                        }}
                                    >
                                        <option value="" style={{color: "silver"}}></option>
                                        {["Puff", "Spoon", "Drop/Minute", "Ampule", "Stick"].map((item, i) => <option value={item} key={item}>{item}</option>)}
                                    </FormControl>
                                :
                                i === 3 ? 
                                    <FormControl 
                                        size="md"
                                        as="select"
                                        name={`dosage[${i}]`} 
                                        value={dosage[i]} 
                                        onChange={(e) => { 
                                            const temp = dosage
                                            temp[i] = e.target.value
                                            setDosage([...temp])
                                         }}
                                    >
                                        <option value="" style={{color: "silver"}}></option>
                                        {["Times", "Hourly"].map((item, i) => <option value={item} key={item}>{item}</option>)}
                                    </FormControl>
                                :
                                    <FormControl 
                                        size="md"
                                        as="select"
                                        name={`dosage[${i}]`} 
                                        value={dosage[i]} 
                                        onChange={(e) => { 
                                            const temp = dosage
                                            temp[i] = e.target.value
                                            setDosage([...temp])
                                        }}
                                    >
                                        <option value="" style={{color: "silver"}}></option>
                                        {administrations.map((item, i) => <option value={item} key={item}>{item}</option>)}
                                    </FormControl>   
                                }
                            </FormGroup>
                        </Col>
                    )}
                    <Col sm="4">
                        <FormGroup controlId="startTime">
                            <FormLabel style={{fontWeight: "bold"}}>Timing</FormLabel>
                            <FormControl 
                                size="md"
                                as="select"
                                name="timing" 
                                value={timing} 
                                onChange={(e) => { setTiming(e.target.value) }}
                            >
                                <option value="" style={{color: "silver"}}></option>
                                {doseTimings.map((item, i) => <option value={item} key={item}>{item}</option>)}
                            </FormControl>
                        </FormGroup>
                    </Col>
                    <Col sm="4">
                        <FormGroup controlId="startTime">
                            <FormLabel style={{fontWeight: "bold"}}>Duration</FormLabel>
                            <FormControl 
                                size="md"
                                as="select"
                                name="continueTill" 
                                value={continueTill} 
                                onChange={(e) => { setContinueTill(e.target.value) }}
                            >
                                <option value="" style={{color: "silver"}}></option>
                                {continueTime.map((item, i) => <option value={item} key={item}>{item}</option>)}
                            </FormControl>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm="2">
                        <Button size="md" variant="success" style={{ width: "100%"}} onClick={addMedicine}>
                            <span style={{marginLeft: "5px"}}>Add Medicine</span>
                        </Button>      
                    </Col>
                </Row>
                <br/>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Ser</th>
                            <th>Name of Medicine</th>
                            <th>Dosage</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            medicineData.map((item, i) =>
                                <tr key={i}>
                                    <td>{i+1}</td>
                                    <td>{item.drugname}</td>
                                    <td>{item.dosage}</td>
                                    <td align="center">
                                        <Button size="sm" variant="danger" onClick={() => deleteMedicine(item.id)}>
                                            Delete
                                        </Button>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>

                </Table>
                <br/>
                <div style={{fontWeight: "bold", fontSize: 18}}>Advices</div>
                <br/>
                <Row>
                    <Col sm="8">
                        <FormGroup controlId="startTime">
                           
                            <FormControl 
                                size="md"
                                as="textarea"
                                rows={5}
                                name="advices" 
                                value={advices}
                                onChange={(e) => setAdvices(e.target.value)} 
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm="8">
                        <FormGroup controlId="startTime">
                            <FormLabel style={{fontWeight: "bold"}}>Follow Up</FormLabel>
                            <FormControl 
                                size="md"
                                type="text"
                                name="followUp" 
                                value={followUp}
                                onChange={(e) => setFollowUp(e.target.value)} 
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm="2">
                        <Button size="md" variant="success" disabled={submitting} style={{ width: "100%"}} onClick={savePrescription}>
                            <span style={{marginLeft: "5px"}}>Submit</span>
                        </Button>  
                    </Col>
                </Row>
                <br/>
            </form>
        </div>
    )
}


export default connect((state) => ({
    notifications: state.notifications,
}))(withRouter(DigitalPrescription));