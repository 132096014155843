import React from 'react'
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useState } from 'react';
import axios from 'axios'
import { setInStorage } from '../../../utils/storage';
import moment from 'moment'
import { withRouter } from 'react-router';

const url = "https://vpzwksy056.execute-api.us-east-1.amazonaws.com/prod";

const StripeCheckoutForm = (props) => {
  
  const stripe = useStripe();
  const elements = useElements();
  const [isPaying, setIsPaying] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault();
    toast.dismiss()
    if (!stripe || !elements) {
        return;
    }
    
    setIsPaying(true)
    
    try {
       //make payment method request
        const paymentMethodReq = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
        });
        if(paymentMethodReq.error) {
          toast.error(paymentMethodReq.error.message)
          setIsPaying(false)
        } else {
          const body = {
            amount: props.amount
          }
          const res = await axios.post(url, body)
          console.log(res.data)
          if(res.data.client_secret) {
            const client_secret = res.data.client_secret
 
            const result = await stripe.confirmCardPayment(client_secret, {
                payment_method: paymentMethodReq.paymentMethod.id
            })
            setInStorage("stripe", result)
            setInStorage("invoice", {inv: result.paymentIntent.id, pay: new Date()})
            setInStorage("inv_num", result.paymentIntent.id)
            setInStorage("pay_time", new Date())
            if (result.error) {
              toast.error(result.error.message);
              setIsPaying(false)
              props.handleClose()
            } else {
              if (result.paymentIntent.status === "succeeded") {
                props.handleSubmit()
                setIsPaying(false)
                toast.success("Payment Successful");
                props.handleClose()
                window.open(`/#${props.location.pathname}`)
              } else {
                toast.success("Payment Failed");
                setIsPaying(false)
                props.handleClose()
                window.open(`/#${props.location.pathname}`)
              } 
            }
          } else {
            toast.error(res.data.errorMessage)
            setIsPaying(false)
          }
      }
    } catch(err) {
        console.log(err.toString())
        setIsPaying(false)
    }
  };

  return (
    <form onSubmit={handleSubmit}>
    
      <CardElement options={{hidePostalCode: true}}/>
      <br/>
      <Button variant="success" type="submit" disabled={isPaying || !stripe}>
        Confirm & Pay
      </Button>
    </form>
  );
};

export default withRouter(StripeCheckoutForm);