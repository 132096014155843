import React, { useState } from "react";
import { Button, FormGroup, FormControl, FormLabel, Alert } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import axios from "axios";
import api from "../../../config/api.json";
import { md5Password } from "../../../utils/md5";

const color = "#229a88";

function ChangePassword(props) {
  const [password, setPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  function validateForm() {
    return password.length > 0 && retypePassword.length > 0;
  }

  function changePassword(password) {
    const body = {
        task: 'changeDoctorPassword',
        phone: props.location.pathname.split("/")[props.location.pathname.split("/").length - 1],
        password
    }
    const headers = { headers: { "Content-Type" : "application/json" } }
    axios.post(api.ProviderApi, body, headers)
    .then(res => {
        if(res.data){
            setPassword("");
            setSuccess("Password Changed Successfully");
            setRetypePassword("");
            setErrors([]);
        } else {
            setErrors(["Password didn't changed"]);
            setSuccess("");
        }
        setIsSubmitting(false);
    }
    )
    .catch(err => {
        setErrors([err.toString()]);
        setSuccess("");
        setIsSubmitting(false);
    })
  }

  function changePatientPassword(password) {
    const body = {
        task: 'changePatientPassword',
        phone: props.location.pathname.split("/")[props.location.pathname.split("/").length - 1],
        password
    }
    const headers = { headers: { "Content-Type" : "application/json" } }
    axios.post(api.ProviderApi, body, headers)
    .then(res => {
        if(res.data){
            setPassword("");
            setSuccess("Password Changed Successfully");
            setRetypePassword("");
            setErrors([]);
        } else {
            setErrors(["Password didn't changed"]);
            setSuccess("");
        }
        setIsSubmitting(false);
    }
    )
    .catch(err => {
        setErrors([err.toString()]);
        setSuccess("");
        setIsSubmitting(false);
    })
  }

  function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);
    if(password !== retypePassword) {
        setErrors(["Passwords are not equal"])
        setSuccess("");
        setIsSubmitting(false);
    } else {
        if(props.location.pathname.split("/")[1] === "doctor")
            changePassword(md5Password(password));
        else 
            changePatientPassword(md5Password(password));
    }
  }

  return (
    <div style={{overflow: "auto"}}>
        <h3 style={{textAlign: "center", color, fontWeight: "bold"}}>Change Password</h3>
        <form onSubmit={handleSubmit}>
            {
                errors.map((error,i) =>
                    <Alert key={i} variant="danger" style={{fontSize: "12px"}}>
                        {error}
                    </Alert>
                )
            }
            {
                success.length > 0 && 
                <Alert variant="success" style={{fontSize: "12px"}}>
                    {success}
                </Alert>
            }
            <FormGroup controlId="password" bssize="large" style={{textAlign: "left"}}>
                <FormLabel>New Password<span style={{color: "red"}}>*</span></FormLabel>
                <FormControl
                    value={password}
                    size="sm"
                    onChange={e => {
                        setPassword(e.target.value);
                        setSuccess("");
                        setErrors([]);
                    }}
                    placeholder="New Password"
                    type="password"
                />
            </FormGroup>
            <FormGroup controlId="retypassword" bssize="large" style={{textAlign: "left"}}>
                <FormLabel>Re-Enter New Password<span style={{color: "red"}}>*</span></FormLabel>
                <FormControl
                    value={retypePassword}
                    onChange={e => {
                        setRetypePassword(e.target.value);
                        setSuccess("");
                        setErrors([]);
                    }}
                    size="sm"
                    placeholder="Re-Enter New Password"
                    type="password"
                />
            </FormGroup>
            <div style={{textAlign: "center"}}>
                <Button size="sm" disabled={!validateForm() || isSubmitting ? true:false} type="submit" style={{background: color, borderColor: color, width: "auto", marginLeft: "auto", marginRight: "auto"}} variant="dark">
                    {!isSubmitting ? "Change Password" : "Loading..."}
                </Button>
            </div>
        </form>
    </div>
  );
}

export default withRouter(ChangePassword);