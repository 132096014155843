import React, {Component} from 'react';
import { Table, Card } from 'react-bootstrap';
import moment from "moment";
import axios from 'axios';
import api from "../../../config/api.json";
import { getFromStorage } from "../../../utils/storage";
import key from "../../../config/key.json";
import errorConfig from "../../../config/errorConfig.json";
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';

const color = "#229a88"

export default class OfficeHours extends Component {
    state = {
        times: ["00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00"],
        minDate: new Date(),
        maxDate: new Date(),
        selectDate: "",
        arrays: [],
        dates: [],
        newDates: [],
        isLoading: true,
        isUpdating: false
    }

    setDates = () => {
        var dates = [];
        var startDate = moment().startOf('week')
        var endDate = moment().endOf('week')
        
        this.setState({ 
            arrays: [], 
            minDate: new Date(startDate), 
            maxDate: new Date(endDate), 
            selectDate: `${startDate.format("DD/MM/YYYY")} - ${endDate.format("DD/MM/YYYY")}` 
        }, () => {
            this.getDoctorCalendar()
        })

        dates.push(startDate.format('YYYY-MM-DD'));          
        while(!startDate.isSame(endDate.format('YYYY-MM-DD'))){
            startDate = startDate.add(1, 'days');
            dates.push(startDate.format('YYYY-MM-DD'));
        }
        this.setState({ dates: dates, newDates: dates, time: "", dayAppointment: "", appointment_date: "", appointment_time: "" })
    }
    //Error Notification
    notifyError = error => {
        toast.error("" + error.toString());
    };
    getDoctorCalendar = () => {

        toast.dismiss()
        const headers = {
            headers: {"Content-Type": "application/json"}
        }
        
        const body = {
            task: "getDocCalendar",
            doctor_id: getFromStorage("doctor"),
            start_date: moment(this.state.minDate).format("YYYY-MM-DD 12:00:00"),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }

        axios 
        .post(api.PatientApi, body, headers)
        .then(res => {
            if(res.data[0]){
                this.setState({doctorCalendar: res.data}, () => {

                    let arr1 = [], arr2 = [], arr3 = [], arr4 = [], arr5 = [], arr6 = [], arr7 = [];
                    res.data[0][0].forEach((item, i) => {
                        if(parseInt(item) === 0) 
                            arr1.push(this.state.times[i] + `${" AM"}`) 
                        else 
                            arr1.push(null)
                    })
                    res.data[0][1].forEach((item, i) => {
                        if(parseInt(item) === 0) 
                            arr1.push(this.state.times[i] + `${" PM"}`) 
                        else 
                            arr1.push(null)
                    })
                    res.data[1][0].forEach((item, i) => {
                        if(parseInt(item) === 0) 
                            arr2.push(this.state.times[i] + `${" AM"}`) 
                        else 
                            arr2.push(null)
                    })
                    res.data[1][1].forEach((item, i) => {
                        if(parseInt(item) === 0) 
                            arr2.push(this.state.times[i] + `${" PM"}`) 
                        else 
                            arr2.push(null)
                    })
                    
                    res.data[2][0].forEach((item, i) => {
                        if(parseInt(item) === 0) 
                            arr3.push(this.state.times[i] + `${" AM"}`) 
                        else 
                            arr3.push(null)
                    })
                    res.data[2][1].forEach((item, i) => {
                        if(parseInt(item) === 0) 
                            arr3.push(this.state.times[i] + `${" PM"}`) 
                        else 
                            arr3.push(null)
                    })
                    res.data[3][0].forEach((item, i) => {
                        if(parseInt(item) === 0) 
                            arr4.push(this.state.times[i] + `${" AM"}`) 
                        else 
                            arr4.push(null)
                    })
                    res.data[3][1].forEach((item, i) => {
                        if(parseInt(item) === 0) 
                            arr4.push(this.state.times[i] + `${" PM"}`) 
                        else 
                            arr4.push(null)
                    })

                    res.data[4][0].forEach((item, i) => {
                        if(parseInt(item) === 0) 
                            arr5.push(this.state.times[i] + `${" AM"}`)
                        else 
                            arr5.push(null) 
                    })
                    res.data[4][1].forEach((item, i) => {
                        if(parseInt(item) === 0) 
                            arr5.push(this.state.times[i] + `${" PM"}`)
                        else 
                            arr5.push(null)
                    })
                    res.data[5][0].forEach((item, i) => {
                        if(parseInt(item) === 0) 
                            arr6.push(this.state.times[i] + `${" AM"}`) 
                        else 
                            arr6.push(null)
                    })
                    res.data[5][1].forEach((item, i) => {
                        if(parseInt(item) === 0) 
                            arr6.push(this.state.times[i] + `${" PM"}`) 
                        else 
                            arr6.push(null)
                    })

                    res.data[6][0].forEach((item, i) => {
                        if(parseInt(item) === 0) 
                            arr7.push(this.state.times[i] + `${" AM"}`) 
                        else 
                            arr7.push(null)
                    })
                    res.data[6][1].forEach((item, i) => {
                        if(parseInt(item) === 0) 
                            arr7.push(this.state.times[i] + `${" PM"}`)
                        else 
                            arr7.push(null) 
                    })
                    this.setState({arrays: [
                        arr1,
                        arr2,
                        arr3,
                        arr4,
                        arr5,
                        arr6,
                        arr7
                    ]})
                    this.setState({ isLoading: false, isUpdating: false})
                })
            } else {
                this.setState({isLoading: false, arrays: [[],[],[],[],[],[],[]], isUpdating: false})
                this.notifyError("Something went wrong")
            }
        })
        .catch(err => {
            this.setState({isLoading: false, arrays: [[],[],[],[],[],[],[]], isUpdating: false})
            this.notifyError(err.toString())
        })
    }

    //First function run when page load
    componentDidMount() {
        toast.dismiss();
        
        if (!getFromStorage(key.loginInfoKey)) {
            this.notifyError(errorConfig.notLoggedInError);
            this.props.history.push("/");
        } else if (getFromStorage(key.loginInfoKey).task === "authAdmin" || getFromStorage(key.loginInfoKey).task === "authDiagAdminPanel") {
            this.props.history.push("/Graph")
        } else if (getFromStorage(key.loginInfoKey).task === "patient") {
            this.props.history.push("/home");
        } else if (getFromStorage(key.loginInfoKey).task === "doctor") {
            this.setDates()
        } else if (getFromStorage(key.loginInfoKey).task === "provider") {
            this.props.history.push("/provider_doctors")
        }
    }
    render(){
        if(this.state.isLoading) {
            return (
                <div style={{marginLeft: this.props.isOpened ? (window.innerWidth > 560 ? "160px" : "20px") : "20px", marginTop: "70px", textAlign: "center"}}>
                    <CircularProgress />
                </div>
            )
        }
        return (
            <div style={{marginLeft: this.props.isOpened ? (window.innerWidth > 560 ? "160px" : "20px") : "20px", marginTop: "70px", marginRight: "20px"}}>
                <Card style={{padding: "20px", fontSize: "14px"}} className="shadow-4">
                <h4 style={{ color, textAlign: "center", fontWeight: "bold", fontSize: 22 }}>Doctor Weekly Office Hours</h4>
                <br/>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr style={{color, fontWeight: "bold"}}>
                            <th>Day</th>
                            <th>Availabilty Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
                        .map((item, i) =>
                            <tr key={item}>
                                <td>{item}</td>
                                <td>
                                    {!this.state.dates[i] ? "Not Available" : this.state.arrays[i].length === 0 ? "Not Available" : this.state.arrays[i].map(item => item && <div key={item} style={{borderBottom: "1px solid gray"}}>{item}</div>)}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                </Card>
            </div>
        )
    }
}