import React, { useState } from "react";
import { Button, FormGroup, FormControl, FormLabel, Alert } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { getFromStorage } from "../../../utils/storage";
import axios from 'axios'
import api from '../../../config/api.json'
import { toast } from "react-toastify";
import { md5Password } from "../../../utils/md5";

const color = "#229a88";

function ChangePassword(props) {
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  function validateForm() {
    return password.length > 0 && retypePassword.length > 0;
  }

  function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);
    if(password === retypePassword) {
        let body = {}
        if(getFromStorage("doctor")) {
            body = { task: "resetDoctorPassword", doctor_id:  getFromStorage("data") ?  getFromStorage("data").id : "", new_password: md5Password(password) }
        } else {
            body = { task: "resetPatientPassword", patient_id:  getFromStorage("data") ?  getFromStorage("data").di_id : "", new_password: md5Password(password) }
        }
        const headers = { headers: { "Content-Type": "application/json" } }
        axios
            .post(getFromStorage("doctor") ? api.DoctorApi : api.PatientApi, body, headers)
            .then(res => {
                console.log(res.data)
                if (!res.data.errorMessage) {
                    setPassword("")
                    setRetypePassword("")
                    setIsSubmitting(false);
                    toast.success("Password Changed Successfully")
                } else {
                    setErrors(["Error! Couldn't Update"])
                    setIsSubmitting(false);
                }
            })
            .catch(err => {
                setIsSubmitting(false);
                setErrors(["Something Went Wrong"])
            })
    } else {
        setIsSubmitting(false);
        setErrors(["Passwords do not match"])
    }
  }

  return (
    <div style={{overflow: "auto"}}>
        <h3 style={{textAlign: "center", color, fontWeight: "bold"}}>Change Password</h3>
        <form onSubmit={handleSubmit}>
            {
                errors.map((error,i) =>
                    <Alert key={i} variant="danger" style={{fontSize: "12px"}}>
                        {error}
                    </Alert>
                )
            }
            {
                success.length > 0 && 
                <Alert variant="success" style={{fontSize: "12px"}}>
                    {success}
                </Alert>
            }
            {/* <FormGroup controlId="phone_number" bssize="large" style={{textAlign: "left"}}>
                <FormLabel>Old Password<span style={{color: "red"}}>*</span></FormLabel>
                <FormControl
                    type="text"
                    placeholder="Old Password"
                    value={oldPassword}
                    size="sm"
                    onChange={e => {
                            setOldPassword(e.target.value);
                            setSuccess("");
                            setErrors([]);
                        }}
                />
            </FormGroup> */}
            <FormGroup controlId="password" bssize="large" style={{textAlign: "left"}}>
                <FormLabel>New Password<span style={{color: "red"}}>*</span></FormLabel>
                <FormControl
                    value={password}
                    size="sm"
                    onChange={e => {
                        setPassword(e.target.value);
                        setSuccess("");
                        setErrors([]);
                    }}
                    placeholder="New Password"
                    type="password"
                />
            </FormGroup>
            <FormGroup controlId="retypassword" bssize="large" style={{textAlign: "left"}}>
                <FormLabel>Re-Enter New Password<span style={{color: "red"}}>*</span></FormLabel>
                <FormControl
                    value={retypePassword}
                    onChange={e => {
                        setRetypePassword(e.target.value);
                        setSuccess("");
                        setErrors([]);
                    }}
                    size="sm"
                    placeholder="Re-Enter New Password"
                    type="password"
                />
            </FormGroup>
            <div style={{textAlign: "center"}}>
                <Button size="sm" disabled={!validateForm() || isSubmitting ? true:false} type="submit" style={{background: color, borderColor: color, width: "auto", marginLeft: "auto", marginRight: "auto"}} variant="dark">
                    {!isSubmitting ? "Change Password" : "Loading..."}
                </Button>
            </div>
        </form>
    </div>
  );
}

export default withRouter(ChangePassword);