import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios'
import Api from '../../../config/api.json';
import { getFromStorage, removeFromStorage } from '../../../utils/storage';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import CardDialog from './CardDialog';
import ConfirmDialog from './ConfirmDialog';
import { connect } from 'react-redux';
import { getNotifications, getToken } from '../saveToken';

const Invoice = (props) => {
    
    const data = getFromStorage("order_lab_test") && getFromStorage("order_lab_test").data;
    const institute = getFromStorage("order_lab_test") && getFromStorage("order_lab_test").institute
    const sum = data && data.map(item => item.rate).reduce((a, b) => a + b, 0)
    const [isPaymentConfirmed, setIsPaymentConfirmed] = useState(false)
    const [invoiceId, setInvoiceId] = useState("")
    const [showError, setShowError] = useState(false)
    const [invoice, setInvoice] = useState("")
    const [paytime, setPayTime] = useState("")
    const [message, setMessage] = useState("")
    const [status, setStatus] = useState("")
    const [show, setShow] = useState(false)
    const [isProcessing, setIsProcessing] = useState("")
    const [isFailed, setIsFailed] = useState(false)
    const [invoiceDB, setInvoiceDB] = useState("")
    const [orderTime, setOrderTime] = useState("")

    //Payment Confirmation
    const confirmAndPay = async () => {
        try {
            let testsData = []
 
            data.forEach(item => {
                testsData.push({id: item.id1, name: item.name, rate: item.rate})
            })
            const body = {
                task: "saveOrderedTests",
                patient_id: getFromStorage("patient"),
                radiology_tests: JSON.stringify(testsData),
                institute: JSON.stringify({institute: institute.institute, address: institute.address, phone: institute.phone, discount_rate: institute.discount_rate}),
                payment_invoice_no: invoice,
                total_amount: data.map(item => item.rate).reduce((a, b) => a + b, 0)
            }
            const res1 = await axios.post(Api.ORDER_TESTS_API, body)
            console.log(res1.data)
            setIsPaymentConfirmed(true)
            getOrderData()
        } catch(err) {
            setIsPaymentConfirmed(true)
        }
    }

    const getOrderData = async () => {
        try {
            const body1 = {
                task: "getOrderedTestsList",
                patient_id: getFromStorage("patient")
            }

            const res1 = await axios.post(Api.ORDER_TESTS_API, body1)

            if(!res1.data.errorMessage) {
                const body = {
                    task: "getOrderedTests",
                    invoice_no: res1.data[res1.data.length - 1].invoice_no
                }
                setInvoiceDB(res1.data[res1.data.length - 1].invoice_no)
                setOrderTime(res1.data[res1.data.length - 1].order_time)
                const res2 = await axios.post(Api.ORDER_TESTS_API, body)
                if(!res1.data.errorMessage) {
                    console.log(res2.data)
                } else {
                    alert(res1.data.errorMessage)
                }
            } else {
                alert(res1.data.errorMessage)
            }
        } catch(err) {
            console.log(err.toString())
        }
    }

    //Query Payment
    const queryPayment = (paymentID, token_id) => {
        const body = {
            task: "queryPayment",
            paymentID,
            token_id
        }
        const headers = {
            headers: { "Content-Type": "application/json" }
        }

        axios.post(Api.PROVIDER_API, body, headers)
            .then(res => {
                if (res.data.agreementStatus === "Completed") {
                    confirmAndPay()
                    if(getFromStorage("invoice")) {
                        setIsPaymentConfirmed(true)
                        setInvoiceId(getFromStorage("invoice").inv)
                        setShowError(true)
                        setInvoice(getFromStorage("invoice").inv)
                        setPayTime(getFromStorage("invoice").pay)
                        setMessage("Payment Successful")
                        removeFromStorage("order_lab_test")
                    }
                } else {
                    if(getFromStorage("invoice")) {
                        setShowError(true)
                        setInvoice(getFromStorage("invoice").inv)
                        setPayTime(getFromStorage("invoice").pay)
                        setMessage("Payment didn't completed, please try again")
                        setStatus(res.data.statusMessage)
                        setIsFailed(true)
                    }
                    setIsProcessing(false)
                    removeFromStorage("card_token")
                    removeFromStorage("bkash");
                }
            })
            .catch(err => {
                setIsProcessing(false)
                alert(err.toString())
                removeFromStorage("card_token")
                removeFromStorage("bkash");
            })
    }

    //Execute Payment
    const executePayment = (paymentID, token_id) => {
        const body = {
            task: "executePayment",
            paymentID,
            token_id
        }
        const headers = {
            headers: { "Content-Type": "application/json" },
            timeout: 30000
        }

        axios.post(Api.PROVIDER_API, body, headers)
            .then(res => {
                if (res.data.statusCode === "0000") {
                    if(getFromStorage("invoice")) {
                        confirmAndPay()
                        setIsPaymentConfirmed(true)
                        setInvoiceId(getFromStorage("invoice").inv)
                        setShowError(true)
                        setInvoice(getFromStorage("invoice").inv)
                        setPayTime(getFromStorage("invoice").pay)
                        setMessage("Payment Successful")
                        removeFromStorage("order_lab_test")
                    }
                } else {
                    setIsProcessing(false)
                    if(getFromStorage("invoice")) {
                        setShowError(true)
                        setInvoice(getFromStorage("invoice").inv)
                        setPayTime(getFromStorage("invoice").pay)
                        setMessage("Payment Failed")
                        setStatus(res.data.statusMessage)
                        setIsFailed(true)
                        removeFromStorage("bkash")
                        removeFromStorage("card_token")
                    }
                }

            })
            .catch(err => {
                queryPayment(paymentID, token_id)
            })
    }

    useEffect(() => {
        if(getFromStorage("patient")) {
            setInvoiceId(new Date().getTime())
            getNotifications(props.dispatch, props.notifications);
            getToken("patients");
            if(getFromStorage("bkash") && getFromStorage("card_token")) {
                if(window.location.search) {
                    setStatus(window.location.search && window.location.search.split("&")[1].split("status=")[1])
                    if(
                        window.location.search.split("&")[1].split("status=")[1] === "success" && 
                        window.location.search.split("&")[0].split("?paymentID=")[1] === getFromStorage("bkash").paymentID
                    ) {
                        setIsProcessing(true)
                        executePayment(getFromStorage("bkash").paymentID, getFromStorage("card_token")); 
                        removeFromStorage("stripe")
                    } else if(
                        window.location.search.split("&")[1].split("status=")[1] === "cancel" && 
                        window.location.search.split("&")[0].split("?paymentID=")[1] === getFromStorage("bkash").paymentID
                    ) {
                        console.log(getFromStorage("invoice"))
                        setStatus(window.location.search.split("&")[1].split("status=")[1])
                        if(getFromStorage("invoice")) {
                            setShowError(true)
                            setInvoice(getFromStorage("invoice").inv)
                            setPayTime(getFromStorage("invoice").pay)
                            setMessage("Payment Cancelled")
                            removeFromStorage("bkash")
                            removeFromStorage("card_token")
                            removeFromStorage("stripe")
                        }
                    } else if(
                        window.location.search.split("&")[1].split("status=")[1] === "failure" && 
                        window.location.search.split("&")[0].split("?paymentID=")[1] === getFromStorage("bkash").paymentID
                    ) {
                        setStatus(window.location.search.split("&")[1].split("status=")[1])
                        if(getFromStorage("invoice")) {
                            setShowError(true)
                            setInvoice(getFromStorage("invoice").inv)
                            setPayTime(getFromStorage("invoice").pay)
                            setMessage("Payment Failed")
                            removeFromStorage("bkash")
                            removeFromStorage("card_token")
                            removeFromStorage("stripe")
                        }
                    }
                }
            } else if(getFromStorage("stripe")) {
                if(getFromStorage("stripe").paymentIntent.status === "succeeded") {
                    getOrderData()
                }
                setShowError(true)
                setStatus(getFromStorage("stripe").paymentIntent.status === "succeeded" ? "success" : "failure")

                removeFromStorage("stripe")
                removeFromStorage("card_token")
                removeFromStorage("bkash")
                removeFromStorage("appointments");
            }
        } else {
            props.history.replace("/")
        }
    }, [])

    return (
        <div style={{marginLeft: props.isOpened ? (window.innerWidth > 560 ? "160px" : "20px") : "20px", marginTop: "70px", marginRight: "20px"}}>
            <form>
                <h1 style={{textAlign: "center"}}>Invoice</h1>
                <div>
                    {/* {invoiceDB.length ? <Row><Col md="3" style={{fontWeight: "bold"}}>Invoice Number DB:</Col><Col md="5">{invoiceDB}</Col></Row> : <></>}
                    {invoice.length ? <Row><Col md="3" style={{fontWeight: "bold"}}>Invoice Number bKash/Stripe:</Col><Col md="5">{invoice}</Col></Row> : <></>}
                    {paytime !== "" && <Row><Col style={{fontWeight: "bold"}} md="3">Date & Time:</Col><Col md="5">{paytime}</Col></Row>} */}
                    {institute && <Row><Col md="3" style={{fontWeight: "bold"}}>Institute:</Col><Col md="5">{institute.institute}</Col></Row>}
                    {institute && <Row><Col md="3" style={{fontWeight: "bold"}}>Address:</Col><Col md="5">{institute.address}</Col></Row>}
                    {institute && <Row><Col md="3" style={{fontWeight: "bold"}}>Phone:</Col><Col md="5">{institute.phone}</Col></Row>}
                </div>
                <br/>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Rate</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data && data.map((item, i) =>
                                <tr key={i}>
                                    <td>{item.name}</td>
                                    <td>{item.rate}</td>
                                </tr>
                            )
                        }
                
                        {data.length ? 
                        <>
                            <tr>
                                <td style={{fontWeight: "bold"}}>SubTotal:</td>
                                <td>{sum}</td>
                            </tr>
                            <tr>
                                <td style={{fontWeight: "bold"}}>Discount:</td>
                                <td>{institute.discount_rate}%</td>
                            </tr>
                            <tr>
                                <td style={{fontWeight: "bold"}}>Total:</td>
                                <td>{sum * ((100 - institute.discount_rate)/100)}</td>
                            </tr>
                        </>
                        :
                        null
                        }
                    </tbody>

                </Table>
                <Row>
                    <Col sm="2">
                        <Button onClick={() => setShow(true)} size="md" variant="success" disabled={data ? !data.length : !data} style={{ width: "100%"}}>
                            <span style={{marginLeft: "5px"}}>Confirm & Pay</span>
                        </Button>    
                    </Col>
                </Row>
            </form>
            <CardDialog id={invoiceId} message={message} cost={sum * ((100 - institute.discount_rate)/100)} show={show} handleClose={() => setShow(false)} handleSubmit={confirmAndPay}/>
            {showError && <ConfirmDialog orderTime={orderTime} invoiceDB={invoiceDB} status={message} statuss={status} invoice={invoice} time={paytime} show={showError} message={status} handleClose={() => setShowError(false)}/>}
        </div>
    )
}


export default connect((state) => ({
    notifications: state.notifications,
}))(withRouter(Invoice));