import React, { useState } from "react";
import { Modal, Button, Alert } from "react-bootstrap";
import Files from "react-butterfiles";
import { FileCopy, Photo } from "@material-ui/icons";
import { Form } from "react-bootstrap";

export default function UploadDocument({setFile, base64, uploadDocument, isUploading, setChecked, checked}) {
    const [errors, setErrors] = useState([]);

    return (
      <>
        <Files
            multiple={false} 
            maxSize="2000mb"
            convertToBase64={true}
            accept={["image/jpg","image/jpeg", "image/png"]}
            onSuccess={files => {
                        setFile(files)
                        setErrors([]);
                    }}
            onError={errors => {
                        setFile([]);
                        setErrors(errors);
                    }}
        >
            {({ browseFiles, getDropZoneProps, getLabelProps }) => (
                <>
                    <div {...getDropZoneProps({ className: "myDropZone" })}/>
                    <div>
                        {base64 && base64.length > 0 && !base64.includes("application/pdf") ?
                            <div style={{overflowX: "auto"}}>
                                <img src={base64} alt="profile" style={{width: "auto", height: "auto"}}/>
                                <br/>
                            </div>
                            :
                            base64 && base64.length > 0 && base64.includes("application/pdf") &&
                            <div style={{overflowX: "auto"}}>
                                <img src={require("../../../image/xlsx.png")} alt="profile" style={{width: "100px", height: "auto"}}/>
                                <br/>
                            </div>
                        }
                    </div>
                    {errors.map(error => (
                        <Alert variant="danger" key={error} style={{fontSize: "12px"}}>
                            {error.file.name} - {error.type}
                        </Alert>
                    ))}
                    
                    <br/>
                    <div 
                        style={{textAlign: "center", cursor: "pointer", fontSize: "20px", color: "#229a88", width: "100%", paddingTop: "30px", paddingBottom: "30px", background: "rgba(34, 154, 136,0.5)", margin: 0}} 
                        onClick={browseFiles}
                        className="profile-modal-upload"
                    >
                        <FileCopy style={{fontSize: "30px"}}/>
                        <span style={{marginLeft: "5px"}}>Select Document</span>
                    </div>
                </>
            )}
        </Files>
        <br/>
        <Form>
            <Form.Group controlId="formBasicRadio">
                <Form.Check type="radio" label="Prescription Files" value={checked} checked={checked === 0} onChange={() => setChecked(0)} id="radio-documents0"/>
            </Form.Group>
            <Form.Group controlId="formBasicRadio">
                <Form.Check type="radio" label="Pathology Files" value={checked} checked={checked === 1} onChange={() => setChecked(1)} id="radio-documents1"/>
            </Form.Group>
            <Form.Group controlId="formBasicRadio">
                <Form.Check type="radio" label="Radiology Document" value={checked} checked={checked === 2} onChange={() => setChecked(2)} id="radio-documents2"/>
            </Form.Group>
            <Form.Group controlId="formBasicRadio">
                <Form.Check type="radio" label="Miscellaneous Files" value={checked} checked={checked === 3} onChange={() => setChecked(3)} id="radio-documents3"/>
            </Form.Group>
        </Form>
        {base64 && base64.length > 0 && 
        <Button variant="success" disabled={isUploading} onClick={uploadDocument} size="sm">
            {!isUploading ? "Upload" : "Uploading Please Wait..."}
        </Button>}
      </>
    );
}