import React, {Component} from "react";
import { Card, Table, Button, Row, Col, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import key from "../../../config/key.json";
import { toast } from "react-toastify";
import { getFromStorage, removeFromStorage, setInStorage } from "../../../utils/storage";
import errorConfig from "../../../config/errorConfig.json";
import { withRouter } from "react-router";
import api from "../../../config/api.json";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { VideoCall, CalendarToday, Delete, ArrowLeft, ArrowRight, Description } from "@material-ui/icons";
import { Markup } from 'interweave';
import TimePicker from 'react-bootstrap-time-picker';
import CardDialog from "./CardDialog";
import ConfirmDialog from "./ConfirmDialog";
import { getToken, getNotifications } from "../saveToken";
import { connect } from "react-redux";

const color = "#229a88";
const designations = ["Professor", "Associate Professor", "Assistant Professor", "Senior Consultant", "Junior Consultant", "Consultant", "Medical Officer"]
const specailities = [
    "Andrologist & Lap Surgeon",
    "Cardiology",
    "Cardio-Vascular Surgery",
    "Chest Diseases",
    "COVID-19(Coronavirus)",
    "Dentist",
    "Dermatology",
    "Diabetes & Hormone",
    "Dietician",
    "ENT",
    "EYE",
    "Gastroenterology",
    "General Physician",
    "General Surgery",
    "Gynae & Gynaeoncology",
    "Gynaecology & Infertility",
    "Haematology",
    "Medicine",
    "Nephrology (Nephrologist & Medical Specialist)",
    "Neurologist",
    "Neurosurgeon & Spine Surgeon",
    "Nutrition (Overweight, child nutrition & diet therapy)",
    "Nutrition Consultant",
    "Onclogy (Cancer)",
    "Orthopaedic",
    "Paediatrician & Paediatric cardiology",
    "Pain Medicine",
    "Pediatrics & Neonate (Child)",
    "Physical Medicine",
    "Physiotherapy",
    "Plastic Surgery",
    "Psychiatrist",
    "Respiratory Medicine",
    "Skin & VD",
    "Urologist",
    "Vascular Surgery"
];

class Home extends Component {
    state = {
        times: ["00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00"],
        appointments: [],
        doctors: [],
        isLoading: true,
        day: "",
        startTime: 0,
        endTime: 0,
        tableData: [],
        tempTableData: [],
        isSubmitting: false,
        designation: "",
        speciality: "",
        isSearching: false,
        minDate: new Date(),
        maxDate: new Date(),
        selectDate: "",
        dates: [],
        time: "",
        dayAppointment: "",
        arrays: [],
        doc_id: "",
        doc_name: "",
        doctorCalendar:[],
        appointment_time: "",
        appointment_date: "",
        show: false,
        isConfirm: false,
        status: "",
        cost: "0",
        message: "",
        isPaying: false,
        firebaseToken: "",
        rateField: "",
        freeService: false
    }
    //Error Notification
    notifyError = error => {
        toast.error("" + error.toString());
    };

    //Success Notification
    notifySuccess = success => {
        toast.success("" + success.toString());
    };

    //fetch appointments
    fetchAppointments = () => {
        const body = {
            task: 'getAppointmentList',
            doctor_id: getFromStorage("doctor"),
            status: "0",
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        const headers = { headers: { "Content-Type" : "application/json" } }
        axios.post(api.DoctorApi, body, headers)
        .then(res => {
            if(res.data.length){
                this.setState({appointments: res.data})
            } else {
                this.setState({appointments: []})
            }
            this.setState({isLoading: false})
        }
        )
        .catch(err => {
            this.notifyError(err.toString())
            this.setState({isLoading: false})
        })
    }

    whenChangeHandler = (e) => {
        const {name, value} = e.target
        this.setState({[name] : value})
    }

    //fetch appointments
    fetchDoctorList = () => {
        const body = {
            task: 'getDocList',
            speciality: this.state.speciality,
            designation: this.state.designation
        }
        const headers = { headers: { "Content-Type" : "application/json" } }
        axios.post(api.PatientApi, body, headers)
        .then(res => {
            if(res.data){
                this.setState({doctors: res.data})
            } else {
                this.notifyError("Not Found")
            }
            this.setState({isLoading: false, isSearching: false})
        }
        )
        .catch(err => {
            this.notifyError(err.toString())
            this.setState({isLoading: false, isSearching: false})
        })
    }

    dateTimeChecker = item => {
        if (item.startTime < this.state.endTime && item.endTime > this.state.startTime) {
          return true;
        }
        return false;
    };

    addTime = () => {
        toast.dismiss()
        if(this.state.startTime !== "" && this.state.endTime !== "" && this.state.day !== "") {
            if(this.state.startTime === 0) {
                this.notifyError("Start Time can not be 12:00 AM")
            } else if(this.state.startTime === this.state.endTime) {
                this.notifyError("Start Time and End Time can not be same")
            } else if(this.state.startTime > this.state.endTime){
                this.notifyError("Start Time should be before End Time")
            } else {
                let arr = this.state.tableData;
                let arr2 = this.state.tempTableData
            
                if(arr.find(item => item.day === this.state.day)) {
                    if(!arr.find(item => this.dateTimeChecker(item))) {
                        arr.push({
                            id: new Date().getTime(),
                            day: this.state.day,
                            startTime: this.state.startTime,
                            endTime: this.state.endTime
                        })
                        arr2.push({
                            id: new Date().getTime(),
                            day: this.state.day,
                            startTime: moment.utc(moment.duration(this.state.startTime, "seconds").asMilliseconds()).format("hh:mm A"),
                            endTime: moment.utc(moment.duration(this.state.endTime, "seconds").asMilliseconds()).format("hh:mm A")
                        })
        
                        this.setState({ tableData: arr, tempTableData: arr2 })
                    } else {
                        this.notifyError("This timeslot already added")
                    }
                } else {
                    arr.push({
                        id: new Date().getTime(),
                        day: this.state.day,
                        startTime: this.state.startTime,
                        endTime: this.state.endTime
                    })
                    arr2.push({
                        id: new Date().getTime(),
                        day: this.state.day,
                        startTime: moment.utc(moment.duration(this.state.startTime, "seconds").asMilliseconds()).format("hh:mm A"),
                        endTime: moment.utc(moment.duration(this.state.endTime, "seconds").asMilliseconds()).format("hh:mm A")
                    })
                    this.setState({ tableData: arr, tempTableData: arr2})
                }
            }
        } else {
            this.notifyError("All Fields Required")
        }
    }

    confirmTime = () => {
        const headers = {
            headers: {"Content-Type": "application/json"}
        }

        const body = {
            task: "setDocCalendar",
            doctor_id:  getFromStorage("doctor"),
            timeslots: this.state.tempTableData.map(item => ({
                day: item.day.toLowerCase(),
                startTime: item.startTime,
                endTime: item.endTime
            })),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        axios 
        .post(api.DoctorApi, body, headers)
        .then(res => {
            if(res.data){
                this.setState({tableData: [], tempTableData: [], day: "",
                startTime: 0, endTime: 0})
                this.notifySuccess("Calender Set Successfully")
            } else {
                this.notifyError("Something went wrong")
            }
            this.setState({isSubmitting: false})
        })
        .catch(err => {
            this.notifyError("Something went wrong")
            this.setState({isSubmitting: false})
        })
    }

    nextWeek = () => {
        var dates = [];
        var start = moment(this.state.minDate).add(1, 'week');
        var end = moment(this.state.maxDate).add(1, 'week');

        this.setState({arrays: [], minDate: new Date(start), maxDate: new Date(end),  selectDate: `${start.format("DD/MM/YYYY")} - ${end.format("DD/MM/YYYY")}` }, () => {
            this.getDoctorCalendar()
        })
        dates.push(start.format('YYYY-MM-DD'));          
        while(!start.isSame(end.format('YYYY-MM-DD'))){
            start = start.add(1, 'days');
            dates.push(start.format('YYYY-MM-DD'));
        }
        this.setState({ dates: dates.map(item => new Date(new Date(item).setHours(0,0,0,0)) >= new Date(new Date().setHours(0,0,0,0)) ? item : null), time: "", dayAppointment: "", appointment_date: "", appointment_time: ""})
    }

    setDates = () => {
        var dates = [];
        var startDate = moment().startOf('week')
        var endDate = moment().endOf('week')
        
        this.setState({ 
            arrays: [], 
            minDate: new Date(startDate), 
            maxDate: new Date(endDate), 
            selectDate: `${startDate.format("DD/MM/YYYY")} - ${endDate.format("DD/MM/YYYY")}` 
        }, () => {
            this.getDoctorCalendar()
        })

        dates.push(startDate.format('YYYY-MM-DD'));          
        while(!startDate.isSame(endDate.format('YYYY-MM-DD'))){
            startDate = startDate.add(1, 'days');
            dates.push(startDate.format('YYYY-MM-DD'));
        }
        this.setState({ dates: dates.map(item =>new Date(new Date(item).setHours(0,0,0,0)) >= new Date(new Date().setHours(0,0,0,0)) ? item : null), time: "", dayAppointment: "", appointment_date: "", appointment_time: "" })
    }

    previousWeek = () => {
        var dates = [];
        var start = moment(this.state.minDate).subtract(1, 'week');
        var end = moment(this.state.maxDate).subtract(1, 'week');

        this.setState({arrays: [], minDate: new Date(start), maxDate: new Date(end),  selectDate: `${start.format("DD/MM/YYYY")} - ${end.format("DD/MM/YYYY")}` }, () => {
            this.getDoctorCalendar();
        })
        dates.push(start.format('YYYY-MM-DD'));          
        while(!start.isSame(end.format('YYYY-MM-DD'))){
            start = start.add(1, 'days');
            dates.push(start.format('YYYY-MM-DD'));
        }
        this.setState({ dates:  dates.map(item =>new Date(new Date(item).setHours(0,0,0,0)) >= new Date(new Date().setHours(0,0,0,0)) ? item : null), time: "", dayAppointment: "", appointment_date: "", appointment_time: ""})
    }
    

    getDoctorCalendar = () => {
        toast.dismiss()
        const headers = {
            headers: {"Content-Type": "application/json"}
        }
        
        const body = {
            task: "getDocCalendar",
            doctor_id: this.state.doc_id,
            start_date: moment(this.state.minDate).format("YYYY-MM-DD 12:00:00"),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }

        axios 
        .post(api.PatientApi, body, headers)
        .then(res => {
            if(res.data[0]){
                this.setState({doctorCalendar: res.data}, () => {

                    let arr1 = [], arr2 = [], arr3 = [], arr4 = [], arr5 = [], arr6 = [], arr7 = [];
                    res.data[0][0].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr1.push(this.state.times[i] + `${" AM"}`) 
                    })
                    res.data[0][1].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr1.push(this.state.times[i] + `${" PM"}`) 
                    })
                    res.data[1][0].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr2.push(this.state.times[i] + `${" AM"}`) 
                    })
                    res.data[1][1].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr2.push(this.state.times[i] + `${" PM"}`) 
                    })
                    
                    res.data[2][0].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr3.push(this.state.times[i] + `${" AM"}`) 
                    })
                    res.data[2][1].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr3.push(this.state.times[i] + `${" PM"}`) 
                    })
                    res.data[3][0].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr4.push(this.state.times[i] + `${" AM"}`) 
                    })
                    res.data[3][1].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr4.push(this.state.times[i] + `${" PM"}`) 
                    })

                    res.data[4][0].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr5.push(this.state.times[i] + `${" AM"}`) 
                    })
                    res.data[4][1].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr5.push(this.state.times[i] + `${" PM"}`) 
                    })
                    res.data[5][0].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr6.push(this.state.times[i] + `${" AM"}`) 
                    })
                    res.data[5][1].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr6.push(this.state.times[i] + `${" PM"}`) 
                    })

                    res.data[6][0].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr7.push(this.state.times[i] + `${" AM"}`) 
                    })
                    res.data[6][1].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr7.push(this.state.times[i] + `${" PM"}`) 
                    })
                    this.setState({arrays: [
                        arr1,
                        arr2,
                        arr3,
                        arr4,
                        arr5,
                        arr6,
                        arr7
                    ]})
                    this.setState({ isLoading: false})
                })
            } else {
                this.setState({isLoading: false})
                this.notifyError("Something went wrong")
            }
        })
        .catch(err => {
            this.setState({isLoading: false})
            this.notifyError(err.toString())
        })
    }


    //get last appointment with doctor
    getLastAppointment = (data) => {
        this.setState({isSubmitting: true}, () => {
            const body ={task:"getLastAppointmentWithDoctor", patient_id: getFromStorage("patient"), doctor_id: this.state.doc_id, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone}
            const headers = { "Content-Type" : "application/json" }
            axios.post(api.PatientApi, body, headers)
            .then(res => {
                if(!res.data.errorMessage){
                    this.setState({rateField: res.data[0].rateField, cost: res.data[0].appointment_cost, show: true, message: res.data[0].appointment_id === 0 ? `This is your first visit with this doctor. Your appointment cost is ${res.data[0].appointment_cost}` : `Your last visit with this doctor was on ${moment(res.data[0].start_time, "YYYYMMDDhh:mm:ss").format("DD-MM-YYYY hh:mm A")}. Your appointment cost is ${res.data[0].appointment_cost}`})
                } 
                this.setState({isSubmitting: false})
            })
            .catch(err => {
                this.notifyError(err.toString());
                this.setState({isSubmitting: false})
            })
        })
    }

    setAppointment = () => {
        this.setState({ isSubmitting: true}, () => {
            const headers = {
                headers: {"Content-Type": "application/json"}
            }
        
            const body = {
                task: "createAppointment",
                doctor_id: getFromStorage("appointments").doc_id,
                patient_id: getFromStorage("patient"),
                time_slot: getFromStorage("appointments").date,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }
            axios 
            .post(api.PatientApi, body, headers)
            .then(res => {
                console.log(res.data)
                if(!res.data.errorMessage){
                    this.notifySuccess("Appointment set successfully")
                    this.setState({ appointment_date: "", appointment_time: "", doc_id: "", doc_name: "" })
                } else {
                    this.notifyError(res.errorMessage)
                }
                removeFromStorage("bkash");
                removeFromStorage("card_token")
                removeFromStorage("appointments");
                this.setState({isSubmitting: false})
            })
            .catch(err => {
                this.setState({isSubmitting: false})
                this.notifyError(err.toString())
                removeFromStorage("appointments");
                removeFromStorage("card_token")
                removeFromStorage("bkash");
            })
        })
    }

    //First function run when page load
    componentDidMount() {
        toast.dismiss();
        
        if (!getFromStorage(key.loginInfoKey)) {
            this.notifyError(errorConfig.notLoggedInError);
            this.props.history.push("/");
        } else if (getFromStorage(key.loginInfoKey).task === "authAdmin" || getFromStorage(key.loginInfoKey).task === "authDiagAdminPanel") {
            this.props.history.push("/Graph")
        } else if (getFromStorage(key.loginInfoKey).task === "patient") {
            getNotifications(this.props.dispatch, this.props.notifications)
            getToken("patients");
            this.fetchDoctorList()
            if(getFromStorage("bkash")) {
                if(window.location.search) {
                    if(
                        window.location.search.split("&")[1].split("status=")[1] === "success" && 
                        window.location.search.split("&")[0].split("?paymentID=")[1] === getFromStorage("bkash").paymentID
                    ) {
                        this.setState({isPaying: true}, () => {
                            this.executePayment();
                        })
                       
                    } else if(
                        window.location.search.split("&")[1].split("status=")[1] === "cancel" && 
                        window.location.search.split("&")[0].split("?paymentID=")[1] === getFromStorage("bkash").paymentID
                    ) {
                        this.setState({isConfirm: true, status:  window.location.search.split("&")[1].split("status=")[1]})
                        removeFromStorage("bkash")
                        removeFromStorage("card_token")
                        removeFromStorage("appointments");
                        removeFromStorage("stripe")
                    } else if(
                        window.location.search.split("&")[1].split("status=")[1] === "failure" && 
                        window.location.search.split("&")[0].split("?paymentID=")[1] === getFromStorage("bkash").paymentID
                    ) {
                        this.setState({isConfirm: true, status:  window.location.search.split("&")[1].split("status=")[1]})
                        removeFromStorage("card_token")
                        removeFromStorage("bkash")
                        removeFromStorage("appointments");
                        removeFromStorage("stripe")
                    }
                }
            } else if(getFromStorage("stripe")) {
                this.setState({isConfirm: true, status: getFromStorage("stripe").paymentIntent.status === "succeeded" ? "success" : "failure"}, () => {
                    removeFromStorage("stripe")
                    removeFromStorage("card_token")
                    removeFromStorage("bkash")
                    removeFromStorage("appointments");
                })
            }
        } else if (getFromStorage(key.loginInfoKey).task === "doctor") {
            getNotifications(this.props.dispatch, this.props.notifications)
            getToken("doctors");
            this.fetchAppointments()
        } else if (getFromStorage(key.loginInfoKey).task === "provider") {
            this.props.history.push("/provider_doctors")
        }
    }

    queryPayment = () => {
        const body = {
            task: "queryPayment",
            paymentID: getFromStorage("bkash").paymentID,
            token_id: getFromStorage("card_token")
        }
        const headers = {
            headers: {"Content-Type": "application/json"}
        }

        axios.post(api.CardApi, body, headers)
        .then(res => {
            if(res.data.agreementStatus === "Completed"){
                this.setAppointment();
                this.setState({isConfirm: true, status: "success", isPaying: false})
                removeFromStorage("stripe")
            } else {
                this.setState({isConfirm: true, status: "Payment didn't completed, please try again", isPaying: false })
                removeFromStorage("card_token")
                removeFromStorage("appointments");
                removeFromStorage("bkash");
                removeFromStorage("stripe")
            }
        })
        .catch(err => {
            this.notifyError(err.toString())
            this.setState({isPaying: false})
            removeFromStorage("card_token")
            removeFromStorage("appointments");
            removeFromStorage("bkash");
            removeFromStorage("stripe")
        })
    }

    executePayment = () => {
        const body = {
            task: "executePayment",
            paymentID: getFromStorage("bkash").paymentID,
            token_id: getFromStorage("card_token")
        }
        const headers = {
            headers: {"Content-Type": "application/json"},
            timeout: 30000
        }

        axios.post(api.CardApi, body, headers)
        .then(res => {
            if(getFromStorage("appointments") && res.data.statusCode === "0000"){
                this.setAppointment();
                this.setState({isConfirm: true, status: "success", isPaying: false})
            } else {
                this.setState({isConfirm: true, status: res.data.statusMessage, isPaying: false })
                removeFromStorage("card_token")
                removeFromStorage("appointments");
                removeFromStorage("bkash");
                removeFromStorage("stripe")
            }
        })
        .catch(err => {
            this.queryPayment();
            removeFromStorage("appointments");
        })
    }

    setFreeAppointmentRequest = () => {
        this.setState({isSubmitting: true}, () => {
            const data = {
                task: "setFreeAppointmentRequest",
                doctor_id: getFromStorage("appointments").doc_id,
                patient_id: getFromStorage("patient"),
                time_slot: getFromStorage("appointments").date,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }

            axios.post(api.PatientApi, data)
            .then(res => {
                console.log(res.data)
                if (!res.data.errorMessage) {
                    this.notifySuccess("Free Appointment Request Sent Successfully")
                    this.setState({ appointment_date: "", appointment_time: "", doc_id: "", doc_name: "" })
                    removeFromStorage("appointments")
                } else {
                    this.notifyError(res.data.errorMessage)
                }
                this.setState({isSubmitting: false})
            })
            .catch(err => {
                alert(err.toString())
                this.setState({isSubmitting: false})
            })
        })
    }

    //Notification Request
    notificationRequest = (token, type, id, notification_id) => {
        const time = this.props.location.pathname.split("/")[
            this.props.location.pathname.split("/").length - 2
        ];

        const headers = {
            "Content-Type": "application/json",
            Authorization:
                "key=AAAAcHXSJak:APA91bFW5NnyfjZx4wYC7soTVuCblryMMawUSVAGezx_E4NVLtB50BF6orjSwvUoTztk4o43JrjGvYM3rx_JgpMErpTPBA6xpe8hHfuyKCSOG9OohBkE2hQNeHMt7mlRHnjwsn12Vr5Z",
        };
        const body = {
            notification: {
                title: "SemDoc",
                body:
                type === "patients"
                    ? `Doctor is calling you and waiting for you at appointment id ${
                        this.props.location.pathname.split("/")[
                        this.props.location.pathname.split("/").length - 3
                        ]
                    }`
                    : `Patient is calling you and waiting for you at appointment id ${
                        this.props.location.pathname.split("/")[
                        this.props.location.pathname.split("/").length - 3
                        ]
                    }`,
            },
            data: {
                id,
                notification_id,
                appointment_id: notification_id,
                date: `${new Date(
                moment(time, "YYYYMMDDhhmmss")
                ).getDate()}&hour=${new Date(
                moment(time, "YYYYMMDDhhmmss").add(60, "minutes")
                ).getHours()}&minute=${new Date(
                moment(time, "YYYYMMDDhhmmss").add(60, "minutes")
                ).getMinutes()}`,
                type,
                role: type === "patients" ? "doctor" : "patient",
                meetingName: notification_id,
                doctor_id: getFromStorage("doctor"),
                patient_id: id,
            },
            android: {
                priority: "high"
            },
            priority: 10,
            to: token,
        };

        fetch("https://fcm.googleapis.com/fcm/send", {
            method: "POST",
            body: JSON.stringify(body),
            headers,
        })
        .then((res) => res.json())
        .then((res) => {
            if (res.success === 1) {
            }
        })
        .catch((err) => {
            console.log(err.response);
            this.notifyError(err.toString());
        });
    }

    //Send notification
    sendNotification = async (type, id, appointment_id, time, id_2) =>  {
        try {
            console.log(type, id)
            let body = { task: "getDoctorDeviceToken", doctor_id: id }
            if(type === "doctors") {
              const res = await axios.post(api.PatientApi, body)
              console.log(res.data)
              if(res.data && res.data.length)
                this.notificationRequest(res.data[0].device_token, type, id, appointment_id)
            } else {
              body = {task: "getPatientDeviceToken", patient_id: id}
              const res = await axios.post(api.DoctorApi, body)
              console.log(res.data)
              if(res.data && res.data.length)
                this.notificationRequest(res.data[0].device_token, type, id, appointment_id)
            }
          } catch (err) {
            console.log(err)
          }
    }

    render() {
        if(this.state.isLoading || this.state.isPaying) {
            return (
                <div style={{marginLeft: this.props.isOpened ? (window.innerWidth > 560 ? "160px" : "20px") : "20px", marginTop: "70px", textAlign: "center"}}>
                    <CircularProgress />
                </div>
            )
        }
        return (
            <div style={{marginLeft: this.props.isOpened ? (window.innerWidth > 560 ? "160px" : "20px") : "20px", marginTop: "70px", marginRight: "20px"}}>
                <Card style={{padding: "20px", fontSize: "14px"}} className="shadow-4">
                {
                    getFromStorage(key.loginInfoKey).task === "doctor" && 
                    <div>
                        <h4 style={{ color, textAlign: "center", fontWeight: "bold", fontSize: 22 }}>Set Availability</h4>
                        <br/>
                        <form>
                            <Row>
                                <Col sm="3">
                                    <FormGroup controlId="day">
                                        <FormLabel>Day<span style={{color: "red"}}>*</span></FormLabel>
                                        <FormControl as="select" size="sm" name="day" value={this.state.day} onChange={this.whenChangeHandler}>
                                            <option value="" style={{color: "silver"}}>Select Day</option>
                                            {["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].map(item => 
                                                    <option value={item} key={item}>{item}</option>
                                            )}
                                        </FormControl>
                                    </FormGroup>
                                </Col>
                                <Col sm="3">
                                    <FormGroup controlId="startTime">
                                        <FormLabel>Start Time<span style={{color: "red"}}>*</span></FormLabel>
                                        <TimePicker 
                                            size="sm"
                                            name="startTime" 
                                            value={this.state.startTime} 
                                            onChange={(startTime) => this.setState({startTime})}
                                            step={30} 
                                        /> 
                                    </FormGroup>
                                </Col>
                                <Col sm="3">
                                    <FormGroup controlId="endTime">
                                        <FormLabel>End Time<span style={{color: "red"}}>*</span></FormLabel>
                                        <TimePicker 
                                            size="sm"
                                            name="endTime" 
                                            value={this.state.endTime} 
                                            onChange={(endTime) => this.setState({endTime})}
                                            step={30} 
                                        /> 
                                    </FormGroup>
                                </Col>
                                <Col sm="3">
                                    <Button size="sm" variant="success" style={{marginTop: window.innerWidth > 560 ? "27px" : "0px", width: "100px"}} onClick={this.addTime}>
                                        <CalendarToday fontSize="small" style={{fontSize: "16px"}}/>
                                        <span style={{marginLeft: "5px"}}>Add Time</span>
                                    </Button>      
                                </Col>
                            </Row>
                            <br/>
                        </form>
                        {this.state.tempTableData.length > 0 && 
                        <div>
                            <Table striped bordered hover size="sm">
                            <thead>
                                <tr style={{color}}>
                                    <th>Day</th>
                                    <th>Start Time</th>
                                    <th>End Time</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.tempTableData.map((item, i) =>
                                        <tr key={item.id}>
                                            <td>{item.day}</td>
                                            <td>{item.startTime}</td>
                                            <td>{item.endTime}</td>
                                            <td align="center" style={{width: "100px"}}>
                                                <Button size="sm" variant="danger" onClick={() => {this.setState({ tableData: this.state.tableData.filter(data => item.id !== data.id), tempTableData: this.state.tempTableData.filter(data => item.id !== data.id) })}}>
                                                    <Delete fontSize="small"/>
                                                    <span style={{marginLeft: "5px"}}>Delete</span>
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                            </Table>
                            <div style={{textAlign: "center"}}>
                                <Button size="sm" variant="success" onClick={() => {this.setState({isSubmitting: true},() => this.confirmTime())}} disabled={this.state.isSubmitting}>
                                    {!this.state.isSubmitting ? "Confirm" : "Loading..."}
                                </Button>
                            </div>
                            {window.innerWidth < 560 && <br/>}
                        </div>
                        }
                        <h4 style={{ color, textAlign: "center", fontWeight: "bold", fontSize: 22 }}>Upcoming Appointments</h4>
                        <br/>
                    </div>
                }
                {getFromStorage(key.loginInfoKey).task === "patient" && 
                    <div style={{paddingLeft: "20px", paddingRight: "20px"}}>
                        {this.state.doc_id !== "" &&
                        <div>
                            <h4 style={{ color, textAlign: "center", fontWeight: "bold", fontSize: 22 }}>Set Appointment</h4>
                            <br/>
                            <form>
                                <div style={{fontWeight: "bold", marginBottom: "5px"}}>
                                    <Row>
                                    <Col sm="3">
                                    Doctor ID: {this.state.doc_id}
                                    </Col>
                                    <Col sm="5">
                                    Doctor Name: {this.state.doc_name}
                                    </Col>
                                    </Row>
                                </div>
                                <Row>
                                    <Col sm="3">
                                        <FormGroup controlId="weekly">
                                            <FormLabel style={{fontWeight: "bold"}}>Weekly<span style={{color: "red"}}>*</span></FormLabel>
                                            <FormControl 
                                                type="text" 
                                                size="sm" 
                                                name="selectDate" 
                                                disabled={false}
                                                value={this.state.selectDate} 
                                                onChange={() => {}}
                                            />
                                            <div style={{marginTop: "5px", textAlign: "center"}}>
                                                <Button variant="outline-danger" size="sm" onClick={this.previousWeek}>
                                                    <ArrowLeft fontSize="small"/>
                                                </Button>
                                                <Button variant="outline-danger" size="sm" style={{marginLeft: "5px"}} onClick={this.nextWeek}>
                                                    <ArrowRight fontSize="small"/>
                                                </Button>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3">
                                        <FormGroup controlId="startTime">
                                            <FormLabel style={{fontWeight: "bold"}}>Date<span style={{color: "red"}}>*</span></FormLabel>
                                            <FormControl 
                                                size="sm"
                                                as="select"
                                                name="appointment_date" 
                                                value={this.state.appointment_date} 
                                                onChange={(e) => { this.setState({appointment_date: e.target.value, appointment_time: ""}) }}
                                            >
                                                <option value="" style={{color: "silver"}}>Select Date</option>
                                                {this.state.dates.map((item, i) =>
                                                item && <option value={item + "$" + i.toString()} key={item}>{item}</option>)}
                                            </FormControl>
                                        </FormGroup>
                                    </Col>
                                    {this.state.arrays.length > 0 &&
                                    <Col sm="3">
                                        <FormGroup controlId="time">
                                            <FormLabel style={{fontWeight: "bold"}}>Time<span style={{color: "red"}}>*</span></FormLabel>
                                            <FormControl
                                                as="select" 
                                                size="sm"
                                                name="time" 
                                                value={this.state.appointment_time} 
                                                onChange={(e) => this.setState({appointment_time: e.target.value})}
                                            >
                                                <option value="" style={{color: "silver"}}>Select Time</option>
                                                {
                                                    this.state.appointment_date.split("$")[1] === "0" ? 
                                                    this.state.arrays[0].map(item => <option key={item} value={item}>{item}</option>) : 
                                                    this.state.appointment_date.split("$")[1] === "1" ? 
                                                    this.state.arrays[1].map(item => <option key={item} value={item}>{item}</option>) : 
                                                    this.state.appointment_date.split("$")[1] === "2" ? 
                                                    this.state.arrays[2].map(item => <option key={item} value={item}>{item}</option>) : 
                                                    this.state.appointment_date.split("$")[1] === "3" ? 
                                                    this.state.arrays[3].map(item => <option key={item} value={item}>{item}</option>) :
                                                    this.state.appointment_date.split("$")[1] === "4" ? 
                                                    this.state.arrays[4].map(item => <option key={item} value={item}>{item}</option>) :  
                                                    this.state.appointment_date.split("$")[1] === "5" ? 
                                                    this.state.arrays[5].map(item => <option key={item} value={item}>{item}</option>) : 
                                                    this.state.appointment_date.split("$")[1] === "6" && 
                                                    this.state.arrays[6].map(item => <option key={item} value={item}>{item}</option>) 
                                                }
                                            </FormControl> 
                                        </FormGroup>
                                    </Col>}
                                    <Col sm="3">
                                        <Button size="sm" variant="success" disabled={this.state.isSubmitting} style={{marginTop: window.innerWidth > 560 ? "27px" : "0px", width: "100px"}} onClick={() => { 
                                            console.log(this.state.freeService)
                                            if(this.state.appointment_date !== "" && this.state.appointment_time !== "") { 
                                                setInStorage("appointments", { date: moment(this.state.appointment_date.split("$")[0]).format("YYYYMMDD") + moment(this.state.appointment_time, ["h:mm A"]).format("HHmm") + "00", doc_id: this.state.doc_id })
                                                if(!this.state.freeService)
                                                    this.getLastAppointment() 
                                                else
                                                    this.setFreeAppointmentRequest()
                                            } else { this.notifyError("All Fields Required"); }
                                        }}>
                                            <span style={{marginLeft: "5px"}}>{!this.state.isSubmitting ? "Confirm" : "Loading..."}</span>
                                        </Button>      
                                    </Col>
                                </Row>
                                <br/>
                            </form>
                        </div>}
                        <h4 style={{ color, textAlign: "center", fontWeight: "bold", fontSize: 22 }}>Doctors</h4>
                        <br/>
                    </div>
                }
                    <div style={{overflow: "auto"}}>
                    {getFromStorage(key.loginInfoKey).task === "doctor" ?
                    <div>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr style={{color}}>
                                <th>Appointment No.</th>
                                <th>Appointment Date</th>
                                <th>Appointment Time</th>
                                <th>Patient Id</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.appointments.map(item =>
                                    <tr key={item.appointment_id}>
                                        <td>{item.appointment_id}</td>
                                        <td>{moment(item.start_time, "YYYYMMDD").format("DD/MM/YYYY")}</td>
                                        <td>{moment(item.start_time, "YYYYMMDDhhmmss").format("hh:mm A")}</td>
                                        <td>{item.patient_id}</td>
                                        <td align="center" style={{width: "100px"}}>
                                            <Button size="sm" variant="success" onClick={() => this.props.history.push(`/appointments/call/${item.appointment_id}/${item.start_time}/${getFromStorage("doctor") ? item.patient_id : getFromStorage("doctor")}`)}>
                                                <Description fontSize="small"/>
                                                <span style={{marginLeft: "5px"}}>Detail</span>
                                            </Button>
                                        </td>
                                        <td align="center" style={{width: "100px"}}>
                                            {(new Date() >= new Date(moment(item.start_time, "YYYYMMDDhhmmss"))) &&
                                            (new Date() <= new Date(moment(item.start_time, "YYYYMMDDhhmmss").add(60, "minutes"))) && getFromStorage("doctor")
                                            &&
                                                <Button size="sm" variant="success" onClick={() => { 
                                                        const meeting_id = item.appointment_id;
                                                        // const user_id1 = getFromStorage("doctor") ? item.patient_id : getFromStorage("doctor")
                                                        const user_id = getFromStorage("doctor") ? getFromStorage("doctor") : getFromStorage("patient")
                                                
                                                        window.open(`/#/devices/${meeting_id}/${user_id}`)
                                                       
                                                        if(getFromStorage(key.loginInfoKey).task === "doctor")
                                                            this.sendNotification("patients", item.patient_id, item.appointment_id.toString(), item.start_time, getFromStorage("doctor"));
                                                        else 
                                                            this.sendNotification("doctors", item.doctor_id, item.appointment_id.toString(), item.start_time, getFromStorage("patient"));
                                                    }}
                                                >
                                                    <VideoCall fontSize="small"/>
                                                    <span style={{marginLeft: "5px"}}>Call</span>
                                                </Button>
                                            }
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>

                        </Table>
                        {this.state.appointments.length === 0 && <div style={{textAlign: "center"}}>No Upcoming Appointments Available</div>}
                        </div>
                        :
                        <div style={{paddingLeft: "20px", paddingRight: "20px"}}>
                            <Row>
                                <Col>
                                    <FormGroup controlId="speciality">
                                        <FormLabel style={{color, fontWeight: "bold"}}>Search By Speciality</FormLabel>
                                        <FormControl size="sm" as="select" name="speciality" value={this.state.speciality} onChange={(e) => {this.setState({speciality: e.target.value, isSearching:true}, () => { this.fetchDoctorList() })}}>
                                            <option value="">All</option>
                                            {specailities.map(item => <option value={item} key={item}>{item}</option>)}
                                        </FormControl>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup controlId="designation">
                                        <FormLabel style={{color, fontWeight: "bold"}}>Search By Designation</FormLabel>
                                        <FormControl as="select" size="sm" name="designation" value={this.state.designation} onChange={(e) => {this.setState({designation: e.target.value, isSearching:true}, () => { this.fetchDoctorList() })}}>
                                            <option value="">All</option>
                                            {designations.map(item => <option value={item} key={item}>{item}</option>)}
                                        </FormControl>
                                    </FormGroup>
                                </Col>
                            </Row>
                                <Table striped bordered hover size="sm">
                                    <thead>
                                        <tr style={{color, fontWeight: "bold"}}>
                                            <th>Id</th>
                                            <th>Name</th>
                                            <th>Designation</th>
                                            <th>Speciality</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    {!this.state.isSearching && 
                                    <tbody>
                                        {
                                            this.state.doctors.map(item =>
                                                <tr key={item.id}>
                                                    <td>{item.id}</td>
                                                    <td>{item.name}</td>
                                                    <td><Markup content={item.designation} /></td>
                                                    <td><Markup content={item.speciality} /></td>
                                                    <td align="center" style={{width: "200px"}}>
                                                        <Button 
                                                            size="sm" 
                                                            variant="primary" 
                                                            onClick={() => {
                                                                this.setState({doc_id: item.id, doc_name: item.name, freeService: false}, () => {
                                                                    this.setDates()
                                                                    window.scrollTo(0,0);
                                                                })
                                                            }}
                                                        >
                                                            <span style={{marginLeft: "5px"}}>Appointment</span>
                                                        </Button>
                                                    </td>
                                                    
                                                    <td align="center" style={{width: "200px"}}>
                                                    {item.freeService === 1 && 
                                                        <Button 
                                                            size="sm" 
                                                            variant="primary" 
                                                            onClick={() => {
                                                                this.setState({doc_id: item.id, doc_name: item.name, freeService: true}, () => {
                                                                    this.setDates()
                                                                    window.scrollTo(0,0);
                                                                })
                                                            }}
                                                        >
                                                            <span style={{marginLeft: "5px"}}>Request Free Appointment</span>
                                                        </Button>
                                                    }
                                                    </td>
                                                    
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                    }
                                </Table>
                                {this.state.isSearching && <div style={{textAlign: "center"}}>Searching...</div>}
                                {this.state.doctors.length === 0 && <div style={{textAlign: "center", fontSize: "14px"}}>Data Not Found</div>}
                        </div>
                        }
                    </div>
                </Card>
                <CardDialog rateField={this.state.rateField} message={this.state.message} cost={this.state.cost} show={this.state.show} doctor_id={this.state.doc_id} handleClose={() => this.setState({show: false})} handleSubmit={this.setAppointment}/>
                {this.state.isConfirm && <ConfirmDialog status={this.state.status} show={this.state.isConfirm} handleClose={() => this.setState({isConfirm: false})}/>}
                <br/>
            </div>
        )
    }
}

export default connect(state => ({
    notifications: state.notifications
}))(withRouter(Home));
