import React, {Component} from "react";
import { Card, Table, Button, FormGroup, FormControl, FormLabel, Row, Col } from "react-bootstrap";
import key from "../../../config/key.json";
import { toast } from "react-toastify";
import { getFromStorage } from "../../../utils/storage";
import errorConfig from "../../../config/errorConfig.json";
import { withRouter } from "react-router";
import api from "../../../config/api.json";
import { CircularProgress } from "@material-ui/core";
import axios from 'axios';
import { Markup } from "interweave";

const color = "#229a88";

class ProviderDoctors extends Component {
    state = {
        isLoading: true,
        isSearching: false,
        doctors: [],
        phone: ""
    }
    //Error Notification
    notifyError = error => {
        toast.error("" + error.toString());
    };

    //Success Notification
    notifySuccess = success => {
        toast.success("" + success.toString());
    };

    //fetch appointments
    fetchDoctorDetail = (e) => {
        e.preventDefault();
        toast.dismiss();
        this.setState({isSearching: true }, () => { 
            const body = {
                task: 'getDoctorDetails',
                phone: this.state.phone
            }
            const headers = { headers: { "Content-Type" : "application/json" } }
            axios.post(api.ProviderApi, body, headers)
            .then(res => {
                if(!res.data.errorMessage){
                    this.setState({doctors: res.data})
                } else {
                    this.notifyError("Not Found")
                }
                this.setState({isLoading: false, isSearching: false, phone: ""})
            }
            )
            .catch(err => {
                this.notifyError(err.toString())
                this.setState({isLoading: false, isSearching: false})
            })
        })
    }

    //Remove Doctor
    removeDoctor = () => {
        toast.dismiss();
        const body = {
            task: 'removeDoctor',
            phone: this.state.doctors[0].phone
        }
        const headers = { headers: { "Content-Type" : "application/json" } }
        axios.post(api.ProviderApi, body, headers)
        .then(res => {
            if(res.data===200){
                this.setState({doctors: []})
            } else {
                this.notifyError("Not Found")
            }
            this.setState({isLoading: false, isSearching: false})
        }
        )
        .catch(err => {
            this.notifyError(err.toString())
            this.setState({isLoading: false, isSearching: false})
        })
    }

    //First function run when page load
    componentDidMount() {
        toast.dismiss();
        
        if (!getFromStorage(key.loginInfoKey)) {
            this.notifyError(errorConfig.notLoggedInError);
            this.props.history.push("/");
        } else if (getFromStorage(key.loginInfoKey).task === "authAdmin" || getFromStorage(key.loginInfoKey).task === "authDiagAdminPanel") {
            this.props.history.push("/Graph");
        } else if (getFromStorage(key.loginInfoKey).task === "patient") {
            this.props.history.push("/home");
        } else if (getFromStorage(key.loginInfoKey).task === "doctor") {
            this.props.history.push("/home");
        } else {
            this.setState({isLoading: false});
        }
    }

    render() {
        if(this.state.isLoading) {
            return (
                <div style={{marginLeft: this.props.isOpened ? (window.innerWidth > 560 ? "180px" : "20px") : "20px", marginTop: "70px", textAlign: "center"}}>
                    <CircularProgress />
                </div>
            )
        }
        return (
            <div style={{marginLeft: this.props.isOpened ? (window.innerWidth > 560 ? "180px" : "20px") : "20px", marginTop: "70px", marginRight: "20px"}}>
                <Card style={{padding: "20px", fontSize: "14px"}} className="shadow-4">
                    <h3 style={{color, textAlign: "center", fontWeight: "bold"}}>Doctors Management</h3>
                    <form onSubmit={this.fetchDoctorDetail}>
                        <Row>
                            <Col sm="3">
                                <FormGroup  controlId="search">
                                    <FormLabel>Search Doctor:</FormLabel>
                                    <FormControl 
                                        placeholder="Phone Number (eg. 01712345678)"
                                        name="phone"
                                        pattern="[0-9]*"
                                        value={this.state.phone}
                                        size="sm"
                                        onChange={(e) => {
                                            if(e.target.validity.valid)
                                                this.setState({phone: e.target.value})
                                        }}
                                    />  
                                </FormGroup>
                            </Col>
                            <Col>
                                <Button size="sm" variant="success" type="submit" style={{marginTop: 27}}>
                                    Search
                                </Button>
                            </Col>
                        </Row>
                    </form>
                    {!this.state.isSearching ?
                    <div style={{overflow: "auto"}}>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr style={{color, fontWeight: "bold"}}>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Phone</th>
                                    <th>Gender</th>
                                    <th>Age</th>
                                    <th>Designation</th>
                                    <th>Speciality</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.doctors.map(item =>
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.name}</td>
                                            <td>{item.phone}</td>
                                            <td>{item.gender === "M" ? "Male" : "Female"}</td>
                                            <td>{item.age}</td>
                                            <td><Markup content={item.designation} /></td>
                                            <td><Markup content={item.speciality} /></td>
                                            <td align="center">
                                                <Button
                                                    size="sm" 
                                                    variant="info"
                                                    onClick={() => {
                                                        this.props.history.push(`/doctor/appointment_list/${item.id}`)
                                                    }} 
                                                >
                                                    <span style={{marginLeft: "5px"}}>Appointment List</span>
                                                </Button>
                                            </td>
                                            <td align="center">
                                                <Button
                                                    size="sm" 
                                                    variant="success" 
                                                    onClick={() => {
                                                        this.props.history.push(`/doctor/change_pass/${item.phone}`)
                                                    }}
                                                >
                                                    <span style={{marginLeft: "5px"}}>Change Password</span>
                                                </Button>
                                            </td>
                                            <td align="center">
                                                <Button
                                                    size="sm" 
                                                    variant="danger"
                                                    onClick={this.removeDoctor} 
                                                >
                                                    <span style={{marginLeft: "5px"}}>Remove</span>
                                                </Button>
                                            </td>
                                            
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                    </div>
                        :
                        <div style={{textAlign: "center"}}>
                            <CircularProgress />
                        </div>
                    }
                </Card>
                <br/>
            </div>
        )
    }
}

export default withRouter(ProviderDoctors);