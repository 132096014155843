import React, { useState } from "react";
import { Button, FormGroup, FormControl, FormLabel, Alert, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import validator from "validator"
import { useEffect } from "react";
import { getFromStorage } from "../../../utils/storage";
import axios from 'axios'
import api from '../../../config/api.json'
import { toast } from "react-toastify";

const color = "#229a88";

function PatientProfile(props) {
  const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [retypePassword, setRetypePassword] = useState("");
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("")
  
  function validateForm() {
    return name.length > 0 && age.length > 0 && gender.length > 0 && email.length > 0  && phone.length > 0;
  }

  function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);

    if (name.trim() !== "" && gender.trim() !== "" && phone.trim() !== "" && age.trim() !== "") {
        if (validator.isEmail(email) || email.trim() === "") {
            const body = { task: "editPatientProfile", patient_id: getFromStorage("data") ? getFromStorage("data").di_id : "", name, age, gender, email, phone, address }
            const headers = { headers: { "Content-Type": "application/json" } }
            axios
                .post(api.PatientApi, body, headers)
                .then(res => {
                    console.log(res.data)
                    if (!res.data.errorMessage) {
                        props.getProfile()
                        toast.success("Profile Updated Successfully")
                        setIsSubmitting(false);
                    } else {
                        setErrors([res.data.errorMessage])
                        setIsSubmitting(false);
                    }
                })
                .catch(err => {
                    setIsSubmitting(false);
                    setErrors(["Something Went Wrong"])
                })
            
        } else {
            setIsSubmitting(false);
            setErrors(["Email not valid"])
        }
    } else {
        setIsSubmitting(false);
        setErrors(["Required Fields Missing"])
    }
  }

  useEffect(() => {
    setName(props.data.name.toString());
    setEmail(props.data.email.toString());
    setAge(props.data.age.toString());
    setPhone(props.data.phone.toString());
    setGender(props.data.gender.toString());
    setAddress(props.data.address.toString())
  }, [])

  return (
        <div style={{overflow: "auto"}}>
            <form onSubmit={handleSubmit}>
                {
                    errors.map((error,i) =>
                        <Alert key={i} variant="danger" style={{fontSize: "12px"}}>
                            {error}
                        </Alert>
                    )
                }
                {
                    success.length > 0 && 
                    <Alert variant="success" style={{fontSize: "12px"}}>
                        {success}
                    </Alert>
                }
                <FormGroup controlId="name" bssize="large" style={{textAlign: "left"}}>
                    <FormLabel>Name<span style={{color: "red"}}>*</span></FormLabel>
                    <FormControl
                        type="text"
                        placeholder="Name"
                        value={name}
                        size="sm"
                        onChange={e => {
                            setName(e.target.value);
                            setSuccess("");
                            setErrors([]);
                        }}
                    />
                </FormGroup>
                <FormGroup controlId="age" bssize="large" style={{textAlign: "left"}}>
                    <FormLabel>Age<span style={{color: "red"}}>*</span></FormLabel>
                    <FormControl
                        type="text"
                        placeholder="Age"
                        value={age}
                        pattern="[0-9]*"
                        size="sm"
                        onChange={e => {
                            if(e.target.validity.valid){
                                setAge(e.target.value);
                                setSuccess("");
                                setErrors([]);
                            }
                        }}
                    />
                </FormGroup>
                <FormGroup controlId="gender" bssize="large" style={{textAlign: "left"}}>
                    <FormLabel>Gender<span style={{color: "red"}}>*</span></FormLabel>
                    <FormControl
                        as="select"
                        value={gender}
                        size="sm"
                        onChange={e => {
                            setGender(e.target.value);
                            setSuccess("");
                            setErrors([]);
                        }}
                    >
                        <option style={{color: "silver"}} value="">Select Gender</option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                    </FormControl>
                </FormGroup>
                
                <FormGroup controlId="email" bssize="large" style={{textAlign: "left"}}>
                    <FormLabel>Email<span style={{color: "red"}}>*</span></FormLabel>
                    <FormControl
                        type="email"
                        placeholder="Email"
                        value={email}
                        size="sm"
                        onChange={e => {
                            setEmail(e.target.value);
                            setSuccess("");
                            setErrors([]);
                        }}
                    />
                </FormGroup>
                <FormGroup controlId="address" bssize="large" style={{textAlign: "left"}}>
                    <FormLabel>Address</FormLabel>
                    <FormControl
                        type="text"
                        placeholder="Address"
                        value={address}
                        size="sm"
                        onChange={e => {
                            setAddress(e.target.value);
                            setSuccess("");
                            setErrors([]);
                        }}
                    />
                </FormGroup>
                <FormGroup controlId="phone_number" bssize="large" style={{textAlign: "left"}}>
                    <FormLabel>Phone Number<span style={{color: "red"}}>*</span></FormLabel>
                    <FormControl
                        type="text"
                        placeholder="Phone Number"
                        value={phone}
                        size="sm"
                        pattern="[0-9]*"
                        onChange={e => {
                            if(e.target.validity.valid){
                                setPhone(e.target.value);
                                setSuccess("");
                                setErrors([]);
                            }
                        }}
                    />
                </FormGroup>
                <div style={{textAlign: "center"}}>
                    <Button size="sm" disabled={!validateForm() || isSubmitting ? true:false} type="submit" style={{background: color, borderColor: color, width: "auto", marginLeft: "auto", marginRight: "auto"}} variant="dark">
                        {!isSubmitting ? "Update Profile" : "Loading..."}
                    </Button>
                </div>
            </form>
      </div>
  );
}

export default withRouter(PatientProfile);