import React, { Component } from "react";
import {
  Modal,
  FormGroup,
  Button,
  FormCheck,
  FormLabel,
  Row,
} from "react-bootstrap";
import axios from "axios";
import { setInStorage, getFromStorage } from "../../../utils/storage";
import { toast } from "react-toastify";
import StripeCheckoutForm from "../BuyMedicine/Stripe";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeKey from '../../../config/stripe.json'

const stripePromise = loadStripe(StripeKey.stripe_pk);

export default class CardDialog extends Component {
  state = {
    value: "bKash",
    isConfirming: false,
    cost: "0",
  };

  //Error Notification
  notifyError = (error) => {
    toast.error("" + error.toString());
  };

  //Grant token
  grantToken = () => {
    this.setState({ isConfirming: true }, () => {
      const body = {
        task: "grantToken",
      };
      const headers = {
        "Content-Type": "application/json",
      };
      axios
        .post(
          "https://w2vramif16.execute-api.us-east-1.amazonaws.com/prod",
          body,
          headers
        )
        .then((res) => {
          if (!res.data.errorMessage) {
            setInStorage("card_token", res.data);
            this.createPayment(res.data);
          }
        })
        .catch((err) => {
          this.notifyError(err.toString());
          this.setState({ isConfirming: false });
        });
    });
  };

  //create payment
  createPayment = (token_id) => {
    const num = this.props.id.toString()
    let body = {};

    if (
      getFromStorage("keyLogin") &&
      getFromStorage("keyLogin").task === "patient"
    ) {
      body = {
        task: "createPayment",
        amount: this.props.cost.toString(),
        callbackURL:"https://semdoc.semion.ai/",
        merchantInvoiceNumber: `${num}`,
        token_id,
        currency:"BDT", mode:"0011", intent:"sale", payerReference: "100",
      };
    } 
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(
        "https://w2vramif16.execute-api.us-east-1.amazonaws.com/prod",
        body,
        headers
      )
      .then((res) => {
        console.log(res.data)
        if (!res.data.errorMessage && res.data.message !== "Unauthorized") {
          if (
            res.data.merchantInvoiceNumber === num &&
            res.data.amount.toString() === this.props.cost.toString() &&
            res.data.intent === "sale"
          ) {
            setInStorage("invoice", {inv:res.data.merchantInvoiceNumber, pay:res.data.paymentCreateTime});
            setInStorage("bkash", res.data);
            setInStorage("payment_on", "invoice")
            window.location = res.data.bkashURL;
          } else {
            this.notifyError("Some details donot matches");
          }
        } else {
          this.notifyError("Unauthorized");
        }
        this.setState({ isConfirming: false });
      })
      .catch((err) => {
        this.notifyError(err.toString());
        this.setState({ isConfirming: false });
      });
  };

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ marginBottom: "-100px" }}>
            Secure Payment
          </Modal.Title>
        </Modal.Header>

        
          <Modal.Body>
            <p>
              {this.props.cost} tk. This amount will be transferred from your
              account.
            </p>
            <form>
              <FormGroup controlId="card">
                <FormLabel style={{ fontWeight: "bold" }}>Choose Card:</FormLabel>
                <Row style={{ marginLeft: "1px" }}>
                  <FormCheck
                    type="radio"
                    label=""
                    value={this.state.value}
                    checked={this.state.value === "bKash"}
                    onChange={() => this.setState({ value: "bKash" })}
                  />
                  <span onClick={() => this.setState({ value: "bKash" })} style={{cursor: "pointer"}}>bKash</span>
                  <img
                    src={require("../../../image/bkash.png")}
                    alt="bkash"
                    style={{ height: "25px", width: "60px" }}
                  />
                </Row>
                <Row style={{ marginLeft: "1px" }}>

                  <FormCheck
                    type="radio"
                    label=""
                    value={this.state.value}
                    checked={this.state.value === "stripe"}
                    onChange={() => this.setState({ value: "stripe" })}
                  />
                  <span onClick={() => this.setState({ value: "stripe" })} style={{cursor: "pointer"}}>Credit/Debit Card</span>
                  <img
                    src={require("../../../image/stripe.png")}
                    alt="bkash"
                    style={{ height: "25px", width: "80px", marginLeft: 10 }}
                  />
                </Row>
              </FormGroup>
            </form>
            {this.state.value === "stripe" && 
              <Elements stripe={stripePromise}>
                <StripeCheckoutForm amount={this.props.cost} handleSubmit={this.props.handleSubmit} handleClose={this.props.handleClose}/>
              </Elements>
            }
          </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            size="sm"
            onClick={this.props.handleClose}
          >
            Close
          </Button>
          {this.state.value === "bKash" && 
            <Button
              variant="success"
              size="sm"
              onClick={() => {
                if(this.state.value === "bKash") {
                  this.grantToken()
                } else {
                  this.setState({bkash: false})
                }
              }}
              disabled={this.state.isConfirming}
            >
              Confirm
            </Button>
          }
        </Modal.Footer>
      </Modal>
    );
  }
}
