import React, { Component } from "react";
import { Button } from "react-bootstrap";

var localstream;

export default class Permission extends Component {
  componentDidMount() {
    this.getAudioPermission()
  }

  getAudioPermission = () => {
    navigator.permissions
      .query({ name: "microphone" })
      .then((permissionObj) => {
        console.log(permissionObj.state);
      })
      .catch((error) => {
        console.log("Got error :", error);
      });

    navigator.permissions
      .query({ name: "camera" })
      .then((permissionObj) => {
        console.log(permissionObj.state);
      })
      .catch((error) => {
        console.log("Got error :", error);
      });

    // if (navigator.mediaDevices.getUserMedia !== null) {
      var options = {
        video: true,
        audio: true,
      };
      navigator.getUserMedia(
        options,
        function (stream) {
          var vid = document.getElementById("vid");
          vid.srcObject = stream;
          localstream = stream;
          vid.play();
          console.log(stream, "streaming");
        },
        function (e) {
          console.log("background error : " + e.name);
        }
      );
    // }
  };

  capOff = () => {
    //clearInterval(theDrawLoop);
    //ExtensionData.vidStatus = 'off';
    var vid = document.getElementById("vid");
    vid.pause();
    vid.src = "";
    if (localstream) {
      localstream.getTracks().forEach((x) => x.stop());
      console.log("all capture devices off");
    }
  };

  render() {
    return (
      <div style={{ textAlign: "center", marginTop: "40px" }}>
        <video id="vid" height="200" width="250" autoplay></video>
        <br />
        <Button size="sm" variant="danger" onClick={this.capOff} style={{ margin: 10 }}>
          Turn Capture Off
        </Button>
        <Button size="sm" variant="success" onClick={this.getAudioPermission} style={{ margin: 10 }}>
          Get Audio and Video Permission
        </Button>
      </div>
    );
  }
}
