import React, { Component } from "react";
// import Dashboard from "./components/Dashboard/Dashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/style.css";
import { Route, Switch, HashRouter } from "react-router-dom";
import Login from "./components/Login/Login";
// import Navbar from "./components/Navbar/Navbar";
// import Sidebar from "./components/Sidebar/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import View from "./components/List/View";
import { getFromStorage } from "./utils/storage";
// import Html from "./components/List/Html";
// import ChangePassword from "./components/Change Password/ChangePassword";
// import Bill from "./components/Bill/Bill";
// import EditCaseInfo from "./components/EditCase/EditCaseInfo";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import NotFound from "./components/NotFound/NotFound";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import Signup from "./components/Signup/Signup";
import PatientSignup from "./components/Signup/PatientSignup";
import Sidebar2 from "./components/Doctor/Sidebar/Sidebar";
import Home from "./components/Doctor/Home/Home";
import Records from "./components/Doctor/Records/Records";
import Call from "./components/Doctor/Call/Call";
import Alerts from "./components/Doctor/Alerts/Alerts";
import Profile from "./components/Doctor/Profile/Profile";
import ChangePass from "./components/Doctor/ChangePassword/ChangePass";
import CardInfo from "./components/Doctor/CardInfo/CardInfo";
import OfficeHours from "./components/Doctor/Home/OfficeHours";
import SidebarProvider from "./components/Provider/Sidebar/Sidebar";
import ProviderDoctors from "./components/Provider/Doctors/Doctors";
import ProviderPatients from "./components/Provider/Patients/Patients";
import AddPatient from "./components/Provider/Patients/AddPatient";
import AddDoctor from "./components/Provider/Doctors/AddDoctor";
import ChangePassProvider from "./components/Provider/ChangePassword/ChangePassword";
import CalculateBill from "./components/Provider/CalculateBills/CalculateBills";
import CallList from "./components/Provider/CallList/CallList";
import PaymentSetup from "./components/Provider/PaymentSetup/PaymentSetup";
import AppointmentList from "./components/Provider/AppointmentList/AppointmentList";
import Appointments from "./components/Provider/Appointments/Appointments";
import SetAppointment from "./components/Provider/Appointments/SetAppointment";
import ProviderSignup from "./components/Signup/ProviderSignup";
import ChangePasswordProvider from "./components/Provider/Change Password/ChangePass";
import Policy from "./components/PrivacyPolicy/Policy";
import Logo from "./components/Logo/Logo";
import RescheduleByDoctor from "./components/Provider/Appointments/rescheduleByDoctor";
import Permission from "./components/permission/Permission";
import OrderLabTest from "./components/Doctor/OrderLabTest/OrderLabTest";
import Invoice from "./components/Doctor/OrderLabTest/Invoice";
import DigitalPrescription from "./components/Doctor/DoctorDigitalPrescription/DigitalPrescription";
import PatientDigitalPrescription from "./components/Doctor/PatientDigitalPrescription/PatientDigitalPrescription";
import PatientRecord from "./components/Doctor/PatientRecords/PatientRecords";
import DocPatientRecord from "./components/Doctor/DocPatientRecords/DocPatientRecords";
import OrderTests from "./components/Doctor/PatientDigitalPrescription/OrderTests";
import OrderTestsInvoice from "./components/Doctor/PatientDigitalPrescription/Invoice";
import { ThemeProvider } from "styled-components";
import {
  lightTheme,
  MeetingProvider,
  NotificationProvider,
  darkTheme,
  GlobalStyles,
} from "amazon-chime-sdk-component-library-react";
import {
  AppStateProvider,
  useAppState,
} from "./components/Doctor/AWSCall/providers/AppStateProvider";
import ErrorProvider from "./components/Doctor/AWSCall/providers/ErrorProvider";
import routes from "./components/Doctor/AWSCall/constants/routes";
import { NavigationProvider } from "./components/Doctor/AWSCall/providers/NavigationProvider";
import { Meeting, DeviceSetup } from "./components/Doctor/AWSCall/views";
import Notifications from "./components/Doctor/AWSCall/containers/Notifications";
import NoMeetingRedirect from "./components/Doctor/AWSCall/containers/NoMeetingRedirect";
import FreeAppointmentRequest from "./components/Provider/FreeAppointmentRequest/FreeAppointmentRequest";
import AdminCallRequests from "./components/Provider/AdminCallRequests/AdminCallRequests";
import BuyMedicine from "./components/Doctor/BuyMedicine/BuyMedicine";
import Player from "./components/Player/Player";
import Invoices from "./components/Doctor/Invoices/Invoices";
import OrderDetail from "./components/Doctor/Invoices/OrderDetail";

class App extends Component {
  state = {
    isOpened: true,
  };

  changeState = () => {
    this.setState({ isOpened: !this.state.isOpened });
  };
  sidebar = () => {
    return (
      <Sidebar2 isOpened={this.state.isOpened} changeState={this.changeState} />
    );
  };

  sidebarProvider = () => {
    return (
      <SidebarProvider
        isOpened={this.state.isOpened}
        changeState={this.changeState}
      />
    );
  };

  async componentDidMount() {
    if (window.innerWidth < 600) {
      this.setState({ isOpened: false });
    }
  }
  render() {
    const image = getFromStorage("View") && getFromStorage("View").image;
    return (
      <div>
        <ToastContainer
          autoClose={false}
          position={toast.POSITION.BOTTOM_RIGHT}
          onClick={() => {
            toast.dismiss();
          }}
        />
        <HashRouter>
          <AppStateProvider>
            <Theme>
              <NotificationProvider>
                <Notifications />
                <ErrorProvider>
                  <MeetingProvider>
                    <NavigationProvider>
                      <Switch>
                        <Route path="/" component={Login} exact />
                        <Route path="/Login" component={Login} exact />

                        {/* <Route path="/Graph" exact>
              <Navbar />
              <Sidebar />
              <Dashboard />
            </Route>
            <Route path="/List" exact>
              <Navbar />
              <Sidebar />
              <Dashboard />
            </Route>
            <Route path="/referring_physician_list" exact>
              <Navbar />
              <Sidebar />
              <Dashboard />
            </Route>
            <Route path="/View" exact>
              <Navbar />
              <Sidebar />
              <View />
            </Route>
            <Route path="/Image" exact>
              <img src={`data:image/png;base64,${image}`} alt="" />
            </Route>
            <Route path="/Html" component={Html} exact />

            <Route path="/ChangePassword" exact>
              <Navbar />
              <Sidebar />
              <ChangePassword />
            </Route>

            <Route path="/Bill" exact>
              <Navbar />
              <Sidebar />
              <Bill />
            </Route>

            <Route path="/EditCase" exact>
              <Navbar />
              <Sidebar />
              <EditCaseInfo />
            </Route> */}
                        <Route path="/Policy" exact>
                          <Policy />
                        </Route>
                        {/* <Route path="/DoctorSignup" exact>
                          <Signup />
                        </Route> */}
                        <Route path="/PatientSignup" exact>
                          <PatientSignup />
                        </Route>
                        <Route path="/ProviderSignup" exact>
                          <ProviderSignup />
                        </Route>
                        <Route path="/home" exact>
                          {this.sidebar()}
                          <Home isOpened={this.state.isOpened} />
                        </Route>
                        <Route path="/appointments" exact>
                          {this.sidebar()}
                          <Records isOpened={this.state.isOpened} />
                        </Route>
                        <Route
                          path="/appointments/call/:appintment_id/:time/:patient_id"
                          exact
                        >
                          {this.sidebar()}
                          <Call isOpened={this.state.isOpened} />
                        </Route>
                        {/* <Route path="/alerts" exact>
              {this.sidebar()}
              <Alerts isOpened={this.state.isOpened}/>
            </Route> */}
                        <Route path="/change_password" exact>
                          {this.sidebar()}
                          <ChangePass isOpened={this.state.isOpened} />
                        </Route>
                        <Route path="/order_lab_test" exact>
                          {this.sidebar()}
                          <OrderLabTest isOpened={this.state.isOpened} />
                        </Route>
                        <Route path="/order_tests" exact>
                          {this.sidebar()}
                          <OrderTests isOpened={this.state.isOpened} />
                        </Route>
                        <Route path="/order_tests_invoice" exact>
                          {this.sidebar()}
                          <OrderTestsInvoice isOpened={this.state.isOpened} />
                        </Route>
                        <Route path="/patient_records" exact>
                          {this.sidebar()}
                          <PatientRecord isOpened={this.state.isOpened} />
                        </Route>
                        <Route
                          path="/previous_patient_records/:patient_id"
                          exact
                        >
                          {this.sidebar()}
                          <DocPatientRecord isOpened={this.state.isOpened} />
                        </Route>
                        <Route path="/invoice" exact>
                          {this.sidebar()}
                          <Invoice isOpened={this.state.isOpened} />
                        </Route>
                        <Route
                          path="/digital_prescription/:appointment_id"
                          exact
                        >
                          {this.sidebar()}
                          <DigitalPrescription isOpened={this.state.isOpened} />
                        </Route>
                        <Route
                          path="/patient_digital_prescription/:appointment_id"
                          exact
                        >
                          {this.sidebar()}
                          <PatientDigitalPrescription
                            isOpened={this.state.isOpened}
                          />
                        </Route>
                        <Route path="/profile" exact>
                          {this.sidebar()}
                          <Profile isOpened={this.state.isOpened} />
                        </Route>
                        <Route path="/office_hours" exact>
                          {this.sidebar()}
                          <OfficeHours isOpened={this.state.isOpened} />
                        </Route>
                        <Route path="/card_information" exact>
                          {this.sidebar()}
                          <CardInfo isOpened={this.state.isOpened} />
                        </Route>
                        <Route path="/buy_medicine">
                          {this.sidebar()}
                          <BuyMedicine isOpened={this.state.isOpened} />
                        </Route>
                        <Route path="/invoices">
                          {this.sidebar()}
                          <Invoices isOpened={this.state.isOpened} />
                        </Route>
                        <Route path="/order_detail/:invoice_no/:order_time">
                          {this.sidebar()}
                          <OrderDetail isOpened={this.state.isOpened} />
                        </Route>
                        <Route path="/provider_doctors" exact>
                          {this.sidebarProvider()}
                          <ProviderDoctors isOpened={this.state.isOpened} />
                        </Route>
                        <Route path="/provider_patients" exact>
                          {this.sidebarProvider()}
                          <ProviderPatients isOpened={this.state.isOpened} />
                        </Route>
                        <Route path="/add_patient" exact>
                          {this.sidebarProvider()}
                          <AddPatient isOpened={this.state.isOpened} />
                        </Route>
                        <Route path="/add_doctor" exact>
                          {this.sidebarProvider()}
                          <AddDoctor isOpened={this.state.isOpened} />
                        </Route>
                        <Route path="/patient/change_pass">
                          {this.sidebarProvider()}
                          <ChangePassProvider isOpened={this.state.isOpened} />
                        </Route>
                        <Route path="/doctor/change_pass">
                          {this.sidebarProvider()}
                          <ChangePassProvider isOpened={this.state.isOpened} />
                        </Route>
                        <Route path="/calculate_bills">
                          {this.sidebarProvider()}
                          <CalculateBill isOpened={this.state.isOpened} />
                        </Route>
                        <Route path="/call_list">
                          {this.sidebarProvider()}
                          <CallList isOpened={this.state.isOpened} />
                        </Route>
                        <Route path="/payment_setup">
                          {this.sidebarProvider()}
                          <PaymentSetup isOpened={this.state.isOpened} />
                        </Route>
                        <Route path="/patient/appointment_list">
                          {this.sidebarProvider()}
                          <AppointmentList isOpened={this.state.isOpened} />
                        </Route>
                        <Route path="/doctor/appointment_list">
                          {this.sidebarProvider()}
                          <AppointmentList isOpened={this.state.isOpened} />
                        </Route>
                        <Route path="/provider_appointments">
                          {this.sidebarProvider()}
                          <Appointments isOpened={this.state.isOpened} />
                        </Route>
                        <Route path="/provider_change_password">
                          {this.sidebarProvider()}
                          <ChangePasswordProvider
                            isOpened={this.state.isOpened}
                          />
                        </Route>
                        <Route path="/set_appointment">
                          {this.sidebarProvider()}
                          <SetAppointment isOpened={this.state.isOpened} />
                        </Route>
                        <Route path="/rescheduleByDoctor">
                          {this.sidebarProvider()}
                          <RescheduleByDoctor isOpened={this.state.isOpened} />
                        </Route>
                        <Route path="/free_appointment_requests">
                          {this.sidebarProvider()}
                          <FreeAppointmentRequest isOpened={this.state.isOpened} />
                        </Route>
                        <Route path="/admin_call_requests">
                          {this.sidebarProvider()}
                          <AdminCallRequests isOpened={this.state.isOpened} />
                        </Route>

                        <Route path="/admin_call_requests">
                          {this.sidebarProvider()}
                          <AdminCallRequests isOpened={this.state.isOpened} />
                        </Route>
                        

                        <Route
                          path="/Logo/MerchantName.png"
                          component={Logo}
                          exact
                        />
                        <Route
                          path="/permission"
                          component={Permission}
                          exact
                        />
                        <Route path={routes.DEVICE} exact>
                          <DeviceSetup />
                        </Route>
                        <Route path={routes.MEETING}>
                          <NoMeetingRedirect>
                            <MeetingModeSelector />
                          </NoMeetingRedirect>
                        </Route>
                        <Route path="/video_player">
                          <Player url="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"/>
                        </Route>
                        <Route path="/" component={NotFound} />
                      </Switch>
                    </NavigationProvider>
                  </MeetingProvider>
                </ErrorProvider>
              </NotificationProvider>
            </Theme>
          </AppStateProvider>
        </HashRouter>
      </div>
    );
  }
}

const Theme = ({ children }) => {
  const { theme } = useAppState();

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      {window.location.pathname.includes("devices") || window.location.pathname.includes("meeting") ? <GlobalStyles /> : <span></span>}
      {children}
    </ThemeProvider>
  );
};

const MeetingModeSelector = () => {
  const { meetingMode } = useAppState();

  return <Meeting mode={meetingMode} />;
};

export default App;
