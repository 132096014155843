import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { Email, Lock, SupervisedUserCircle, Person, PersonAdd, LocalHospital, Call, LocalHospitalOutlined, PersonAddOutlined } from "@material-ui/icons";
import ReactInputHandler from "react-input-handler";
import { withRouter, NavLink } from "react-router-dom";
import apiURL from "../../config/api.json";
import key from "../../config/key.json";
import errorConfig from "../../config/errorConfig.json";
import { setInStorage, getFromStorage } from "../../utils/storage";
import { toast } from "react-toastify";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import image from "../../image/login.jpg";
import logo from "../../image/logo.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { md5Password } from "../../utils/md5";

const color = "#229a88";

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleChange = ReactInputHandler.bind(this);
    this.state = {
      admin: false,
      role: "patient",
      diagnostic: false,
      email: "",
      password: "",
      isLogging: true,
      isLogin: true
    };
  }

  //Error notification
  notifyError = error => {
    toast.error("" + error.toString());
  };

  //Authentication for admin
  authenticateAdmin = (password) => {
    axios
      .post(
        apiURL.postMethodURL,
        {
          task: "authAdmin",
          email: this.state.email,
          password
        },
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(res => {
        
        if (res.data.length !== 0) {
          setInStorage("dashboard_name", res.data[0].institution)
          setInStorage("owner_id", res.data[0].id.toString())
          setInStorage(key.loginInfoKey, {
            email: this.state.email,
            task: "authAdmin"
          });
          this.setState(
            { email: "", password: "", role: "", isLogging: true },
            () => {
              this.props.history.push("/Graph");
            }
          );
        } else {
          this.setState({ isLogging: true });
          this.notifyError(errorConfig.authenticationError);
        }
      })
      .catch(err => {
        this.setState({ isLogging: true })
        this.notifyError(err.toString());
      });
  };

  //Authentication for diagnostic
  authenticateDiagnostic = (password) => {
    axios
      .post(
        apiURL.postMethodURL,
        {
          task: "authDiagAdminPanel",
          email: this.state.email,
          password
        },
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(res => {
        if (res.data.length !== 0) {
          setInStorage("dashboard_name", res.data[0].institute)
          setInStorage("owner_id", res.data[0].di_id.toString())
          setInStorage(key.loginInfoKey, {
            email: this.state.email,
            task: "authDiagAdminPanel"
          });
          this.setState(
            { email: "", password: "", role: "", isLogging: true },
            () => {
              this.props.history.push("/Graph");
            }
          );
        } else {
          this.setState({ isLogging: true });
          this.notifyError(errorConfig.authenticationError);
        }
      })
      .catch(err => {
        this.setState({ isLogging: true })
        this.notifyError(err.toString());
      });
  };

  authenticatePatient = (password) => {
    const body = { task: "authenticatePatient", phone: this.state.email, password }
    const headers = { headers: { "Content-Type" : "application/json" } }
    axios.post(apiURL.PatientApi, body, headers)
    .then(res => {
      if (res.data.length !== 0) {
        setInStorage("data", res.data[0])
        setInStorage("patient", res.data[0].di_id.toString())
        this.patientLoginStatus(res.data[0].di_id.toString())
        setInStorage(key.loginInfoKey, {
          name: res.data[0].name.toString(),
          email: this.state.email,
          task: "patient"
        });
        this.setState(
          { email: "", password: "", role: "", isLogging: true },
          () => {
            this.props.history.push("/home");
          }
        );
      } else {
        this.setState({ isLogging: true });
        this.notifyError(errorConfig.authenticationError);
      }
    })
    .catch(err => {
      this.setState({ isLogging: true })
      this.notifyError(err.toString());
    })
  }

  authenticateDoctor = (password) => {
    const body = { task: "authenticateDoctor", phone: this.state.email, password}
    const headers = { headers: { "Content-Type" : "application/json" } }
    axios.post(apiURL.DoctorApi, body, headers)
    .then(res => {
      if (res.data.length !== 0) {
        setInStorage("data", res.data[0])
        setInStorage("doctor", res.data[0].id.toString())
        setInStorage(key.loginInfoKey, {
          name: res.data[0].name.toString(),
          email: this.state.email,
          task: "doctor"
        });
        this.setState(
          { email: "", password: "", role: "", isLogging: true },
          () => {
            this.props.history.push("/home");
          }
        );
      } else {
        this.setState({ isLogging: true });
        this.notifyError(errorConfig.authenticationError);
      }
    })
    .catch(err => {
      this.setState({ isLogging: true })
      this.notifyError(err.toString());
    })
  }

  authenticateProvider = (password) => {
    const body = { task: "authenticateProvider", phone: this.state.email, password }
    const headers = { headers: { "Content-Type" : "application/json" } }
    axios.post(apiURL.ProviderApi, body, headers)
    .then(res => {
      if (res.data.length !== 0) {
        setInStorage("provider", res.data[0].id.toString())
        setInStorage(key.loginInfoKey, {
          name: res.data[0].name.toString(),
          task: "provider"
        });
        this.setState(
          { email: "", password: "", role: "", isLogging: true },
          () => {
            this.props.history.push("/provider_doctors");
          }
        );
      } else {
        this.setState({ isLogging: true });
        this.notifyError(errorConfig.authenticationError);
      }
    })
    .catch(err => {
      this.setState({ isLogging: true })
      this.notifyError(err.toString());
    })
  }

  //Patient login status
  patientLoginStatus = (patient_id) => {
    const body = { task: "setPatientLoggedIn", patient_id: patient_id.toString(), logged_in_status: 1 }
    const headers = { headers: { "Content-Type" : "application/json" } }
    axios.post(apiURL.PatientApi, body, headers)
    .then(res => {
      if(!res.data.errorMessage) {
        console.log(res.data)
      } else {
        this.setState({ isLogging: true })
        this.notifyError("Something went wrong")
      }
    })
    .catch(err => {
      this.setState({ isLogging: true })
      this.notifyError(err.toString())
    })
  }

  //Login Handler
  loginHandler = e => {
    e.preventDefault();
    if (
      this.state.email.trim() !== "" &&
      this.state.password.trim() !== "" &&
      this.state.role !== ""
    ) {
        if (this.state.role === "admin") {
          this.setState({ isLogging: false }, () => this.authenticateAdmin(md5Password(this.state.password)));
        } else if(this.state.role === "referring physician"){
          this.setState({ isLogging: false }, () => this.authenticateDoctor(md5Password(this.state.password)))
        } else if(this.state.role === "patient"){
          this.setState({ isLogging: false }, () => this.authenticatePatient(md5Password(this.state.password)))
        } else if(this.state.role === "provider"){
          this.setState({ isLogging: false }, () => this.authenticateProvider(md5Password(this.state.password)))
        } else {
          this.setState({ isLogging: false }, () =>
            this.authenticateDiagnostic(md5Password(this.state.password))
          );
        }  
    } else {
      this.setState({ isLogging: true })
      this.notifyError(errorConfig.requireError);
    }
  };

  componentDidMount() {
    toast.dismiss();
    if (getFromStorage(key.loginInfoKey)) {
      if(getFromStorage(key.loginInfoKey).task !== "patient" && getFromStorage(key.loginInfoKey).task !== "doctor" && getFromStorage(key.loginInfoKey).task !== "provider"){
        this.props.history.push("/Graph");
      } else if (getFromStorage(key.loginInfoKey).task === "patient") {
        if(getFromStorage("payment_on")) {
          this.props.history.push(getFromStorage("payment_on"));
        } else if(!getFromStorage("payment_on")) {
          this.props.history.push("/home");
        }
      } else if (getFromStorage(key.loginInfoKey).task === "doctor") {
        this.props.history.push("/home");
      } else if (getFromStorage(key.loginInfoKey).task === "provider")  {
        this.props.history.push("/home");
      }
    } else {
      this.setState({isLogin: false})
    }
  }

  render() {
    if (!this.state.isLogin) {
      return (
        <div
          className="login"
          style={{
            color: "teal",
            minWidth: "320px",
            marginTop: -20
          }}
        >
          <div style={{ float: "left", width: "60%", height: "100vh", display: window.innerWidth > 790 ? "" : "none" }}>
          <Carousel showArrows={false} showThumbs={false} autoPlay={true} showStatus={false}>
            <div>
                <img src={image} alt="cover" style={{ height: "100vh" }}/>
            </div>
            <div>
                <img src={image} alt="cover" style={{ height: "100vh" }}/>
            </div>
            <div>
                <img src={image} alt="cover" style={{ height: "100vh" }}/>
            </div>
          </Carousel>
          </div>
              <h1 style={{ marginBottom: "10px", paddingTop: "10px", textAlign: "center", fontWeight: "bold" }}>
                <img src={logo} style={{width: "200px", height: "200px", marginBottom: "-50px", marginTop: "-30px"}} alt="logo"/>
                <br/>
                <span style={{marginTop: "-100px", fontSize: 24}}>Login</span>
              </h1>
              <hr/>
              <Form onSubmit={this.loginHandler}>
                <Form.Group>
                  {/* <span style={{fontWeight: "bold", marginLeft: "9.5%", fontSize: "20px"}}>Role</span>{" "}<br/>
                 
                  <label style={{ cursor: "pointer", marginLeft: "9.2%" }}>
                    <Person />
                    <input
                      type="radio"
                      name="role"
                      value="admin"
                      checked={this.state.role === "admin"}
                      style={{
                        marginLeft: "20px",
                        marginRight: "5px"
                      }}
                      onChange={() => {
                        this.setState({ role: "admin" });
                      }}
                    />
                    Admin
                  </label>
                  <br/>
                  <label style={{ cursor: "pointer", marginLeft: "9.2%"  }}>
                    <SupervisedUserCircle />
                    <input
                      type="radio"
                      name="role"
                      value="diagnostic"
                      checked={this.state.role === "diagnostic"}
                      style={{
                        marginLeft: "20px",
                        marginRight: "5px"
                      }}
                      onChange={() => {
                        this.setState({ role: "diagnostic" });
                      }}
                    />
                    Diagnostic
                  </label><br/>
                  <label style={{ cursor: "pointer", marginLeft: "9.2%"  }}>
                    <PersonAdd />
                    <input
                      type="radio"
                      name="role"
                      value="radiologist"
                      checked={this.state.role === "radiologist"}
                      style={{
                        marginLeft: "20px",
                        marginRight: "5px"
                      }}
                      onChange={() => {
                        this.setState({ role: "radiologist" });
                      }}
                    />
                    Radiologist
                  </label><br/> */}
                  <label style={{ cursor: "pointer", marginLeft: "9.2%"  }}>
                    <LocalHospitalOutlined />
                    <input
                      type="radio"
                      name="role"
                      value="patient"
                      checked={this.state.role === "patient"}
                      style={{
                        marginLeft: "20px",
                        marginRight: "5px"
                      }}
                      onChange={() => {
                        this.setState({ role: "patient" });
                      }}
                    />
                    Patient
                  </label><br/>
                  <label style={{ cursor: "pointer", marginLeft: "9.2%"  }}>
                    <LocalHospital />
                    <input
                      type="radio"
                      name="role"
                      value="referring physician"
                      checked={this.state.role === "referring physician"}
                      style={{
                        marginLeft: "20px",
                        marginRight: "5px"
                      }}
                      onChange={() => {
                        this.setState({ role: "referring physician" });
                      }}
                    />
                    Physician
                  </label><br/>
                  <label style={{ cursor: "pointer", marginLeft: "9.2%"  }}>
                    <PersonAddOutlined />
                    <input
                      type="radio"
                      name="role"
                      value="provider"
                      checked={this.state.role === "provider"}
                      style={{
                        marginLeft: "20px",
                        marginRight: "5px"
                      }}
                      onChange={() => {
                        this.setState({ role: "provider" });
                      }}
                    />
                    Provider
                  </label>
                </Form.Group>
                
                <hr />
                <Form.Group
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto"
                  }}
                  align="center"
                >
                  {(this.state.role === "diagnostic" || this.state.role === "admin" || this.state.role === "radiologist") ?
                    <Email
                      style={{
                        position: "relative",
                        left: "30px",
                        marginTop: "-2px",
                        color: "#484848",
                        fontSize: "18px"
                      }}
                    />
                    :
                    <Call
                      style={{
                        position: "relative",
                        left: "30px",
                        marginTop: "-2px",
                        color: "#484848",
                        fontSize: "18px"
                      }}
                    />
                  }
                  {(this.state.role === "diagnostic" || this.state.role === "admin" || this.state.role === "radiologist") ?
                    <Form.Control
                      name="email"
                      type="email"
                      placeholder="Email"
                      style={{
                        width: "300px",
                        paddingLeft: "40px",
                        borderRadius: "25px",
                        height: "45px",
                        color: "black",
                        display: "inline"
                      }}
                      onChange={this.handleChange}
                    />
                    :
                    <Form.Control
                      name="email"
                      type="text"
                      placeholder="Phone (eg. 01712345678)"
                      pattern="[0-9]*"
                      style={{
                        width: "300px",
                        paddingLeft: "40px",
                        borderRadius: "25px",
                        height: "45px",
                        color: "black",
                        display: "inline"
                      }}
                      value={this.state.email}
                      onChange={(e) => {
                        if(e.target.validity.valid){
                          this.setState({email: e.target.value})
                        }
                      }}
                    />
                  }
                </Form.Group>
                <Form.Group
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto"
                  }}
                  align="center"
                >
                  <Lock
                    style={{
                      position: "relative",
                      left: "30px",
                      marginTop: "-2px",
                      color: "#484848",
                      fontSize: "18px"
                    }}
                  />
                  <Form.Control
                    name="password"
                    type="password"
                    value={this.state.password}
                    placeholder="Password"
                    style={{
                      width: "300px",
                      paddingLeft: "40px",
                      borderRadius: "25px",
                      height: "45px",
                      color: "black",
                      display: "inline"
                    }}
                    onChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto"
                  }}
                  align="center"
                >
                  {this.state.isLogging ? (
                    <Button
                      variant="success"
                      style={{
                        width: "300px",
                        borderRadius: "25px",
                        height: "45px",
                        fontWeight: "bold",
                        marginLeft: "2%"
                      }}
                      type="submit"
                    >
                      LOGIN
                    </Button>
                  ) : (
                    <CircularProgress />
                  )}
                </Form.Group>
               {(this.state.role === "patient" || this.state.role === "referring physician" || this.state.role === "provider") &&
                 <Form.Group style={{textAlign: "center", marginTop: "10px", marginBottom: "-10px"}}>
                  <Form.Label>
                      <span style={{color: "gray"}}>Don't have an account?</span> {" "}
                      {this.state.role !== "referring physician" ? 
                        <NavLink to={this.state.role === "patient" ? "/PatientSignup" : this.state.role === "provider" && "/ProviderSignup"}>
                            <span style={{color: color, fontWeight: "bold", cursor: "pointer"}}>Signup</span>
                        </NavLink>
                        :
                        <span style={{color: color, fontWeight: "bold", cursor: "pointer"}} onClick={() => alert("If you are a certified doctor, contact office to provide documentation and register as doctor.")}>Signup</span>
                      }
                  </Form.Label>
                </Form.Group>
              }
              </Form>
              <hr/>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default withRouter(Login);
