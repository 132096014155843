//const defining type of action object
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";

// following are action creators, these will simply return action objects
export function addNotificationAction(id, detail) {
  return {
    type: ADD_NOTIFICATION,
    notification: {
      id,
      ...detail
    }
  };
}

export function updateNotificationAction(id) {
  return {
    type: UPDATE_NOTIFICATION,
    notification: {
        id
    }
  };
}