import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

export default class ViewImageDialog extends Component {

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom: "-100px"}}>Document</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {!this.props.document.includes(".pdf") ? 
                        <img src={this.props.document} style={{overflowX: "auto", width: "100%"}} alt=""/> 
                        : 
                        <iframe src={this.props.document} width="100%" height="500px"/>
                    }
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" size="sm" onClick={this.props.handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}