import firebase from "firebase/app";
import 'firebase/messaging';

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyDTIMAtL7n7DjEMAdwVCrSGzk8Dqdv8FnY",
  authDomain: "semdoc-a78d4.firebaseapp.com",
  databaseURL: "https://semdoc-a78d4.firebaseio.com",
  projectId: "semdoc-a78d4",
  storageBucket: "semdoc-a78d4.appspot.com",
  messagingSenderId: "483013043625",
  appId: "1:483013043625:web:0b29399c011d7e9f9a90b8",
  measurementId: "G-YMZWDZ6JKS",
};

// Initialize Firebase
var fire = firebase.initializeApp(firebaseConfig);
// if (firebase.messaging.isSupported()) {
  const messaging = fire.messaging();
  messaging.usePublicVapidKey(
    // Project Settings => Cloud Messaging => Web Push certificates
    "BHi7C_tcXe3D64Nghgqogs5Oh5ulX9L0u1i3WXPbYvxtCEQPAJifD8wQQ4qpbIf3EmpJaKGh89l2TTaCOClTdDI"
  );
// }
export default fire;
