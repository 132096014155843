// Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react';

import { MetricItemProps } from './MetricItem';
import { StyledList } from './Styled';

export const MediaStatsList = ({ children }) => (
  <StyledList>{children}</StyledList>
);

export default MediaStatsList;