import React, { Component } from "react";
import {
  Add,
  Event,
  Notifications,
  ExitToApp,
  Menu,
  Person,
  Home,
  Lock,
  CreditCard,
  Timelapse,
  LabelTwoTone,
  Call,
} from "@material-ui/icons";
import { NavLink, withRouter } from "react-router-dom";
import { removeFromStorage, getFromStorage } from "../../../utils/storage";
import key from "../../../config/key.json";
import fire from "../../../firebase/fire";
import { connect } from "react-redux";
import axios from 'axios';
import apiURL from '../../../config/api.json'
import { addNotificationAction } from "../../../actions/notificationAction";
import CallDialog from './CallDialog'
import Sound from 'react-sound';
import { toast } from "react-toastify";
// import ringtone from './ringtone.mp3'
// import ReactAudioPlayer from 'react-audio-player';

const backgroundColor = "#545151";
const color = "white";

class Sidebar extends Component {
  state = {
    show: false,
    notifications: [],
    isNew: false,
    showDialog: false,
    message: null,
    play: false,
    isRequesting: false
  };

  notifyError = () => {
    
  }

  requestAdminCall = () => {
    this.setState({isRequesting: true})

    const data = {
      task: "setAdminCallRequest",
      patient_id: getFromStorage("patient"),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    axios.post(apiURL.PatientApi, data)
    .then(res => {
      if(!res.data.errorMessage) {
        toast.success("Admin Call Request Sent Successfully")
      } else {
        toast.error(res.data.errorMessage)
      }
      this.setState({isRequesting: false})
    }) 
    .catch(err => {
      toast.error(err.toString())
      this.setState({isRequesting: false})
    })
  }

  //Patient logout status
  patientLogoutStatus = () => {
    if (getFromStorage("patient")) {
      const body = {
        task: "setPatientLoggedIn",
        patient_id: getFromStorage("patient").toString(),
        logged_in_status: 0,
      };
      const headers = { headers: { "Content-Type": "application/json" } };
      axios
        .post(apiURL.PatientApi, body, headers)
        .then((res) => {
          if (!res.data.errorMessage) {
            removeFromStorage(key.loginInfoKey);
            removeFromStorage("doctor");
            removeFromStorage("patient");
            this.props.history.push("/");
          } else {
            this.setState({ isLogging: true });
            console.log("")
          }
        })
        .catch((err) => {
          this.setState({ isLogging: true });
          console.log(err.toString())
        });
    } else {
        removeFromStorage(key.loginInfoKey);
        removeFromStorage("doctor");
        removeFromStorage("patient");
        this.props.history.push("/");
    }
  };

  calling = (message) => {
      console.log(message)
  
      this.setState({showDialog: true, message: message.data.firebaseMessaging.payload.data, play: true})
      setTimeout(() => this.setState({play: false, showDialog: false}), 40000)
  }

  componentDidMount() {
    navigator.serviceWorker.addEventListener("message", (message) => {
      console.log("abc")
      this.calling(message)
    });
  }

  componentWillUnmount() {
    navigator.serviceWorker.removeEventListener("message", (message) => {
      this.calling(message)
    });
  }
  render() {
    return (
      <div style={{ position: "fixed", top: 0, zIndex: 1 }}>
        <div
          style={{
            cursor: "pointer",
            height: "50px",
            backgroundColor,
            marginLeft: this.props.isOpened ? "140px" : "0px",
            width: "100%",
            top: 0,
            left: 0,
            right: 0,
            position: "fixed",
            border: "0.5px solid #5c5857",
          }}
        >
          <Menu
            style={{
              marginLeft: "10px",
              color,
              fontSize: "25px",
              marginTop: "10px",
            }}
            onClick={this.props.changeState}
          />
          <span
            style={{
              fontSize: "14px",
              color: "white",
              position: "absolute",
              right: "15%",
              marginTop: "13px",
            }}
          >
            Helpline: 01531961381, 09642444222
          </span>
        </div>
        <div
          className="sidebar1"
          style={{
            width: this.props.isOpened ? "140px" : "0px",
            backgroundColor,
            height: "100vh",
            border: "0.5px solid #5c5857",
          }}
        >
          {this.props.isOpened && (
            <div
              style={{
                position: "absolute",
                color,
                marginLeft: "10px",
                width: "125px",
              }}
            >
              <ul style={{ borderBottom: "0.5px solid #5c5857" }}>
                <li style={{ marginLeft: "5px" }}>
                  <span style={{ fontSize: "18px" }}>SemDoc</span>
                </li>

                <li
                  style={{
                    fontSize: "14px",
                    color: "silver",
                    marginLeft: "10px",
                    marginTop: "-15px",
                  }}
                >
                  {getFromStorage(key.loginInfoKey)
                    ? getFromStorage(key.loginInfoKey).name
                    : "name"}
                </li>
              </ul>
              <ul
                className="lists"
                style={{
                  marginTop: "-10px",
                  marginLeft: "10px",
                  color: "silver",
                  fontSize: "14px",
                }}
              >
                <NavLink
                  to="/home"
                  activeStyle={{ color: "white" }}
                  style={{ textDecoration: "none", color: "silver" }}
                >
                  <li>
                    <Home style={{ fontSize: "20px" }} />
                    <span style={{ marginLeft: "5px" }}>Home</span>
                  </li>
                </NavLink>
                <NavLink
                  to="/appointments"
                  activeStyle={{ color: "white" }}
                  style={{ textDecoration: "none", color: "silver" }}
                >
                  <li>
                    <Event style={{ fontSize: "20px" }} />
                    <span style={{ marginLeft: "5px" }}>Appointments</span>
                  </li>
                </NavLink>
                {getFromStorage("doctor") && (
                  <NavLink
                    to="/office_hours"
                    activeStyle={{ color: "white" }}
                    style={{ textDecoration: "none", color: "silver" }}
                  >
                    <li>
                      <Timelapse style={{ fontSize: "20px" }} />
                      <span style={{ marginLeft: "5px" }}>Office Hours</span>
                    </li>
                  </NavLink>
                )}
                {/* <NavLink
                  to="/alerts"
                  activeStyle={{ color: "white" }}
                  style={{ textDecoration: "none", color: "silver" }}
                >
                  <li>
                    <Notifications style={{ fontSize: "20px" }} />
                    <span style={{ marginLeft: "5px" }}>Alerts </span>
                    {this.props.notifications.find((item) => !item.read) && (
                      <span
                        style={{
                          backgroundColor: "red",
                          padding: "3px",
                          borderRadius: "10px",
                          fontSize: "12px",
                        }}
                      >
                        New
                      </span>
                    )}
                  </li>
                </NavLink> */}
                <NavLink
                  to="/change_password"
                  activeStyle={{ color: "white" }}
                  style={{ textDecoration: "none", color: "silver" }}
                >
                  <li>
                    <Lock style={{ fontSize: "20px" }} />
                    <span style={{ marginLeft: "5px" }}>Password</span>
                  </li>
                </NavLink>
                <NavLink
                  to="/profile"
                  activeStyle={{ color: "white" }}
                  style={{ textDecoration: "none", color: "silver" }}
                >
                  <li>
                    <Person style={{ fontSize: "20px" }} />
                    <span style={{ marginLeft: "5px" }}>Profile</span>
                  </li>
                </NavLink>
                {getFromStorage("patient") && (
                  <NavLink
                    to="/patient_records"
                    activeStyle={{ color: "white" }}
                    style={{ textDecoration: "none", color: "silver" }}
                  >
                    <li>
                      <LabelTwoTone style={{ fontSize: "20px" }} />
                      <span style={{ marginLeft: "5px" }}>Records</span>
                    </li>
                  </NavLink>
                )}
                {getFromStorage("patient") && (
                  <NavLink
                    to="/invoices"
                    activeStyle={{ color: "white" }}
                    style={{ textDecoration: "none", color: "silver" }}
                  >
                    <li>
                      <LabelTwoTone style={{ fontSize: "20px" }} />
                      <span style={{ marginLeft: "5px" }}>Invoices</span>
                    </li>
                  </NavLink>
                )}
                {getFromStorage("patient") && (
                  <NavLink
                    to="/order_lab_test"
                    activeStyle={{ color: "white" }}
                    style={{ textDecoration: "none", color: "silver" }}
                  >
                    <li>
                      <LabelTwoTone style={{ fontSize: "20px" }} />
                      <span style={{ marginLeft: "5px" }}>Order Lab Test</span>
                    </li>
                  </NavLink>
                )}
                {getFromStorage("patient") && (
                  <NavLink
                    to="/buy_medicine"
                    activeStyle={{ color: "white" }}
                    style={{ textDecoration: "none", color: "silver" }}
                  >
                    <li>
                      <LabelTwoTone style={{ fontSize: "20px" }} />
                      <span style={{ marginLeft: "5px" }}>Buy Medicine</span>
                    </li>
                  </NavLink>
                )}
                {getFromStorage("patient") && (
                  <NavLink
                    to="/invoices"
                    activeStyle={{ color: "white" }}
                    style={{ textDecoration: "none", color: "silver" }}
                  >
                    <li>
                      <LabelTwoTone style={{ fontSize: "20px" }} />
                      <span style={{ marginLeft: "5px" }}>Invoices</span>
                    </li>
                  </NavLink>
                )}
                {getFromStorage("patient") && (
                  <li style={{cursor: "pointer"}} onClick={() => {if(!this.state.isRequesting){this.requestAdminCall()}else{alert("Previous Request Already Loading")}}}>
                    <Call style={{ fontSize: "20px" }} />
                    <span style={{ marginLeft: "5px" }}>Request Admin Call</span>
                  </li>
                )}
                <li
                  onClick={this.patientLogoutStatus}
                  style={{ cursor: "pointer" }}
                >
                  <ExitToApp style={{ fontSize: "20px" }} />
                  <span style={{ marginLeft: "5px" }}>Logout</span>
                </li>
                <NavLink
                  to="/Policy"
                  activeStyle={{ color: "white" }}
                  style={{ textDecoration: "none", color: "silver" }}
                >
                  <li>
                    <span style={{ marginLeft: "5px" }}>
                      Terms & Conditions
                    </span>
                  </li>
                </NavLink>
              </ul>
            </div>
          )}
        </div>
        {this.state.showDialog && <CallDialog show={this.state.showDialog} handleClose={() => this.setState({showDialog: false})} message={this.state.message}/>}
      </div>
    );
  }
}

export default connect((state) => ({
  notifications: state.notifications,
}))(withRouter(Sidebar));
