import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";


class Policy extends Component {
  render(){
      return( 
        <div>
<body  style={{padding : '50px'}}>
<h1> Terms and Conditions</h1>
<p>Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the Website/Site at: www.https://semdoc.semion.ai and/or Mobile Applications for "Semion", "I Online Doc"; termed as "Service" available for download on Play Store for Android Devices and on App Store for iOS Devices respectively, and operated by Semion Inc. ("Company")</p>

<p>
In this agreement, the words "you" and "your" mean any person or that person's authorized representative who accesses, registers or uses any product and/or service offered through our Website and/or Mobile Applications. The words "we","us" and "our" means Semion Inc. and its world-wide affiliates, which provide requested products or services.</p>

<p>
Your access to and use of the website and/or mobile applications is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.
</p>

<p>
By accessing, registering or using the Service you agree to be bound by these Terms and consent to our usage of information as outlined in our Privacy Policy. If you disagree with any part of the terms then do not use our Website and/or Mobile Application.
</p>

<p>
If you wish to use and/or access any service made available through our website and/or mobile applications, you may be asked to supply certain information relevant to your Purchase including, without limitation, your personal health information, credit card information and any other information required to perform the Service.
</p>

<p>
You must be at least eighteen (18) years of age to use this website. By using this website and/or Mobile Applications; and by agreeing to these terms and conditions you warrant and represent that you are at least eighteen (18) years of age.
</p>

<p>
This website and/or Mobile Application uses cookies. By using this website and agreeing to these terms and conditions, you consent to Semion Inc.'s use of cookies in accordance with the terms of our Privacy Policy.
</p>

<h2>License to use</h2>
<p>
Unless otherwise stated, Semion Inc. and/or its licensors own the intellectual property rights in the website and material on the website, as well as the mobile applications and material on the mobile applications. Subject to the license below, all these intellectual property rights are reserved.
</p>

<p>
You may view, download for caching purposes only, and print pages from the website for your own personal use, subject to the restrictions set out below and elsewhere in these terms and conditions.
</p>

<p>
You must not (or assist other/s):
</p>

<p>
Re-publish material and/or content and/or services from our website/mobile applications;
Sell, rent or sub-license material and/or content and/or services from our website/mobile applications;
Show, Share any material and/or content and/or services from the website and/or mobile application in public;
Reproduce, duplicate, copy or otherwise exploit material and/or content and/or services for a commercial purpose or otherwise;
Edit or otherwise modify material and/or content and/or services from our website/mobile applications; or
Redistribute material and/or content and/or services from our website/mobile applications.
</p>

<p>
Acceptable use
We hereby grant you a limited, non-exclusive, nontransferable right to access our website and/or mobile applications and use the Services solely for your personal non-commercial use and only as permitted under these Terms of Use and any separate agreements you may have entered into with us
</p>

<p>
You agree to the following with regards to usage of our website and/or mobile applications:
</p>

<p>
not to use our website and/or mobile applications or Services in any way that causes, or may cause, damage or impairment of the availability or accessibility of the website and/or mobile applications; or in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity.
not to use our website and/or mobile applications or Services to copy, store, host, transmit, send, use, publish or distribute any material which consists of or is linked to any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or any other malicious computer and/or mobile/smartphone software or code through or onto our Services.
not to conduct any systematic or automated data collection activities including without limitation scraping, data mining, data extraction and data harvesting on or in relation to our website and/or mobile applications or Services without Company’s express written consent.
not to use our website and/or mobile applications or Services to transmit or send unsolicited commercial communications.
not to use our website and/or mobile applications or Services for any purposes related to marketing.
not to use our website and/or mobile applications or Services for any Medical Emergency.
not to use our website and/or mobile applications as a substitute for health insurance or a primary care doctor or in-person doctor visits.
not to use our website and/or mobile applications or Services to impersonate any person or entity, or otherwise misrepresent your affiliation with a person or entity.
not to use our website and/or mobile applications or Services to adapt, modify, prepare derivative works based upon, distribute, license, sublicense, transfer, display, perform, or otherwise exploit our Services in impermissible or unauthorized manners, or in ways that burden, impair, or harm us, our Services, website and/or mobile applications systems, our users, or others
not to use our website and/or mobile applications or Services to violate any local, state, national or international law.
not to use our website and/or mobile applications or Services to reverse engineer, alter, modify, create derivative works from, decompile, or extract code, disassemble, decompile, or translate any software or other components of our website and/or mobile applications or Services
not to gain or attempt to gain unauthorized access to our website and/or mobile applications or Services
not to use our website and/or mobile applications or Services to interfere with or disrupt the integrity or performance of our Services
not to use our website and/or mobile applications or Services to create accounts for our Services through unauthorized or automated means
not to use our website and/or mobile applications or Services to collect the information of or about our users in any impermissible or unauthorized manner
not to use our website and/or mobile applications or Services to distribute or make our Services available over a network where they could be used by multiple devices at the same time
not to use our website and/or mobile applications or Services in any manner that exceeds the scope of use granted above
Please dial 911 (or other Applicable Phone Number based on your location) for any Medical Emergency.
</p>

<p>
Consent to Participate in Telemedicine Consultation
</p>

<p>
You consent to, agree to and understand the following with regards to usage of our website and/or mobile applications to participate in a telemedicine / video consultation:
</p>

<p>
video conferencing technology will be used to affect consultation and this consultation will not be the same as a direct patient/healthcare provider visit due to the fact that user will not be in the same room as healthcare provider.
there are potential risks to this technology, including interruptions, unauthorized access and technical difficulties.
healthcare provider or user can discontinue the telemedicine consult/visit if it is felt that the videoconferencing connection is not adequate for the situation.
User healthcare information may be shared with other individuals for scheduling and billing purposes.
Others may be present during the consultation other than healthcare provider in order to enable them to provide effective services. All such personnel will maintain confidentiality of the information obtained.
</p>

<p>
Services
</p>

<p>
Our Site and/or Mobile Application, allows participating Licensed Healthcare Providers to communicate with patients ("Patients") to provide healthcare services online and perform virtual house calls via Video Call (the "Services"). We are not a medical service provider, health insurance company, or licensed to sell health insurance.
</p>

<p>
To the extent medical advice and/or diagnosis provided to you by Online Healthcare Provider through the Services, such medical advice and/or diagnosis is based on your personal health data, information and symptoms as provided by you to the Healthcare provider at the time of your Video Call The medical advice provided by your healthcare provider is not under any control of the Company, nor is it provided to or used by the Company in any form.
</p>

<p>
Company does endorse or recommend or imply recommendation in any manner or form any Healthcare Provider, Health advice, test, procedure, opinion or any other information that may be mentioned on our Site and/or Mobile Application. You understand and agree that prescriptions if provided by Online Healthcare Provider are limited in type and duration, and you agree not to use Company to request prescriptions for any medications or for any duration not permitted by law in any manner or form.
</p>

<p>
Availability of Services
</p>
<p>
Our Services may be interrupted, including for maintenance, repairs, upgrades, updates or network or equipment failures. We may discontinue some or all of our Services, including certain features and the support for certain devices and platforms, at any time. Events beyond our control may affect our Services, including but not limited to natural events and other force majeure events.
</p>

<p>
Restricted access
</p>
<p>
Access to certain areas of our website and/or mobile applications is restricted. Semion Inc. reserves the right to restrict access to other areas of our website and/or mobile applications, or restrict access to entire website and/or mobile applications.
</p>

<p>
If Company provides you with a user ID and password to enable you to access restricted areas of our website and/or mobile applications or other content or services, you must ensure that the user ID and password are kept confidential.
</p>

<p>
Company may disable your user ID and password without notice or explanation.
</p>

<p>
User content
</p>
<p>
In these terms and conditions, “your user content” means material (including without limitation text, images, audio material, video material and audio-visual material) that you submit to our website and/or mobile applications, for whatever purpose.
</p>

<p>
You grant to the Company a worldwide, irrevocable, non-exclusive, royalty-free license to use, reproduce, adapt, publish, translate and distribute your user content in any existing or future media. You also grant to the Company the right to sublicense these rights, and the right to bring an action for infringement of these rights.
</p>

<p>
Your user content must not be illegal or unlawful, must not infringe any third party's legal rights, and must not be capable of giving rise to legal action whether against you or the Company or a third party (in each case under any applicable law).
</p>

<p>
You must not to share, upload material (including without limitation text, images, audio material, video material and audio-visual material) of any kind that may contain any content or expressions of hate, abuse, offensive images or conduct, obscenity, pornography, sexually explicit, medically inappropriate, or any material that could give rise to any civil or criminal liability under applicable law or regulations.
</p>

<p>
You must not submit any user content to our website and/or mobile applications that is or has ever been the subject of any threatened or actual legal proceedings or other similar complaint.
</p>

<p>
Company reserves the right to edit or remove any material submitted to our website and/or mobile applications, or stored on servers, or hosted or published upon our website and/or mobile applications.
</p>

<p>
Notwithstanding, Company’s rights under these terms and conditions in relation to user content, Company does not undertake to monitor the submission of such content to, or the publication of such content on our website and/or mobile applications.
</p>

<p>
Languages
</p>

<p>
Company is not responsible or liable in any event of inaccuracy where any content or Service (including these Terms of Use) are translated into any language/s other than English. Any and all such translations are for convenience only. In any event of conflict/s including but not limited to the Terms of Service or any other content or Service translated into any other language other than English Language, the English-language version shall control and apply.
</p>

<p>
You fully and completely understand that any content, including but not limited to questions and answers, diagnosis, treatments, medication names and any other information may not have the same meaning in translation and may differ from country to country and may not be available at all places.
</p>

<p>
Additionally, you fully and completely understand and agree that service with healthcare Provider may not be available in a language other than English, and you agree not to conduct a consultation with a doctor in a language in which you are not proficient. You understand and agree that the healthcare Provider who speaks your language may not be a native speaker and Provider’s ability to communicate may be limited. You fully and completely understand that Company is not liable for any errors or omissions in translation in any event.
</p>

<p>
No warranties
</p>

<p>
Our website and/or mobile applications are provided “as is” without any representations or warranties, express or implied. The Company makes no representations or warranties in relation to our website and/or mobile applications or the information and materials provided on our website and/or mobile applications.
</p>

<p>
Without prejudice to the generality of the foregoing paragraph, Company does not warrant that:
</p>

<p>
our website and/or mobile applications will be constantly available, or available at all; or that our services are fit for a particular purpose, title, non-infringement, and free from computer/mobile device virus or other harmful code; or
the information on our website and/or mobile applications is complete, true, accurate or non-misleading.
Nothing on our website and/or mobile applications constitutes, or is meant to constitute, advice of any kind.
</p>

<p>
The Company also does not warrant that our services will be operational, error free, secure, or safe, or that our services will function without disruptions, delays, or imperfections. The Company does not control, and is not responsible for, controlling how or when our users use our services or the features, services, and interfaces our services provide. The Company is not responsible for and is not obligated to control the actions or information (including content) of our users or other third parties.
</p>

<p>
Payment
</p>
<p>
You agree to pay all fees or charges in accordance with the fees, charges due and payable. You authorize Company to immediately invoice your credit card or any other payment account and/or payment methods provided for any and all fees and charges due and payable and agree that no additional notice or consent is required by Company. Company's fees or charges are net of any applicable taxes and you will be responsible for payment of any and all such taxes and any related penalties applicable if subject to such taxes in any applicable jurisdiction. You agree to indemnify Company for any liability or expense incurred in connection with any such taxes. Company may automatically charge and/or withhold such taxes for services to be delivered within any jurisdictions that it deems is required. Unless otherwise agreed to by Company in writing, all fees paid are non-refundable. Your credit card information and other financial data needed to process your payment are collected and stored by third party payment processors, and we may collect some information. The use of your data by any such third party payment processors is subject to their privacy policies. Company reserves the right, without notice, to modify, change, terminate, or suspend service for a subscription plan you are on if, for any reason, payment for such plan is not made or cannot be processed on the due date.
</p>

<p>
Refunds
</p>
<p>
Unless otherwise provided by law or by a particular Service offer, all purchases are final and non-refundable.
</p>

<p>
If you believe that Company has charged you in error, you must contact us within 14 days of such charge. No credits will be given for any charges more than 14 days old. We reserve the right to issue credits at our sole discretion. If we issue a credit, we are under no obligation to issue the same or similar credit in the future. This refund policy does not affect any statutory rights that may apply.
</p>

<p>
You will be ineligible for a refund of any type; if at any time, you have behaved inappropriately or used inappropriate language.
</p>

<p>
You are responsible for monitoring your financial accounts and statements, and Company is not responsible for any charges actually or allegedly not authorized by any account holder.
</p>

<p>
Cancellation
You may cancel a previously booked appointment at any time.
</p>

<p>
Cancellation within 72 hours of the actual booked appointment will be charged to the full and will be completely non-refundable.
</p>

<p>
Cancellation prior to 72 hours of the actual booked appointment will be credited back in the form of Electronic Coupon (E-Coupon) sent to the registered Email ID of the Primary Account Holder. The E-Coupon so generated will be valid for a single time future use for a consultation with the same price as the initially booked appointment. The E-Coupon generated can also be used for partial payment for a single future higher priced consultation in combination of other regular mode of payment. The E-Coupon so generated may be used only once and can not be transferred to any other users except for the Primary Account Holder.
</p>

<p>
Cancellation within 72 hours of the actual booked appointment for a Second Opinion will be charged to the full and will be completely non-refundable.
</p>

<p>
Cancellation prior to 72 hours of the actual booked appointment for a Second Opinion will be credited back in the form of Electronic Coupon (E-Coupon) sent to the registered Email ID of the Primary Account Holder. The E-Coupon so generated will be valid for a consultation with the same price as the initially booked Second Opinion. The E-Coupon generated can also be used for partial payment for a single future higher priced consultation in combination of other regular mode of payment. The E-Coupon so generated may be used only once and can not be transferred to any other users except for the Primary Account Holder.
</p>

<p>
User responsibility
You agree that the information you provide during your registration or at any other time while using or accessing our service on our mobile application or Site including but not limited to your name, email, password, country and certain other information (collectively “Account Information”) is true, accurate, current and complete.
</p>

<p>
You agree not to transfer or share your account password with anyone or create more than one account for the use of our Services.
</p>

<p>
The company reserves the right to take any and all action, as it deems necessary or reasonable, regarding the security of the Site and your Account Information and to prevent fraud.
</p>

<p>
While using Company Site and/or Mobile Applications, you agree to meet the system requirements, including but not limited to having the required device, operating system, internet connection as well as any and all other requirements needed for us to perform our services.
</p>

<p>
If you are a Patient, you accept full and complete responsibility for yourself in the use of the Services. You acknowledge that your relationship for healthcare services on our Site and/or Mobile Applications is with your Healthcare Provider. You understand as well as fully and completely accept that obtaining services through the Company’s Site and/or Mobile Applications, from the Healthcare Provider is solely at your own risk and you assume full responsibility for all risk associated therewith, to the extent permitted by law. By using the Service, you agree to not hold the Company liable in any way for any malpractice or substandard treatment the Healthcare Provider may render.
</p>

<p>
We do try and confirm the credentials of Healthcare Providers using our Services and do try to validate that they are in good standing with their respective licensure board(s). However, it is the patient’s responsibility to separately confirm that a healthcare Provider is in good standing with his or her respective licensing board(s) at the time of using the Service.
</p>

<p>
If you are a Healthcare Provider, you accept full and complete responsibility for your Patients as well as yourself in the use of this Service. You are also fully and completely responsible for the quality of the services you provide during your use of the Service and for complying with all applicable laws in connection with your use of the Service. You are fully and completely responsible for obtaining any and all Federal, State and local licenses and certifications which may be required to practice your profession when using this Service and maintaining malpractice and liability insurance in compliance with regulatory and local requirements.
</p>

<p>
If you are a Healthcare Provider, you are also responsible for obtaining Patient consent if required by law and complying with any and all privacy laws applicable to the use of this Service with your patients.
</p>

<p>
Company has established reasonable safeguards and procedures to protect the security of patient information, but you must also take steps to protect privacy and confidentiality of your patients.
</p>

<p>
If you are a healthcare Provider, you may register to participate as healthcare experts in the use of this Service. Healthcare Provider whose licenses are or become suspended or revoked, for any reason, are not permitted and agree not to participate in any way in the use of Service including but not limited to submitting content to Company as Medical Expert.
</p>

<p>
Third Party
</p>

<p>
Our Service may contain links to third party websites or services that are not owned or controlled by the Company.
</p>

<p>
Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party websites or services. You further acknowledge and agree that Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such websites or services.
</p>

<p>
Termination
</p>

<p>
We may modify, suspend, or terminate your access to or use of our Services anytime for any reason, such as if you violate the letter or spirit of our Terms and Conditions, or create harm, risk, or possible legal exposure for us, our users, or others. All remaining provisions of these Terms and Conditions, shall remain in full force and effect including but not limited to "No Warranties", "Indemnity", "Liability", "Assignment", "Jurisdiction", "Disclaimer".
</p>

<p>
Indemnity
You agree to defend, indemnify, and hold Company, its officers, directors, employees, agents, licensors, and suppliers, harmless from and against any claims, actions or damages, liabilities and settlements including without limitation, reasonable legal and accounting fees, resulting from, or alleged to result from, your violation of these Terms and Conditions.
</p>

<p>
Liability
</p>
<p>
The use of the Website, Mobile Applications’ Services and Content is at your own risk.
</p>

<p>
When using our Website and/or Mobile Applications, information will be transmitted over a medium that may be beyond the control and jurisdiction of the Company. Company assumes no liability for or relating to the delay, failure, interruption, or corruption of any data or other information transmitted in connection with use of our Website and/or Mobile Applications.
</p>

<p>
The Company Website and/or Mobile Applications, and the content are provided on an "as is" basis. The Company, ITS LICENSORS, AND ITS SUPPLIERS, TO THE FULLEST EXTENT PERMITTED BY LAW, DISCLAIM ALL WARRANTIES, EITHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES RIGHTS, AND FITNESS FOR PARTICULAR PURPOSE. Without limiting the foregoing, Company, its licensors, and its suppliers make no representations or warranties about the following:
</p>

<p>
1. The accuracy, reliability, completeness, correctness, or timeliness of the Content, software, text, graphics, links, or communications provided on or through the use of the Company Site and/or Mobile Applications. 2. The satisfaction of any government regulations requiring disclosure of information on products or services or the approval or compliance of any software tools with regard to the Content contained on the Company Site and/or Mobile Applications.
</p>

<p>
In no event shall Company, its licensors, its suppliers, or any third parties associated with Company be liable for any damages (including, without limitation, incidental and consequential damages, personal injury/wrongful death, lost profits, or damages resulting from lost data or business interruption) resulting from the use of or inability to use the Company Site, Mobile Applications or the Content, whether based on warranty, contract, tort, or any other legal theory, and whether or not Company, its licensors, its suppliers, or any third parties associated with Company are advised of the possibility of such damages. Company, its licensors, its suppliers, or any third parties associated with Company shall be liable only to the extent of actual damages incurred by you, not to exceed U.S. $100 (United States Hundred Dollars). Company, its licensors, its suppliers, or any third parties associated with Company are not liable for any personal injury, including death, caused by your use or misuse of the Site, Mobile Applications, Content, or Public Areas of the Site and/or Mobile Applications. Any claims arising in connection with your use of the Site, Mobile Applications, any Content, or the Public Areas must be brought within one (1) month of the date of the event giving rise to such action occurred. Remedies under these Terms and Conditions are exclusive and are limited to those expressly provided for in these Terms and Conditions.
</p>

<p>
Assignment
</p>
<p>
Semion may transfer, sub-contract or otherwise deal with Semion’s rights and/or obligations under these terms and conditions without notifying you or obtaining your consent.
</p>

<p>
You agree not to transfer, sub-contract or otherwise deal with your rights and/or obligations under these terms and conditions.
</p>

<p>
Jurisdiction
</p>
<p>
This Website and Mobile Application originates from the State of Delaware. This Agreement will be governed by the laws of the State of Delaware without regard to its conflict of law principles to the contrary.
</p>

<p>
You expressly agree that exclusive jurisdiction for any dispute with Company or in any way relating to our website and/or mobile applications, resides in the courts of the State of Delaware and you further agree and expressly consent to the exercise of personal jurisdiction in the courts of the State of Delaware in connection with any such dispute including any claim involving Company or its affiliates, subsidiaries, employees, contractors, officers, directors, telecommunication providers, and content providers.
</p>

<p>
Any cause of action or claim you may have with respect to Company must be commenced within one (1) month after it arises, except to the extent such litigation is not enforceable.

</p>
<p>
Failure to exercise or delay in exercising any right hereunder, or failure to insist upon or enforce strict performance of any provision of this agreement, shall not be considered waiver thereof. No single waiver shall be considered a continuing or permanent waiver.
</p>

<p>
If any provision of these Terms and Conditions is found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms and Conditions, which shall remain in full force and effect. No waiver of any of these Terms and Conditions shall be deemed a further or continuing waiver of such term/s or condition/s or any other term/s or condition/s.
</p>

<p>
Notice and Takedown Procedures
</p>
<p>
If you believe any materials accessible on or from the Site and/or Mobile Application infringe your copyright, you may request removal of those materials (or access thereto) by contacting Company and providing the following information:
</p>

<p>
Identification of the material that you believe to be infringing and its location, and where possible include a copy or the location (e.g., URL) of an authorized version of the work. Please describe any other pertinent information that will allow us to locate the material. Please provide your name, address, telephone number and e-mail address.
A statement that the information that you have supplied is accurate, and indicating that "under penalty of perjury," you are the copyright owner or are authorized to act on the copyright owner's behalf.
</p>
<p>
Complete Agreement
</p>
<p>
Except as expressly provided by Company, these Terms and Conditions, our Privacy Policy and our Disclaimer constitute the entire agreement between you and Company with respect to the use of the Company Website, Content and Mobile Applications.
</p>

<p>
Changes and Revisions
</p>
<p>
We reserve the right, at our sole discretion, to modify or replace these Terms and Conditions at any time. If a revision is material we may try to provide notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
</p>

<p>
Contact Us
</p>
<p>
If you have any questions about these Terms and Conditions, please contact us by filling in details in our Contact Us form available on our website: www.https://semdoc.semion.ai.
</p>

<p>
Credit
</p>
<p>
This document was created using help of a Contractology template available at http://www.contractology.com and http://www.ionlinedoctor.com
</p>

<h1> Privacy Policy</h1>
<p>
Semion Inc. ("Company"); is committed to respecting your privacy.
</p>

<p>
In this policy, the words "you" and "your" mean any person or that person's authorized representative who agrees to our Terms and Conditions and who has requested from us any product and/or service offered by us. The words "we", "us" and "our" means Semion Inc. and its world-wide affiliates, which provide requested products or services. The word "Information" means personal, financial and other details about you that you provide to us and we obtain from others outside Semion Inc., including through the products and/or services you use.
</p>

<p>
This Privacy Policy is applicable only to our website www.https://semdoc.semion.ai and our mobile applications "semdoc" available on App store for IOS Devices and on Play Store for Android Devices, and not to any other website/s or mobile application/s that you may be able to access from our website, each of which may have practices and policies that may differ materially from this Privacy Policy. Your use of our website and/or mobile applications as well as our products and/or services is governed by this Privacy Policy and to our Terms and Conditions.
</p>

<p>
You acknowledge, authorize and agree as follows:
</p>

<p>
Collection and Usage:
</p>

<p>
We may collect Information from you including:
</p>

<p>
Personal identification details about you and your background, including your name, address, contact information, date of birth, and other identification, medical history, medical information
records of your interactions, transactions and/or dealings with us over the phone, on your mobile device or through email or the Internet
details about your browsers activities, your browser or mobile device
determine you location through use of various technologies
cookies that may uniquely identify your browser or your User Account
your preferences and activities.
You authorize the collection of Information to us as required in order to serve you as our customer and to administer our business, including but not limited to:
</p>

<p>
verify your identity
</p>

<p>
evaluate and process your accounts, transactions and reports
provide you with ongoing service and information related to the products, accounts and services with us
analyze your needs and activities to help us serve you better and develop new products and services
help protect us against fraud and error
help manage and assess our risks, operations and relationship with you
organizations with whom you make arrangements, other service providers or agents including payment card networks
conduct statistical research on individual or aggregate health or medical trends
comply with applicable laws and requirements of regulators, including self-regulatory organizations.
We may collect, use, preserve, and share your information if we have a good-faith belief that it is reasonably necessary to: (a) respond pursuant to applicable law or regulations, to legal process, or to government requests; (b) enforce our Terms and Conditions and any other applicable terms and policies, including for investigations of potential violations; (c) detect, investigate, prevent, and address fraud and other illegal activity, security, or technical issues; or (d) protect the rights, property, and safety of our users, or others.
</p>

<p>
Information Disclosure
</p>

<p>
We may disclose Information, including as follows:
</p>

<p>
in response to a court order, search warrant or other demand or request, which we believe to be valid
to meet requests for information from regulators, including self-regulatory organizations of which we may be a member or participant, or to satisfy legal and regulatory requirements applicable to us
to payment card networks in order to operate or administer the payment card system that supports the products, services or accounts you have with us (including for any products or services provided or made available by the payment card network as part of your product, services or accounts with us), or for any contests or other promotions they may make available to you
when we buy a business or sell all or part of our business or when considering those transactions
to help us collect a debt or enforce an obligation owed to us by you
where permitted by law
</p>

<p>
Information Sharing
</p>

<p>
Within Semion Inc. we may share Information world-wide for the following purposes:
</p>

<p>
to manage your total relationship within Semion Inc., including servicing your accounts and maintaining consistent Information about you
to manage and assess our risks and operations, including to collect a debt owed to us by you
to comply with legal or regulatory requirements.
You may not withdraw your consent for these purposes
</p>

<p>
Within Semion Inc., we may also share Information world-wide to allow other businesses within Semion Inc. to tell you about products and services.
</p>

<p>
Your medical history and/or medical information may be shared within our employees, Health Care Providers, Contractors and Agents who need to know that information in order to enable them to provide effective services and/or products.
</p>

<p>
Marketing Purposes
</p>

<p>
We may also use your Information for marketing purposes, including to:
</p>

<p>
tell you about other products and services that may be of interest to you, including those offered by other businesses within Semion Inc. and third parties we select
determine your eligibility to participate in contests, surveys or promotions
conduct research, analysis, and surveys to assess your satisfaction with us as a customer, and to develop products and services
contact you by telephone, fax, text messaging, or other electronic means like internet, mail, email and other methods.
</p>

<p>
With respect to these marketing purposes, you may choose not to have us:
</p>

<p>
contact you occasionally either by telephone, fax, text message, internet, mail, email, or all of these methods, with offers that may be of interest to you
contact you to participate in customer research and surveys.
Information Storage
When speaking with one of our telephone service representatives, internet live chat agents, and/or Video Conferencing with our Health Care Providers, or messaging with us through social media, we may monitor and/or record our discussions for our mutual protection, to enhance customer service and to confirm our discussions with you.
</p>

<p>
We will not be responsible for the information loss or privacy loss due to the incorrect information provided by you.
</p>

<p>
We make efforts in good faith to receive, transmit and store Personal Information in a secure environment but we cannot guarantee that there will be no unintended disclosures of your Personal Information
</p>

<p>
Security
</p>

<p>
At Semion Inc, we work hard to protect us and our users, from unauthorized access to or unauthorized alteration, disclosure or destruction of information we hold. In particular:
</p>

<p>
We may encrypt some of our services.
</p>

<p>
We review our information collection, storage and processing practices, to guard against unauthorized access to systems.
We restrict access to personal information to our employees, contractors and agents who need to know that information in order to process it for us, and who are subject to strict confidentiality obligations and may be disciplined or terminated if they fail to meet these obligations.
We regularly review our compliance with our Privacy Policy. When we receive formal written complaints, we will contact the person who made the complaint to follow up. We will make reasonable efforts to notify you of the nature and extent of disclosure as soon as reasonably possible and as permitted by law in case of security breach.
</p>

<p>
Any information that you may reveal in a review posting or online discussion or forum is public.
</p>

<p>
Fraud
</p>

<p>
In order to prevent, detect or suppress financial abuse, fraud, criminal activity, protect our assets and interests, assist us with any internal or external investigation into potentially illegal or suspicious activity or manage, defend or settle any actual or potential loss in connection with the foregoing, we may collect from, use and disclose your Information to any person or organization, fraud prevention agency, regulatory or government body, the operator of any database or registry used to check information provided against existing information, or other insurance companies or financial or lending institutions. For these purposes, your Information may be pooled with data belonging to other individuals and subject to data analytics.
</p>

<p>
Revisions / Editions / Changes / Amendments
</p>

<p>
You acknowledge that our Privacy Policy may be updated from time to time. We will not reduce your rights under this Privacy Policy without your explicit consent. We will post any privacy policy changes on our website and mobile applications and, if the changes are significant, we may provide a more prominent notice (including, for certain services, email notification of privacy policy changes). You acknowledge, authorize and agree to be bound by any such revisions, editions, changes or amendments.
</p>

<p>
Please do not use our site and/or our mobile applications if you disagree to the terms and conditions of our Privacy Policy.
</p>
</body>
	</div>);
 }
}
export default withRouter(Policy);

