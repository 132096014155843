import fire from "../../firebase/fire";
import firebase from "firebase";
import { getFromStorage } from "../../utils/storage";
import { toast } from "react-toastify";
import { addNotificationAction } from "../../actions/notificationAction";
import axios from 'axios'
import Api from '../../config/api.json'

const notifyError = (err) => {
  toast.error(err);
};

export const getNotifications = (dispatch, notifications, func) => {
  if (notifications.length === 0) {
    fire
      .database()
      .ref(
        `${getFromStorage("patient") ? "patients" : "doctors"}/${
          getFromStorage("patient")
            ? getFromStorage("patient")
            : getFromStorage("doctor")
        }`
      )
      .once("value")
      .then((snapshot) => {
        if (snapshot.val().notifications) {
          snapshot.val().notifications.map((notification, i) => {
            fire
              .database()
              .ref(`notifications/${notification}`)
              .once("value")
              .then((res) => {
                if (res.val()) {
                  dispatch(addNotificationAction(notification, res.val()));
                  if (func && i === snapshot.val().notifications.length - 1) {
                    func();
                  }
                } else {
                  if (func) {
                    func();
                  }
                }
              })
              .catch((err) => {
                if (func) {
                  func();
                }
              });
          });
        } else {
          if (func) {
            func();
          }
        }
      })
      .catch((err) => {
        if (func) {
          func();
        }
      });
  } else {
    if (func) {
      func();
    }
  }
};

const saveToken = async (token, id, type) => {
    try {
      let url = ""
      let body = {}
      if(type === "patients") {
        body = {task:"setDeviceToken", patient_id: id, device_token: token }
        url = Api.PatientApi
      } else {
        body = {task:"setDeviceToken", doctor_id: id, device_token: token }
        url = Api.DoctorApi
      }
      console.log(body)
      const res = await axios.post(url, body);
      console.log(res.data)
    } catch(err) {
        alert(err.toString())
    }
  // fire
  //   .database()
  //   .ref(`${type}/${id}`)
  //   .once("value")
  //   .then((snapshot) => {
  //     if (snapshot.val()) {
  //       let arr = snapshot.val().tokens ? snapshot.val().tokens : [];

  //       const isFound = arr.find((res) => res === token);
  //       if (!isFound) {
  //         arr.push(token);
  //         fire
  //           .database()
  //           .ref(`${type}/${id}`)
  //           .set({
  //             tokens: arr,
  //             notifications: snapshot.val().notifications
  //               ? snapshot.val().notifications
  //               : [],
  //           });
  //       }
  //     } else {
  //       fire
  //         .database()
  //         .ref(`${type}/${id}`)
  //         .set({ tokens: [token], notifications: [] });
  //     }
  //   })
  //   .catch((err) => console.log(err.toString()));
};

export const getToken = async (type) => {

  // if (firebase.messaging.isSupported()) {
    const messaging = fire.messaging();
    // const token = await messaging.getToken();

    messaging
      .requestPermission()
      .then(async function () {
        const token = await messaging.getToken();
        console.log(token)
        if (type === "patients") {
          saveToken(token, getFromStorage("patient"), type);
        } else {
          saveToken(token, getFromStorage("doctor"), type);
        }
      })
      .catch(function (err) {
        console.log("Unable to get permission to notify.", err);
      });
  // }
};
