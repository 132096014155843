// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

const awsPath = '/Prod';
export const rootPath = '/';

const routes = {
  HOME: `/`,
  DEVICE: `/devices/:meeting_id/:user_id`,
  MEETING: `/meeting`,
};

export default routes;
