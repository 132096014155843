import React, {Component} from "react";
import { Card, Table, Button, Row, Col } from "react-bootstrap";
import key from "../../../config/key.json";
import { toast } from "react-toastify";
import { getFromStorage } from "../../../utils/storage";
import errorConfig from "../../../config/errorConfig.json";
import { withRouter } from "react-router";
import api from "../../../config/api.json";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import { getToken, getNotifications } from "../saveToken";
import { connect } from "react-redux";
import QRCode from "qrcode.react";

const color = "#229a88";

class OrderDetail extends Component {
    state = {
        data: [],
        isLoading: true,
        sum: 0,
        institute: {discount_rate: 0},
        otherData: null
    }
    //Error Notification
    notifyError = error => {
        toast.error("" + error.toString());
    };

    //Success Notification
    notifySuccess = success => {
        toast.success("" + success.toString());
    };

    //getting order tests list
    getOrderedTestsList = async () => {
        try {
            const body = {
                task: "getOrderedTests",
                invoice_no: this.props.match.params.invoice_no
            }
            const res = await axios.post(api.ORDER_TESTS_API, body)
            if(!res.data.errorMessage) {
                this.setState({data: JSON.parse(res.data[res.data.length - 1].radiology_tests), sum: JSON.parse(res.data[res.data.length - 1].radiology_tests).map(item => item.rate).reduce((a, b) => a + b, 0), institute: JSON.parse(res.data[res.data.length - 1].institute), otherData: res.data[0]})
            } else {
                this.notifyError(res.data.errorMessage)
            }
            this.setState({isLoading: false})
            console.log(res.data)
        } catch(err) {
            this.setState({isLoading: false})
            this.notifyError(err.toString())
        }
    }

    //First function run when page load
    componentDidMount() {
        toast.dismiss();
        console.log(this.props.location)
        if (!getFromStorage(key.loginInfoKey)) {
            this.notifyError(errorConfig.notLoggedInError);
            this.props.history.push("/");
        } else if (getFromStorage(key.loginInfoKey).task === "authAdmin" || getFromStorage(key.loginInfoKey).task === "authDiagAdminPanel") {
            this.props.history.push("/Graph")
        } else if (getFromStorage(key.loginInfoKey).task === "patient") {
            getNotifications(this.props.dispatch, this.props.notifications)
            getToken("patients");
            this.getOrderedTestsList()
        } else if (getFromStorage(key.loginInfoKey).task === "doctor") {
            this.props.history.push("/");
        } else if (getFromStorage(key.loginInfoKey).task === "provider") {
            this.props.history.push("/provider_doctors")
        }
    }

    render() {
        if(this.state.isLoading) {
            return (
                <div style={{marginLeft: this.props.isOpened ? (window.innerWidth > 560 ? "160px" : "20px") : "20px", marginTop: "70px", textAlign: "center"}}>
                    <CircularProgress />
                </div>
            )
        }
        return (
            <div style={{marginLeft: this.props.isOpened ? (window.innerWidth > 560 ? "160px" : "20px") : "20px", marginTop: "70px", marginRight: "20px"}}>
                <Card style={{padding: "20px", fontSize: "14px"}} className="shadow-4">
                    <h4 style={{ color, textAlign: "center", fontWeight: "bold", fontSize: 22 }}>Order Detail</h4>
                    <div style={{overflow: "auto"}}>
                        <div style={{paddingLeft: "20px", paddingRight: "20px"}}>
                            <div>
                                {this.props.match.params && <Row><Col md="3" style={{fontWeight: "bold"}}>Invoice Number DB:</Col><Col md="5">{this.props.match.params.invoice_no}</Col></Row>}
                                {this.state.otherData && <Row><Col md="3" style={{fontWeight: "bold"}}>Order Time:</Col><Col md="5">{this.state.otherData.order_time}</Col></Row>}
                                {this.state.institute && this.state.institute.institute && <Row><Col md="3" style={{fontWeight: "bold"}}>Institute:</Col><Col md="5">{this.state.institute.institute}</Col></Row>}
                                {this.state.institute && this.state.institute.address && <Row><Col md="3" style={{fontWeight: "bold"}}>Address:</Col><Col md="5">{this.state.institute.address}</Col></Row>}
                                {this.state.institute && this.state.institute.phone && <Row><Col md="3" style={{fontWeight: "bold"}}>Phone:</Col><Col md="5">{this.state.institute.phone}</Col></Row>}
                                {this.state.otherData && <Row><Col md="3" style={{fontWeight: "bold"}}>Status:</Col><Col md="5">{this.state.otherData.status === 1 ? "Completed" : "Pending"}</Col></Row>}
                            </div>
                            <br/>
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr style={{color, fontWeight: "bold"}}>
                                        <th>Name</th>
                                        <th>Rate</th>
                                    </tr>
                                </thead>
                                {!this.state.isLoading && 
                                <tbody>
                                    {
                                        this.state.data.map(item =>
                                            <tr key={item.id}>
                                                <td>{item.name}</td>
                                                <td>{item.rate}</td>
                                            </tr>
                                        )
                                    }
                                    <tr>
                                        <td style={{fontWeight: "bold"}}>SubTotal:</td>
                                        <td style={{fontWeight: "bold"}}>{this.state.sum}</td>
                                    </tr>
                                    <tr>
                                        <td style={{fontWeight: "bold"}}>Discount:</td>
                                        <td style={{fontWeight: "bold"}}>{this.state.institute.discount_rate}%</td>
                                    </tr>
                                    <tr>
                                        <td style={{fontWeight: "bold"}}>Total:</td>
                                        <td style={{fontWeight: "bold"}}>{this.state.sum * ((100 - this.state.institute.discount_rate)/100)}</td>
                                    </tr>
                                </tbody>
                                }
                            </Table>
                            {this.state.isLoading && <div style={{textAlign: "center"}}>Searching...</div>}
                            {this.state.data.length === 0 && <div style={{textAlign: "center", fontSize: "14px"}}>Data Not Found</div>}
                            <QRCode
                                value={`Invoice Number DB: ${this.props.match.params.invoice_no}, Patient ID: ${getFromStorage("patient")}, Order Time: ${this.props.match.params.order_time}`}
                                style={{ width: "200px", height: "200px" }}
                            />
                        </div>
                    </div>
                </Card>
                <br/>
            </div>
        )
    }
}

export default connect(state => ({
    notifications: state.notifications
}))(withRouter(OrderDetail));
