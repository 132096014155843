import React, {Component} from "react";
import { Card, Table, Button, Row, Col, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import key from "../../../config/key.json";
import { toast } from "react-toastify";
import { getFromStorage } from "../../../utils/storage";
import errorConfig from "../../../config/errorConfig.json";
import { withRouter } from "react-router";
import api from "../../../config/api.json";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import { Markup } from 'interweave';

const color = "#229a88";
const designations = ["Professor", "Associate Professor", "Assistant Professor", "Senior Consultant", "Junior Consultant", "Consultant", "Medical Officer"]
const specailities = [
    "Andrologist & Lap Surgeon",
    "Cardiology",
    "Cardio-Vascular Surgery",
    "Chest Diseases",
    "COVID-19(Coronavirus)",
    "Dentist",
    "Dermatology",
    "Diabetes & Hormone",
    "Dietician",
    "ENT",
    "EYE",
    "Gastroenterology",
    "General Physician",
    "General Surgery",
    "Gynae & Gynaeoncology",
    "Gynaecology & Infertility",
    "Haematology",
    "Medicine",
    "Nephrology (Nephrologist & Medical Specialist)",
    "Neurologist",
    "Neurosurgeon & Spine Surgeon",
    "Nutrition (Overweight, child nutrition & diet therapy)",
    "Nutrition Consultant",
    "Onclogy (Cancer)",
    "Orthopaedic",
    "Paediatrician & Paediatric cardiology",
    "Pain Medicine",
    "Pediatrics & Neonate (Child)",
    "Physical Medicine",
    "Physiotherapy",
    "Plastic Surgery",
    "Psychiatrist",
    "Respiratory Medicine",
    "Skin & VD",
    "Urologist",
    "Vascular Surgery"
];

class RescheduleByDoctor extends Component {
    state = {
        times: ["00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00"],
        appointments: [],
        doctors: [],
        isLoading: true,
        day: "",
        startTime: 0,
        endTime: 0,
        tableData: [],
        tempTableData: [],
        isSubmitting: false,
        designation: "",
        speciality: "",
        isSearching: false,
        minDate: new Date(),
        maxDate: new Date(),
        selectDate: "",
        dates: [],
        time: "",
        dayAppointment: "",
        arrays: [],
        doc_id: "",
        doc_name: "",
        doctorCalendar:[],
        appointment_time: "",
        appointment_date: "",
        show: false,
        isConfirm: false,
        status: "",
        cost: "0",
        message: "",
        isPaying: false
    }
    //Error Notification
    notifyError = error => {
        toast.error("" + error.toString());
    };

    //Success Notification
    notifySuccess = success => {
        toast.success("" + success.toString());
    };

    whenChangeHandler = (e) => {
        const {name, value} = e.target
        this.setState({[name] : value})
    }

    //fetch appointments
    fetchDoctorList = () => {
        const body = {
            task: 'getDocList',
            speciality: this.state.speciality,
            designation: this.state.designation
        }
        const headers = { headers: { "Content-Type" : "application/json" } }
        axios.post(api.PatientApi, body, headers)
        .then(res => {
            if(res.data){
                this.setState({doctors: res.data})
            } else {
                this.notifyError("Not Found")
            }
            this.setState({isLoading: false, isSearching: false})
        }
        )
        .catch(err => {
            this.notifyError(err.toString())
            this.setState({isLoading: false, isSearching: false})
        })
    }

    dateTimeChecker = item => {
        if (item.startTime < this.state.endTime && item.endTime > this.state.startTime) {
          return true;
        }
        return false;
    };

    addTime = () => {
        toast.dismiss()
        if(this.state.startTime !== "" && this.state.endTime !== "" && this.state.day !== "") {
            if(this.state.startTime === 0) {
                this.notifyError("Start Time can not be 12:00 AM")
            } else if(this.state.startTime === this.state.endTime) {
                this.notifyError("Start Time and End Time can not be same")
            } else if(this.state.startTime > this.state.endTime){
                this.notifyError("Start Time should be before End Time")
            } else {
                let arr = this.state.tableData;
                let arr2 = this.state.tempTableData
            
                if(arr.find(item => item.day === this.state.day)) {
                    if(!arr.find(item => this.dateTimeChecker(item))) {
                        arr.push({
                            id: new Date().getTime(),
                            day: this.state.day,
                            startTime: this.state.startTime,
                            endTime: this.state.endTime
                        })
                        arr2.push({
                            id: new Date().getTime(),
                            day: this.state.day,
                            startTime: moment.utc(moment.duration(this.state.startTime, "seconds").asMilliseconds()).format("hh:mm A"),
                            endTime: moment.utc(moment.duration(this.state.endTime, "seconds").asMilliseconds()).format("hh:mm A")
                        })
        
                        this.setState({ tableData: arr, tempTableData: arr2 })
                    } else {
                        this.notifyError("This timeslot already added")
                    }
                } else {
                    arr.push({
                        id: new Date().getTime(),
                        day: this.state.day,
                        startTime: this.state.startTime,
                        endTime: this.state.endTime
                    })
                    arr2.push({
                        id: new Date().getTime(),
                        day: this.state.day,
                        startTime: moment.utc(moment.duration(this.state.startTime, "seconds").asMilliseconds()).format("hh:mm A"),
                        endTime: moment.utc(moment.duration(this.state.endTime, "seconds").asMilliseconds()).format("hh:mm A")
                    })
                    this.setState({ tableData: arr, tempTableData: arr2})
                }
            }
        } else {
            this.notifyError("All Fields Required")
        }
    }

    nextWeek = () => {
        var dates = [];
        var start = moment(this.state.minDate).add(1, 'week');
        var end = moment(this.state.maxDate).add(1, 'week');

        this.setState({arrays: [], minDate: new Date(start), maxDate: new Date(end),  selectDate: `${start.format("DD/MM/YYYY")} - ${end.format("DD/MM/YYYY")}` }, () => {
            this.getDoctorCalendar()
        })
        dates.push(start.format('YYYY-MM-DD'));          
        while(!start.isSame(end.format('YYYY-MM-DD'))){
            start = start.add(1, 'days');
            dates.push(start.format('YYYY-MM-DD'));
        }
        this.setState({ dates: dates.map(item => new Date(new Date(item).setHours(0,0,0,0)) >= new Date(new Date().setHours(0,0,0,0)) ? item : null), time: "", dayAppointment: "", appointment_date: "", appointment_time: ""})
    }

    setDates = () => {
        var dates = [];
        var startDate = moment().startOf('week')
        var endDate = moment().endOf('week')
        
        this.setState({ 
            arrays: [], 
            minDate: new Date(startDate), 
            maxDate: new Date(endDate), 
            selectDate: `${startDate.format("DD/MM/YYYY")} - ${endDate.format("DD/MM/YYYY")}` 
        }, () => {
            this.getDoctorCalendar()
        })

        dates.push(startDate.format('YYYY-MM-DD'));          
        while(!startDate.isSame(endDate.format('YYYY-MM-DD'))){
            startDate = startDate.add(1, 'days');
            dates.push(startDate.format('YYYY-MM-DD'));
        }
        this.setState({ dates: dates.map(item =>new Date(new Date(item).setHours(0,0,0,0)) >= new Date(new Date().setHours(0,0,0,0)) ? item : null), time: "", dayAppointment: "", appointment_date: "", appointment_time: "" })
    }

    previousWeek = () => {
        var dates = [];
        var start = moment(this.state.minDate).subtract(1, 'week');
        var end = moment(this.state.maxDate).subtract(1, 'week');

        this.setState({arrays: [], minDate: new Date(start), maxDate: new Date(end),  selectDate: `${start.format("DD/MM/YYYY")} - ${end.format("DD/MM/YYYY")}` }, () => {
            this.getDoctorCalendar();
        })
        dates.push(start.format('YYYY-MM-DD'));          
        while(!start.isSame(end.format('YYYY-MM-DD'))){
            start = start.add(1, 'days');
            dates.push(start.format('YYYY-MM-DD'));
        }
        this.setState({ dates:  dates.map(item =>new Date(new Date(item).setHours(0,0,0,0)) >= new Date(new Date().setHours(0,0,0,0)) ? item : null), time: "", dayAppointment: "", appointment_date: "", appointment_time: ""})
    }
    

    getDoctorCalendar = () => {
        toast.dismiss()
        const headers = {
            headers: {"Content-Type": "application/json"}
        }
        
        const body = {
            task: "getDocCalendar",
            doctor_id: this.state.doc_id,
            start_date: moment(this.state.minDate).format("YYYY-MM-DD 12:00:00"),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }

        axios 
        .post(api.PatientApi, body, headers)
        .then(res => {
            if(res.data[0]){
                this.setState({doctorCalendar: res.data}, () => {

                    let arr1 = [], arr2 = [], arr3 = [], arr4 = [], arr5 = [], arr6 = [], arr7 = [];
                    res.data[0][0].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr1.push(this.state.times[i] + `${" AM"}`) 
                    })
                    res.data[0][1].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr1.push(this.state.times[i] + `${" PM"}`) 
                    })
                    res.data[1][0].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr2.push(this.state.times[i] + `${" AM"}`) 
                    })
                    res.data[1][1].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr2.push(this.state.times[i] + `${" PM"}`) 
                    })
                    
                    res.data[2][0].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr3.push(this.state.times[i] + `${" AM"}`) 
                    })
                    res.data[2][1].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr3.push(this.state.times[i] + `${" PM"}`) 
                    })
                    res.data[3][0].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr4.push(this.state.times[i] + `${" AM"}`) 
                    })
                    res.data[3][1].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr4.push(this.state.times[i] + `${" PM"}`) 
                    })

                    res.data[4][0].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr5.push(this.state.times[i] + `${" AM"}`) 
                    })
                    res.data[4][1].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr5.push(this.state.times[i] + `${" PM"}`) 
                    })
                    res.data[5][0].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr6.push(this.state.times[i] + `${" AM"}`) 
                    })
                    res.data[5][1].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr6.push(this.state.times[i] + `${" PM"}`) 
                    })

                    res.data[6][0].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr7.push(this.state.times[i] + `${" AM"}`) 
                    })
                    res.data[6][1].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr7.push(this.state.times[i] + `${" PM"}`) 
                    })
                    this.setState({arrays: [
                        arr1,
                        arr2,
                        arr3,
                        arr4,
                        arr5,
                        arr6,
                        arr7
                    ]})
                    this.setState({ isLoading: false})
                })
            } else {
                this.setState({isLoading: false})
                this.notifyError("Something went wrong")
            }
        })
        .catch(err => {
            this.setState({isLoading: false})
            this.notifyError(err.toString())
        })
    }

    setAppointment = () => {
        this.setState({ isSubmitting: true}, () => {
            const headers = {
                headers: {"Content-Type": "application/json"}
            }
        
            const body = {
                task: "rescheduleAppointment",
                doctor_id: this.state.doc_id,
                appointment_id: this.props.location.pathname.split("/")[this.props.location.pathname.split("/").length - 2],
                patient_id: this.props.location.pathname.split("/")[this.props.location.pathname.split("/").length - 1],
                time_slot: moment(this.state.appointment_date.split("$")[0]).format("YYYYMMDD") + moment(this.state.appointment_time, ["h:mm A"]).format("HHmm") + "00"
            }
        
            axios 
            .post(api.ProviderApi, body, headers)
            .then(res => {
                console.log(res.data)
                if(res.data === 200){
                    this.notifySuccess("Appointment set successfully")
                    this.setState({ appointment_date: "", appointment_time: "", doc_id: "", doc_name: "" })
                } else {
                    this.notifyError("Something went wrong")
                }
                this.setState({isSubmitting: false})
            })
            .catch(err => {
                this.setState({isSubmitting: false})
                this.notifyError(err.toString())
            })
        })
    }

    //First function run when page load
    componentDidMount() {
        toast.dismiss();
        
        if (!getFromStorage(key.loginInfoKey)) {
            this.notifyError(errorConfig.notLoggedInError);
            this.props.history.push("/");
        } else if (getFromStorage(key.loginInfoKey).task === "authAdmin" || getFromStorage(key.loginInfoKey).task === "authDiagAdminPanel") {
            this.props.history.push("/Graph")
        } else if (getFromStorage(key.loginInfoKey).task === "patient") {
            this.props.history.push("/home")
        } else if (getFromStorage(key.loginInfoKey).task === "doctor") {
            this.props.history.push("/home")
        } else if (getFromStorage(key.loginInfoKey).task === "provider") {
            this.fetchDoctorList()
        }
    }

    render() {
        if(this.state.isLoading) {
            return (
                <div style={{marginLeft: this.props.isOpened ? (window.innerWidth > 560 ? "160px" : "20px") : "20px", marginTop: "70px", textAlign: "center"}}>
                    <CircularProgress />
                </div>
            )
        }
        return (
            <div style={{marginLeft: this.props.isOpened ? (window.innerWidth > 560 ? "180px" : "20px") : "20px", marginTop: "70px", marginRight: "20px"}}>
                <Card style={{padding: "20px", fontSize: "14px"}} className="shadow-4">
                {getFromStorage(key.loginInfoKey).task === "provider" && 
                    <div style={{paddingLeft: "20px", paddingRight: "20px"}}>
                        {this.state.doc_id !== "" &&
                        <div>
                            <h4 style={{ color, textAlign: "center", fontWeight: "bold", fontSize: 22 }}>Set Appointment</h4>
                            <br/>
                            <form>
                                <div style={{fontWeight: "bold", marginBottom: "5px"}}>
                                    <Row>
                                    <Col sm="3">
                                    Doctor ID: {this.state.doc_id}
                                    </Col>
                                    <Col sm="5">
                                    Doctor Name: {this.state.doc_name}
                                    </Col>
                                    </Row>
                                </div>
                                <Row>
                                    <Col sm="3">
                                        <FormGroup controlId="weekly">
                                            <FormLabel style={{fontWeight: "bold"}}>Weekly<span style={{color: "red"}}>*</span></FormLabel>
                                            <FormControl 
                                                type="text" 
                                                size="sm" 
                                                name="selectDate" 
                                                disabled={false}
                                                value={this.state.selectDate} 
                                                onChange={() => {}}
                                            />
                                            <div style={{marginTop: "5px", textAlign: "center"}}>
                                                <Button variant="outline-danger" size="sm" onClick={this.previousWeek}>
                                                    <ArrowLeft fontSize="small"/>
                                                </Button>
                                                <Button variant="outline-danger" size="sm" style={{marginLeft: "5px"}} onClick={this.nextWeek}>
                                                    <ArrowRight fontSize="small"/>
                                                </Button>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3">
                                        <FormGroup controlId="startTime">
                                            <FormLabel style={{fontWeight: "bold"}}>Date<span style={{color: "red"}}>*</span></FormLabel>
                                            <FormControl 
                                                size="sm"
                                                as="select"
                                                name="appointment_date" 
                                                value={this.state.appointment_date} 
                                                onChange={(e) => { this.setState({appointment_date: e.target.value, appointment_time: ""}) }}
                                            >
                                                <option value="" style={{color: "silver"}}>Select Date</option>
                                                {this.state.dates.map((item, i) =>
                                                item && <option value={item + "$" + i.toString()} key={item}>{item}</option>)}
                                            </FormControl>
                                        </FormGroup>
                                    </Col>
                                    {this.state.arrays.length > 0 &&
                                    <Col sm="3">
                                        <FormGroup controlId="time">
                                            <FormLabel style={{fontWeight: "bold"}}>Time<span style={{color: "red"}}>*</span></FormLabel>
                                            <FormControl
                                                as="select" 
                                                size="sm"
                                                name="time" 
                                                value={this.state.appointment_time} 
                                                onChange={(e) => this.setState({appointment_time: e.target.value})}
                                            >
                                                <option value="" style={{color: "silver"}}>Select Time</option>
                                                {
                                                    this.state.appointment_date.split("$")[1] === "0" ? 
                                                    this.state.arrays[0].map(item => <option key={item} value={item}>{item}</option>) : 
                                                    this.state.appointment_date.split("$")[1] === "1" ? 
                                                    this.state.arrays[1].map(item => <option key={item} value={item}>{item}</option>) : 
                                                    this.state.appointment_date.split("$")[1] === "2" ? 
                                                    this.state.arrays[2].map(item => <option key={item} value={item}>{item}</option>) : 
                                                    this.state.appointment_date.split("$")[1] === "3" ? 
                                                    this.state.arrays[3].map(item => <option key={item} value={item}>{item}</option>) :
                                                    this.state.appointment_date.split("$")[1] === "4" ? 
                                                    this.state.arrays[4].map(item => <option key={item} value={item}>{item}</option>) :  
                                                    this.state.appointment_date.split("$")[1] === "5" ? 
                                                    this.state.arrays[5].map(item => <option key={item} value={item}>{item}</option>) : 
                                                    this.state.appointment_date.split("$")[1] === "6" && 
                                                    this.state.arrays[6].map(item => <option key={item} value={item}>{item}</option>) 
                                                }
                                            </FormControl> 
                                        </FormGroup>
                                    </Col>}
                                    <Col sm="3">
                                        <Button size="sm" variant="success" disabled={this.state.isSubmitting} style={{marginTop: window.innerWidth > 560 ? "27px" : "0px", width: "100px"}} onClick={() => { if(this.state.appointment_date !== "" && this.state.appointment_time !== "") { 
                                            this.setAppointment() } else { this.notifyError("All Fields Required"); 
                                        }}}>
                                            <span style={{marginLeft: "5px"}}>{!this.state.isSubmitting ? "Confirm" : "Loading..."}</span>
                                        </Button>      
                                    </Col>
                                </Row>
                                <br/>
                            </form>
                        </div>}
                        <h4 style={{ color, textAlign: "center", fontWeight: "bold", fontSize: 22 }}>Doctors</h4>
                        <br/>
                    </div>
                }
                    <div style={{overflow: "auto"}}>
                    {getFromStorage(key.loginInfoKey).task === "provider" &&
                        <div style={{paddingLeft: "20px", paddingRight: "20px"}}>
                            <Row>
                                <Col>
                                    <FormGroup controlId="speciality">
                                        <FormLabel style={{color, fontWeight: "bold"}}>Search By Speciality</FormLabel>
                                        <FormControl size="sm" as="select" name="speciality" value={this.state.speciality} onChange={(e) => {this.setState({speciality: e.target.value, isSearching:true}, () => { this.fetchDoctorList() })}}>
                                            <option value="">All</option>
                                            {specailities.map(item => <option value={item} key={item}>{item}</option>)}
                                        </FormControl>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup controlId="designation">
                                        <FormLabel style={{color, fontWeight: "bold"}}>Search By Designation</FormLabel>
                                        <FormControl as="select" size="sm" name="designation" value={this.state.designation} onChange={(e) => {this.setState({designation: e.target.value, isSearching:true}, () => { this.fetchDoctorList() })}}>
                                            <option value="">All</option>
                                            {designations.map(item => <option value={item} key={item}>{item}</option>)}
                                        </FormControl>
                                    </FormGroup>
                                </Col>
                            </Row>
                                <Table striped bordered hover size="sm">
                                    <thead>
                                        <tr style={{color, fontWeight: "bold"}}>
                                            <th>Id</th>
                                            <th>Name</th>
                                            <th>Designation</th>
                                            <th>Speciality</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    {!this.state.isSearching && 
                                    <tbody>
                                        {
                                            this.state.doctors.map(item =>
                                                <tr key={item.id}>
                                                    <td>{item.id}</td>
                                                    <td>{item.name}</td>
                                                    <td><Markup content={item.designation} /></td>
                                                    <td><Markup content={item.speciality} /></td>
                                                    <td align="center" style={{width: "200px"}}>
                                                        <Button 
                                                            size="sm" 
                                                            variant="primary" 
                                                            onClick={() => {
                                                                this.setState({doc_id: item.id, doc_name: item.name}, () => {
                                                                    this.setDates()
                                                                    window.scrollTo(0,0);
                                                                })
                                                            }}
                                                        >
                                                            <span style={{marginLeft: "5px"}}>Appointment</span>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                    }
                                </Table>
                                {this.state.isSearching && <div style={{textAlign: "center"}}>Searching...</div>}
                                {this.state.doctors.length === 0 && <div style={{textAlign: "center", fontSize: "14px"}}>Data Not Found</div>}
                        </div>
                        }
                    </div>
                </Card>
                <br/>
            </div>
        )
    }
}

export default withRouter(RescheduleByDoctor);
