import React, { useState, useEffect } from "react";
import { Button, FormGroup, FormControl, FormLabel, Alert, Row, Col } from "react-bootstrap";
import { NavLink, withRouter } from "react-router-dom";
import validator from "validator"
import { getFromStorage } from "../../../utils/storage";
import axios from 'axios'
import api from '../../../config/api.json'
import { toast } from "react-toastify";

const color = "#229a88";

function DoctorProfile(props) {
  const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [retypePassword, setRetypePassword] = useState("");
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [number, setNumber] = useState("");
  const [address, setAddress] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [designation, setDesignation] = useState("");
  const [reg_number, setRegistration] = useState("");
  const designations = ["Professor", "Associate Professor", "Assistant Professor", "Senior Consultant", "Junior Consultant", "Consultant", "Medical Officer"]
  const specailities = [
      "Andrologist & Lap Surgeon",
      "Cardiology",
      "Cardio-Vascular Surgery",
      "Chest Diseases",
      "COVID-19(Coronavirus)",
      "Dentist",
      "Dermatology",
      "Diabetes & Hormone",
      "Dietician",
      "ENT",
      "EYE",
      "Gastroenterology",
      "General Surgery",
      "Gynae & Gynaeoncology",
      "Gynaecology & Infertility",
      "Haematology",
      "Medicine",
      "Nephrology (Nephrologist & Medical Specialist)",
      "Neurologist",
      "Neurosurgeon & Spine Surgeon",
      "Nutrition (Overweight, child nutrition & diet therapy)",
      "Nutrition Consultant",
      "Onclogy (Cancer)",
      "Orthopaedic",
      "Paedistrician & Paediatric cardiology",
      "Pain Medicine",
      "Pediatrics & Neonate (Child)",
      "Physical Medicine",
      "Physiotherapy",
      "Plastic Surgery",
      "Psychiatrist",
      "Respiratory Medicine",
      "Skin & VD",
      "Urologist",
      "Vascular Surgery"
  ];

  function validateForm() {
    return name.length > 0 && age.length > 0 && gender.length > 0 && email.length > 0 && number.length > 0 && speciality.length > 0 && designation.length > 0;
  }

  function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);

    if (validator.isEmail(email) || email.trim() === "") {
        const body = { task: "editDoctorProfile", doctor_id: getFromStorage("data") ? getFromStorage("data").id : "", name, age, gender, email, phone: number, professional_workplace: address, speciality, designation }
        const headers = { headers: { "Content-Type": "application/json" } }
        axios
            .post(api.DoctorApi, body, headers)
            .then(res => {
                console.log(res.data)
                if (!res.data.errorMessage) {
                    props.getProfile()
                    toast.success("Profile Updated Successfully")
                    setIsSubmitting(false);
                } else {
                    setErrors([res.data.errorMessage])
                    setIsSubmitting(false);
                }
            })
            .catch(err => {
                setIsSubmitting(false);
                setErrors(["Something Went Wrong"])
            })
        
    } else {
        setIsSubmitting(false);
        setErrors(["Email not valid"])
    }
  }

  useEffect(() => {
    setName(props.data.name.toString());
    setEmail(props.data.email.toString());
    setAge(props.data.age.toString());
    setNumber(props.data.phone.toString());
    setGender(props.data.gender.toString());
    setDesignation(props.data.designation.toString());
    setSpeciality(props.data.speciality.toString());
    setAddress(props.data.address)
  }, [])

//   const signup = () => {
//     const body = {task: "registerDoctor", name, age, gender, email, phone: number, address, password, speciality, designation, bmdc_no: reg_number}
//     const headers = {"Content-Type": "application/json"}
//     axios.post(Api.DoctorApi, body, headers)
//     .then(res => {
//         if(res.data === 200){
//             setName("");
//             setAge("");
//             setGender("")
//             setEmail("")
//             setPassword("")
//             setRetypePassword("")
//             setSpeciality("")
//             setNumber("")
//             setAddress("")
//             setDesignation("")
//             setRegistration("")
//             setIsSubmitting(false)
//             setSuccess("Successfully Signed Up You can now log in")
//             setErrors([])
//         } else {
//             setErrors(["Error! Couldn't Signup"])
//             setSuccess("")
//             setIsSubmitting(false)
//         }
//     })
//     .catch(err => { 
//         setErrors(["Something went wrong"])
//         setSuccess("")
//         setIsSubmitting(false)
//     })
//  }

  return (
    <div style={{overflow: "auto"}}>
        <h3 style={{textAlign: "center", color, fontWeight: "bold"}}>Profile</h3>
        <form onSubmit={handleSubmit}>
            {
                errors.map((error,i) =>
                    <Alert key={i} variant="danger" style={{fontSize: "12px"}}>
                        {error}
                    </Alert>
                )
            }
            {
                success.length > 0 && 
                <Alert variant="success" style={{fontSize: "12px"}}>
                    {success}
                </Alert>
            }
            <FormGroup controlId="name" bssize="large" style={{textAlign: "left"}}>
                <FormLabel>Name<span style={{color: "red"}}>*</span></FormLabel>
                <FormControl
                    type="text"
                    placeholder="Name"
                    value={name}
                    size="sm"
                    onChange={e => {
                        setName(e.target.value);
                        setSuccess("");
                        setErrors([]);
                    }}
                />
            </FormGroup>
            <FormGroup controlId="age" bssize="large" style={{textAlign: "left"}}>
                <FormLabel>Age<span style={{color: "red"}}>*</span></FormLabel>
                <FormControl
                    type="text"
                    placeholder="Age"
                    value={age}
                    pattern="[0-9]*"
                    size="sm"
                    onChange={e => {
                        if(e.target.validity.valid){
                            setAge(e.target.value);
                            setSuccess("");
                            setErrors([]);
                        }
                    }}
                />
            </FormGroup>
            <FormGroup controlId="gender" bssize="large" style={{textAlign: "left"}}>
                <FormLabel>Gender<span style={{color: "red"}}>*</span></FormLabel>
                <FormControl
                    as="select"
                    value={gender}
                    size="sm"
                    onChange={e => {
                        setGender(e.target.value);
                        setSuccess("");
                        setErrors([]);
                    }}
                >
                    <option style={{color: "silver"}} value="">Select Gender</option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                </FormControl>
            </FormGroup>
            
            <FormGroup controlId="email" bssize="large" style={{textAlign: "left"}}>
                <FormLabel>Email<span style={{color: "red"}}>*</span></FormLabel>
                <FormControl
                    type="email"
                    placeholder="Email"
                    value={email}
                    size="sm"
                    onChange={e => {
                        setEmail(e.target.value);
                        setSuccess("");
                        setErrors([]);
                    }}
                />
            </FormGroup>
            <FormGroup controlId="address" bssize="large" style={{textAlign: "left"}}>
                <FormLabel>Address</FormLabel>
                <FormControl
                    type="text"
                    placeholder="Address"
                    value={address}
                    size="sm"
                    onChange={e => {
                        setAddress(e.target.value);
                        setSuccess("");
                        setErrors([]);
                    }}
                />
            </FormGroup>
            <FormGroup controlId="phone_number" bssize="large" style={{textAlign: "left"}}>
                <FormLabel>Phone Number<span style={{color: "red"}}>*</span></FormLabel>
                <FormControl
                    type="text"
                    placeholder="Phone Number"
                    value={number}
                    size="sm"
                    pattern="[0-9]*"
                    onChange={e => {
                        if(e.target.validity.valid){
                            setNumber(e.target.value);
                            setSuccess("");
                            setErrors([]);
                        }
                    }}
                />
            </FormGroup>
            <FormGroup controlId="speciality" bssize="large" style={{textAlign: "left"}}>
                <FormLabel>Speciality<span style={{color: "red"}}>*</span></FormLabel>
                <FormControl
                    as="select"
                    value={speciality}
                    onChange={e => {
                        setSpeciality(e.target.value);
                        setSuccess("");
                        setErrors([]);
                    }}
                    size="sm"
                >
                    <option value="" style={{color: "silver"}}>Select Speciality</option>
                    {specailities.map(spec =>
                        <option value={spec} key={spec}>{spec}</option>    
                    )}
                </FormControl>
            </FormGroup>
            <FormGroup controlId="designation" bssize="large" style={{textAlign: "left"}}>
                <FormLabel>Designation<span style={{color: "red"}}>*</span></FormLabel>
                <FormControl
                    as="select"
                    value={designation}
                    onChange={e => {
                        setDesignation(e.target.value);
                        setSuccess("");
                        setErrors([]);
                    }}
                    size="sm"
                >
                    <option value="" style={{color: "silver"}}>Select Designation</option>
                    {designations.map(designation => <option value={designation} key={designation}>{designation}</option>)}
                </FormControl>
            </FormGroup>
            
            {/* <FormGroup controlId="reg_no" bssize="large" style={{textAlign: "left"}}>
                <FormLabel>Registration Number</FormLabel>
                <FormControl
                    type="text"
                    placeholder="Registration Number"
                    value={reg_number}
                    onChange={e => {
                        setRegistration(e.target.value);
                        setSuccess("");
                        setErrors([]);
                    }}
                    size="sm"
                />
            </FormGroup> */}
            <div style={{textAlign: "center"}}>
                <Button size="sm" disabled={!validateForm() || isSubmitting ? true:false} type="submit" style={{background: color, borderColor: color, width: "auto", marginLeft: "auto", marginRight: "auto"}} variant="dark">
                    {!isSubmitting ? "Update Profile" : "Loading..."}
                </Button>
            </div>
        </form>
    </div>
  );
}

export default withRouter(DoctorProfile);
