import React, {Component} from "react";
import { Card, Table, Button, Tabs, Tab } from "react-bootstrap";
import key from "../../../config/key.json";
import { toast } from "react-toastify";
import { getFromStorage } from "../../../utils/storage";
import errorConfig from "../../../config/errorConfig.json";
import { withRouter } from "react-router";
import api from "../../../config/api.json";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { VideoCall, Description } from "@material-ui/icons";
import { getToken, getNotifications } from "../saveToken";
import { connect } from "react-redux";
import fire from '../../../firebase/fire';

const color = "#229a88";

class Records extends Component {
    state = {
        appointmentsUpcoming: [],
        appointmentsCompleted: [],
        isLoading: true
    }
    //Error Notification
    notifyError = error => {
        toast.error("" + error.toString());
    };

    //fetch appointments
    fetchAppointmentsUpcoming = () => {
        const body = {
            task: 'getAppointmentList',
            doctor_id: getFromStorage("doctor"),
            status: "0",
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        const headers = { headers: { "Content-Type" : "application/json" } }
        axios.post(api.DoctorApi, body, headers)
        .then(res => {
            if(res.data.length){
                this.setState({appointmentsUpcoming: res.data})
            } else {
                this.setState({isLoading: false})
            }
        }
        )
        .catch(err => {
            this.notifyError(err.toString())
            this.setState({isLoading: false})
        })
    }

     //fetch appointments
     fetchAppointmentsCompleted = () => {
        const body = {
            task: 'getAppointmentList',
            doctor_id: getFromStorage("doctor"),
            status: "1",
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        const headers = { headers: { "Content-Type" : "application/json" } }
        axios.post(api.DoctorApi, body, headers)
        .then(res => {
            console.log(res.data)
            if(res.data.length){
                this.setState({appointmentsCompleted: res.data})
            } 
            this.setState({isLoading: false})
        }
        )
        .catch(err => {
            this.notifyError(err.toString())
            this.setState({isLoading: false})
        })
    }

    //fetch appointments
    fetchAppointmentsUpcomingPatient = () => {
        const body = {
            task: 'showAppointments',
            patient_id: getFromStorage("patient"),
            status: "0",
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        const headers = { headers: { "Content-Type" : "application/json" } }
        axios.post(api.PatientApi, body, headers)
        .then(res => {
            if(res.data.length){
                this.setState({appointmentsUpcoming: res.data})
            } else {
                this.setState({isLoading: false})
            }
        }
        )
        .catch(err => {
            this.notifyError(err.toString())
            this.setState({isLoading: false})
        })
    }

     //fetch appointments
     fetchAppointmentsCompletedPatient = () => {
        const body = {
            task: 'showAppointments',
            patient_id: getFromStorage("patient"),
            status: "1",
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        const headers = { headers: { "Content-Type" : "application/json" } }
        axios.post(api.PatientApi, body, headers)
        .then(res => {
            
            if(res.data.length){
                this.setState({appointmentsCompleted: res.data})
            } 
            this.setState({isLoading: false})
        }
        )
        .catch(err => {
            this.notifyError(err.toString())
            this.setState({isLoading: false})
        })
    }

    //Notification Request
    notificationRequest = (token, type, id, notification_id) => {
        const time = this.props.location.pathname.split("/")[
            this.props.location.pathname.split("/").length - 2
        ];

        const headers = {
            "Content-Type": "application/json",
            Authorization:
                "key=AAAAcHXSJak:APA91bFW5NnyfjZx4wYC7soTVuCblryMMawUSVAGezx_E4NVLtB50BF6orjSwvUoTztk4o43JrjGvYM3rx_JgpMErpTPBA6xpe8hHfuyKCSOG9OohBkE2hQNeHMt7mlRHnjwsn12Vr5Z",
        };
        const body = {
            notification: {
                title: "SemDoc",
                body:
                type === "patients"
                    ? `Doctor is calling you and waiting for you at appointment id ${
                        this.props.location.pathname.split("/")[
                        this.props.location.pathname.split("/").length - 3
                        ]
                    }`
                    : `Patient is calling you and waiting for you at appointment id ${
                        this.props.location.pathname.split("/")[
                        this.props.location.pathname.split("/").length - 3
                        ]
                    }`,
            },
            data: {
                id,
                notification_id,
                appointment_id: notification_id,
                date: `${new Date(
                moment(time, "YYYYMMDDhhmmss")
                ).getDate()}&hour=${new Date(
                moment(time, "YYYYMMDDhhmmss").add(60, "minutes")
                ).getHours()}&minute=${new Date(
                moment(time, "YYYYMMDDhhmmss").add(60, "minutes")
                ).getMinutes()}`,
                type,
                role: type === "patients" ? "doctor" : "patient",
                meetingName: notification_id,
                doctor_id: getFromStorage("doctor"),
                patient_id: id,
            },
            android: {
                priority: "high"
            },
            priority: 10,
            to: token,
        };

        fetch("https://fcm.googleapis.com/fcm/send", {
            method: "POST",
            body: JSON.stringify(body),
            headers,
        })
        .then((res) => res.json())
        .then((res) => {
            if (res.success === 1) {
            }
        })
        .catch((err) => {
            console.log(err.response);
            this.notifyError(err.toString());
        });
    }

    //Send notification
    sendNotification = async (type, id, appointment_id, time, id_2) =>  {
        try {
            console.log(type, id)
            let body = { task: "getDoctorDeviceToken", doctor_id: id }
            if(type === "doctors") {
              const res = await axios.post(api.PatientApi, body)
              console.log(res.data)
              if(res.data && res.data.length)
                this.notificationRequest(res.data[0].device_token, type, id, appointment_id)
            } else {
              body = {task: "getPatientDeviceToken", patient_id: id}
              const res = await axios.post(api.DoctorApi, body)
              console.log(res.data)
              if(res.data && res.data.length)
                this.notificationRequest(res.data[0].device_token, type, id, appointment_id)
            }
          } catch (err) {
            console.log(err)
          }
    }


    //First function run when page load
    componentDidMount() {
        toast.dismiss();
        
        if (!getFromStorage(key.loginInfoKey)) {
            this.notifyError(errorConfig.notLoggedInError);
            this.props.history.push("/");
        } else if (getFromStorage(key.loginInfoKey).task === "authAdmin" || getFromStorage(key.loginInfoKey).task === "authDiagAdminPanel") {
            this.props.history.push("/Graph")
        } else if (getFromStorage(key.loginInfoKey).task === "patient") {
            getNotifications(this.props.dispatch, this.props.notifications)
            getToken("patients")
            this.fetchAppointmentsUpcomingPatient()
            this.fetchAppointmentsCompletedPatient()
        } else if (getFromStorage(key.loginInfoKey).task === "doctor") {
            getToken("doctors")
            this.fetchAppointmentsUpcoming()
            this.fetchAppointmentsCompleted()
        } else if (getFromStorage(key.loginInfoKey).task === "provider") {
            this.props.history.push("/provider_doctors")
        }
    }

    render() {
        if(this.state.isLoading) {
            return (
                <div style={{marginLeft: this.props.isOpened ? (window.innerWidth > 560 ? "160px" : "20px") : "20px", marginTop: "70px", textAlign: "center"}}>
                    <CircularProgress />
                </div>
            )
        }
        return (
            <div style={{marginLeft: this.props.isOpened ? (window.innerWidth > 560 ? "160px" : "20px") : "20px", marginTop: "70px", marginRight: "20px"}}>
                <Card style={{padding: "20px", fontSize: "14px"}} className="shadow-4">
                    <Tabs defaultActiveKey="upcoming_appointment" variant="pills" id="uncontrolled-tab-example" style={{marginLeft: "2px", marginRight: "10px"}}>
                        <Tab eventKey="upcoming_appointment" title="Upcoming Appointments">
                            <br/>
                            <div style={{overflow: "auto"}}>
                                <Table striped bordered hover size="sm">
                                    <thead>
                                        <tr style={{color}}>
                                            <th>Appointment No.</th>
                                            <th>Appointment Date</th>
                                            <th>Appointment Time</th>
                                            <th>{getFromStorage(key.loginInfoKey).task === "patient" ? "Doctor Id" : "Patient Id"}</th>
                                            <th></th>
                                            {getFromStorage("doctor") && <th></th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.appointmentsUpcoming.map(item =>
                                                <tr key={item.appointment_id}>
                                                    <td>{item.appointment_id}</td>
                                                    <td>{moment(item.start_time, "YYYYMMDD").format("DD/MM/YYYY")}</td>
                                                    <td>{moment(item.start_time, "YYYYMMDDhhmmss").format("hh:mm A")}</td>
                                                    <td>{getFromStorage(key.loginInfoKey).task === "patient" ? item.doctor_id: item.patient_id}</td>
                                                    <td align="center" style={{width: "100px"}}>
                                                        <Button size="sm" variant="success" onClick={() => this.props.history.push(`/appointments/call/${item.appointment_id}/${item.start_time}/${getFromStorage(key.loginInfoKey).task === "patient" ? item.doctor_id: item.patient_id}`)}>
                                                            <Description fontSize="small"/>
                                                            <span style={{marginLeft: "5px"}}>Detail</span>
                                                        </Button>
                                                    </td>
                                                    {getFromStorage("doctor") && <td align="center" style={{width: "100px"}}>
                                                        {(new Date() >= new Date(moment(item.start_time, "YYYYMMDDhhmmss"))) &&
                                                        (new Date() <= new Date(moment(item.start_time, "YYYYMMDDhhmmss").add(60, "minutes"))) && getFromStorage("doctor")
                                                        &&
                                                            <Button size="sm" variant="success" onClick={() => { 
                                                                const meeting_id = item.appointment_id;
                                                                const user_id = getFromStorage("doctor") ? getFromStorage("doctor") : getFromStorage("patient")
                                                            
                                                                window.open(`/#/devices/${meeting_id}/${user_id}`)
                                                                    // window.open(`https://twilio.semionmed.com/?id=${getFromStorage(key.loginInfoKey).task === "doctor" ? getFromStorage("doctor") : getFromStorage("patient")}&appointment_id=${item.appointment_id}&date=${new Date(moment(item.start_time, "YYYYMMDDhhmmss")).getDate()}&hour=${new Date(moment(item.start_time, "YYYYMMDDhhmmss").add(60,"minutes")).getHours()}&minute=${new Date(moment(item.start_time, "YYYYMMDDhhmmss").add(60,"minutes")).getMinutes()}`);
                                                                    if(getFromStorage(key.loginInfoKey).task === "doctor")
                                                                        this.sendNotification("patients", item.patient_id, item.appointment_id.toString(), item.start_time, getFromStorage("doctor"));
                                                                    else 
                                                                        this.sendNotification("doctors", item.doctor_id, item.appointment_id.toString(), item.start_time, getFromStorage("patient"));
                                                                }}
                                                            >
                                                                <VideoCall fontSize="small"/>
                                                                <span style={{marginLeft: "5px"}}>Call</span>
                                                            </Button>
                                                        } 
                                                    </td>}
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                                {this.state.appointmentsUpcoming.length === 0 &&
                                <div style={{textAlign: "center"}}>
                                    No Upcoming Appointments Available
                                </div>}
                            </div>
                        </Tab>
                        <Tab eventKey="completed_appointment" title="Completed Appointments">
                            <br/>
                            <div style={{overflow: "auto"}}>
                                <Table striped bordered hover size="sm">
                                    <thead>
                                        <tr style={{color}}>
                                            <th>Appointment No.</th>
                                            <th>Appointment Date</th>
                                            <th>Appointment Time</th>
                                            <th>{getFromStorage(key.loginInfoKey).task === "patient" ? "Doctor Id" : "Patient Id"}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.appointmentsCompleted.map(item =>
                                                <tr key={item.appointment_id}>
                                                    <td>{item.appointment_id}</td>
                                                    <td>{moment(item.start_time, "YYYYMMDD").format("DD/MM/YYYY")}</td>
                                                    <td>{moment(item.start_time, "YYYYMMDDhhmmss").format("hh:mm A")}</td>
                                                    <td>{getFromStorage(key.loginInfoKey).task === "patient" ? item.doctor_id: item.patient_id}</td>
                                                    <td align="center">
                                                        <Button size="sm" variant="success" onClick={() => this.props.history.push(`/appointments/call/${item.appointment_id}/${item.start_time}/${getFromStorage(key.loginInfoKey).task === "patient" ? item.doctor_id: item.patient_id}`)}>
                                                            <Description fontSize="small"/>
                                                            <span style={{marginLeft: "5px"}}>Detail</span>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                                {this.state.appointmentsCompleted.length === 0 &&
                                <div style={{textAlign: "center"}}>
                                    No Completed Appointments Available
                                </div>}
                            </div>
                        </Tab>
                    </Tabs>
                    
                </Card>
            </div>
        )
    }
}

export default connect(state => ({
    notifications: state.notifications
}))(withRouter(Records));