import React, { Component } from 'react';
import { Modal, FormGroup, Button, FormCheck, FormLabel } from 'react-bootstrap';
import { getFromStorage } from '../../../utils/storage';
import DoctorProfile from './DoctorProfile';
import PatientProfile from './PatientProfile';

export default class ProfileDialog extends Component {
    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom: "-100px"}}>Update Profile</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {getFromStorage("doctor") ? <DoctorProfile getProfile={this.props.getProfile} data={this.props.data}/> : <PatientProfile getProfile={this.props.getProfile} data={this.props.data}/>}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" size="sm" onClick={this.props.handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}