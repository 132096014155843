// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useContext, useState } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import {
  PrimaryButton,
  Flex,
  Label,
  useMeetingManager,
  Modal,
  ModalBody,
  ModalHeader,
  DeviceLabels
} from 'amazon-chime-sdk-component-library-react';

import routes from '../constants/routes';
import Card from '../components/Card';
import { useAppState } from '../providers/AppStateProvider';
import { getErrorContext } from '../providers/ErrorProvider';
import { createGetAttendeeCallback, fetchMeeting } from '../utils/api';
import { useEffect } from 'react';
import Spinner from '../components/Spinner';

const MeetingJoinDetails = (props) => {
  const meetingManager = useMeetingManager();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { meetingId, localUserName } = useAppState();

  const {meeting_id, user_id} = useParams()
  const {
    setAppMeetingInfo,
    meetingId: appMeetingId
  } = useAppState();
  const [meetingId1, setMeetingId1] = useState(appMeetingId);
  const [name, setName] = useState(user_id);
  const { errorMessage, updateErrorMessage } = useContext(getErrorContext());
  const { setMeetingMode } = useAppState();

  const handleJoinMeetingM = async () => {
    // if(props.location && props.location.state && props.location.state.meeting_id && props.location.state.user_id) {
    if (meeting_id && user_id) {
      const id = meeting_id;
      const attendeeName = user_id;

      console.log(id)
      setIsLoading(true);

      try {
        const JoinInfo = await fetchMeeting(id, attendeeName);
        
        if(JoinInfo.status === "failed"){
          updateErrorMessage("Meeting Id and User Id not found");
          setIsLoading(false)
        } else {
          await meetingManager.join({
            meetingInfo: JoinInfo.meeting.Meeting,
            attendeeInfo: JoinInfo.attendee.Attendee,
            deviceLabels: DeviceLabels.AudioAndVideo,
          });
        
          setAppMeetingInfo(id, attendeeName);
        
          setMeetingMode(JoinInfo.attendee.Attendee);
          setIsLoading(false)
        }
      } catch (error) {
        updateErrorMessage(error.message);
      }
    } else {
      updateErrorMessage("Meeting Id and User Id not found");
    }
  };

  const closeError = () => {
    updateErrorMessage('');
    setMeetingId1('');
    setName('');
    setIsLoading(false);
  };

  const handleJoinMeeting = async () => {
    setIsLoading(true);

    try {
      await meetingManager.start();
      setIsLoading(false);
      history.push(`${routes.MEETING}/${meetingId1}`);
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
    }
  };

  useEffect(() => {
    handleJoinMeetingM()
  }, [])

  if(isLoading) {
    return (
      <div style={{textAlign: "center"}}>
        <Spinner /> 
        <br/>
        <span style={{fontSize: 12}}>Loading Please Wait...</span>
      </div>
    )
  }

  return (
    <>
      <Flex container alignItems="center" flexDirection="column">
        <PrimaryButton
          label={isLoading ? 'Loading...' : 'Join meeting'}
          onClick={handleJoinMeeting}
        />
        {meetingId && 
          <Label style={{ margin: '.75rem 0 0 0' }}>
            Joining meeting <b>{meetingId}</b> as <b>{localUserName}</b>
          </Label>
        }
      </Flex>
      {error && (
        <Modal size="md" onClose={() => setError('')}>
          <ModalHeader title={`Meeting ID: ${meetingId}`} />
          <ModalBody>
            <Card
              title="Unable to join meeting"
              description="There was an issue in joining this meeting. Check your connectivity and try again."
              smallText={error}
            />
          </ModalBody>
        </Modal>
      )}
      {errorMessage && (
          <Modal size="md" onClose={closeError}>
            <ModalHeader title={``} />
            <ModalBody>
              <Card
                title="Unable to join meeting"
                description="There was an issue finding that meeting. The meeting may have already ended, or your authorization may have expired."
                smallText={errorMessage}
              />
            </ModalBody>
          </Modal>
        )}
    </>
  );
};

export default withRouter(MeetingJoinDetails);
