import React, { Component } from "react";
import { getFromStorage } from "../../../utils/storage";
import axios from "axios";
import Api from "../../../config/api.json";
import { CircularProgress } from "@material-ui/core";
import { Card, Table, Button, Tabs, Tab } from "react-bootstrap";
import { CloudDownload, Description } from "@material-ui/icons";
import { toast } from "react-toastify";
import UploadDocument from "./UploadDocument";
import moment from "moment";
import imageCompression from "browser-image-compression";
import ViewDocumentDialog from './ViewDocumentDialog'
const color = "#229a88";

export default class PatientRecord extends Component {
  state = {
    isLoading: true,
    list: [],
    isUploading: false,
    date: new Date(),
    checked: 0,
    image: "",
    uri: "",
    maxDate: new Date(),
    minDate: new Date(),
    document_date: "",
    files: null,
    isDownloading: false,
    showDate: false,
    isViewing: false,
    viewImage: "",
    showDoc: false,
    viewPdf: ""
  };

  getRecords = () => {
    const headers = {
      headers: { "Content-Type": "application/json" },
    };

    const body = {
      task: "getPatientDocumentsList",
      patient_id: getFromStorage("patient"),
    };

    if (getFromStorage("patient")) {
      axios
        .post(Api.PatientApi, body, headers)
        .then((res) => {
          if (res.data) {
            console.log(res.data);
            this.setState({ isLoading: false, list: res.data });
          } else {
            this.setState({ isLoading: false });
            toast.error("Something went wrong");
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          toast.error("Something went wrong");
        });
    } else {
      this.setState({ isLoading: false });
    }
  };

  uploadDocument = () => {
    this.setState({ isUploading: true });
    const headers = {
      headers: { "Content-Type": "application/json" },
    };

    const body = {
      task: "uploadPatientFiles",
      patient_id: getFromStorage("patient"),
      document_type: this.state.checked,
      // document_date: this.state.document_date.length
      //   ?  moment(this.state.document_date).format("YYYY-MM-DD hh:mm:ss")
      //   : moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
    };

    if (getFromStorage("patient")) {
      axios
        .post(Api.PatientApi, body, headers)
        .then((res) => {
          if (res.data && !res.data.errorMessage) {
            console.log(res.data);
            this.UploadingDocument(res.data.fields, res.data.url);
          } else {
            this.setState({ isUploading: false });
            alert("Something went wrong");
          }
        })
        .catch((err) => {
          this.setState({ isUploading: false });
          alert("Something went wrong");
        });
    } else {
      this.setState({ isUploading: false });
    }
  };

  //Download Document
  downloadDocument = (data) => {
    console.log("calling dl");
    this.setState({ isDownloading: true });
    console.log(data);
    let doc_type = ""
    const headers = {
      headers: { "Content-Type": "application/json" },
    };
    let body = {};
    if (data.report_id === 0) {
      console.log("no report_id");
      body = {
        task: "downloadPatientFiles",
        patient_id: getFromStorage("patient"),
        document_type: data.document_type,
        document_date: data.document_date,
        document_source: data.document_source,
      };
      console.log(body)
    } else {
      console.log("in report_id");
      if (data.document_date.includes("_report")) {
        doc_type = `pdf`;
      } else if (data.document_date.includes("_image")) {
        doc_type = `jpg`;
      } else {
        doc_type = `unk`;
      }
      console.log(doc_type);
      body = {
        task: "downloadRadiologyReport",
        report_id: data.report_id,
        document_type: doc_type,
      };
    }

    if (getFromStorage("patient")) {
      axios
        .post(Api.PatientApi, body, headers)
        .then((res) => {
          if (res.data) {
            console.log(res.data);
            this.setState({ isDownloading: false });
            window.open(res.data)
          } else {
            this.setState({ isDownloading: false });
            toast.error("Something went wrong");
          }
        })
        .catch((err) => {
          this.setState({ isDownloading: false });
          toast.error("Something went wrong");
        });
    } else {
      this.setState({ isDownloading: false });
    }
  };

  //View Document
  viewDocument = (data) => {
    console.log("calling dl");
    this.setState({ isViewing: true });
    console.log(data);
    let doc_type = ""
    const headers = {
      headers: { "Content-Type": "application/json" },
    };
    let body = {};
    if (data.report_id === 0) {
      console.log("no report_id");
      body = {
        task: "downloadPatientFiles",
        patient_id: getFromStorage("patient"),
        document_type: data.document_type,
        document_date: data.document_date,
        document_source: data.document_source,
      };
      console.log(body)
    } else {
      console.log("in report_id");
      if (data.document_date.includes("_report")) {
        doc_type = `pdf`;
      } else if (data.document_date.includes("_image")) {
        doc_type = `jpg`;
      } else {
        doc_type = `unk`;
      }
      console.log(doc_type);
      body = {
        task: "downloadRadiologyReport",
        report_id: data.report_id,
        document_type: doc_type,
      };
    }

    if (getFromStorage("patient")) {
      axios
        .post(Api.PatientApi, body, headers)
        .then((res) => {
          if (res.data) {
           
            this.setState({ isViewing: false, showDoc: true, viewImage: res.data, viewPdf: res.data });
           
          } else {
            this.setState({ isViewing: false });
            toast.error("Something went wrong");
          }
        })
        .catch((err) => {
          this.setState({ isViewing: false });
          toast.error("Something went wrong");
        });
    } else {
      this.setState({ isViewing: false });
    }
  };

  UploadingDocument = (fields, presignedURL) => {
    this.setState({ isUploading: true }, () => {
      const formData = new FormData();
      const arr = Object.keys(fields);
      arr.forEach((key) => {
        formData.append(key, fields[key]);
      });
      formData.append("file", this.state.files);
      console.log(formData)
      //   formData.append("file", {
      //     uri: this.state.base64,
      //     name: `document_date${this.state.checked.toString()}.jpg`,
      //     type: "image/jpg",
      //   });
      axios
        .post(presignedURL, formData)
        .then((res) =>
          this.setState({ isUploading: false, files: null, base64: "" }, () => {
            toast.success("Document Uploaded Successfully");
            this.getRecords();
          })
        )
        .catch((err) =>
          this.setState({ isUploading: false }, () => {
            toast.error("Something went wrong! Didn't Uploaded");
          })
        );
    });
  };

  setFile = (data) => {
    const file = data.map(file => (
        new File([file.src.file], `document_date${this.state.checked.toString()}.${file.name.split(".")[file.name.split(".").length - 1]}`, {type: file.type})
    ));
    this.setState(
      {
        files: data.length > 0 ? file[0] : null,
        base64: data.length > 0 ? data[0].src.base64 : "",
      },
      () => {
        // this.state.files.name = `document_date${this.state.checked.toString()}.${this.state.files.name.split(".")[this.state.files.name.split(".").length - 1]}`
        console.log(this.state.files);
        if (this.state.files && data[0].type !== "application/pdf") {
          const options = {
            maxSizeMB: 0.01,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          };
          imageCompression(this.state.files, options)
            .then((res) => {
              this.setState({ files: res });
            })
            .catch((err) => console.log(err));
        }
      }
    );
  };

  async componentDidMount() {
    this.getRecords();
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div
          style={{
            marginLeft: this.props.isOpened
              ? window.innerWidth > 560
                ? "160px"
                : "20px"
              : "20px",
            marginTop: "70px",
            textAlign: "center",
          }}
        >
          <CircularProgress />
        </div>
      );
    }

    return (
      <div
        style={{
          marginLeft: this.props.isOpened
            ? window.innerWidth > 560
              ? "160px"
              : "20px"
            : "20px",
          marginTop: "70px",
          marginRight: "20px",
        }}
      >
        <Card
          style={{ padding: "20px", fontSize: "14px" }}
          className="shadow-4"
        >
          <Tabs
            defaultActiveKey="Prescription_Files"
            variant="pills"
            id="uncontrolled-tab-example"
            style={{ marginLeft: "2px", marginRight: "10px" }}
          >
            <Tab eventKey="Prescription_Files" title="Prescription Files">
              <br />
              <div style={{ overflow: "auto" }}>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr style={{ color }}>
                      <th>Date</th>
                      <th>View</th>
                      <th>Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.list[0] &&
                      this.state.list[0].map((item, i) => (
                        <tr key={i}>
                          <td>{item.document_date}</td>
                          <td>
                            <Button size="sm" disabled={this.state.isViewing} variant="success" onClick={() => this.viewDocument(item)}>
                              <Description fontSize="small" />
                              <span style={{ marginLeft: "5px" }}>View</span>
                            </Button>
                          </td>
                          <td>
                            <Button size="sm" disabled={this.state.isDownloading} variant="success" onClick={() => this.downloadDocument(item)}>
                              <CloudDownload fontSize="small" />
                              <span style={{ marginLeft: "5px" }}>
                                Download
                              </span>
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {this.state.list[0] && this.state.list[0].length === 0 && (
                  <div style={{ textAlign: "center" }}>
                    No Prescription Files Available
                  </div>
                )}
              </div>
            </Tab>
            <Tab eventKey="Pathology_Files" title="Pathology Files">
              <br />
              <div style={{ overflow: "auto" }}>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr style={{ color }}>
                      <th>Date</th>
                      <th>View</th>
                      <th>Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.list[1] &&
                      this.state.list[1].map((item, i) => (
                        <tr key={i}>
                          <td>{item.document_date}</td>
                          <td>
                          <Button size="sm" disabled={this.state.isViewing} variant="success" onClick={() => this.viewDocument(item)}>
                              <Description fontSize="small" />
                              <span style={{ marginLeft: "5px" }}>View</span>
                            </Button>
                          </td>
                          <td>
                            <Button size="sm" disabled={this.state.isDownloading} variant="success" onClick={() => this.downloadDocument(item)}>
                              <CloudDownload fontSize="small" />
                              <span style={{ marginLeft: "5px" }}>
                                Download
                              </span>
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {this.state.list[1] && this.state.list[1].length === 0 && (
                  <div style={{ textAlign: "center" }}>
                    No Pathology Files Available
                  </div>
                )}
              </div>
            </Tab>
            <Tab eventKey="Radiology_Documents" title="Radiology Documents">
              <br />
              <div style={{ overflow: "auto" }}>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr style={{ color }}>
                      <th>Date</th>
                      <th>View</th>
                      <th>Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.list[2] &&
                      this.state.list[2].map((item, i) => (
                        <tr key={i}>
                          <td>{item.document_date}</td>
                          <td>
                          <Button size="sm" disabled={this.state.isViewing} variant="success" onClick={() => this.viewDocument(item)}>
                              <Description fontSize="small" />
                              <span style={{ marginLeft: "5px" }}>View</span>
                            </Button>
                          </td>
                          <td>
                            <Button size="sm" disabled={this.state.isDownloading} variant="success" onClick={() => this.downloadDocument(item)}>
                              <CloudDownload fontSize="small" />
                              <span style={{ marginLeft: "5px" }}>
                                Download
                              </span>
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {this.state.list[2] && this.state.list[2].length === 0 && (
                  <div style={{ textAlign: "center" }}>
                    No Radiology Documents Available
                  </div>
                )}
              </div>
            </Tab>
            <Tab eventKey="Miscellaneous_Files" title="Miscellaneous Files">
              <br />
              <div style={{ overflow: "auto" }}>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr style={{ color }}>
                      <th>Date</th>
                      <th>View</th>
                      <th>Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.list[3] &&
                      this.state.list[3].map((item, i) => (
                        <tr key={i}>
                          <td>{item.document_date}</td>
                          <td>
                          <Button size="sm" disabled={this.state.isViewing} variant="success" onClick={() => this.viewDocument(item)}>
                              <Description fontSize="small" />
                              <span style={{ marginLeft: "5px" }}>View</span>
                            </Button>
                          </td>
                          <td>
                            <Button size="sm" disabled={this.state.isDownloading} variant="success" onClick={() => this.downloadDocument(item)}>
                              <CloudDownload fontSize="small" />
                              <span style={{ marginLeft: "5px" }}>
                                Download
                              </span>
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {this.state.list[3] && this.state.list[3].length === 0 && (
                  <div style={{ textAlign: "center" }}>
                    No Miscellaneous Files Available
                  </div>
                )}
              </div>
            </Tab>
            <Tab eventKey="Upload_Document" title="Upload Document">
              <br />
              {/* {this.state.base64 && this.state.base64.length && <input type="date" value={this.state.document_date} onChange={(e) => this.setState({document_date: e.target.value})}/>} */}
              <UploadDocument
                base64={this.state.base64}
                setFile={this.setFile}
                isUploading={this.state.isUploading}
                uploadDocument={this.uploadDocument}
                setChecked={(data) => this.setState({ checked: data })}
                checked={this.state.checked}
              />
            </Tab>
          </Tabs>
          <ViewDocumentDialog document={this.state.viewPdf} image={this.state.viewImage} handleClose={() => this.setState({showDoc: false, viewImage: "", viewPdf: ""})} show={this.state.showDoc}/>
        </Card>
      </div>
    );
  }
}
