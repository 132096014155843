// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import routes from '../constants/routes';
import axios from 'axios'

export const BASE_URL = routes.HOME;

// interface MeetingResponse {
//   JoinInfo: {
//     attendee: any;
//     meeting: any;
//   };
// }

export async function fetchMeeting(
  meetingId,
  name
) {
  const body = {
    appointment_id: meetingId,
    doctor_id: name,
  }
  try {
    // const response = await axios.post(`https://hk7jwm3qkd.execute-api.us-east-1.amazonaws.com/prod`, body);
    const response = await axios.post(`https://kc7v5uvvf9.execute-api.us-east-1.amazonaws.com/dev`, body);
    console.log(response.data)
    if (response.data.error) {
      throw new Error(`Server error: ${response.data.error}`);
    }
  
    return response.data;
  } catch(err) {
    return false
  }
  
}

export function createGetAttendeeCallback(meetingId) {
  return async (chimeAttendeeId, externalUserId) => {
    const attendeeUrl = `${BASE_URL}attendee?title=${encodeURIComponent(
      meetingId
    )}&attendee=${encodeURIComponent(chimeAttendeeId)}`;
    const res = await fetch(attendeeUrl, {
      method: 'GET'
    });

    if (!res.ok) {
      throw new Error('Invalid server response');
    }

    const data = await res.json();

    return {
      name: data.AttendeeInfo.Name
    };
  };
}

export async function endMeeting(meetingId) {
  const res = await fetch(
    `${BASE_URL}end?title=${encodeURIComponent(meetingId)}`,
    {
      method: 'POST'
    }
  );

  if (!res.ok) {
    throw new Error('Server error ending meeting');
  }
}
