import React from "react";

const NotFound = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <br />
      <br />
      <br />
      <h1>
        Page Not Found
        <br />
        404 :(
      </h1>
    </div>
  );
};

export default NotFound;
