import React, { useState } from "react";
import { Button, FormGroup, FormControl, FormLabel, Alert, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import Api from "../../../config/api.json"
import validator from "validator"
import 'react-phone-input-2/lib/style.css'
import errorConfig from "../../../config/errorConfig.json"
import { md5Password } from "../../../utils/md5";
const color = "#229a88";

function AddDoctor(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [number, setNumber] = useState("");
  const [address, setAddress] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [designation, setDesignation] = useState("");
  const [reg_number, setRegistration] = useState("");
  const [first_visit_rate, setFirstVisitRate] = useState("");
  const [after1week_rate, setAfter1WeekRate] = useState(""); 
  const [after2week_rate, setAfter2WeekRate] = useState("");
  const [after1month_rate, setAfter1MonthRate] = useState("");
  const [after3month_rate, setAfter3MonthRate] = useState("");   
  const designations = ["Professor", "Associate Professor", "Assistant Professor", "Senior Consultant", "Junior Consultant", "Consultant", "Medical Officer"]
const specailities = [
    "Andrologist & Lap Surgeon",
    "Cardiology",
    "Cardio-Vascular Surgery",
    "Chest Diseases",
    "COVID-19(Coronavirus)",
    "Dentist",
    "Dermatology",
    "Diabetes & Hormone",
    "Dietician",
    "ENT",
    "EYE",
    "Gastroenterology",
    "General Physician",
    "General Surgery",
    "Gynae & Gynaeoncology",
    "Gynaecology & Infertility",
    "Haematology",
    "Medicine",
    "Nephrology (Nephrologist & Medical Specialist)",
    "Neurologist",
    "Neurosurgeon & Spine Surgeon",
    "Nutrition (Overweight, child nutrition & diet therapy)",
    "Onclogy (Cancer)",
    "Orthopaedic",
    "Paedistrician & Paediatric cardiology",
    "Pain Medicine",
    "Pediatrics & Neonate (Child)",
    "Physical Medicine",
    "Physiotherapy",
    "Plastic Surgery",
    "Psychiatrist",
    "Respiratory Medicine",
    "Skin & VD",
    "Urologist",
    "Vascular Surgery"
];

  function validateForm() {
    return name.length > 0 && age.length > 0 && gender.length > 0 && email.length > 0  && password.length > 0 && retypePassword.length > 0 && number.length > 0 && speciality.length > 0 && designation.length > 0;
  }

  function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);

    if(password !== retypePassword) {
        setErrors(["Passwords are not equal"])
        setSuccess("");
        setIsSubmitting(false);
    } else if (!validator.isMobilePhone(number)){
        setErrors(["Phone Number not valid"]);
        setSuccess("");
        setIsSubmitting(false);
    } else if (!validator.isEmail(email)){
        setErrors(["Email not valid"]);
        setSuccess("");
        setIsSubmitting(false);
    } else {
        signup();
    }
  }

  const signup = () => {
    const body = {task: "registerDoctor", name, age, gender, email, phone: number, address, password: md5Password(password), speciality, designation, bmdc_no: reg_number, first_visit_rate: first_visit_rate.length === 0 ? "1000" : first_visit_rate, after1week_rate: after1week_rate.length === 0 ? "400" : after1week_rate, after2week_rate: after2week_rate.length === 0 ? "500" : after2week_rate, after1month_rate: after1month_rate.length === 0 ? "600" : after1month_rate, after3month_rate: after3month_rate.length === 0 ? "700" : after3month_rate}
    const headers = {"Content-Type": "application/json"}
    axios.post(Api.DoctorApi, body, headers)
    .then(res => {
        if(res.data === 200){
            setName("");
            setAge("");
            setGender("")
            setEmail("")
            setPassword("")
            setRetypePassword("")
            setSpeciality("")
            setNumber("")
            setAddress("")
            setDesignation("")
            setRegistration("")
            setAfter3MonthRate("")
            setFirstVisitRate("")
            setAfter1WeekRate("")
            setAfter2WeekRate("")
            setAfter1MonthRate("")
            setIsSubmitting(false)
            setSuccess("Successfully Doctor Added")
            setErrors([])
        } else {
            setErrors(["Error! Couldn't Add Doctor"])
            setSuccess("")
            setIsSubmitting(false)
        }
    })
    .catch(err =>{
        this.setState({ isLogging: true });
        this.notifyError(errorConfig.authenticationError);
    })
 }

  return (
    <div className="Login" style={{marginLeft: props.isOpened ? (window.innerWidth > 560 ? "180px" : "20px") : "20px", marginTop: "70px", marginRight: "20px"}}>
      <div style={{backgroundColor: "white", marginLeft: "20px", marginRight: "20px", marginTop: "40px", padding: 20, borderRadius: 10}} className="ma2 pa4 shadow-4">
        <h3 style={{textAlign: "center", color, fontWeight: "bold"}}>Add Doctor</h3>
        <form onSubmit={handleSubmit}>
            {
                errors.map((error,i) =>
                    <Alert key={i} variant="danger" style={{fontSize: "12px"}}>
                        {error}
                    </Alert>
                )
            }
            {
                success.length > 0 && 
                <Alert variant="success" style={{fontSize: "12px"}}>
                    {success}
                </Alert>
            }
            <Row>
            <FormGroup as={Col} sm="3" controlId="name" bssize="large" style={{textAlign: "left"}}>
                <FormLabel>Name<span style={{color: "red"}}>*</span></FormLabel>
                <FormControl
                    type="text"
                    placeholder="Name"
                    value={name}
                    size="sm"
                    onChange={e => {
                        setName(e.target.value);
                        setSuccess("");
                        setErrors([]);
                    }}
                />
            </FormGroup>
            <FormGroup as={Col} sm="3" controlId="age" bssize="large" style={{textAlign: "left"}}>
                <FormLabel>Age<span style={{color: "red"}}>*</span></FormLabel>
                <FormControl
                    type="text"
                    placeholder="Age"
                    value={age}
                    pattern="[0-9]*"
                    size="sm"
                    onChange={e => {
                        if(e.target.validity.valid){
                            setAge(e.target.value);
                            setSuccess("");
                            setErrors([]);
                        }
                    }}
                />
            </FormGroup>
            <FormGroup as={Col} sm="3" controlId="gender" bssize="large" style={{textAlign: "left"}}>
                <FormLabel>Gender<span style={{color: "red"}}>*</span></FormLabel>
                <FormControl
                    as="select"
                    value={gender}
                    size="sm"
                    onChange={e => {
                        setGender(e.target.value);
                        setSuccess("");
                        setErrors([]);
                    }}
                >
                    <option style={{color: "silver"}} value="">Select Gender</option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                </FormControl>
            </FormGroup>
            
            <FormGroup as={Col} sm="3" controlId="email" bssize="large" style={{textAlign: "left"}}>
                <FormLabel>Email<span style={{color: "red"}}>*</span></FormLabel>
                <FormControl
                    type="email"
                    placeholder="Email"
                    value={email}
                    size="sm"
                    onChange={e => {
                        setEmail(e.target.value);
                        setSuccess("");
                        setErrors([]);
                    }}
                />
            </FormGroup>
            </Row>
            <Row>
            <FormGroup as={Col} sm="9" controlId="address" bssize="large" style={{textAlign: "left"}}>
                <FormLabel>Address</FormLabel>
                <FormControl
                    type="text"
                    placeholder="Address"
                    value={address}
                    size="sm"
                    onChange={e => {
                        setAddress(e.target.value);
                        setSuccess("");
                        setErrors([]);
                    }}
                />
            </FormGroup>
            <FormGroup as={Col} sm="3" controlId="phone_number" bssize="large" style={{textAlign: "left"}}>
                <FormLabel>Phone Number<span style={{color: "red"}}>*</span></FormLabel>
                <FormControl
                    name="number"
                    type="text"
                    size="sm"
                    placeholder="Phone (eg. 01712345678)"
                    pattern="[0-9]*"
                    style={{
                    color: "black",
                    display: "inline"
                    }}
                    value={number}
                    onChange={(e) => {
                    if(e.target.validity.valid){
                        setNumber(e.target.value)
                        setSuccess("");
                        setErrors([]);
                    }
                    }}
                />
            </FormGroup>
            </Row>
            <Row>
            <FormGroup as={Col} sm="6" controlId="password" bssize="large" style={{textAlign: "left"}}>
                <FormLabel>Password<span style={{color: "red"}}>*</span></FormLabel>
                <FormControl
                    value={password}
                    size="sm"
                    onChange={e => {
                        setPassword(e.target.value);
                        setSuccess("");
                        setErrors([]);
                    }}
                    placeholder="Password"
                    type="password"
                />
            </FormGroup>
            <FormGroup as={Col} sm="6" controlId="retypassword" bssize="large" style={{textAlign: "left"}}>
                <FormLabel>Re-Enter Password<span style={{color: "red"}}>*</span></FormLabel>
                <FormControl
                    value={retypePassword}
                    onChange={e => {
                        setRetypePassword(e.target.value);
                        setSuccess("");
                        setErrors([]);
                    }}
                    size="sm"
                    placeholder="Re-Enter Password"
                    type="password"
                />
            </FormGroup>
            </Row>
            <Row>
            <FormGroup as={Col} sm="4" controlId="speciality" bssize="large" style={{textAlign: "left"}}>
                <FormLabel>Speciality<span style={{color: "red"}}>*</span></FormLabel>
                <FormControl
                    as="select"
                    value={speciality}
                    onChange={e => {
                        setSpeciality(e.target.value);
                        setSuccess("");
                        setErrors([]);
                    }}
                    size="sm"
                >
                    <option value="" style={{color: "silver"}}>Select Speciality</option>
                    {specailities.map(spec =>
                        <option value={spec} key={spec}>{spec}</option>    
                    )}
                </FormControl>
            </FormGroup>
            <FormGroup as={Col} sm="4" controlId="designation" bssize="large" style={{textAlign: "left"}}>
                <FormLabel>Designation<span style={{color: "red"}}>*</span></FormLabel>
                <FormControl
                    as="select"
                    value={designation}
                    onChange={e => {
                        setDesignation(e.target.value);
                        setSuccess("");
                        setErrors([]);
                    }}
                    size="sm"
                >
                    <option value="" style={{color: "silver"}}>Select Designation</option>
                    {designations.map(designation => <option value={designation} key={designation}>{designation}</option>)}
                </FormControl>
            </FormGroup>
            
            <FormGroup as={Col} sm="4" controlId="reg_no" bssize="large" style={{textAlign: "left"}}>
                <FormLabel>Registration Number</FormLabel>
                <FormControl
                    type="text"
                    placeholder="Registration Number"
                    value={reg_number}
                    onChange={e => {
                        setRegistration(e.target.value);
                        setSuccess("");
                        setErrors([]);
                    }}
                    size="sm"
                />
            </FormGroup>
            </Row>
            <Row>
                <FormGroup as={Col} sm="2" controlId="first_visit_rate" bssize="large" style={{textAlign: "left"}}>
                    <FormLabel>First Visit Rate</FormLabel>
                    <FormControl
                        name="first_visit_rate"
                        type="text"
                        size="sm"
                        placeholder="First Visit Rate"
                        pattern="[0-9]*"
                        style={{
                        color: "black",
                        display: "inline"
                        }}
                        value={first_visit_rate}
                        onChange={(e) => {
                        if(e.target.validity.valid){
                            setFirstVisitRate(e.target.value)
                            setSuccess("");
                            setErrors([]);
                        }
                        }}
                    />
                </FormGroup>
                <FormGroup as={Col} sm="2" controlId="after1week_rate" bssize="large" style={{textAlign: "left"}}>
                    <FormLabel>After 1 Week Rate</FormLabel>
                    <FormControl
                        name="after1week_rate"
                        type="text"
                        size="sm"
                        placeholder="After 1 Week Rate"
                        pattern="[0-9]*"
                        style={{
                        color: "black",
                        display: "inline"
                        }}
                        value={after1week_rate}
                        onChange={(e) => {
                        if(e.target.validity.valid){
                            setAfter1WeekRate(e.target.value)
                            setSuccess("");
                            setErrors([]);
                        }
                        }}
                    />
                </FormGroup>
                <FormGroup as={Col} sm="2" controlId="after2week_rate" bssize="large" style={{textAlign: "left"}}>
                    <FormLabel>After 2 Weeks Rate</FormLabel>
                    <FormControl
                        name="after2week_rate"
                        type="text"
                        size="sm"
                        placeholder="After 2 Week Rate"
                        pattern="[0-9]*"
                        style={{
                        color: "black",
                        display: "inline"
                        }}
                        value={after2week_rate}
                        onChange={(e) => {
                        if(e.target.validity.valid){
                            setAfter2WeekRate(e.target.value)
                            setSuccess("");
                            setErrors([]);
                        }
                        }}
                    />
                </FormGroup>
                <FormGroup as={Col} sm="3" controlId="after1month_rate" bssize="large" style={{textAlign: "left"}}>
                    <FormLabel>After 1 Month Rate</FormLabel>
                    <FormControl
                        name="after1month_rate"
                        type="text"
                        size="sm"
                        placeholder="After 1 Month Rate"
                        pattern="[0-9]*"
                        style={{
                        color: "black",
                        display: "inline"
                        }}
                        value={after1month_rate}
                        onChange={(e) => {
                        if(e.target.validity.valid){
                            setAfter1MonthRate(e.target.value)
                            setSuccess("");
                            setErrors([]);
                        }
                        }}
                    />
                </FormGroup>
                <FormGroup as={Col} sm="3" controlId="after3month_rate" bssize="large" style={{textAlign: "left"}}>
                    <FormLabel>After 3 Months Rate</FormLabel>
                    <FormControl
                        name="after3month_rate"
                        type="text"
                        size="sm"
                        placeholder="After 3 Month Rate"
                        pattern="[0-9]*"
                        style={{
                        color: "black",
                        display: "inline"
                        }}
                        value={after3month_rate}
                        onChange={(e) => {
                        if(e.target.validity.valid){
                            setAfter3MonthRate(e.target.value)
                            setSuccess("");
                            setErrors([]);
                        }
                        }}
                    />
                </FormGroup>
            </Row>
            <Button block bssize="large" disabled={!validateForm() || isSubmitting ? true:false} type="submit" style={{background: color, borderColor: color, width: "auto", marginLeft: "auto", marginRight: "auto"}} variant="dark">
                {!isSubmitting ? "Add Doctor" : "Loading..."}
            </Button>
        </form>
      </div>
    </div>
  );
}

export default withRouter(AddDoctor);