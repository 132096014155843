import React, {Component} from "react";
import { toast } from "react-toastify";
import key from "../../../config/key.json"
import { getFromStorage } from "../../../utils/storage";
import errorConfig from "../../../config/api.json"
import { Card, Tab, Row, Col, Nav, Form, Button } from "react-bootstrap";

const color = "#229a88";

export default class CardInfo extends Component{
    state = {
        cardNumber: "",
        cardHolderName: "",
        address: "",
        expiryDate: "",
        pin: "",
        cardType: "",
        isCard: false
    }

    //Error Notification
    notifyError = error => {
        toast.error("" + error.toString());
    };

    //First function run when page load
    componentDidMount() {
        toast.dismiss();
        
        if (!getFromStorage(key.loginInfoKey)) {
            this.notifyError(errorConfig.notLoggedInError);
            this.props.history.push("/");
        } else if (getFromStorage(key.loginInfoKey).task === "authAdmin" || getFromStorage(key.loginInfoKey).task === "authDiagAdminPanel") {
            this.props.history.push("/Graph")
        } else if (getFromStorage(key.loginInfoKey).task === "patient") {
            this.props.history.push("/home")
        } else if (getFromStorage(key.loginInfoKey).task === "doctor") {
            this.props.history.push("/home")
        } else if (getFromStorage(key.loginInfoKey).task === "provider") {
            
        }
    }

    render(){
        return (
            <div style={{marginLeft: this.props.isOpened ? (window.innerWidth > 560 ? "180px" : "20px") : "20px", marginTop: "70px", marginRight: "20px"}}>
                <Card style={{padding: "20px", fontSize: "14px"}} className="shadow-4">
                    <div style={{overflow: "auto"}}>
                        <h3 style={{textAlign: "center", color, fontWeight: "bold"}}>Payment Setup</h3>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row style={{marginLeft: "10px", marginRight: "10px"}}>
                                <Col sm={3}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                    <Nav.Link eventKey="first">Add Card</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                    <Nav.Link eventKey="second">Recharge</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                </Col>
                                <Col sm={9}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <form>
                                            <Form.Label>Choose Card</Form.Label>
                                            <Form.Group controlId="choose_card">
                                                <Form.Check
                                                    inline
                                                    value={this.state.cardType}
                                                    checked={this.state.cardType === "bkash"}
                                                    type="radio" 
                                                    size="sm"
                                                    label="bkash"
                                                />
                                                {/* <Form.Check
                                                    inline
                                                    type="radio" 
                                                    size="sm"
                                                    label="Card 2"
                                                />
                                                <Form.Check
                                                    inline
                                                    type="radio" 
                                                    size="sm"
                                                    label="Card 3"
                                                /> */}
                                            </Form.Group>
                                            <Form.Group controlId="card_number">
                                                <Form.Label>Card Number</Form.Label>
                                                <Form.Control
                                                    value={this.state.cardNumber}
                                                    pattern="[0-9]*"
                                                    placeholder="Valid Card Number"
                                                    type="text" 
                                                    size="sm"
                                                    onChange={(e) => {
                                                        if(e.target.validity.valid){
                                                            this.setState({cardNumber: e.target.value})
                                                        }
                                                    }}
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="card_holder_name">
                                                <Form.Label>Card Holder Name</Form.Label>
                                                <Form.Control
                                                    value={this.state.cardHolderName}
                                                    placeholder="Card Holder Name"
                                                    type="text" 
                                                    size="sm"
                                                    onChange={(e) => {
                                                        this.setState({cardHolderName: e.target.value})
                                                    }}
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="address">
                                                <Form.Label>Address</Form.Label>
                                                <Form.Control
                                                    value={this.state.address}
                                                    placeholder="Address"
                                                    type="text" 
                                                    size="sm"
                                                    onChange={(e) => {
                                                        this.setState({address: e.target.value})
                                                    }}
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="expiry_date">
                                                <Form.Label>Expiry Date (Month YYYY)</Form.Label>
                                                <Form.Control
                                                    value={this.state.expiryDate}
                                                    type="month" 
                                                    size="sm"
                                                    onChange={(e) => {
                                                        this.setState({expiryDate: e.target.value})
                                                    }}
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="address">
                                                <Form.Label>PIN</Form.Label>
                                                <Form.Control
                                                    value={this.state.pin}
                                                    pattern="[0-9]*"
                                                    placeholder="PIN"
                                                    type="text" 
                                                    size="sm"
                                                    onChange={(e) => {
                                                        if(e.target.validity.valid){
                                                            this.setState({pin: e.target.value})
                                                        }
                                                    }}
                                                />
                                            </Form.Group>
                                            <div style={{textAlign: "center"}}>
                                                <Button size="sm" style={{background: color, borderColor: color, width: "auto", marginLeft: "auto", marginRight: "auto"}} variant="dark">
                                                    Add Card
                                                </Button>
                                            </div>
                                        </form>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <form>
                                            <Form.Label>Available Balance: 42432$</Form.Label>
                                            <Form.Group controlId="recharge">
                                                <Form.Label>Recharge Amount</Form.Label>
                                                <Form.Control
                                                    type="text" 
                                                    size="sm"
                                                    patten="[0-9]*"
                                                />
                                            </Form.Group>
                                            <div style={{textAlign: "center"}}>
                                                <Button size="sm" style={{background: color, borderColor: color, width: "auto", marginLeft: "auto", marginRight: "auto"}} variant="dark">
                                                    Recharge Now
                                                </Button>
                                            </div>
                                        </form>
                                    </Tab.Pane>
                                </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>
                </Card>
            </div>
        )
    }
}