import React, {Component} from "react";
import { toast } from "react-toastify";
import key from "../../../config/key.json"
import { getFromStorage } from "../../../utils/storage";
import errorConfig from "../../../config/api.json"
import { Card, Tab, Row, Col, Nav, Form, Button } from "react-bootstrap";
import { getToken, getNotifications } from "../saveToken";
import { connect } from "react-redux";

const color = "#229a88";

class CardInfo extends Component{

    //Error Notification
    notifyError = error => {
        toast.error("" + error.toString());
    };

    //First function run when page load
    componentDidMount() {
        toast.dismiss();
        
        if (!getFromStorage(key.loginInfoKey)) {
            this.notifyError(errorConfig.notLoggedInError);
            this.props.history.push("/");
        } else if (getFromStorage(key.loginInfoKey).task === "authAdmin" || getFromStorage(key.loginInfoKey).task === "authDiagAdminPanel") {
            this.props.history.push("/Graph")
        } else if (getFromStorage(key.loginInfoKey).task === "patient") {
            getNotifications(this.props.dispatch, this.props.notifications)
            getToken("patients")
        } else if (getFromStorage(key.loginInfoKey).task === "doctor") {
            getNotifications(this.props.dispatch, this.props.notifications)
            getToken("doctors")
        } else if (getFromStorage(key.loginInfoKey).task === "provider") {
            this.props.history.push("/provider_doctors")
        }
    }

    render(){
        return (
            <div style={{marginLeft: this.props.isOpened ? (window.innerWidth > 560 ? "160px" : "20px") : "20px", marginTop: "70px", marginRight: "20px"}}>
                <Card style={{padding: "20px", fontSize: "14px"}} className="shadow-4">
                    <div style={{overflow: "auto"}}>
                        <h3 style={{textAlign: "center", color, fontWeight: "bold"}}>Billing Setup</h3>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row style={{marginLeft: "10px", marginRight: "10px"}}>
                                <Col sm={3}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                    <Nav.Link eventKey="first">Card Information</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                    <Nav.Link eventKey="second">Recharge</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                </Col>
                                <Col sm={9}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <form>
                                            <Form.Group controlId="card_setting">
                                                <Form.Label>Card Setting</Form.Label>
                                                <Form.Control
                                                    type="text" 
                                                    size="sm"
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="card_type">
                                                <Form.Label>Card Type</Form.Label>
                                                <Form.Control
                                                    as="select" 
                                                    size="sm"
                                                >
                                                    <option value="" style={{color: "silver"}}>Select Card Type</option>
                                                </Form.Control>
                                            </Form.Group>
                                            <Form.Group controlId="card_info">
                                                <Form.Label>Card Info</Form.Label>
                                            </Form.Group>
                                            <div style={{textAlign: "center"}}>
                                                <Button size="sm" style={{background: color, borderColor: color, width: "auto", marginLeft: "auto", marginRight: "auto"}} variant="dark">
                                                    Save Card Information
                                                </Button>
                                            </div>
                                        </form>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <form>
                                            <Form.Label>Available Balance: 42432$</Form.Label>
                                            <Form.Group controlId="recharge">
                                                <Form.Label>Recharge Amount</Form.Label>
                                                <Form.Control
                                                    type="text" 
                                                    size="sm"
                                                    patten="[0-9]*"
                                                />
                                            </Form.Group>
                                            <div style={{textAlign: "center"}}>
                                                <Button size="sm" style={{background: color, borderColor: color, width: "auto", marginLeft: "auto", marginRight: "auto"}} variant="dark">
                                                    Recharge Now
                                                </Button>
                                            </div>
                                        </form>
                                    </Tab.Pane>
                                </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>
                </Card>
            </div>
        )
    }
}

export default connect(state => ({
    notifications: state.notifications
}))(CardInfo)