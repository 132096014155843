import React, {Component} from "react"
import PatientProfile from "./PatientProfile";
import { toast } from "react-toastify";
import key from "../../../config/key.json"
import { getFromStorage } from "../../../utils/storage";
import api from "../../../config/api.json"
import { Card, Button } from "react-bootstrap";
import { getToken, getNotifications } from "../saveToken";
import { connect } from "react-redux";
import { Edit } from "@material-ui/icons";
import ProfileDialog from "./ProfileDialog";
import axios from 'axios';
import { CircularProgress } from "@material-ui/core";

const color = "#229a88";

class Profile extends Component{
    state = {
        show: false,
        doctorData: {name: "", age: "", gender: "", email: "", phone: "", designation: "", speciality: "", address: ""},
        patientData: {name: "", age: "", gender: "", email: "", phone: "", address: ""},
        isScreenLoading: true
    }
    //Error Notification
    notifyError = error => {
        toast.error("" + error.toString());
    };

    getPatientProfile = () => {
        const body = {
            task: "getPatientProfile",
            patient_id: getFromStorage("data") ? getFromStorage("data").di_id : ""
        }
        axios.post(api.PatientApi, body)
        .then(res => {
            console.log(res.data)
            if(res.data && !res.data.errorMessage && res.data.length)
                this.setState({patientData: {name: res.data[0].name, age: res.data[0].age, gender: res.data[0].gender, email: res.data[0].email, phone: res.data[0].phone, address: res.data[0].address}, isScreenLoading: false})
            else 
                this.setState({isScreenLoading: false})
        })
        .catch(err => {
            this.setState({isScreenLoading: false})
            console.log(err.toString())
        })
    }

    getDoctorProfile = () => {
        console.log(getFromStorage("data").id)
        const body = {
            task: "getDoctorProfile",
            doctor_id: getFromStorage("data") ? getFromStorage("data").id : ""
        }
        axios.post(api.DoctorApi, body)
        .then(res => {
            console.log(res.data)
            if(res.data && !res.data.errorMessage && res.data.length)
                this.setState({doctorData: {name: res.data[0].name, age: res.data[0].age, gender: res.data[0].gender, email: res.data[0].email, phone: res.data[0].phone, designation: res.data[0].designation, speciality: res.data[0].speciality, address: res.data[0].professional_workplace}, isScreenLoading: false})
            else 
                this.setState({isScreenLoading: false})
        })
        .catch(err => {
            this.setState({isScreenLoading: false})
            console.log(err.toString())
        })
    }

    //First function run when page load
    componentDidMount() {
        toast.dismiss();
        console.log(getFromStorage("data"))
        if (!getFromStorage(key.loginInfoKey)) {
            this.notifyError(api.notLoggedInError);
            this.props.history.push("/");
        } else if (getFromStorage(key.loginInfoKey).task === "authAdmin" || getFromStorage(key.loginInfoKey).task === "authDiagAdminPanel") {
            this.props.history.push("/Graph")
        } else if (getFromStorage(key.loginInfoKey).task === "patient") {
            this.getPatientProfile()
            getNotifications(this.props.dispatch, this.props.notifications)
            getToken("patients")
        } else if (getFromStorage(key.loginInfoKey).task === "doctor") {
            this.getDoctorProfile()
            getNotifications(this.props.dispatch, this.props.notifications)
            getToken("doctors")
        } else if (getFromStorage(key.loginInfoKey).task === "provider") {
            this.props.history.push("/provider_doctors")
        }
    }
    render(){
        if(this.state.isScreenLoading) {
            return (
                <div style={{textAlign: "center", marginLeft: this.props.isOpened ? (window.innerWidth > 560 ? "160px" : "20px") : "20px", marginTop: "70px", marginRight: "20px"}}>
                    <CircularProgress />
                </div>
            )
        }
        if (getFromStorage(key.loginInfoKey).task === "patient") {
            return (
                <div style={{marginLeft: this.props.isOpened ? (window.innerWidth > 560 ? "160px" : "20px") : "20px", marginTop: "70px", marginRight: "20px"}}>
                    <Card style={{padding: "20px", fontSize: "14px"}} className="shadow-4">

                        <div>
                            <h3 style={{textAlign: "center", color, fontWeight: "bold"}}>Profile</h3>
                            <table>
                                <body>
                                    <tr>
                                        <td style={{fontSize: "14px", color}}>Name:</td>
                                        <td>{this.state.patientData.name}</td>
                                    </tr>
                                    <tr>
                                        <td style={{fontSize: "14px", color}}>Age:</td>
                                        <td>{this.state.patientData.age}</td>
                                    </tr>
                                    <tr>
                                        <td style={{fontSize: "14px", color}}>Email:</td>
                                        <td>{this.state.patientData.email}</td>
                                    </tr>
                                    <tr>
                                        <td style={{fontSize: "14px", color}}>Gender:</td>
                                        <td>{this.state.patientData.gender === "M"? "Male" : "Female"}</td>
                                    </tr>
                                    <tr>
                                        <td style={{fontSize: "14px", color}}>Phone:</td>
                                        <td>{this.state.patientData.phone}</td>
                                    </tr>
                                    <tr>
                                        <td style={{fontSize: "14px", color}}>Address:</td>
                                        <td>{this.state.patientData.address}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <br/>
                                            <Button size="sm" variant="success" style={{width: "auto"}} onClick={() => this.setState({show: true})}>
                                                <Edit fontSize="small" style={{fontSize: "16px"}}/>
                                                <span style={{marginLeft: "5px"}}>Edit Profile</span>
                                            </Button>
                                        </td>
                                    </tr>
                                </body>
                            </table>
                        </div>
                        <ProfileDialog data={this.state.patientData} show={this.state.show} getProfile={this.getPatientProfile} handleClose={() => this.setState({show: false})} />
                    </Card>
                </div>
            )
        } else if (getFromStorage(key.loginInfoKey).task === "doctor") {
            return (
                <div style={{marginLeft: this.props.isOpened ? (window.innerWidth > 560 ? "160px" : "20px") : "20px", marginTop: "70px", marginRight: "20px"}}>
                    <Card style={{padding: "20px", fontSize: "14px"}} className="shadow-4">
                        <div>
                            <h3 style={{textAlign: "center", color, fontWeight: "bold"}}>Profile</h3>
                            <table>
                                <body>
                                    <tr>
                                        <td style={{fontSize: "14px", color}}>Name:</td>
                                        <td>{this.state.doctorData.name}</td>
                                    </tr>
                                    <tr>
                                        <td style={{fontSize: "14px", color}}>Age:</td>
                                        <td>{this.state.doctorData.age}</td>
                                    </tr>
                                    <tr>
                                        <td style={{fontSize: "14px", color}}>Email:</td>
                                        <td>{this.state.doctorData.email}</td>
                                    </tr>
                                    <tr>
                                        <td style={{fontSize: "14px", color}}>Gender:</td>
                                        <td>{this.state.doctorData.gender === "M"? "Male" : "Female"}</td>
                                    </tr>
                                    <tr>
                                        <td style={{fontSize: "14px", color}}>Phone:</td>
                                        <td>{this.state.doctorData.phone}</td>
                                    </tr>
                                    <tr>
                                        <td style={{fontSize: "14px", color}}>Speciality:</td>
                                        <td>{this.state.doctorData.speciality}</td>
                                    </tr>
                                    <tr>
                                        <td style={{fontSize: "14px", color}}>Designation:</td>
                                        <td>{this.state.doctorData.designation}</td>
                                    </tr>
                                    <tr>
                                        <td style={{fontSize: "14px", color}}>Address:</td>
                                        <td>{this.state.doctorData.address}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <br/>
                                            <Button size="sm" variant="success" style={{width: "auto"}} onClick={() => this.setState({show: true})}>
                                                <Edit fontSize="small" style={{fontSize: "16px"}}/>
                                                <span style={{marginLeft: "5px"}}>Edit Profile</span>
                                            </Button>
                                        </td>
                                    </tr>
                                </body>
                            </table>
                        </div>
                        <ProfileDialog data={this.state.doctorData} show={this.state.show} getProfile={this.getDoctorProfile} handleClose={() => this.setState({show: false})} />
                    </Card>
                </div>
            )
        }
        return <div></div>
    }
}

export default connect(state => ({
    notifications: state.notifications
}))(Profile)