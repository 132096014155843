import React, { useEffect, useState } from 'react';
// import * as Localization from 'expo-localization';
import axios from 'axios'
import Api from '../../../config/api.json'
import { getFromStorage, removeFromStorage, setInStorage } from '../../../utils/storage';
import { CircularProgress } from '@material-ui/core';
import { FormLabel, Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { FormGroup } from 'react-bootstrap';
import { FormControl } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Dropdown } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import { Table } from 'react-bootstrap';
import {Link, withRouter} from 'react-router-dom'
import { geolocated } from "react-geolocated";
import { connect } from 'react-redux';
import { getNotifications, getToken } from '../saveToken';

const OrderTests = (props) => {
    
    const [data, setData] = useState(props.location.state ? props.location.state.tests : [])
    const [isLoading, setIsLoading] = useState(true)
    const [institutes, setInstitutes] = useState([])
    const [institute, setInstitute] = useState("")
    console.log(props.location.state)
    //get nearest lab
    const getNearestLab = () => {
        console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)
        console.log(props.isGeolocationAvailable)
        const body = {
            longitude: "90.37337348117207",
            latitude: "22.702018042282415",
            task: "getNearestLab"
        }
        axios.post(Api.NEAREST_LAB_API, body)
        .then(res => {
            console.log(res.data)
            setIsLoading(false)
            setInstitutes(res.data)
        })
        .catch(err => {
            console.log(err)
            setIsLoading(false)
        })
    }

    //get current location
    const getCurrentLocation = () => {
        getNearestLab()
    }

    //This function will run first
    useEffect(() => {
        if(getFromStorage("patient")) {
            getNotifications(props.dispatch, props.notifications);
            getToken("patients");
        } else {
            props.history.replace("/")
        }
        setIsLoading(false)
    }, [])

    if(isLoading) {
        return (
            <div style={{marginLeft: props.isOpened ? (window.innerWidth > 560 ? "160px" : "20px") : "20px", marginTop: "70px", textAlign: "center"}}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <div style={{marginLeft: props.isOpened ? (window.innerWidth > 560 ? "160px" : "20px") : "20px", marginTop: "70px", marginRight: "20px"}}>
            <form>
                {institute && institute.length ? 
                    <div>
                        {institute && institute.length && <Row><Col md="3" style={{fontWeight: "bold"}}>Institute:</Col><Col md="5">{institutes.length && institutes.find(item => item.institute === institute).institute}</Col></Row>}
                        {institute && institute.length && <Row><Col md="3" style={{fontWeight: "bold"}}>Address:</Col><Col md="5">{institutes.length && institutes.find(item => item.institute === institute).address}</Col></Row>}
                        {institute && institute.length && <Row><Col md="3" style={{fontWeight: "bold"}}>Phone:</Col><Col md="5">{institutes.length && institutes.find(item => item.institute === institute).phone}</Col></Row>}
                        <Button size="sm" variant="success" onClick={() => setInstitute("")}>
                            Change
                        </Button>
                        <br/>
                        <br/>
                    </div>
                    :
                    <Table style={{backgroundColor: "white"}}>
                        <thead>
                            <tr>
                                <th>Institute</th>
                                <th>Address</th>
                                <th>Discount Rate</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                institutes.map((item, i) =>
                                    <tr key={i}>
                                        <td>{item.institute}</td>
                                        <td>{item.address}</td>
                                        <td>{item.discount_rate}%</td>
                                        <td>
                                            <Button size="sm" variant="success" onClick={() => setInstitute(item.institute)}>
                                                Select
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                }
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Rate</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((item, i) =>
                                <tr key={i}>
                                    <td>{item.name}</td>
                                    <td>{item.rate}</td>
                                </tr>
                            )
                        }
                        {data.length ? 
                        <>
                            <tr>
                                <td style={{fontWeight: "bold"}}>SubTotal:</td>
                                <td>{data.map(item => item.rate).reduce((a, b) => a + b, 0)}</td>
                            </tr>
                            <tr>
                                <td style={{fontWeight: "bold"}}>Discount:</td>
                                <td>{institute && institute.length && institutes.length ? institutes.find(item => item.institute === institute).discount_rate : 0}%</td>
                            </tr>
                            <tr>
                                <td style={{fontWeight: "bold"}}>Total:</td>
                                <td>{data.map(item => item.rate).reduce((a, b) => a + b, 0) * ((100 - (institute && institute.length && institutes.length ? institutes.find(item => item.institute === institute).discount_rate : 0))/100)}</td>
                            </tr>
                        </>
                        :
                        null
                        }
                    </tbody>

                </Table>
           
                <Row>
                    <Col sm="2">
                        {/* <Link to={{pathname: "/invoice", state: {data, institute: institutes.length ? institutes.find(item => item.institute === institute) : { institute, address: "abc", phone: "01234" }}}}> */}
                            <Button size="md" variant="success" disabled={!data.length && !institute.length} style={{ width: "100%"}} onClick={() => { 
                                setInStorage("order_tests", {data, institute: institutes.length ? institutes.find(item => item.institute === institute) : { institute, address: "abc", phone: "01234" }});
                                props.history.push("/invoice")
                            }}>
                                <span style={{marginLeft: "5px"}}>Submit</span>
                            </Button>  
                        {/* </Link>     */}
                    </Col>
                </Row>
            </form>
        </div>
    )
}


export default geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
})(connect((state) => ({
    notifications: state.notifications,
}))(withRouter(OrderTests)));