import React, { useEffect, useState } from 'react';
// import * as Localization from 'expo-localization';
import axios from 'axios'
import Api from '../../../config/api.json'
import { getFromStorage, removeFromStorage, setInStorage } from '../../../utils/storage';
import { CircularProgress } from '@material-ui/core';
import { FormLabel, Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { FormGroup } from 'react-bootstrap';
import { FormControl } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Dropdown } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import { Table } from 'react-bootstrap';
import {Link, withRouter} from 'react-router-dom'
import { geolocated } from "react-geolocated";
import { connect } from 'react-redux';
import { getNotifications, getToken } from '../saveToken';

const OrderLabTest = (props) => {
    const [data, setData] = useState(props.location.state ? props.location.state.tests : [])
    const [testInfo, setTestInfo] = useState("")
    const [country, setCountry] = useState("")
    const [city, setCity] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const [institutes, setInstitutes] = useState([])
    const [institute, setInstitute] = useState("")
    const [radiologyTests, setRadiologyTests] = useState([])
    const [pathologyTests, setPathologyTests] = useState([])
    const [testInfoRadiology, setTestInfoRadiology] = useState("")
    const [testInfoPathology, setTestInfoPathology] = useState("")
    const [filteredRadiology, setFilteredRadiology] = useState([])
    const [filteredPathology, setFilteredPathology] = useState([])
    const [searching, setSearching] = useState(false)
    const [isInvoiceShow, setIsInvoiceShow] = useState(false)

    const isBalanced = (input) => {

        let brackets = "[]{}()<>"
        let stack = []
      
        for(let bracket of input) {
          let bracketsIndex = brackets.indexOf(bracket)
      
          if (bracketsIndex === -1){
            continue
          }
      
          if(bracketsIndex % 2 === 0) {
            stack.push(bracketsIndex + 1)
          } else {
            if(stack.pop() !== bracketsIndex) {
              return false;
            }
          }
        }
        return stack.length === 0
      }

    //Search Tests
    const onSearch = (text, task) => {
        const temp = text.toLowerCase()
        console.log(temp)
        setSearching(true)
        if (text && text.length === 3) {
            getTests(task, temp)
        } else if(!text.length) {
            setSearching(false)
        } else {
            if(task === "getRadiologyTestNames") {
                if(isBalanced(temp)) {
                    const tempList = radiologyTests.filter(item => {
                        if(item.testName.toLowerCase().match(new RegExp(`${temp}`)))
                            return item
                    })
                    console.log(tempList)
                    setFilteredRadiology(tempList)
                }
            } else {
                if(isBalanced(temp)) {
                    const tempList = pathologyTests.filter(item =>{ 
                        if(item.testName.toLowerCase().match(new RegExp(`${temp}`)))
                            return item
                    })
                    setFilteredPathology(tempList)
                }
            }
            setSearching(false)
        }
    }

    //Get Tests
    const getTests = (task, substr) => {
        const body = {
            task,
            substr
        }
        console.log(task)
        axios.post(Api.TEST_API, body)
        .then(res => {
            console.log(res.data)
            if(task === "getRadiologyTestNames") {
                setRadiologyTests(res.data)
                setFilteredRadiology(res.data)
            } else {
                setPathologyTests(res.data)
                setFilteredPathology(res.data)
            }
            setSearching(false)
        })
        .catch(err => {
            console.log(err)
        })
    }

    //delete data
    const deleteData = (id) => {
        setData([...data.filter(item => item.id !== id)])
    }

    //Add Data 
    const addData = () => {
        if(institute.length && (testInfoPathology.trim().length || testInfoRadiology.trim().length)) {
            if(testInfoPathology.trim().length && pathologyTests.find(item => item.testName === testInfoPathology)) {
                const findTest = pathologyTests.find(item => item.testName === testInfoPathology)
                data.push({id: data.length, name: findTest.testName, rate: findTest.rate, id1: findTest.id})
                setData([...data])
                setTestInfoPathology("")
            } 

            if (testInfoRadiology.trim().length && radiologyTests.find(item => item.testName === testInfoRadiology)) {
                const findTest = radiologyTests.find(item => item.testName === testInfoRadiology)
                data.push({id: data.length, name: findTest.testName, rate: findTest.rate, id1: findTest.id})
                setData([...data])
                setTestInfoRadiology("")
            }

            if(!pathologyTests.find(item => item.testName === testInfoPathology) && !radiologyTests.find(item => item.testName === testInfoRadiology)) {
                alert("Test names are incorrect")
            }
        } else {
            alert("Test and Institute Required or not correct")
        }
    }

    //get nearest lab
    const getNearestLab = () => {
        console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)
        console.log(props.isGeolocationAvailable)
        const body = {
            longitude: "90.37337348117207",
            latitude: "22.702018042282415",
            task: "getNearestLab"
        }
        axios.post(Api.NEAREST_LAB_API, body)
        .then(res => {
            console.log(res.data)
            setIsLoading(false)
            setInstitutes(res.data)
        })
        .catch(err => {
            console.log(err)
            setIsLoading(false)
        })
    }

    //get current location
    const getCurrentLocation = () => {
        getNearestLab()
    }

    //This function will run first
    useEffect(() => {
        if(getFromStorage("patient")) {
            getNotifications(props.dispatch, props.notifications);
            getCurrentLocation()
            getToken("patients");
        } else {
            props.history.replace("/")
        }
        setIsLoading(false)
    }, [])

    if(isLoading) {
        return (
            <div style={{marginLeft: props.isOpened ? (window.innerWidth > 560 ? "160px" : "20px") : "20px", marginTop: "70px", textAlign: "center"}}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <div style={{marginLeft: props.isOpened ? (window.innerWidth > 560 ? "160px" : "20px") : "20px", marginTop: "70px", marginRight: "20px"}}>
            <form>
                {/* <Row>
                    <Col sm="8">
                        <FormGroup controlId="institute">
                            <FormLabel style={{fontWeight: "bold"}}>Test Institute</FormLabel>
                            {!data.length || props.location.state ? 
                            <FormControl 
                                size="md"
                                as="select"
                                name="institute" 
                                value={institute} 
                                onChange={(e) => { setInstitute(e.target.value) }}
                            >
                                <option value="" style={{color: "silver"}}>Select Test Institute</option>
                                {institutes.map((item, i) => <option value={item.institute} key={item}>{item.institute + ", " + item.address}</option>)}
                            </FormControl>
                            :
                            <div>{institute}</div>
                            }
                        </FormGroup>
                    </Col>
                </Row> */}
                {institute && institute.length ? 
                    <div>
                        {institute && institute.length && <Row><Col md="3" style={{fontWeight: "bold"}}>Institute:</Col><Col md="5">{institutes.length && institutes.find(item => item.institute === institute).institute}</Col></Row>}
                        {institute && institute.length && <Row><Col md="3" style={{fontWeight: "bold"}}>Address:</Col><Col md="5">{institutes.length && institutes.find(item => item.institute === institute).address}</Col></Row>}
                        {institute && institute.length && <Row><Col md="3" style={{fontWeight: "bold"}}>Phone:</Col><Col md="5">{institutes.length && institutes.find(item => item.institute === institute).phone}</Col></Row>}
                        <Button size="sm" variant="success" onClick={() => setInstitute("")}>
                            Change
                        </Button>
                        <br/>
                        <br/>
                    </div>
                    :
                    <Table style={{backgroundColor: "white"}}>
                        <thead>
                            <tr>
                                <th>Institute</th>
                                <th>Address</th>
                                <th>Discount Rate</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                institutes.map((item, i) =>
                                    <tr key={i}>
                                        <td>{item.institute}</td>
                                        <td>{item.address}</td>
                                        <td>{item.discount_rate}%</td>
                                        <td>
                                            <Button size="sm" variant="success" onClick={() => setInstitute(item.institute)}>
                                                Select
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                }
                {!props.location.state && 
                <>
                    <Row>
                        <Col sm="4">
                            <FormGroup controlId="startTime">
                                <FormLabel style={{fontWeight: "bold"}}>Radiology Tests</FormLabel>
                                <Dropdown
                                    placeholder='Search Radiology Test'
                                    fluid
                                    search
                                    selection
                                    options={filteredRadiology.map(item => ({value: item.testName, text: item.testName}))}
                                    value={testInfoRadiology}
                                    onSearchChange={(e) => {
                                        // setTestInfoRadiology(e.target.value)
                                        onSearch(e.target.value, "getRadiologyTestNames")
                                    }}
                                    onChange={(e, {value}) => {
                                        console.log(value)
                                        setTestInfoRadiology(value)
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="4">
                            <FormGroup controlId="startTime">
                                <FormLabel style={{fontWeight: "bold"}}>Pathology Tests</FormLabel>
                                <Dropdown
                                    placeholder='Search Pathology Test'
                                    fluid
                                    search
                                    selection
                                    options={filteredPathology.map(item => ({value: item.testName, text: item.testName}))}
                                    value={testInfoPathology}
                                    onSearchChange={(e) => {
                                        console.log(e.target.value)
                                        onSearch(e.target.value, "getPathologyTestNames")
                                    }}
                                    onChange={(e, {value}) => setTestInfoPathology(value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="2">
                            <Button size="md" variant="success" style={{ width: "100%"}} onClick={addData}>
                                <span style={{marginLeft: "5px"}}>Add Test</span>
                            </Button>      
                        </Col>
                    </Row>
                </>
                }
                <br/>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Rate</th>
                            {!props.location.state && <th>Delete</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((item, i) =>
                                <tr key={i}>
                                    <td>{item.name}</td>
                                    <td>{item.rate}</td>
                                    {!props.location.state && 
                                    <td align="center">
                                        <Button size="sm" variant="danger" onClick={() => deleteData(item.id)}>
                                            Delete
                                        </Button>
                                    </td>}
                                </tr>
                            )
                        }
                        {data.length ? 
                        <>
                            <tr>
                                <td style={{fontWeight: "bold"}}>SubTotal:</td>
                                <td>{data.map(item => item.rate).reduce((a, b) => a + b, 0)}</td>
                            </tr>
                            <tr>
                                <td style={{fontWeight: "bold"}}>Discount:</td>
                                <td>{institute && institute.length && institutes.length ? institutes.find(item => item.institute === institute).discount_rate : 0}%</td>
                            </tr>
                            <tr>
                                <td style={{fontWeight: "bold"}}>Total:</td>
                                <td>{data.map(item => item.rate).reduce((a, b) => a + b, 0) * ((100 - (institute && institute.length && institutes.length ? institutes.find(item => item.institute === institute).discount_rate : 0))/100)}</td>
                            </tr>
                        </>
                        :
                        null
                        }
                    </tbody>

                </Table>
           
                <Row>
                    <Col sm="2">
                        {/* <Link to={{pathname: "/invoice", state: {data, institute: institutes.length ? institutes.find(item => item.institute === institute) : { institute, address: "abc", phone: "01234" }}}}> */}
                            <Button size="md" variant="success" disabled={!institute.length || !data.length} style={{ width: "100%"}} onClick={() => { 
                                setInStorage("order_lab_test", {data, institute: institutes.length ? institutes.find(item => item.institute === institute) : { institute, address: "abc", phone: "01234", discount_rate: 0 }});
                                props.history.push("/invoice")
                            }}>
                                <span style={{marginLeft: "5px"}}>Submit</span>
                            </Button>  
                        {/* </Link>     */}
                    </Col>
                </Row>
            </form>
        </div>
    )
}


export default geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
})(connect((state) => ({
    notifications: state.notifications,
}))(withRouter(OrderLabTest)));