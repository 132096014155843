import React, {Component} from "react";
import { Card, Table, Button, FormGroup, FormControl, FormLabel, Row, Col } from "react-bootstrap";
import key from "../../../config/key.json";
import { toast } from "react-toastify";
import { getFromStorage } from "../../../utils/storage";
import errorConfig from "../../../config/errorConfig.json";
import { withRouter } from "react-router";
import api from "../../../config/api.json";
import { CircularProgress } from "@material-ui/core";
import axios from 'axios';
import moment from 'moment';
import { ArrowRight, ArrowLeft } from "@material-ui/icons";

const color = "#229a88";

class ProviderAppointments extends Component {
    state = {
        times: ["00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00"],
        isLoading: true,
        isSearching: false,
        appointments: [],
        appointment_id: "",
        minDate: new Date(),
        maxDate: new Date(),
        time: "",
        dayAppointment: "",
        arrays: [],
        doc_id: "",
        selectDate: "",
        dates: [],
        tableData: [],
        tempTableData: [],
        isSubmitting: false,
        patient_id: "",
        doctorCalendar:[],
        appointment_time: "",
        appointment_date: "",
    }
    //Error Notification
    notifyError = error => {
        toast.error("" + error.toString());
    };

    //Success Notification
    notifySuccess = success => {
        toast.success("" + success.toString());
    };

    dateTimeChecker = item => {
        if (item.startTime < this.state.endTime && item.endTime > this.state.startTime) {
          return true;
        }
        return false;
    };

    nextWeek = () => {
        var dates = [];
        var start = moment(this.state.minDate).add(1, 'week');
        var end = moment(this.state.maxDate).add(1, 'week');

        this.setState({arrays: [], minDate: new Date(start), maxDate: new Date(end),  selectDate: `${start.format("DD/MM/YYYY")} - ${end.format("DD/MM/YYYY")}` }, () => {
            this.getDoctorCalendar()
        })
        dates.push(start.format('YYYY-MM-DD'));          
        while(!start.isSame(end.format('YYYY-MM-DD'))){
            start = start.add(1, 'days');
            dates.push(start.format('YYYY-MM-DD'));
        }
        this.setState({ dates: dates.map(item => new Date(new Date(item).setHours(0,0,0,0)) >= new Date(new Date().setHours(0,0,0,0)) ? item : null), time: "", dayAppointment: "", appointment_date: "", appointment_time: ""})
    }

    setDates = () => {
        var dates = [];
        var startDate = moment().startOf('week')
        var endDate = moment().endOf('week')
        
        this.setState({ 
            arrays: [], 
            minDate: new Date(startDate), 
            maxDate: new Date(endDate), 
            selectDate: `${startDate.format("DD/MM/YYYY")} - ${endDate.format("DD/MM/YYYY")}` 
        }, () => {
            this.getDoctorCalendar()
        })

        dates.push(startDate.format('YYYY-MM-DD'));          
        while(!startDate.isSame(endDate.format('YYYY-MM-DD'))){
            startDate = startDate.add(1, 'days');
            dates.push(startDate.format('YYYY-MM-DD'));
        }
        this.setState({ dates: dates.map(item =>new Date(new Date(item).setHours(0,0,0,0)) >= new Date(new Date().setHours(0,0,0,0)) ? item : null), time: "", dayAppointment: "", appointment_date: "", appointment_time: "" })
    }

    previousWeek = () => {
        var dates = [];
        var start = moment(this.state.minDate).subtract(1, 'week');
        var end = moment(this.state.maxDate).subtract(1, 'week');

        this.setState({arrays: [], minDate: new Date(start), maxDate: new Date(end),  selectDate: `${start.format("DD/MM/YYYY")} - ${end.format("DD/MM/YYYY")}` }, () => {
            this.getDoctorCalendar();
        })
        dates.push(start.format('YYYY-MM-DD'));          
        while(!start.isSame(end.format('YYYY-MM-DD'))){
            start = start.add(1, 'days');
            dates.push(start.format('YYYY-MM-DD'));
        }
        this.setState({ dates:  dates.map(item =>new Date(new Date(item).setHours(0,0,0,0)) >= new Date(new Date().setHours(0,0,0,0)) ? item : null), time: "", dayAppointment: "", appointment_date: "", appointment_time: ""})
    }

    getDoctorCalendar = () => {
        toast.dismiss()
        const headers = {
            headers: {"Content-Type": "application/json"}
        }
        
        const body = {
            task: "getDocCalendar",
            doctor_id: this.state.doc_id,
            start_date: moment(this.state.minDate).format("YYYY-MM-DD 12:00:00"),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }

        axios 
        .post(api.PatientApi, body, headers)
        .then(res => {
            if(res.data[0]){
                this.setState({doctorCalendar: res.data}, () => {

                    let arr1 = [], arr2 = [], arr3 = [], arr4 = [], arr5 = [], arr6 = [], arr7 = [];
                    res.data[0][0].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr1.push(this.state.times[i] + `${" AM"}`) 
                    })
                    res.data[0][1].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr1.push(this.state.times[i] + `${" PM"}`) 
                    })
                    res.data[1][0].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr2.push(this.state.times[i] + `${" AM"}`) 
                    })
                    res.data[1][1].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr2.push(this.state.times[i] + `${" PM"}`) 
                    })
                    
                    res.data[2][0].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr3.push(this.state.times[i] + `${" AM"}`) 
                    })
                    res.data[2][1].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr3.push(this.state.times[i] + `${" PM"}`) 
                    })
                    res.data[3][0].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr4.push(this.state.times[i] + `${" AM"}`) 
                    })
                    res.data[3][1].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr4.push(this.state.times[i] + `${" PM"}`) 
                    })

                    res.data[4][0].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr5.push(this.state.times[i] + `${" AM"}`) 
                    })
                    res.data[4][1].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr5.push(this.state.times[i] + `${" PM"}`) 
                    })
                    res.data[5][0].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr6.push(this.state.times[i] + `${" AM"}`) 
                    })
                    res.data[5][1].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr6.push(this.state.times[i] + `${" PM"}`) 
                    })

                    res.data[6][0].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr7.push(this.state.times[i] + `${" AM"}`) 
                    })
                    res.data[6][1].forEach((item, i) => {
                        if(parseInt(item) < 3) 
                            arr7.push(this.state.times[i] + `${" PM"}`) 
                    })
                    this.setState({arrays: [
                        arr1,
                        arr2,
                        arr3,
                        arr4,
                        arr5,
                        arr6,
                        arr7
                    ]})
                    this.setState({ isLoading: false})
                })
            } else {
                this.setState({isLoading: false})
                this.notifyError("Something went wrong")
            }
        })
        .catch(err => {
            this.setState({isLoading: false})
            this.notifyError(err.toString())
        })
    }

    setAppointment = () => {
        this.setState({ isSubmitting: true}, () => {
            if(this.state.appointment_date !== "" && this.state.appointment_time !== "") {
                const headers = {
                    headers: {"Content-Type": "application/json"}
                }
           
                const body = {
                    task: "rescheduleAppointment",
                    appointment_id: this.state.appointment_id,
                    time_slot: moment(this.state.appointment_date.split("$")[0]).format("YYYYMMDD") + moment(this.state.appointment_time, ["h:mm A"]).format("HHmm") + "00"
                }
                axios 
                .post(api.ProviderApi, body, headers)
                .then(res => {
                    if(!res.data.errorMessage){
                        this.notifySuccess("Appointment set successfully")
                        this.setState({ patient_id: "", doc_id: "" })
                    } else {
                        this.notifyError("Something went wrong")
                    }
                    this.setState({isSubmitting: false})
                })
                .catch(err => {
                    this.setState({isSubmitting: false})
                    this.notifyError(err.toString())
                })
            } else {
                this.notifyError("All Fields Required")
                this.setState({isSubmitting: false})
            }
        })
    }

    //fetch appointments
    fetchAppointmentDetail = (e) => {
        e.preventDefault();
        toast.dismiss();
        this.setState({isSearching: true }, () => { 
            const body = {
                task: 'getAppointmentDetails',
                appointment_id: this.state.appointment_id
            }
            const headers = { headers: { "Content-Type" : "application/json" } }
            axios.post(api.ProviderApi, body, headers)
            .then(res => {
                console.log(res.data)
                if(!res.data.errorMessage){
                    this.setState({appointments: res.data})
                } else {
                    this.notifyError("Not Found")
                }
                this.setState({isLoading: false, isSearching: false, appointment_id: ""})
            }
            )
            .catch(err => {
                this.notifyError(err.toString())
                this.setState({isLoading: false, isSearching: false})
            })
        })
    }

    //Remove Doctor
    removeAppointment = () => {
        toast.dismiss();
        const body = {
            task: 'removeAppointment',
            appointment_id: this.state.appointments[0].appointment_id
        }
        const headers = { headers: { "Content-Type" : "application/json" } }
        axios.post(api.ProviderApi, body, headers)
        .then(res => {
            if(res.data){
                this.setState({appointments: []})
            } else {
                this.notifyError("Not Found")
            }
            this.setState({isLoading: false, isSearching: false})
        }
        )
        .catch(err => {
            this.notifyError(err.toString())
            this.setState({isLoading: false, isSearching: false})
        })
    }

    //First function run when page load
    componentDidMount() {
        toast.dismiss();
        
        if (!getFromStorage(key.loginInfoKey)) {
            this.notifyError(errorConfig.notLoggedInError);
            this.props.history.push("/");
        } else if (getFromStorage(key.loginInfoKey).task === "authAdmin" || getFromStorage(key.loginInfoKey).task === "authDiagAdminPanel") {
            this.props.history.push("/Graph");
        } else if (getFromStorage(key.loginInfoKey).task === "patient") {
            this.props.history.push("/home");
        } else if (getFromStorage(key.loginInfoKey).task === "doctor") {
            this.props.history.push("/home");
        } else {
            this.setState({isLoading: false});
        }
    }

    render() {
        if(this.state.isLoading) {
            return (
                <div style={{marginLeft: this.props.isOpened ? (window.innerWidth > 560 ? "180px" : "20px") : "20px", marginTop: "70px", textAlign: "center"}}>
                    <CircularProgress />
                </div>
            )
        }
        return (
            <div style={{marginLeft: this.props.isOpened ? (window.innerWidth > 560 ? "180px" : "20px") : "20px", marginTop: "70px", marginRight: "20px"}}>
                <Card style={{padding: "20px", fontSize: "14px"}} className="shadow-4">
                    <h3 style={{color, textAlign: "center", fontWeight: "bold"}}>Appointments Management</h3>
                    <form>
                    {(this.state.doc_id !== "" && this.state.patient_id !== "") &&
                    <div>
                        <h4 style={{color, textAlign: "center", fontWeight: "bold", fontSize: 22}}>Reschedule Appointment</h4>
                        <br/>
                        <Row>
                            <Col sm="3">
                                <FormGroup controlId="weekly">
                                    <FormLabel style={{fontWeight: "bold"}}>Weekly<span style={{color: "red"}}>*</span></FormLabel>
                                    <FormControl 
                                        type="text" 
                                        size="sm" 
                                        name="selectDate" 
                                        disabled={false}
                                        value={this.state.selectDate} 
                                        onChange={() => {}}
                                    />
                                    <div style={{marginTop: "5px", textAlign: "center"}}>
                                        <Button variant="outline-danger" size="sm" onClick={this.previousWeek}>
                                            <ArrowLeft fontSize="small"/>
                                        </Button>
                                        <Button variant="outline-danger" size="sm" style={{marginLeft: "5px"}} onClick={this.nextWeek}>
                                            <ArrowRight fontSize="small"/>
                                        </Button>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col sm="3">
                                <FormGroup controlId="startTime">
                                    <FormLabel style={{fontWeight: "bold"}}>Date<span style={{color: "red"}}>*</span></FormLabel>
                                    <FormControl 
                                        size="sm"
                                        as="select"
                                        pattern="[0-9]*"
                                        name="appointment_date" 
                                        value={this.state.appointment_date} 
                                        onChange={(e) => { 
                                           if(e.target.validity.valid)
                                                this.setState({appointment_date: e.target.value, appointment_time: ""}) 
                                        }}
                                    >
                                        <option value="" style={{color: "silver"}}>Select Date</option>
                                        {this.state.dates.map((item, i) =>
                                        item && <option value={item + "$" + i.toString()} key={item}>{item}</option>)}
                                    </FormControl>
                                </FormGroup>
                            </Col>
                            {this.state.arrays.length > 0 &&
                            <Col sm="3">
                                <FormGroup controlId="time">
                                    <FormLabel style={{fontWeight: "bold"}}>Time<span style={{color: "red"}}>*</span></FormLabel>
                                    <FormControl
                                        as="select" 
                                        size="sm"
                                        name="time" 
                                        value={this.state.appointment_time} 
                                        onChange={(e) => this.setState({appointment_time: e.target.value})}
                                    >
                                        <option value="" style={{color: "silver"}}>Select Time</option>
                                        {
                                            this.state.appointment_date.split("$")[1] === "0" ? 
                                            this.state.arrays[0].map(item => <option key={item} value={item}>{item}</option>) : 
                                            this.state.appointment_date.split("$")[1] === "1" ? 
                                            this.state.arrays[1].map(item => <option key={item} value={item}>{item}</option>) : 
                                            this.state.appointment_date.split("$")[1] === "2" ? 
                                            this.state.arrays[2].map(item => <option key={item} value={item}>{item}</option>) : 
                                            this.state.appointment_date.split("$")[1] === "3" ? 
                                            this.state.arrays[3].map(item => <option key={item} value={item}>{item}</option>) :
                                            this.state.appointment_date.split("$")[1] === "4" ? 
                                            this.state.arrays[4].map(item => <option key={item} value={item}>{item}</option>) :  
                                            this.state.appointment_date.split("$")[1] === "5" ? 
                                            this.state.arrays[5].map(item => <option key={item} value={item}>{item}</option>) : 
                                            this.state.appointment_date.split("$")[1] === "6" && 
                                            this.state.arrays[6].map(item => <option key={item} value={item}>{item}</option>) 
                                        }
                                    </FormControl> 
                                </FormGroup>
                            </Col>}
                            <Col sm="3">
                                <Button size="sm" variant="success" disabled={this.state.isSubmitting} style={{marginTop: window.innerWidth > 560 ? "27px" : "0px", width: "100px"}} onClick={this.setAppointment}>
                                    <span style={{marginLeft: "5px"}}>{!this.state.isSubmitting ? "Confirm" : "Loading..."}</span>
                                </Button>      
                            </Col>
                        </Row>
                        </div>}
                        <br/>
                    </form>
                    <form onSubmit={this.fetchAppointmentDetail}>
                        <Row>
                            <Col sm="3">
                                <FormGroup controlId="search">
                                    <FormLabel>Search Appointment:</FormLabel>
                                    <FormControl 
                                        placeholder="Appointment ID"
                                        name="appointment"
                                        value={this.state.appointment_id}
                                        size="sm"
                                        onChange={(e) => this.setState({appointment_id: e.target.value})}
                                    />  
                                </FormGroup>
                            </Col>
                            <Col>
                                <Button size="sm" variant="success" type="submit" style={{marginTop: 27}}>
                                    Search
                                </Button>
                            </Col>
                        </Row>
                    </form>
                    {!this.state.isSearching ?
                    <div style={{overflow: "auto"}}>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr style={{color, fontWeight: "bold"}}>
                                    <th>Appointment ID</th>
                                    <th>Patient Name</th>
                                    <th>Patient Mobile Number</th>
                                    <th>Doctor's Name</th>
                                    <th>Doctor's Mobile Number</th>
                                    <th>Speciality</th>
                                    <th>Appointment Time</th>
                                    <th>Status</th>
                                    <th>Payment Status</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.appointments.map(item =>
                                        <tr key={item.appointment_id}>
                                            <td>{item.appointment_id}</td>
                                            <td>{item.patient_name}</td>
                                            <td>Patient Mobile Number</td>
                                            <td>Doctor's Name</td>
                                            <td>Doctor's Mobile Number</td>
                                            <td>Speciality</td>
                                            <td>{moment(item.start_time, "YYYYMMDDhhmmss").format("DD/MM/YYYY hh:mm A")}</td>
                                            <td>{item.status === 0 ? <span style={{color: "red"}}>Pending</span> : <span style={{color: "green"}}>Completed</span>}</td>
                                            <td>Payment Status</td>
                                            <td align="center" style={{width: "200px"}}>
                                                <Button
                                                    size="sm" 
                                                    variant="info"
                                                    onClick={() => {
                                                        this.props.history.push(`/rescheduleByDoctor/${item.appointment_id}/${item.patient_id}`);
                                                        // this.setState({patient_id: item.patient_id, doc_id: "117", appointment_id: item.appointment_id}, () => {
                                                        //     this.setDates();
                                                        // })
                                                    }} 
                                                >
                                                    <span style={{marginLeft: "5px"}}>Reschedule Appointment</span>
                                                </Button>
                                            </td>
                                            <td align="center">
                                                <Button
                                                    size="sm" 
                                                    variant="danger"
                                                    onClick={this.removeAppointment} 
                                                >
                                                    <span style={{marginLeft: "5px"}}>Remove</span>
                                                </Button>
                                            </td>
                                            
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                    </div>
                        :
                        <div style={{textAlign: "center"}}>
                            <CircularProgress />
                        </div>
                    }
                </Card>
                <br/>
            </div>
        )
    }
}

export default withRouter(ProviderAppointments);