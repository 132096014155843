import React, { useEffect, useState } from 'react';
// import * as Localization from 'expo-localization';
import { CircularProgress } from '@material-ui/core';
import { Button } from 'react-bootstrap';
import 'semantic-ui-css/semantic.min.css'
import {withRouter} from 'react-router-dom'
import { connect } from 'react-redux';
import 'tachyons'
import { DeleteOutline, ShoppingCart, ShoppingCartOutlined } from '@material-ui/icons';
import CartModal from './CartModal';
import CardDialog from './CardDialog';
import { getFromStorage, removeFromStorage } from '../../../utils/storage';
import axios from 'axios'
import Api from '../../../config/api.json';
import ConfirmDialog from './ConfirmDialog';
import { getNotifications, getToken } from '../saveToken';

const sampleData = [
    {
        id: 1,
        name: "Medicine",
        description: "Description",
        price: 100
    },
    {
        id: 2,
        name: "Medicine",
        description: "Description",
        price: 200
    },
    {
        id: 3,
        name: "Medicine",
        description: "Description",
        price: 300
    },
    {
        id: 4,
        name: "Medicine",
        description: "Description",
        price: 900
    },
    {
        id: 5,
        name: "Medicine",
        description: "Description",
        price: 1200
    },
    {
        id: 6,
        name: "Medicine",
        description: "Description",
        price: 1300
    },
    {
        id: 7,
        name: "Medicine",
        description: "Description",
        price: 1500
    },
    {
        id: 8,
        name: "Medicine",
        description: "Description",
        price: 1200
    },
    {
        id: 9,
        name: "Medicine",
        description: "Description",
        price: 1300
    },
    {
        id: 10,
        name: "Medicine",
        description: "Description",
        price: 1500
    }
]

const BuyMedicine = (props) => {
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [show, setShow] = useState(false)
    const [ids, setIds] = useState([])
    const [message, setMessage] = useState("")
    const [showCard, setShowCard] = useState(false)
    const [isPaymentConfirmed, setIsPaymentConfirmed] = useState(false)
    const [invoiceId, setInvoiceId] = useState("")
    const [showError, setShowError] = useState(false)
    const [invoice, setInvoice] = useState("")
    const [paytime, setPayTime] = useState("")
    const [status, setStatus] = useState("")
    const [isProcessing, setIsProcessing] = useState("")
    const [isFailed, setIsFailed] = useState(false)


    //Query Payment
    const queryPayment = (paymentID, token_id) => {
        const body = {
            task: "queryPayment",
            paymentID,
            token_id
        }
        const headers = {
            headers: { "Content-Type": "application/json" }
        }

        axios.post(Api.PROVIDER_API, body, headers)
            .then(res => {
                if (res.data.agreementStatus === "Completed") {
                    // confirmAndPay()
                    if(getFromStorage("invoice")) {
                        setIsPaymentConfirmed(true)
                        setInvoiceId(getFromStorage("invoice").inv)
                        setShowError(true)
                        setInvoice(getFromStorage("invoice").inv)
                        setPayTime(getFromStorage("invoice").pay)
                        setMessage("Payment Successful")
                        removeFromStorage("order_lab_test")
                    }
                } else {
                    if(getFromStorage("invoice")) {
                        setShowError(true)
                        setInvoice(getFromStorage("invoice").inv)
                        setPayTime(getFromStorage("invoice").pay)
                        setMessage("Payment didn't completed, please try again")
                        setStatus(res.data.statusMessage)
                        setIsFailed(true)
                    }
                    setIsProcessing(false)
                    removeFromStorage("card_token")
                    removeFromStorage("bkash");
                }
            })
            .catch(err => {
                setIsProcessing(false)
                alert(err.toString())
                removeFromStorage("card_token")
                removeFromStorage("bkash");
            })
    }

    //Execute Payment
    const executePayment = (paymentID, token_id) => {
        const body = {
            task: "executePayment",
            paymentID,
            token_id
        }
        const headers = {
            headers: { "Content-Type": "application/json" },
            timeout: 30000
        }

        axios.post(Api.PROVIDER_API, body, headers)
            .then(res => {
                if (res.data.statusCode === "0000") {
                    if(getFromStorage("invoice")) {
                        setIsPaymentConfirmed(true)
                        setInvoiceId(getFromStorage("invoice").inv)
                        setShowError(true)
                        setInvoice(getFromStorage("invoice").inv)
                        setPayTime(getFromStorage("invoice").pay)
                        setMessage("Payment Successful")
                        removeFromStorage("order_lab_test")
                    }
                } else {
                    setIsProcessing(false)
                    if(getFromStorage("invoice")) {
                        setShowError(true)
                        setInvoice(getFromStorage("invoice").inv)
                        setPayTime(getFromStorage("invoice").pay)
                        setMessage("Payment Failed")
                        setStatus(res.data.statusMessage)
                        setIsFailed(true)
                        removeFromStorage("bkash")
                        removeFromStorage("card_token")
                    }
                }

            })
            .catch(err => {
                queryPayment(paymentID, token_id)
            })
    }

    useEffect(() => {
        setIsLoading(false)
        if(getFromStorage("patient")) {
            setInvoiceId(new Date().getTime())
            getNotifications(props.dispatch, props.notifications);
            getToken("patients");
            if(getFromStorage("bkash") && getFromStorage("card_token")) {
                if(window.location.search) {
                    setStatus(window.location.search && window.location.search.split("&")[1].split("status=")[1])
                    if(
                        window.location.search.split("&")[1].split("status=")[1] === "success" && 
                        window.location.search.split("&")[0].split("?paymentID=")[1] === getFromStorage("bkash").paymentID
                    ) {
                        setIsProcessing(true)
                        executePayment(getFromStorage("bkash").paymentID, getFromStorage("card_token")); 
                        removeFromStorage("stripe")
                    } else if(
                        window.location.search.split("&")[1].split("status=")[1] === "cancel" && 
                        window.location.search.split("&")[0].split("?paymentID=")[1] === getFromStorage("bkash").paymentID
                    ) {
                        console.log(getFromStorage("invoice"))
                        setStatus(window.location.search.split("&")[1].split("status=")[1])
                        if(getFromStorage("invoice")) {
                            setShowError(true)
                            setInvoice(getFromStorage("invoice").inv)
                            setPayTime(getFromStorage("invoice").pay)
                            setMessage("Payment Cancelled")
                            removeFromStorage("bkash")
                            removeFromStorage("card_token")
                            removeFromStorage("stripe")
                        }
                    } else if(
                        window.location.search.split("&")[1].split("status=")[1] === "failure" && 
                        window.location.search.split("&")[0].split("?paymentID=")[1] === getFromStorage("bkash").paymentID
                    ) {
                        setStatus(window.location.search.split("&")[1].split("status=")[1])
                        if(getFromStorage("invoice")) {
                            setShowError(true)
                            setInvoice(getFromStorage("invoice").inv)
                            setPayTime(getFromStorage("invoice").pay)
                            setMessage("Payment Failed")
                            removeFromStorage("bkash")
                            removeFromStorage("card_token")
                            removeFromStorage("stripe")
                        }
                    }
                }
            } else if(getFromStorage("stripe")) {
                setShowError(true)
                setStatus(getFromStorage("stripe").paymentIntent.status === "succeeded" ? "success" : "failure")
                
                removeFromStorage("stripe")
                removeFromStorage("card_token")
                removeFromStorage("bkash")
                removeFromStorage("appointments");
            }
        } else {
            props.history.replace("/")
        }
    }, [])

    const addCart = (medicine) => {
        const temp = data;
        temp.push(medicine)
        setData([...temp])
        const id = ids;
        id.push(medicine.id)
        setIds([...id])
    } 

    const deleteCart = (medicine) => {
        setData(data.filter(item => item.id !== medicine.id))
        setIds(ids.filter(item => item !== medicine.id))
        console.log(data, ids)
    }

    if(isLoading) {
        return (
            <div style={{marginLeft: props.isOpened ? (window.innerWidth > 560 ? "160px" : "20px") : "20px", marginTop: "70px", textAlign: "center"}}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <div style={{marginLeft: props.isOpened ? (window.innerWidth > 560 ? "160px" : "20px") : "20px", marginTop: "70px", marginRight: "20px", textAlign: "center"}}>
            <Button variant="danger" style={{marginTop: 10}} onClick={() => setShow(true)}>
                <ShoppingCartOutlined />
                Cart & Checkout
            </Button>
            <br/>
            <br/>
           {sampleData.map((medicine, i) => 
                <div className="ma2 bg-white dib pa0 shadow-4" key={i} style={{width: "200px"}}>
                    <img src={`https://images.theconversation.com/files/369567/original/file-20201116-23-18wlnv.jpg?ixlib=rb-1.1.0&q=45&auto=format&w=1200&h=1200.0&fit=crop`} alt="Avatar" />
                    <div style={{padding: 10}}>
                        <div style={{fontWeight: "bold", textAlign: "left"}}>{medicine.name} {i}</div>
                        <p style={{color: "silver", fontWeight: "normal", fontSize: 12, marginTop: 10, textAlign: "left"}}>{medicine.description}</p>
                        <div style={{fontWeight: "bold", textAlign: "left", fontSize: 16}}>{medicine.price} TK</div>
                        <div style={{textAlign: "center"}}>
                            {!ids.includes(medicine.id) ?
                                <Button variant="success" size="sm" style={{marginTop: 10}} onClick={() => addCart(medicine)}>
                                    <ShoppingCart />
                                    Add To Cart
                                </Button>
                                :
                                <Button variant="danger" size="sm" style={{marginTop: 10}} onClick={() => deleteCart(medicine)}>
                                    <DeleteOutline />
                                    Delete From Cart
                                </Button>
                            }
                        </div>
                    </div>
                </div>
            )}
            {/* <br/>
            <br/>
            <Button variant="dark" style={{marginTop: 10}} onClick={() => {}}>
                Loading More
            </Button> */}
            <br/>
            <br/>
            <Button variant="danger" style={{marginTop: 10}} onClick={() => setShow(true)}>
                <ShoppingCartOutlined />
                Cart & Checkout
            </Button>
            <br/>
            <br/>
            <CardDialog id={new Date().getTime()} message={message} cost={data.map(item => item.price).reduce((a, b) => a + b, 0)} show={showCard} 
                handleClose={() => {
                    setShowCard(false)
                }} 
                handleSubmit={()=>{
                    setIds([])
                    setData([])
                }}
            />
            <CartModal show={show} showCard={showCard} setShowCard={setShowCard} handleClose={() => setShow(false)} data={data} deleteCart={deleteCart}/>
            {showError && <ConfirmDialog status={message} statuss={status} invoice={invoice} time={paytime} show={showError} message={status} handleClose={() => setShowError(false)}/>}
        </div>
    )
}


export default connect((state) => ({
    notifications: state.notifications,
}))(withRouter(BuyMedicine));