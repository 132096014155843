import React, {Component} from "react"
import { toast } from "react-toastify";
import key from "../../../config/key.json"
import { getFromStorage } from "../../../utils/storage";
import Api from "../../../config/api.json"
import { Card, Table } from "react-bootstrap";
import { withRouter } from "react-router";
import axios from 'axios'
import { CircularProgress } from "@material-ui/core";
import moment from 'moment'
import { Button } from "react-bootstrap";
import { VideoCall } from "@material-ui/icons";

const color = "#229a88";
class FreeAppointmentRequest extends Component{
    state = {
        requests: [],
        isLoading: true,
        processing: []
    }

    //Error Notification
    notifyError = error => {
        toast.error("" + error.toString());
    };

    //show pending appointments
    showPendingAppointments = async (i) => {
        try {
            const data = {
                task: "showPendingAppointmentRequests",
                provider_id: getFromStorage("provider")
            }
    
            const res = await axios.post(Api.ProviderApi, data)
            
            if(!res.data.errorMessage) {
                this.setState({requests: res.data})
                let temp = []
                res.data.forEach((item, i) => {
                    temp.push(false)
                    if(i === res.data.length - 1) {
                        this.setState({processing: temp})
                    }
                })
            } else {
                this.notifyError(res.data.errorMessage)
            }
            this.setState({isLoading: false})
            if(i) {
                let temp1 = this.state.processing
                temp1[i] = false
                this.setState({processing: temp1})
            }
        } catch(err) {
            this.notifyError(err.toString())
            this.setState({isLoading: false})
            let temp1 = this.state.processing
            temp1[i] = false
            this.setState({processing: temp1})
        }
    }

    //Accept or Reject
    acceptOrReject = async (request_id, action, i, doctor_id, patient_id, time_slot) => {
        toast.dismiss()
        try {
            let temp = this.state.processing
            temp[i] = true
            this.setState({processing: temp})
            const data = {
                task: "processPendingAppointmentRequests",
                request_id,
                doctor_id,
                patient_id,
                time_slot: moment(time_slot).format("YYYYMMDD") + moment(time_slot, ["h:mm A"]).format("HHmm"),
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                action
            }
    
            const res = await axios.post(Api.ProviderApi, data)
      
            if(!res.data.errorMessage) {
                toast.success(`Successfully ${action === 1 ? "Accepted" : "Rejected"}`)
                this.showPendingAppointments(i)
            } else {
                this.notifyError(res.data.errorMessage)
                let temp1 = this.state.processing
                temp1[i] = false
                this.setState({processing: temp1})
            }
        } catch(err) {
            this.notifyError(err.toString())
            let temp1 = this.state.processing
            temp1[i] = false
            this.setState({processing: temp1})
        }
    }

    //Notification Request
    notificationRequest = (token, type, id, notification_id, request_id) => {
        const headers = {
        "Content-Type": "application/json",
        Authorization:
            "key=AAAAcHXSJak:APA91bFW5NnyfjZx4wYC7soTVuCblryMMawUSVAGezx_E4NVLtB50BF6orjSwvUoTztk4o43JrjGvYM3rx_JgpMErpTPBA6xpe8hHfuyKCSOG9OohBkE2hQNeHMt7mlRHnjwsn12Vr5Z",
        };
        const body = {
            notification: {
                title: "SemDoc",
                body: `Admin is calling you for free appointment request`
            },
            data: {
                id,
                notification_id,
                appointment_id: request_id,
                date: ``,
                type,
                role: "provider",
                meetingName: request_id,
                doctor_id: getFromStorage("provider"),
                patient_id: id,
            },
            android: {
                priority: "high"
            },
            priority: 10,
            to: token,
        };

        console.log(body)

        fetch("https://fcm.googleapis.com/fcm/send", {
            method: "POST",
            body: JSON.stringify(body),
            headers,
        })
        .then((res) => res.json())
        .then((res) => {
            if (res.success === 1) {
                console.log(res)
            }
        })
        .catch((err) => {
            console.log(err.response);
            this.notifyError(err.toString());
        });
    };

    //Send notification
    sendNotification = async (type, id, request_id) => {
        try {
            const body = {task: "getPatientDeviceToken", patient_id: id}
            const res = await axios.post(Api.DoctorApi, body)
            console.log(res.data)
            if(res.data && res.data.length)
            this.notificationRequest(res.data[0].device_token, type, id, new Date().getTime(), request_id)
        } catch (err) {
        console.log(err)
        }
    };

    //First function run when page load
    componentDidMount() {
        toast.dismiss();
        
        if (!getFromStorage(key.loginInfoKey)) {
            this.notifyError(Api.notLoggedInError);
            this.props.history.push("/");
        } else if (getFromStorage(key.loginInfoKey).task === "authAdmin" || getFromStorage(key.loginInfoKey).task === "authDiagAdminPanel") {
            this.props.history.push("/Graph");
        } else if (getFromStorage(key.loginInfoKey).task === "patient") {
            this.props.history.push("/home");
        } else if (getFromStorage(key.loginInfoKey).task === "doctor") {
            this.props.history.push("/home");
        } else if (getFromStorage(key.loginInfoKey).task === "provider") {
            this.showPendingAppointments()
        }
    }
    render(){
        if(this.state.isLoading) {
            return (
                <div style={{marginLeft: this.props.isOpened ? (window.innerWidth > 560 ? "180px" : "20px") : "20px", marginTop: "70px", textAlign: "center"}}>
                    <CircularProgress />
                </div>
            )
        }
        if (getFromStorage(key.loginInfoKey).task === "provider") {
            return (
                <div style={{marginLeft: this.props.isOpened ? (window.innerWidth > 560 ? "180px" : "20px") : "20px", marginTop: "70px", marginRight: "20px"}}>
                    <Card style={{padding: "20px", fontSize: "14px"}} className="shadow-4">
                        <h3 style={{color, textAlign: "center", fontWeight: "bold"}}>Free Appointment Requests</h3>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr style={{color, fontWeight: "bold"}}>
                                    <th>Id</th>
                                    <th>Appointment Date</th>
                                    <th>Patient Id</th>
                                    <th>Patient Phone</th>
                                    <th>Doctor Id</th>
                                    <th style={{textAlign: "center"}}>Action</th>
                                    <th style={{textAlign: "center"}}>Call</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.requests.map((item, i) => 
                                    <tr key={item.request_id}>
                                        <td>{item.request_id}</td>
                                        <td>{moment(item.appointment_dt).format("DD/MM/YYYY hh:mm:ss A")}</td>
                                        <td>{item.patient_id}</td>
                                        <td>{item.patient_phone}</td>
                                        <td>{item.doctor_id}</td>
                                        <td style={{textAlign: "center"}}>
                                            {!this.state.processing[i] ?
                                                <>
                                                    <Button size="sm" variant="success" onClick={() => this.acceptOrReject(item.request_id, 1, i, item.doctor_id, item.patient_id, item.appointment_dt)}>
                                                        Accept
                                                    </Button>
                                                    <Button size="sm" variant="danger" style={{marginLeft: 10}} onClick={() => this.acceptOrReject(item.request_id, 2, i, item.doctor_id, item.patient_id, item.appointment_dt)}>
                                                        Reject
                                                    </Button>
                                                </>
                                                :
                                                <div>Loading...</div>
                                            }
                                        </td>
                                        <td style={{textAlign: "center"}}>
                                            <Button
                                                onClick={() => {
                                                    this.sendNotification(
                                                        "patients",
                                                        item.patient_id,
                                                        item.request_id
                                                    );
                                                
                                                    const meeting_id = item.request_id
                                                    const user_id = getFromStorage("provider")
                                                    
                                                    window.open(`/#/devices/${meeting_id}/${user_id}`)
                                                    
                            
                                                    }}
                                                    size="sm"
                                                    variant="success"
                                                    style={{
                                                        margin: "5px",
                                                        width: "170px",
                                                        color: "white",
                                                        textTransform: "none",
                                                    }}
                                            >
                                                <VideoCall fontSize="small" />
                                                <span style={{ marginLeft: "5px" }}>Call Now</span>
                                            </Button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Card>
                </div>
            )
        }
        return <div></div>
    }
}

export default withRouter(FreeAppointmentRequest)
