import React, { useEffect, useState } from 'react';
import axios from 'axios'
import Api from '../../../config/api.json'
import { CircularProgress } from '@material-ui/core';
import { Button, Col, FormLabel, Row, Table } from 'react-bootstrap';
import { getFromStorage, setInStorage } from '../../../utils/storage';
import { useParams, withRouter, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { getNotifications, getToken } from '../saveToken';


const PatientDigitalPrescription = (props) => {
    const [tests, setTests] = useState([])
    const {appointment_id} = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const [medicineData, setMedicineData] = useState([])
    const [complaint, setComplaint] = useState("")
    const [comment, setComment] = useState("")
    const [examinations, setExaminations] = useState({otherExams: []})
    const [sickness, setSickness] = useState({dm: false, htn: false, bronchial: false, smoking: false, dysilipidemia: false})
    const [advices, setAdvices] = useState("")
    const [followUp, setFollowUp] = useState("")
    const [diagnosis, setDiagnosis] = useState("")

    //get prescription
    const getPrescription = () => {
        const body = {
            task: "getPrescription",
            appointment_id: appointment_id.toString()
        }
        axios.post(Api.TEST_API, body)
        .then(res => {
            if(res.data && res.data.length) {
                setComplaint(res.data[res.data.length-1].complaint)
                setComment(res.data[res.data.length-1].comments)
                setTests(JSON.parse(res.data[res.data.length-1].diagnostic_tests))
                setMedicineData(JSON.parse(res.data[res.data.length-1].medicines))
                setExaminations(JSON.parse(res.data[res.data.length-1].examinations))
                // setSickness(JSON.parse(res.data[res.data.length-1].sickness))
                setAdvices(res.data[res.data.length-1].advices)
                setFollowUp(res.data[res.data.length-1].followup)
                setDiagnosis(res.data[res.data.length-1].diagnoses)
                setIsLoading(false)
            } else {
                setIsLoading(false)
            }
        })
        .catch(err => {
            console.log(err.toString())
            setIsLoading(false)
        })
    }

    //This function will run first
    useEffect(() => {
        if(getFromStorage("patient")) {
            getNotifications(props.dispatch, props.notifications);
            getToken("patients");
            getPrescription()
        } else {
            props.history.replace("/")
        }
    }, [])

    if(isLoading) {
        return (
            <div style={{marginLeft: props.isOpened ? (window.innerWidth > 560 ? "160px" : "20px") : "20px", marginTop: "70px", textAlign: "center"}}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <div style={{marginLeft: props.isOpened ? (window.innerWidth > 560 ? "160px" : "20px") : "20px", marginTop: "70px", marginRight: "20px"}}>
            <form>
                <div style={{marginTop: 10}}><span style={{fontWeight: "bold",fontSize: 18}}>Chief Complaint</span> </div><div><span>{complaint}</span></div>
                <br/>
                <div style={{marginTop: 10}}><span style={{fontWeight: "bold",fontSize: 18}}>History</span> </div><div><span>{comment}</span></div>
                <br/>
                <div style={{fontWeight: "bold",fontSize: 18}}>On Examination</div>
                <br/>
                <table style={{marginLeft: -14}}>
                    <tr style={{marginTop: 10}}><td style={{fontWeight: "bold", fontSize: 14}}>Weight: </td> <td style={{fontSize: 14}}>{examinations.weight}</td></tr>
                    <tr style={{marginTop: 10}}><td style={{fontWeight: "bold", fontSize: 14}}>BP: </td> <td style={{fontSize: 14}}>{examinations.bp}</td></tr>
                    <tr style={{marginTop: 10}}><td style={{fontWeight: "bold", fontSize: 14}}>Pulse: </td> <td style={{fontSize: 14}}>{examinations.pulse}</td></tr>
                    <tr style={{marginTop: 10}}><td style={{fontWeight: "bold", fontSize: 14}}>Body Temperature: </td> <td style={{fontSize: 14}}>{examinations.bodyTemperature}</td></tr>
                    {examinations.otherExams.map((item, i) =>
                        <>
                            <tr style={{marginTop: 10}}><td style={{fontWeight: "bold", fontSize: 14}}>{item.name}: </td> <td style={{fontSize: 14}}>{item.value}</td></tr>
                        </>
                    )}
                </table>
                <br/>
                <div style={{fontWeight: "bold",fontSize: 18}}>Diagnosis</div>
                <div><span>{diagnosis}</span></div>
                <br/>
                <div style={{fontWeight: "bold",fontSize: 18}}>Investigation</div>
                <br/>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Ser</th>
                            <th>Name of Test</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tests.map((item, i) =>
                                <tr key={i}>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                </tr>
                            )
                        }
                    </tbody>

                </Table>
                <br/>
                <div style={{fontWeight: "bold",fontSize: 18}}>Medication</div>
                <br/>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Ser</th>
                            <th>Name of Medicine</th>
                            <th>Dosage</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            medicineData.map((item, i) =>
                                <tr key={i}>
                                    <td>{item.id}</td>
                                    <td>{item.drugname}</td>
                                    <td>{item.dosage}</td>
                                </tr>
                            )
                        }
                    </tbody>

                </Table>
                <br/>
                <div><span style={{fontWeight: "bold", fontSize: 18}}>Advices</span> </div><div><span>{advices}</span></div>
                <div style={{marginTop: 10}}><span style={{fontWeight: "bold"}}>Next Follow Up</span> </div><div><span>{followUp}</span></div>
                <br/>
                {tests.length ? 
                <Row>
                    <Col sm="2">
                        <Link to={{pathname: `/order_lab_test`, state: { appointment_id, tests }}}>
                            <Button size="md" variant="success" style={{ width: "100%"}}>
                                <span style={{marginLeft: "5px"}}>Order Tests</span>
                            </Button>  
                        </Link>
                    </Col>
                </Row> : null}
                <br/>
            </form>
        </div>
    )
}


export default connect((state) => ({
    notifications: state.notifications,
}))(withRouter(PatientDigitalPrescription));