import React, { useState } from "react";
import { Button, FormGroup, FormControl, FormLabel, Alert, Row, Col } from "react-bootstrap";
import { NavLink, withRouter } from "react-router-dom";
import axios from 'axios';
import Api from "../../config/api.json"
import validator from "validator"
import errorConfig from "../../config/errorConfig.json"
import 'react-phone-input-2/lib/style.css'
import { md5Password } from "../../utils/md5";
const color = "#229a88";

function ProviderSignup(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [address, setAddress] = useState("");
  
  function validateForm() {
    return name.length > 0 && password.length > 0 && retypePassword.length > 0 && number.length > 0;
  }

  function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);

    if(password !== retypePassword) {
        setErrors(["Passwords are not equal"])
        setSuccess("");
        setIsSubmitting(false);
    } else if (email.length > 0 && !validator.isEmail(email)){
        setErrors(["Email not valid"]);
        setSuccess("");
        setIsSubmitting(false);
    }  else if (!validator.isMobilePhone(number)){
        setErrors(["Phone Number not valid"]);
        setSuccess("");
        setIsSubmitting(false);
    } else {
        signup();
    }
  }

    const signup = () => {
        const body = {task: "registerProvider", name, email, phone: number, address, password: md5Password(password)}
        const headers = {"Content-Type": "application/json"}
        axios.post(Api.ProviderApi, body, headers)
        .then(res => {
            if(res.data === 200){
                setName("");
                setEmail("")
                setPassword("")
                setRetypePassword("")
                setNumber("")
                setAddress("")
                setIsSubmitting(false)
                setSuccess("Successfully Signed Up You can now log in")
                setErrors([])
            } else {
                setErrors(["Error! Couldn't Signup"])
                setSuccess("")
                setIsSubmitting(false)
            }
        })
        .catch(err => { 
            setErrors(["Something went wrong"])
            setSuccess("")
            setIsSubmitting(false)
        })
    }

  return (
    <div className="Login" style={{textAlign: "center"}}>
      <div style={{backgroundColor: "white", padding: 20, borderRadius: 10, marginLeft: "20px", marginRight: "20px", marginTop: "40px"}} className="ma2 pa4 shadow-4">
        <h3 style={{textAlign: "center", color, fontWeight: "bold", fontSize: 22}}>Provider Signup</h3>
        <br/>
        <form onSubmit={handleSubmit}>
            {
                errors.map((error,i) =>
                    <Alert key={i} variant="danger" style={{fontSize: "12px"}}>
                        {error}
                    </Alert>
                )
            }
            {
                success.length > 0 && 
                <Alert variant="success" style={{fontSize: "12px"}}>
                    {success}
                </Alert>
            }
            <Row>
            <FormGroup as={Col} sm="6" controlId="name" bssize="large" style={{textAlign: "left"}}>
                <FormLabel>Name<span style={{color: "red"}}>*</span></FormLabel>
                <FormControl
                    type="text"
                    placeholder="Name"
                    value={name}
                    size="sm"
                    onChange={e => {
                        setName(e.target.value);
                        setSuccess("");
                        setErrors([]);
                    }}
                />
            </FormGroup>
            
            <FormGroup as={Col} sm="6" controlId="email" bssize="large" style={{textAlign: "left"}}>
                <FormLabel>Email</FormLabel>
                <FormControl
                    type="email"
                    placeholder="Email"
                    value={email}
                    size="sm"
                    onChange={e => {
                        setEmail(e.target.value);
                        setSuccess("");
                        setErrors([]);
                    }}
                />
            </FormGroup>
            </Row>
            <Row>
            <FormGroup as={Col} sm="9" controlId="address" bssize="large" style={{textAlign: "left"}}>
                <FormLabel>Address</FormLabel>
                <FormControl
                    type="text"
                    placeholder="Address"
                    value={address}
                    size="sm"
                    onChange={e => {
                        setAddress(e.target.value);
                        setSuccess("");
                        setErrors([]);
                    }}
                />
            </FormGroup>
            <FormGroup as={Col} sm="3" controlId="phone_number" bssize="large" style={{textAlign: "left"}}>
                <FormLabel>Phone Number<span style={{color: "red"}}>*</span></FormLabel>
                <FormControl
                    name="number"
                    type="text"
                    size="sm"
                    placeholder="Phone (eg. 01712345678)"
                    pattern="[0-9]*"
                    style={{
                    color: "black",
                    display: "inline"
                    }}
                    value={number}
                    onChange={(e) => {
                    if(e.target.validity.valid){
                        setNumber(e.target.value)
                        setSuccess("");
                        setErrors([]);
                    }
                    }}
                />
            </FormGroup>
            </Row>
            <Row>
            <FormGroup as={Col} sm="6" controlId="password" bssize="large" style={{textAlign: "left"}}>
                <FormLabel>Password<span style={{color: "red"}}>*</span></FormLabel>
                <FormControl
                    value={password}
                    size="sm"
                    onChange={e => {
                        setPassword(e.target.value);
                        setSuccess("");
                        setErrors([]);
                    }}
                    placeholder="Password"
                    type="password"
                />
            </FormGroup>
            <FormGroup as={Col} sm="6" controlId="retypassword" bssize="large" style={{textAlign: "left"}}>
                <FormLabel>Re-Enter Password<span style={{color: "red"}}>*</span></FormLabel>
                <FormControl
                    value={retypePassword}
                    onChange={e => {
                        setRetypePassword(e.target.value);
                        setSuccess("");
                        setErrors([]);
                    }}
                    size="sm"
                    placeholder="Re-Enter Password"
                    type="password"
                />
            </FormGroup>
            </Row>
            <Button block bssize="large" disabled={!validateForm() || isSubmitting ? true:false} type="submit" style={{background: color, borderColor: color, width: "auto", marginLeft: "auto", marginRight: "auto"}} variant="dark">
                {!isSubmitting ? "Signup" : "Loading..."}
            </Button>
            <FormGroup style={{textAlign: "center", marginTop: "10px", marginBottom: "-10px"}}>
                <FormLabel>
                    <span style={{color: "gray"}}>Already have an account?</span> {" "}
                    <NavLink to="/">
                        <span style={{color: color, fontWeight: "bold", cursor: "pointer"}}>Login</span>
                    </NavLink>
                </FormLabel>
            </FormGroup>
        </form>
      </div>
    </div>
  );
}

export default withRouter(ProviderSignup);