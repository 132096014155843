import React, {Component} from "react";
import { Card } from "react-bootstrap";
import key from "../../../config/key.json";
import { toast } from "react-toastify";
import { getFromStorage } from "../../../utils/storage";
import errorConfig from "../../../config/errorConfig.json";
import { withRouter } from "react-router";
import { CircularProgress } from "@material-ui/core";
import { getToken, getNotifications } from "../saveToken";
import { connect } from "react-redux";
import moment from "moment";
import fire from "../../../firebase/fire";
import { updateNotificationAction } from "../../../actions/notificationAction";
// const color = "#229a88";

class Alerts extends Component {
    state = {
        appointmentsUpcoming: [],
        appointmentsCompleted: [],
        isLoading: true
    }

    notifyError = (err) => {
        toast.error(err)
    }

    markAsRead = (id) => {
        fire.database().ref(`notifications/${id}`).once("value").then(snapshot => {
            if(snapshot.val()){
                fire.database().ref(`notifications/${id}`).set({
                    read: true,
                    appointment_id: snapshot.val().appointment_id,
                    body: snapshot.val().body,
                    create_date: snapshot.val().create_date,
                    doctor_id: snapshot.val().doctor_id,
                    time: snapshot.val().time
                })
                this.props.dispatch(updateNotificationAction(id))
            }
        })
        .catch(err => this.notifyError(err.toString()))
    }

    //First function run when page load
    componentDidMount() {
        toast.dismiss();
        
        if (!getFromStorage(key.loginInfoKey)) {
            this.notifyError(errorConfig.notLoggedInError);
            this.props.history.push("/");
        } else if (getFromStorage(key.loginInfoKey).task === "authAdmin" || getFromStorage(key.loginInfoKey).task === "authDiagAdminPanel") {
            this.props.history.push("/Graph")
        } else if (getFromStorage(key.loginInfoKey).task === "patient") {
            getToken("patients")
            getNotifications(this.props.dispatch, this.props.notifications, () => this.setState({isLoading: false}))
        } else if (getFromStorage(key.loginInfoKey).task === "doctor") {
            getToken("doctors")
            getNotifications(this.props.dispatch, this.props.notifications, () => this.setState({isLoading: false}))
        } else if (getFromStorage(key.loginInfoKey).task === "provider") {
            this.props.history.push("/provider_doctors")
        }
    }

    render() {
        if(this.state.isLoading) {
            return (
                <div style={{marginLeft:    this.props.isOpened ? (window.innerWidth > 560 ? "160px" : "20px") : "20px", marginTop: "70px", textAlign: "center"}}>
                    <CircularProgress />
                </div>
            )
        }
        return (
            <div style={{marginLeft: this.props.isOpened ? (window.innerWidth > 560 ? "160px" : "20px") : "20px", marginTop: "70px", marginRight: "20px"}}>
                {this.props.notifications.length === 0 ? 
                <Card style={{padding: "20px", fontSize: "14px"}} className="shadow-4">
                    <div style={{textAlign: "center"}}>
                        No Notifications
                    </div>
                </Card>
                :
                this.props.notifications.map(item => 
                <Card style={{backgroundColor: !item.read ? "#add8e6" : "white",cursor: "pointer", padding: "20px", fontSize: "14px", marginBottom: "10px"}} key={item.id} className="shadow-4" onClick={() =>{
                    this.markAsRead(item.id);
                    this.props.history.push(`/appointments/call/${item.appointment_id}/${item.time}/${item.doctor_id}`)}}
                >
                    <div style={{textAlign: "left"}}>
                        {item.body}
                    </div>
                    <div style={{color: "gray"}}>
                        {moment(item.create_date).fromNow()}
                    </div>
                </Card>).reverse()
                }
            </div>
        )
    }
}

export default connect(state => ({
    notifications: state.notifications
}))(withRouter(Alerts));