import React, {Component} from "react";
import { Card, Table,  Tabs, Tab } from "react-bootstrap";
import key from "../../../config/key.json";
import { toast } from "react-toastify";
import { getFromStorage } from "../../../utils/storage";
import errorConfig from "../../../config/errorConfig.json";
import { withRouter } from "react-router";
import api from "../../../config/api.json";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";

const color = "#229a88";

class AppointmentList extends Component {
    state = {
        appointmentsUpcoming: [],
        appointmentsCompleted: [],
        isLoading: true
    }
    //Error Notification
    notifyError = error => {
        toast.error("" + error.toString());
    };

    //fetch appointments
    fetchAppointmentsUpcoming = () => {
        const body = {
            task: 'getAppointmentList',
            doctor_id: this.props.location.pathname.split("/")[this.props.location.pathname.split("/").length - 1],
            status: "0",
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        const headers = { headers: { "Content-Type" : "application/json" } }
        axios.post(api.DoctorApi, body, headers)
        .then(res => {
            if(res.data.length){
                this.fetchAppointmentsCompleted()
                this.setState({appointmentsUpcoming: res.data})
            } else {
                this.setState({isLoading: false})
            }
        }
        )
        .catch(err => {
            this.notifyError(err.toString())
            this.setState({isLoading: false})
        })
    }

     //fetch appointments
     fetchAppointmentsCompleted = () => {
        const body = {
            task: 'getAppointmentList',
            doctor_id: this.props.location.pathname.split("/")[this.props.location.pathname.split("/").length - 1],
            status: "1",
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        const headers = { headers: { "Content-Type" : "application/json" } }
        axios.post(api.DoctorApi, body, headers)
        .then(res => {
            
            if(res.data.length){
                this.setState({appointmentsCompleted: res.data})
            } 
            this.setState({isLoading: false})
        }
        )
        .catch(err => {
            this.notifyError(err.toString())
            this.setState({isLoading: false})
        })
    }

    //fetch appointments
    fetchAppointmentsUpcomingPatient = () => {
        const body = {
            task: 'showAppointments',
            patient_id: this.props.location.pathname.split("/")[this.props.location.pathname.split("/").length - 1],
            status: "0",
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        const headers = { headers: { "Content-Type" : "application/json" } }
        axios.post(api.PatientApi, body, headers)
        .then(res => {
            if(res.data.length){
                this.fetchAppointmentsCompletedPatient()
                this.setState({appointmentsUpcoming: res.data})
            } else {
                this.setState({isLoading: false})
            }
        }
        )
        .catch(err => {
            this.notifyError(err.toString())
            this.setState({isLoading: false})
        })
    }

     //fetch appointments
     fetchAppointmentsCompletedPatient = () => {
        const body = {
            task: 'showAppointments',
            patient_id: this.props.location.pathname.split("/")[this.props.location.pathname.split("/").length - 1],
            status: "1",
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        const headers = { headers: { "Content-Type" : "application/json" } }
        axios.post(api.PatientApi, body, headers)
        .then(res => {
            
            if(res.data.length){
                this.setState({appointmentsCompleted: res.data})
            } 
            this.setState({isLoading: false})
        }
        )
        .catch(err => {
            this.notifyError(err.toString())
            this.setState({isLoading: false})
        })
    }


    //First function run when page load
    componentDidMount() {
        toast.dismiss();
        
        if (!getFromStorage(key.loginInfoKey)) {
            this.notifyError(errorConfig.notLoggedInError);
            this.props.history.push("/");
        } else if (getFromStorage(key.loginInfoKey).task === "authAdmin" || getFromStorage(key.loginInfoKey).task === "authDiagAdminPanel") {
            this.props.history.push("/Graph")
        } else if (getFromStorage(key.loginInfoKey).task === "patient") {
            this.props.history.push("/home")
        } else if (getFromStorage(key.loginInfoKey).task === "doctor") {
            this.props.history.push("/home")
        } else if (getFromStorage(key.loginInfoKey).task === "provider") {
            if(this.props.location.pathname.split("/")[1] === "patient") {
                this.fetchAppointmentsUpcomingPatient();
            }
            else {     
                this.fetchAppointmentsUpcoming();
            }
        }
    }

    render() {
        if(this.state.isLoading) {
            return (
                <div style={{marginLeft: this.props.isOpened ? (window.innerWidth > 560 ? "180px" : "20px") : "20px", marginTop: "70px", textAlign: "center"}}>
                    <CircularProgress />
                </div>
            )
        }
        return (
            <div style={{marginLeft: this.props.isOpened ? (window.innerWidth > 560 ? "180px" : "20px") : "20px", marginTop: "70px", marginRight: "20px"}}>
                <Card style={{padding: "20px", fontSize: "14px"}} className="shadow-4">
                    <Tabs defaultActiveKey="upcoming_appointment" variant="pills" id="uncontrolled-tab-example" style={{marginLeft: "2px", marginRight: "10px"}}>
                        <Tab eventKey="upcoming_appointment" title="Upcoming Appointments">
                            <br/>
                            <div style={{overflow: "auto"}}>
                                <Table striped bordered hover size="sm">
                                    <thead>
                                        <tr style={{color}}>
                                            <th>Appointment No.</th>
                                            <th>Appointment Date</th>
                                            <th>Appointment Time</th>
                                            <th>{this.props.location.pathname.split("/")[1] === "patient" ? "Doctor Id" : "Patient Id"}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.appointmentsUpcoming.map(item =>
                                                <tr key={item.appointment_id}>
                                                    <td>{item.appointment_id}</td>
                                                    <td>{moment(item.start_time, "YYYYMMDD").format("DD/MM/YYYY")}</td>
                                                    <td>{moment(item.start_time, "YYYYMMDDhhmmss").format("hh:mm A")}</td>
                                                    <td>{this.props.location.pathname.split("/")[1] === "patient" ? item.doctor_id: item.patient_id}</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                                {this.state.appointmentsUpcoming.length === 0 &&
                                <div style={{textAlign: "center"}}>
                                    No Upcoming Appointments Available
                                </div>}
                            </div>
                        </Tab>
                        <Tab eventKey="completed_appointment" title="Completed Appointments">
                            <br/>
                            <div style={{overflow: "auto"}}>
                                <Table striped bordered hover size="sm">
                                    <thead>
                                        <tr style={{color}}>
                                            <th>Appointment No.</th>
                                            <th>Appointment Date</th>
                                            <th>Appointment Time</th>
                                            <th>{this.props.location.pathname.split("/")[1] === "patient" ? "Doctor Id" : "Patient Id"}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.appointmentsCompleted.map(item =>
                                                <tr key={item.appointment_id}>
                                                    <td>{item.appointment_id}</td>
                                                    <td>{moment(item.start_time, "YYYYMMDD").format("DD/MM/YYYY")}</td>
                                                    <td>{moment(item.start_time, "YYYYMMDDhhmmss").format("hh:mm A")}</td>
                                                    <td>{getFromStorage(key.loginInfoKey).task === "patient" ? item.doctor_id: item.patient_id}</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                                {this.state.appointmentsCompleted.length === 0 &&
                                <div style={{textAlign: "center"}}>
                                    No Completed Appointments Available
                                </div>}
                            </div>
                        </Tab>
                    </Tabs>
                    
                </Card>
            </div>
        )
    }
}

export default withRouter(AppointmentList);