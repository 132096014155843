import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ringtone from './ringtone.mp3'
import ReactAudioPlayer from 'react-audio-player';
import { withRouter } from 'react-router';

class CallDialog extends Component {

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.handleClose}>
                
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom: "-100px"}}>Call</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <ReactAudioPlayer
                        src={ringtone}
                        autoPlay={true}
                        controls={false}
                    />
                    {this.props.message ? `Doctor is Calling for appointment no.${this.props.message.appointment_id}...` : ""} 
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" size="sm" onClick={() => {
                            this.props.handleClose()
                            const meeting_id = this.props.message.appointment_id;
                            const user_id = this.props.message.patient_id
                        
                            window.open(`/#/devices/${meeting_id}/${user_id}`)
                        }}
                    >
                        Connect
                    </Button>
                    <Button variant="danger" size="sm" onClick={this.props.handleClose}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default withRouter(CallDialog)